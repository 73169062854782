import { t } from 'i18next';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Chat from './features/chat/pages/Chat';
import CompletedLessons from './features/completedLessons/CompletedLessons';
import Dashboard from './features/dashboard/Dashboard';
import Earnings from './features/earnings/Earnings';
import ResetPassword from './features/forgot-password/ResetPassword';
import MyBookings from './features/my-bookings/MyBookings';
import AdditionalInformation from './features/my-profile/pages/AdditionalInformation';
import ChildInformations from './features/my-profile/pages/ChildInformations';
import GeneralAvailability from './features/my-profile/pages/GeneralAvailability';
import MyTeachings from './features/my-profile/pages/MyTeachings';
import PersonalInformation from './features/my-profile/pages/PersonalInformation';
import ProfileAccount from './features/my-profile/pages/ProfileAccount';
import MyReviews from './features/myReviews/MyReviews';
import Notifications from './features/notifications/Notifications';
import Onboarding from './features/onboarding';
import SearchTutors from './features/searchTutors/SearchTutors';
import TutorProfile from './features/searchTutors/TutorProfile';
import TutorManagment from './features/tutor-managment/TutorManagment';
import TutorManagmentProfile from './features/tutor-managment/TutorProfile';
import { Role } from './types/role';
import ResetToken from './pages/ResetToken';
import StripeConnected from './pages/StripeConnected';
import StripeFail from './pages/StripeFail';
import PermissionsGate from './components/PermissionGate';
import { AdminTutorVideoPage } from './components/admin/tutor-video/AdminTutorVideoPage';
import TokenNotValid from './pages/TokenNotValid';
import TutorBookingsNew from './features/tutor-bookings/TutorBookingsNew';
import { StudentManagement } from './features/student-management/StudentManagement';
import { StudentProfile } from './features/student-management/StudentProfile';
import { BookingManagement } from './features/booking-management/BookingManagement';
import useMount from './utils/useMount';
import TutorOnboarding from './features/onboarding/tutor';
import TutorOnboardingProvider from './features/onboarding/tutor/providers/TutorOnboardingProvider';
import useSyncLanguage from './utils/useSyncLanguage';
import { useAppSelector } from './store/hooks';

export const PATHS = {
    FORGOT_PASSWORD: t('PATHS.FORGOT_PASSWORD'),
    RESET_PASSWORD: t('PATHS.RESET_PASSWORD'),
    MY_BOOKINGS: t('PATHS.MY_BOOKINGS'),
    SEARCH_TUTORS: t('PATHS.SEARCH_TUTORS'),
    SEARCH_TUTORS_TUTOR_PROFILE: t('PATHS.SEARCH_TUTORS_TUTOR_PROFILE'),
    STUDENT_PROFILE: t('PATHS.STUDENT_PROFILE'),
    SEARCH_TUTORS_TUTOR_BOOKINGS: t('PATHS.SEARCH_TUTORS_TUTOR_BOOKINGS'),
    MY_REVIEWS: t('PATHS.MY_REVIEWS'),
    COMPLETED_LESSONS: t('PATHS.COMPLETED_LESSONS'),
    CHAT: t('PATHS.CHAT'),
    DASHBOARD: t('PATHS.DASHBOARD'),
    NOTIFICATIONS: t('PATHS.NOTIFICATIONS'),
    EARNINGS: t('PATHS.EARNINGS'),
    TERMS: t('PATHS.TERMS'),
    PRIVACY: t('PATHS.PRIVACY'),
    TUTOR_MANAGMENT: t('PATHS.TUTOR_MANAGMENT'),
    STUDENT_MANAGEMENT: t('PATHS.STUDENT_MANAGEMENT'),
    BOOKING_MANAGEMENT: t('PATHS.BOOKING_MANAGEMENT'),
    TUTOR_VIDEOS: t('PATHS.TUTOR_VIDEOS'),
    TUTOR_MANAGMENT_TUTOR_PROFILE: t('PATHS.TUTOR_MANAGMENT_TUTOR_PROFILE'),
    RESEND_ACTIVATION_TOKEN: t('PATHS.RESEND_ACTIVATION_TOKEN'),
    STRIPE_CONNECTED: t('PATHS.STRIPE_CONNECTED'),
    STRIPE_FAIL: t('PATHS.STRIPE_FAIL'),
    TOKEN_NOT_VALID: t('PATHS.TOKEN_NOT_VALID'),
};

export const LANDING_PATHS = {
    HOW_IT_WORKS: t('PATHS.LANDING_PATHS.HOW_IT_WORKS'),
    BECOME_TUTOR: t('PATHS.LANDING_PATHS.BECOME_TUTOR'),
    PRICING: t('PATHS.LANDING_PATHS.PRICING'),
};

export const PROFILE_PATHS = {
    MY_PROFILE: t('PATHS.PROFILE_PATHS.MY_PROFILE'),
    MY_PROFILE_INFO: t('PATHS.PROFILE_PATHS.MY_PROFILE_INFO'),
    MY_PROFILE_INFO_PERSONAL: t('PATHS.PROFILE_PATHS.MY_PROFILE_INFO_PERSONAL'),
    MY_PROFILE_INFO_AVAILABILITY: t('PATHS.PROFILE_PATHS.MY_PROFILE_INFO_AVAILABILITY'),
    MY_PROFILE_INFO_TEACHINGS: t('PATHS.PROFILE_PATHS.MY_PROFILE_INFO_TEACHINGS'),
    MY_PROFILE_INFO_ADDITIONAL: t('PATHS.PROFILE_PATHS.MY_PROFILE_INFO_ADDITIONAL'),
    MY_PROFILE_ACCOUNT: t('PATHS.PROFILE_PATHS.MY_PROFILE_ACCOUNT'),
    MY_PROFILE_CHILD_INFO: t('PATHS.PROFILE_PATHS.MY_PROFILE_CHILD_INFO'),
};

export const ONBOARDING_PATHS = {
    ONBOARDING: t('PATHS.ONBOARDING_PATHS.ONBOARDING'),
    TUTOR_ONBOARDING: t('PATHS.ONBOARDING_PATHS.TUTOR_ONBOARDING'),
};

interface IRoute {
    path: string;
    key: string;
    exact?: boolean;
    component: React.ComponentType;
    roles?: Role[];
    isMenu?: boolean;
    isPublic?: boolean;
    routes?: IRoute[];
    lang?: string;
}

const ROUTES: IRoute[] = [
    {
        path: PATHS.STUDENT_PROFILE,
        key: 'STUDENT_PROFILE',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <StudentProfile />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.STUDENT_MANAGEMENT,
        key: 'STUDENT_MANAGEMENT',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <StudentManagement />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.TOKEN_NOT_VALID,
        key: 'TOKEN_NOT_VALID',
        exact: true,
        component: () => <TokenNotValid />,
    },

    {
        path: ONBOARDING_PATHS.ONBOARDING,
        key: 'ONBOARDING',
        isMenu: false,
        component: (props: any) => (
            <Onboarding>
                <RenderRoutes {...props} />
            </Onboarding>
        ),
        routes: [
            {
                path: ONBOARDING_PATHS.TUTOR_ONBOARDING,
                key: 'TUTOR_ONBOARDING',
                isMenu: false,
                exact: true,
                component: () => (
                    <PermissionsGate roles={[Role.Tutor]}>
                        <TutorOnboardingProvider>
                            <TutorOnboarding />
                        </TutorOnboardingProvider>
                    </PermissionsGate>
                ),
            },
        ],
    },
    {
        path: PATHS.RESET_PASSWORD,
        key: 'RESET_PASSWORD',
        exact: true,
        component: () => <ResetPassword />,
    },
    {
        path: PATHS.MY_BOOKINGS,
        key: 'MY_BOOKINGS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.Parent, Role.Student, Role.SuperAdmin, Role.Child]}>
                <MyBookings />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.CHAT,
        key: 'CHAT',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.Parent, Role.Student, Role.SuperAdmin, Role.Child]}>
                <Chat />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.MY_REVIEWS,
        key: 'MY_REVIEWS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.SuperAdmin]}>
                <MyReviews />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.DASHBOARD,
        key: 'DASHBOARD',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.SuperAdmin, Role.Parent, Role.Student, Role.Child]}>
                <Dashboard />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.NOTIFICATIONS,
        key: 'NOTIFICATIONS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.SuperAdmin, Role.Parent, Role.Student, Role.Child]}>
                <Notifications />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.EARNINGS,
        key: 'EARNINGS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Tutor, Role.SuperAdmin]} checkStripeConnection>
                <Earnings />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.SEARCH_TUTORS,
        key: 'SEARCH_TUTORS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Parent, Role.Student, Role.SuperAdmin]}>
                <SearchTutors />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.SEARCH_TUTORS_TUTOR_PROFILE,
        key: 'SEARCH_TUTORS_TUTOR_PROFILE',
        exact: true,
        component: () => <TutorProfile />,
    },
    {
        path: PATHS.SEARCH_TUTORS_TUTOR_BOOKINGS,
        key: 'SEARCH_TUTORS_TUTOR_BOOKINGS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Parent, Role.Student, Role.SuperAdmin]}>
                {/*<TutorBookings />*/}
                <TutorBookingsNew />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.COMPLETED_LESSONS,
        key: 'COMPLETED_LESSONS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.Parent, Role.Student, Role.SuperAdmin, Role.Child]}>
                <CompletedLessons />
            </PermissionsGate>
        ),
    },
    {
        path: PROFILE_PATHS.MY_PROFILE,
        key: 'MY_PROFILE',
        component: (props: any) => {
            return (
                <PermissionsGate roles={[Role.Tutor, Role.Parent, Role.Student, Role.SuperAdmin]}>
                    <RenderRoutes {...props} />
                </PermissionsGate>
            );
        },
        routes: [
            {
                path: PROFILE_PATHS.MY_PROFILE_INFO,
                key: 'MY_PROFILE_INFO',
                component: (props: any) => <RenderRoutes {...props} />,
                routes: [
                    {
                        path: PROFILE_PATHS.MY_PROFILE_INFO_PERSONAL,
                        key: 'MY_PROFILE_INFO_PERSONAL',
                        exact: true,
                        component: () => <PersonalInformation />,
                    },
                    {
                        path: PROFILE_PATHS.MY_PROFILE_INFO_AVAILABILITY,
                        key: 'MY_PROFILE_INFO_AVAILABILITY',
                        exact: true,
                        component: () => <GeneralAvailability />,
                    },
                    {
                        path: PROFILE_PATHS.MY_PROFILE_INFO_TEACHINGS,
                        key: 'MY_PROFILE_INFO_TEACHINGS',
                        exact: true,
                        component: () => <MyTeachings />,
                    },
                    {
                        path: PROFILE_PATHS.MY_PROFILE_INFO_ADDITIONAL,
                        key: 'MY_PROFILE_INFO_ADDITIONAL',
                        exact: true,
                        component: () => <AdditionalInformation />,
                    },
                ],
            },
            {
                path: PROFILE_PATHS.MY_PROFILE_ACCOUNT,
                key: 'MY_PROFILE_ACCOUNT',
                exact: true,
                component: () => <ProfileAccount />,
            },
            {
                path: PROFILE_PATHS.MY_PROFILE_CHILD_INFO,
                key: 'MY_PROFILE_CHILD_INFO',
                exact: true,
                component: () => <ChildInformations />,
            },
        ],
    },
    {
        path: PATHS.TUTOR_VIDEOS,
        key: 'TUTOR_VIDEOS',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <AdminTutorVideoPage />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.STUDENT_MANAGEMENT,
        key: 'STUDENT_MANAGEMENT',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <StudentManagement />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.TUTOR_MANAGMENT,
        key: 'TUTOR_MANAGMENT',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <TutorManagment />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.TUTOR_MANAGMENT_TUTOR_PROFILE,
        key: 'TUTOR_MANAGMENT_TUTOR_PROFILE',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <TutorManagmentProfile />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.BOOKING_MANAGEMENT,
        key: 'BOOKING_MANAGEMENT',
        exact: true,
        component: () => (
            <PermissionsGate roles={[Role.SuperAdmin]}>
                <BookingManagement />
            </PermissionsGate>
        ),
    },
    {
        path: PATHS.RESEND_ACTIVATION_TOKEN,
        key: 'RESEND_ACTIVATION_TOKEN',
        exact: true,
        component: () => <ResetToken />,
    },
    {
        path: PATHS.STRIPE_CONNECTED,
        key: 'STRIPE_CONNECTED',
        exact: true,
        component: () => <StripeConnected />,
    },
    {
        path: PATHS.STRIPE_FAIL,
        key: 'STRIPE_FAIL',
        exact: true,
        component: () => <StripeFail />,
    },
    {
        path: '/en/*',
        key: 'DEFAULT',
        component: () => <Redirect to={PATHS.DASHBOARD} />,
    },
    {
        path: '/hr/*',
        key: 'DEFAULT',
        component: () => <Redirect to={PATHS.DASHBOARD} />,
    },
    {
        path: '*',
        key: 'DEFAULT',
        component: () => <Redirect to={PATHS.DASHBOARD} />,
    },
];
//handle subroutes by <RenderRoutes {...props} /> inside PermissionGate if needed

function RouteWithSubRoutes(route: any) {
    return (
        <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={(props: any) => <route.component {...props} routes={route.routes} />}
        />
    );
}

export function RenderRoutes(routesObj: IRoutesProps) {
    const { routes } = routesObj;

    return (
        <Switch>
            {routes.map((route: any) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
        </Switch>
    );
}

interface IRoutesProps {
    routes: IRoute[];
}

export function Routes({ routes }: IRoutesProps) {
    const sync = useSyncLanguage();
    const { user } = useAppSelector((state) => state.auth);

    useMount(() => {
        sync(user);
    });

    return (
        <Switch>
            {routes.map((route: any) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
        </Switch>
    );
}
export default ROUTES;
