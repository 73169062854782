import { EN_US } from '../en-US/en-US';

export const HR_HR: typeof EN_US = {
    MAIN_TITLE: 'Teorem',
    WATERMARK: '© Teorem',
    BIRTH_DATE_FORMAT: 'DD/MM/YYYY',
    DATE_FORMAT: 'DD. MMM YYYY',
    ERROR_HANDLING: {
        UNHANDLED_ERROR: 'Došlo je do neočekivane pogreške!',
        SUPPORT: 'Nešto je pošlo po zlu, molimo Vas kontaktirajte podršku',
        CREDIT_CARD_MISSING: 'Nedostaju podatci kartice. Molimo dodajte način plaćanja prije kreiranja rezervacije.',
        DEFAULT_CARD_MISSING: 'Molimo odredite jednu od svojih kartica kao standardni način plaćanja.',
        NO_CHILD_USERNAME: 'Ne postoji dijete s danim korisničkim imenom',
        REGISTERED_SUCCESSFULLY: 'Registracija uspješna.',
    },
    TRIAL_POPUP: {
        WELCOME: 'Welcome to Teorem',
        DESCRIPTION:
            'Your profile is now ready to use. Fill out the agenda, sit back and relax, wait for students to reach out to you!',
        EXPIRATION: 'Your free trial will expire in 30 days!',
        REMIND: 'Don’t worry, we’ll remind you on time.',
        CONFIRM_BUTTON: 'Got it',
    },
    EMAIL_CONFIRMATION_POPUP: {
        WELCOME: 'Dobrodošli na Teorem!',
        DESCRIPTION:
            'Molimo provjerite svoj sandučiċ i potvrdite Vašu e-mail adresu klikom na link koji smo Vam poslali.',
        EXPIRATION: 'Ponovno pošalji email za potvrdu',
        RESEND_TIMEOUT: 'Probajte ponovno za',
        CONFIRM_BUTTON: 'U redu',
    },
    ONBOARDING: {
        BACK: 'Nazad',
        QUESTIONS: 'Pitanja?',
        NEXT: 'Nastavi',
        GET_STARTED: 'Započni',
        PUBLISH: 'Objavi',
        GET_QUICK_TIPS: 'Pronađi brze savjete',
        TUTOR: {
            START: {
                TITLE: 'Početi na Teoremu je jednostavno',
                POINT_1: {
                    TITLE: 'Recite nam nešto o svojim lekcijama',
                    DESCRIPTION: 'Postavite svoju dostupnost, i odaberite predmete i razine koje podučavate.',
                },
                POINT_2: {
                    TITLE: 'Istaknite svoj profil',
                    DESCRIPTION: 'Dodajte video, fotografiju i osnovne informacije, poput Vaših interesa i iskustva.',
                },
                POINT_3: {
                    TITLE: 'Dovršite i objavite',
                    DESCRIPTION: 'Postavite cijenu i objavite svoj profil kad budete spremni.',
                },
            },
            LESSONS: {
                STEP_LABEL: 'Korak 1',
                TITLE: 'Recite nam nešto o svojim lekcijama',
                DESCRIPTION:
                    'U ovom koraku ćemo Vas pitati koje predmete podučavate i koje razine Vam odgovaraju. Zatim nam recite kada ste dostupni za podučavanje.',
            },
            SUBJECTS: {
                TITLE: 'Koje predmete podučavate?',
                SUBTITLE: 'Kasnije možete dodati ili ukloniti predmete putem postavki svog profila.',
                LEVEL_LABEL: 'Razina',
                SUBJECT_LABEL: 'Predmet',
                ADD_SUBJECT: 'Dodaj još jedan predmet',
                REMOVE_SUBJECT: 'Ukloni ovaj predmet',
            },
            AVAILABILITY: {
                TITLE: 'Kada ste dostupni?',
                SUBTITLE:
                    'Učenici mogu rezervirati lekcije u ovim terminima. Imat ćete 24 sata da prihvatite rezervacije.',
                TIMEZONE_LABEL: 'Odaberite svoju vremensku zonu',
            },
            PROFILE: {
                STEP_LABEL: 'Korak 2',
                TITLE: 'Istaknite svoj profil',
                DESCRIPTION:
                    'U ovom koraku ćete dodati svoju profilnu sliku i osnovne informacije o Vašim interesima i iskustvu. Zatim ćete snimiti ili prenijeti kratak video kako bi Vas potencijalni učenici bolje upoznali.',
            },
            PHOTO: {
                TITLE: 'Dodajte svoju profilnu sliku',
                SUBTITLE: 'Koristite portret fotografiju visoke kvalitete na kojoj je vidljivo Vaše cijelo lice.',
                DRAG_TITLE: 'Dovucite mišem',
                DRAG_DESCRIPTION: 'ili pretražite svoj uređaj',
                BROWSE: 'Pretraži',
                POINT_SMILE: 'Nasmiješite se i gledajte u kameru',
                POINT_FRAME: 'Centrirajte Vašu glavu i ramena',
                POINT_FACE: 'Vaše lice i oči su potpuno vidljivi',
                POINT_PERSON: 'Vi ste jedina osoba na fotografiji',
                POINT_LOGO: 'Ne uključujte logotipe ili kontaktne informacije',
            },
            EDUCATION: {
                TITLE: 'Recite nam više o Vašem obrazovanju',
                SUBTITLE:
                    'Roditelji često traže instruktore koji su studirali na određenim sveučilištima. Podijelite više o svojim studijima kako bi povećali vidljivost svog profila.',
                CHECKBOX_NO_DEGREE: 'Nemam diplomu više stručne spreme',
                UNI_LABEL: 'Na kojem ste sveučilištu studirali?',
                DEGREE_LABEL: 'Odaberite vrstu diplome',
                MAJOR_LABEL: 'Unesite naziv svog kolegija ili smjera',
                STARTED_LABEL: 'Početak',
                FINISHED_LABEL: 'Završetak',
                PRESENT: 'Sada',
                ADD_DEGREE: 'Dodaj još jednu diplomu',
            },
            TITLE: {
                TITLE: 'Postavite naslov svog profila',
                SUBTITLE: 'Neka bude kratko. Uvijek ga možete promijeniti kasnije.',
            },
            DESCRIPTION: {
                TITLE: 'Napišite svoj opis',
                SUBTITLE: 'Recite nešto o svojim interesima i metodama podučavanja.',
            },
            VIDEO: {
                TITLE: 'Sada, dodajmo video na Vaš profil',
                SUBTITLE: 'Predstavite se studentima! Možete pregledati i ponovno snimiti prije nego što nastavite.',
                ADD_VIDEO_LABEL: 'Dodajte video',
                RECORD_BUTTON: 'Snimi',
                OR: 'ili',
                UPLOAD_BUTTON: 'Učitaj',
                POINT_TITLE: 'Molimo slijedite ove smjernice kako bi Vaš profil bio odobren',
                POINT_DEMO: 'Kliknite ovdje da vidite primjer videa',
                POINT_LENGTH: 'Neka Vaš video traje između 30 sekundi i 2 minute',
                POINT_HORIZONTAL: 'Snimajte u horizontalnom načinu i u visini očiju',
                POINT_BACKGROUND: 'Koristite neutralnu pozadinu i dobro osvjetljenje',
                POINT_SURFACE: 'Koristite stabilnu površinu kako se video ne bi tresao',
                POINT_FACE: 'Potpuno vidljivo lice i oči',
                POINT_EXPERIENCE: 'Istaknite svoje iskustvo i znanje u nastavi',
                POINT_GREET: 'Srdačno pozdravite svoje učenike i pozovite ih da rezerviraju sat',
                POINT_SURNAME: 'Nemojte uključivati svoje prezime ili kontaktne podatke',
                POINT_LOGO: 'Nemojte uključivati logotipe ili poveznice',
                POINT_PEOPLE: 'Nemojte imati druge osobe vidljive u Vašem videu',
            },
            FINISHING: {
                STEP_LABEL: 'Korak 3',
                TITLE: 'Dovršite i objavite',
                DESCRIPTION: 'Na kraju, postavite svoju cijenu, isplate i objavite svoj profil.',
            },
            NOTIFICATION: {
                TITLE: 'Odlučite kako ćete potvrditi rezervacije',
                SUBTITLE: 'Moći ćete sinkronizirati svoj Google kalendar kako biste izbjegli dvostruke rezervacije.',
                INSTANT_BOOK_TITLE: 'Koristi Instant Book',
                INSTANT_BOOK_SUBTITLE: 'Roditelji i učenici mogu rezervirati odmah.',
                MANUAL_APPROVE_TITLE: 'Odobri ili odbij zahtjeve',
                MANUAL_APPROVE_SUBTITLE: 'Imat ćete 24 sata da odgovorite na zahtjev za rezervaciju.',
            },
            PRICE: {
                CURRENCY: '€',
                TITLE: 'Sada, postavite svoju cijenu',
                SUBTITLE: 'Možete ju promijeniti u bilo kojem trenutku.',
                PRICE_BREAKDOWN_SHORT: 'Vi zaradite',
                PRICE_BREAKDOWN_BASE: 'Osnovna cijena',
                PRICE_BREAKDOWN_FEE: 'Teorem naknada',
                PRICE_BREAKDOWN_EARNED: 'Vi zaradite',
                PRICE_BREAKDOWN_PAID: 'Učenici plaćaju',
                SHOW_LESS: 'Prikaži manje',
                LEARN_MORE_LABEL: 'Saznaj više o cijenama',
                LEARN_MORE: {
                    TITLE: 'Više o cijenama',
                    DESCRIPTION_1:
                        'Vi određujete svoju cijenu, a možete je promijeniti u bilo kojem trenutku. Rezervacije nisu zajamčene.',
                    BULLET_1: 'Cijena po lekciji',
                    DESCRIPTION_2:
                        'Postavljate cijenu za lekcije od 50 minuta. Obavezno ostavite nekoliko minuta između lekcija za dovršavanje Izvještaja o lekcijama.',
                    BULLET_2: 'Teorem naknada',
                    DESCRIPTION_3:
                        'Kada postavljate cijenu i prikazuje se razdioba cijene, Teoremova uslužna naknada automatski će se izračunati kao 15% Vaše cijene lekcije. Teorem zadržava pravo na promjene u stopama provizije.',
                },
                ERROR_LOW_TITLE: 'Ups, postoji problem s vašom cijenom',
                ERROR_LOW_DESCRIPTION: 'Unesite osnovnu cijenu veću od',
            },
            ENTITY: {
                TITLE: 'Kako podučavate na Teoremu?',
                PRIVATE_ENTITY_TITLE: 'Podučavam kao privatna osoba',
                PRIVATE_ENTITY_SUBTITLE: 'Želite primati isplate na svoj osobni bankovni račun.',
                COMPANY_ENTITY_TITLE: 'Podučavam kao tvrtka',
                COMPANY_ENTITY_SUBTITLE: 'Imate registriranu tvrtku preko koje želite primati isplate.',
                CHECKBOX_AGREE: 'Slažem se sa Stripeovim',
                CHECKBOX_TERMS: 'Uvjetima korištenja',
            },
            LEGAL_INFO: {
                TITLE: 'Molimo Vas da unesete zadnja 4 broja vašeg SSN-a',
                TITLE_HR: 'Molimo navedite svoj OIB',
                TITLE_COMPANY: 'Molimo vas da unesete informacije o vašoj tvrtki',
                SUBTITLE:
                    'Provest ćemo provjeru identiteta kako bi bili sigurni da se vaše ime, SSN i ostali podaci podudaraju.',
                SUBTITLE_HR:
                    'Provest ćemo provjeru identiteta kako bi bili sigurni da se Vaše ime i ostali podaci podudaraju.',
                SUBTITLE_COMPANY: '',
                SSN_LABEL: 'Zadnja 4 broja SSN-a',
                OIB_LABEL: 'OIB',
                OIB_LABEL_COMPANY: 'OIB tvrtke',
                NAME_LABEL_COMPANY: 'Naziv tvrtke',
            },
            PAYOUT_INFO: {
                TITLE: 'Gdje želite da šaljemo Vaše isplate?',
                SUBTITLE: 'Obavezno koristite bankovni račun registriran na Vaše ime.',
                SUBTITLE_COMPANY: 'Obavezno koristite bankovni račun registriran na ime Vaše tvrtke.',
                ROUTING_NUMBER_LABEL: 'Broj usmjeravanja',
                ACCOUNT_NUMBER_LABEL: 'Broj računa',
                IBAN_LABEL: 'IBAN',
            },
            ADDRESS: {
                TITLE: 'Koja je Vaša adresa?',
                SUBTITLE: 'Trebamo vašu adresu za obradu isplata. Nećemo je javno dijeliti.',
                COUNTRY_LABEL: 'Država',
                STREET_LABEL: 'Ulica i kućni broj',
                APARTMENT_LABEL: 'Stan, apartman, jedinica (ako je primjenjivo)',
                POSTAL_CODE_LABEL: 'Poštanski broj',
                STATE_LABEL: 'Država / pokrajina',
                CITY_LABEL: 'Grad / općina',
            },
            PHONE: {
                TITLE: 'Prije nego završimo, je li ovo Vaš broj telefona?',
                SUBTITLE: 'Koristit ćemo ga u sigurnosne svrhe i verifikaciju isplata.',
                PHONE_LABEL: 'Broj telefona',
            },
            PUBLISH: {
                TITLE: 'Sjajno! Vrijeme je za objavu.',
                SUBTITLE: 'Ovo ćemo prikazati roditeljima i učenicima. Prije objave, svakako provjerite detalje.',
                SHOW_PREVIEW_BUTTON: 'Pregled profila',
                STEPS_TITLE: 'Što slijedi?',
                STEP_1_TITLE: 'Sinkronizirajte svoj kalendar',
                STEP_1_DESCRIPTION:
                    'Sinkronizirajte svoj Google (ili drugi) kalendar. Time ćete osigurati da Vaša dostupnost uvijek bude ažurna.',
                STEP_2_TITLE: 'Pripremite se za svoju prvu lekciju',
                STEP_2_DESCRIPTION: 'Prođite kroz našu interaktivnu ploču i postavite svoje preference obavijesti.',
                STEP_3_TITLE: 'Podijelite svoj profil',
                STEP_3_DESCRIPTION:
                    'Podijelite poveznicu svog profila na društvenim mrežama kako bi brže dobili prvu rezervaciju.',
            },
        },
    },
    TUTOR_ONBOARDING: {
        TITLE: 'Hvala što ste potvrdili svoju adresu e-pošte! Dovršimo vaš profil!',
        SUBTITLE: 'Ovo obično traje 8 minuta.',
        IMAGE_NOTE:
            'Za sada možete preskočiti, ali morat ćete dodati profilnu sliku prije nego što počnete podučavati.',
        ABOUT_ME_TIPS: {
            TIP_1: 'Detaljno opišite svoj način predavanja i sebe.',
            TIP_2: 'Najučinkovitiji profili sadrže 100+ riječi u rubirkama <u><i>više o meni</i></u> i <u><i>više o mojim predavanjima</i></u>.',
        },
        TOOLTIPS: {
            AVAILABILITY_1: 'Odaberite kada ste dostupni za instrukcije, po dijelovima dana u tjednu.',
            AVAILABILITY_2: 'Svoju dostupnost možete kasnije izmijeniti u bilo kojem trenutku.',
            SUBJECTS: 'Kasnije možete dodati ili obrisati predmete u postavkama Vašeg profila.',
            SUBJECTS_TIP_1: 'Dodajte predmete koje želite podučavati i odredite svoju cijenu.',
            SUBJECTS_TIP_2: 'Imajte na umu da Teorem uzima proviziju od 15%.',
        },
        IMAGE_TIPS: {
            TITLE: 'Naputci za fotografiju',
            TIP_1: 'portret oblik',
            TIP_2: 'ozbiljnog stava s osmjehom',
            TIP_3: 'visoke kvalitete (min 500x500 piksela)',
        },
        PAYOUTS_BUTTON: {
            PRIVATE: 'Privatna osoba',
            COMPANY: 'Tvrtka',
        },
    },
    FORM_VALIDATION: {
        AVAILABILITY: 'Dostupnost je obvezna',
        AVAILABILITY_TIME: 'Morate odabrati barem jedan termin u danu',
        END_YEAR: 'Godina završetka mora biti veća od godine početka',
        MIN_ONE: 'Mora biti barem jedan',
        MIN_PRICE: 'Cijena mora biti veća od',
        PHONE_SHORT: 'Broj telefona mora imati najmanje 7 znakova',
        START_YEAR: 'Godina početka mora biti veća od 1950',
        UNIQUE_SUBJECT: 'Predmeti moraju biti jedinstveni',
        NO_EMPTY_SUBJECT: 'Morate odabrati predmet i razinu',
        INVALID_EMAIL: 'Neispravan email',
        INVALID_IMAGE: 'Slika mora biti formata jpg, png, jpeg ili svg i manja od 2MB veličine.',
        IMAGE_TYPE: 'Slika mora biti formata jpg, png, jpeg ili svg',
        IMAGE_SIZE: 'Slika mora biti manja od 5MB veličine.',
        TOO_SHORT: 'Pretratko!',
        TOO_LONG: 'Predugo!',
        EMAIL_EXISTS: 'Ovaj email se već koristi. Molimo ulogirajte se.',
        MAX_LIMIT: 'Maksimalni broj slova je',
        MIN_LIMIT: 'Minimalni broj riječi je',
        TOO_BIG: 'Broj je prevelik',
        REQUIRED: 'Neophodno polje',
        LOWERCASE: 'Jedno malo slovo (a-z)',
        UPPERCASE: 'Jedno veliko slovo (A-Z)',
        NUMBER: 'Jednu brojku (0-9)',
        MIN_CHARACTERS: '8 znakova',
        SPECIAL_CHAR: 'Jedan poseban znak (@, $, !, %, *, ?, &...)',
        PASSWORD_MUST: 'Lozinka mora sadržavati barem',
        PASSWORD_MATCH: 'Lozinke se moraju poklapati',
        PASSWORD_STRENGTH: 'Lozinka mora ispunjavati uvjete',
        PRICE: 'Minimalno ',
        WHOLE_NUMBER: 'Nije cijeli broj',
        FUTURE_DATE: 'Datum ne može biti u budućnosti',
        VALID_DATE: 'Datum je nevažeći',
        PHONE_NUMBER: 'Neispravan broj telefona',
        MINIMUM_AGE: 'Morate imati više od 18 godina',
        MAXIMUM_AGE: 'Morate imati manje od 100 godina',
        TUTOR_AGE: 'Instruktor mora imati više od 18 godina',
        WRONG_REQUIREMENTS: 'Niste ispunili sva potrebna polja',
        NEGATIVE: 'Ne može biti negativan broj',
        USERNAME_EXIST: 'Korisničko ime već postoji',
        TOAST: {
            UPDATE: 'Uspješno ste ažurirali podatke o djetetu',
            CREATE: 'Uspješno ste dodali dijete',
            DELETE: 'Uspješno ste uklonili dijete',
        },
        LEVEL_REQUIRED: 'Razina je obvezna',
        SUBJECT_REQUIRED: 'Predmet je obvezan',
        CHILD_REQUIRED: 'Dijete je obvezno',
        AGREE_TERMS_REQUIRED: 'Morate prihvatiti odredbe i uvjete',
        CHILDLESS_CALENDAR_1: 'Dodajte dijete u',
        CHILDLESS_CALENDAR_2: 'postavkama profila',
        INVALID_IBAN: 'Neispravan IBAN',
        IBAN_WHITESPACES: 'IBAN ne smije sadržavati prazne znakove',
        IBAN_MATCH: 'IBANi se moraju podudarati',
        MIN_50_WORDS: 'Najmanje 50 riječi',
        MAX_2500_CHARS: 'Najviše 2500 znakova',
        MAX_75_CHARS: 'Najviše 75 znakova',
        MAX_100_CHARS: 'Najviše 100 znakova',
        MIN_10_CHARS: 'Najmanje 10 znakova',
        NAME_EMAIL_NOT_IN_PASSWORD: 'Ne sadržava ni ime ni email adresu',
    },
    NAVIGATION: {
        MY_BOOKINGS: 'Kalendar',
        MY_REVIEWS: 'Moje recenzije',
        SEARCH_TUTORS: 'Pretraga instruktora',
        TUTOR_MANAGMENT: 'Upravljanje instruktorima',
        STUDENT_MANAGEMENT: 'Upravljanje studentima',
        BOOKING_MANAGEMENT: 'Upravljanje rezervacijama',
        TUTOR_VIDEOS: 'Preview videi',
        MY_PROFILE: 'Moj profil',
        COMPLETED_LESSONS: 'Završene lekcije',
        CHAT: 'Razgovori',
        DASHBOARD: 'Upravljačka ploča',
        EARNINGS: 'Prihod',
        SHARE_PROFILE: 'Podijeli profil',
        TEXT_COPIED: 'Profil kopiran!',
        LOGIN: 'Prijava',
        REGISTER: 'Registracija',
    },
    SEARCH_TUTORS: {
        AVAILABILITY: {
            TIME_OF_DAY: {
                LABEL: 'Vrijeme dana',
                BEFORE_NOON: 'Prije 12:00',
                NOON_TO_FIVE: '12:00 - 17:00',
                AFTER_FIVE: 'Nakon 17:00',
            },
            DAY_OF_WEEK: {
                LABEL: 'Dan u tjednu',
                MON: 'pon',
                TUE: 'uto',
                WED: 'sri',
                THU: 'čet',
                FRI: 'pet',
                SAT: 'sub',
                SUN: 'ned',
            },
        },
        PLACEHOLDER: {
            LEVEL: 'Razina',
            SUBJECT: 'Predmet',
            AVAILABILITY: 'Dostupnost',
        },
        NO_OPTIONS_MESSAGE: 'Molimo prvo odaberite razinu',
        TITLE: 'Pretraga instruktora',
        VIEW_PROFILE: 'Pogledaj Profil',
        BOOK_LESSON: 'Rezerviraj',
        RESET_FILTER: 'Resetiraj pretragu',
        TUTOR_AVAILABLE: 'Dostupni instruktori',
        COMPLETED_LESSONS: 'završenih lekcija',
        NO_REVIEWS: 'Još nema recenzija',
        NO_COMPLETED_LESSONS: 'Još nema završenih lekcija',
        NO_VIDEO: 'Instruktor još nije učitao video',
        SUBJECT_LIST: {
            MORE: 'više',
        },
        NO_RESULT: {
            TITLE: 'Bez rezultata',
            DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
        },
        NOT_FILLED: 'Ovi podatci još nisu ispunjeni',
        YEARS_OF_EXPERIENCE: 'Godine iskustva: ',
        NUMBER_OF_REVIEWS: 'ocjena',
        TUTOR_PROFILE: {
            ABOUT_ME: 'O meni',
            EMPTY_STATE_ABOUT: 'Ovaj instruktor nije ispunio odjeljak "O meni"',
            EMPTY_STATE_LESSON: 'Ovaj instruktor nije ispunio odjeljak "O predmetima"',
            ADDITIONAL_INFORMATION_TITLE: 'O meni',
            ADDITIONAL_INFORMATION_DESC: 'Izmjena i ažuriranje sekcije "O meni" Vašeg profila.',
            UPDATE_ADDITIONAL_INFO_SUCCESS: 'Dodatni podatci ažurirani!',
            UPDATE_TEACHINGS_SUCCESS: 'Predmeti ažurirani!',
            LESSON_LENGTH: '50 min predavanje',
            FORM: {
                ABOUT_TUTOR_LABEL: 'Recite nam više o sebi*',
                ABOUT_TUTOR_PLACEHOLDER: 'Što Vas najbolje opisuje, koji su Vaši hobiji, pristup...',
                ABOUT_TUTOR_TOOLTIP: 'Detaljno opišite sebe, svoje interese, iskustvo i hobije.',
                SUBMIT_BTN: 'Spremanje',
            },
            NO_PRICE: 'Cijena nije navedena',
            HOUR: 'sat',
        },
        PRICE_SORT: 'Sortirati po',
        SORT_LOW: 'Najnižoj cijeni',
        SORT_HIGH: 'Najvišoj cijeni',
        SORT_NONE: 'Bez sortiranja',
        NEW_TUTOR: {
            PART_1: 'Novi',
            PART_2: 'instruktor',
        },
    },
    TUTOR_MANAGMENT: {
        TITLE: 'Popis instruktora',
        UNPROCESSED: 'NEOBRAĐENO',
        APPROVED: 'ODOBRENO',
        DENIED: 'ODBIJENO',
        SUBJECT_LIST: {
            MORE: 'više',
        },
        NO_RESULT: {
            TITLE: 'Nema rezultata',
            DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
        },
        TABLE: {
            FIRST_NAME: 'IME',
            LAST_NAME: 'PREZIME',
            EMAIL: 'EMAIL',
            COUNTRY: 'DRŽAVA',
            DATE_OF_BIRTH: 'DATUM ROĐENJA',
            PREVIEW_PROFILE: 'Pregled profila',
            PHONE_NUMBER: 'BROJ TELEFONA',
            CREATED_AT: 'DATUM KREIRANJA',
            ROLE: 'ULOGA',
            CREDITS: 'KREDITI',
            NUMBER_OF_LESSONS: 'PREDAVANJA',
            ID_VERIFIED: 'POTVRÐEN IDENTITET',
            DOCUMENTS_UPLOADED: 'DOKUMENTI UČITANI',
        },
        ACTIONS: {
            APPROVE: 'Odobri',
            DECLINE: 'Odbij',
            DELETE: 'Izbriši',
            APPROVE_TUTOR: 'Odobri instruktora',
            DECLINE_TUTOR: 'Odbij instruktora',
            DELETE_TUTOR: 'Izbriši instruktora',
            EDIT_NOTE: 'Uredi bilješku',
            CANCEL: 'Odustani',
            PLAY_VIDEO: 'Pogledaj video',
        },
        NOTE: 'Bilješka',
        NOTE_PLACEHOLDER: 'Unesi bilješku...',
        DETAILS: ' DETALJI',
        SEARCH_PLACEHOLDER: 'Pronađi instruktora',
        VIDEO_PREVIEW: {
            DECLINE_AND_SEND_BUTTON: 'Odbij i pošalji',
            MESSAGE_TITLE: 'Razlog odbijanja videa',
        },
    },
    STUDENT_MANAGEMENT: {
        TITLE: 'Popis studenata/roditelja',
        NO_RESULT: {
            TITLE: 'Nema rezultata',
            DESC: ' Hmm, izgleda da nema studenata/roditelja za ovu određenu pretragu.',
        },
        SEARCH_PLACEHOLDER: 'Pronađi studenta/roditelja',
        TABLE: {
            FIRST_NAME: 'IME',
            LAST_NAME: 'PREZIME',
            EMAIL: 'EMAIL',
            PHONE_NUMBER: 'BROJ TELEFONA',
            ROLE: 'ULOGA',
            NUMBER_OF_LESSONS: 'LESSONS',
            CREDITS: 'CREDITS',
            STUDENT: 'STUDENT',
            LEVEL: 'RAZINA',
            SUBJECT: 'PREDMET',
            TUTOR: 'INSTRUKTOR',
            TUTOR_EMAIL: 'EMAIL INSTRUKTORA',
            TUTOR_PHONE: 'BROJ TELEFONA INSTRUKTORA',
            START_TIME: 'VRIJEME POČETKA',
            CREATED_AT: 'KREIRANO',
            PRICE: 'CIJENA',
            STATE: 'STANJE',
            ACCEPTED: 'PRIHVAĆENO',
            DELETED: 'OBRISANO',
            RESCHEDULE: 'RESCHEDULE',
        },
    },
    CHAT: {
        PLACEHOLDER: 'Pošaljite poruku kako biste započeli razgovor',
        SEARCH_PLACEHOLDER: 'Pretražite razgovor',
        BOOK_SESSION: 'Rezervirajte lekciju',
        FREE_CONSULTATION: 'Započnite besplatan poziv',
        ACCEPT_CONSULTATION: 'Prihvati',
        DENY_CONSULTATION: 'Odbij',
        DENY_FREE_CONSULTATION: 'Odustanite od poziva',
        CHAT_REQUEST_TITLE: 'Dolazni videopoziv',
        CHAT_MISSED_CALL:
            '<span class="chat-missed-incoming-message">Propušten poziv od korisnika userInsert={user}</span>',
    },
    ROLE_SELECTION: {
        TITLE: 'Registracija',
        ACTION: 'Želim se registrirati kao',
        ACCOUNT: 'Već imate korisnički račun?',
        LOG_IN: 'Prijavite se',
        STUDENT_TITLE: 'Učenik',
        STUDENT_DESCRIPTION: 'Ovdje sam da proširim svoje znanje.',
        PARENT_TITLE: 'Roditelj / Skrbnik',
        PARENT_DESCRIPTION: 'Ovdje sam za svoje dijete.',
        TUTOR_TITLE: 'Instruktor',
        TUTOR_DESCRIPTION: 'Ovdje sam da podučavam učenike.',
    },
    RESET_PASSWORD: {
        TITLE: 'Zaboravili ste lozinku?',
        FORM: {
            PASSWORD: 'Lozinka',
            REPEAT_PASSWORD: 'Ponovite lozinku',
            EMAIL: 'Email',
            SUBMIT_BTN: 'Resetiraj lozinku',
        },
        SUCCESS: 'Uspješno ste resetirali lozinku. Molimo provjerite svoj email.',
        BACK_BTN: 'Povratak na prijavu',
    },
    LOGIN: {
        TITLE: 'Prijava',
        FORM: {
            EMAIL: 'Email',
            PASSWORD: 'Lozinka',
            SUBMIT_BTN: 'Prijava',
        },
        FORGOT_PASSWORD: 'Zaboravili ste lozinku?',
        ACCOUNT: 'Nemate korisnički račun?',
        REGISTER: 'Registrirajte se',
    },
    BACKEND_ERRORS: {
        BASE: {
            UNAUTHORIZED: 'Niste ovlašteni.',
            FORBIDDEN: 'Nemate dopuštenje.',
            NOT_FOUND: 'Resurs nije pronađen',
            CONFLICT: 'Resurs već postoji',
            INTERNAL_ERROR: 'Pogreška servera',
            BAD_REQUEST: 'Loš zahtjev',
        },
        USER: {
            EMAIL_CONFLICT: 'Korisnik s ovom email adresom već postoji',
            LOGIN_WRONG_FORM_DATA: {
                BODY: 'Pogrešni podaci. Molimo vas pokušajte ponovo.',
                TITLE: 'Probajmo još jednom',
            },
            NOT_FOUND: 'Korisnik nije pronađen',
            PROFILE_NOT_ACTIVE: {
                SENT_AGAIN: 'Molimo potvrdite vašu e-mail adresu.',
            },
            PASSWORD_RESET: {
                TOKEN_EXPIRED: 'Vaš token je istekao, molimo zatražite novi e-mail',
                TOKEN_INVALID: 'Vaš token je neispravan, molimo zatražite novi e-mail',
                MISMATCH: 'Lozinke se ne podudaraju',
            },
        },
        LEVEL: {
            NOT_FOUND: 'Razina nije pronađena',
        },
        FILE: {
            NOT_FOUND: 'Dokument nije pronađen',
        },
        SUBJECT: {
            NOT_FOUND: 'Predmet nije pronađen',
        },
        TUTOR: {
            NOT_FOUND: 'Instruktor nije pronađen',
        },
        TUTOR_AVAILABILITY: {
            CONFLICT: 'Dostupnost instruktora već je određena',
            NOT_FOUND: 'Dostupnost instruktora nije pronađena',
        },
        BOOKING: {
            NOT_FOUND: 'Rezervacija nije pronađena',
        },
        ROLE: {
            NOT_FOUND: 'Uloga nije pronađena',
        },
        REVIEW: {
            CONFLICT: 'Već ste napisali recenziju za ovog instruktora',
        },
        INPUT_FAILED: 'Pogrešni ulazni podatci',
        INVALID_IMAGE: 'Nevažeća slika',
    },
    NOT_FOUND: {
        TITLE: 'Stranica nije pronađena',
        SUBTITLE: ' Oprostite, izgleda da smo Vas poslali pogrešnim putem. Dopustite nam da Vas vratimo natrag!',
        BACK_BUTTON: 'Povratak na početak',
    },
    UPCOMING_LESSONS: {
        TITLE: 'NADOLAZEĆI DOGAĐAJI',
        EMPTY: 'Nema nadolazećih lekcija',
    },
    SKIP_FOR_NOW: 'Zanemari za sada',
    REGISTER: {
        TITLE: 'Registracija',
        FORM: {
            BUTTONS_SUBTITLE: 'Tip računa',
            BUTTON_PARENT: 'Roditelj',
            BUTTON_STUDENT: 'Učenik',
            BUTTON_TUTOR: 'Instruktor',
            NAME_SUBTITLE: 'Ime',
            FIRST_NAME: 'Ime kao na osobnoj iskaznici',
            FIRST_NAME_PLACEHOLDER: 'Enter First Name',
            LAST_NAME: 'Prezime kao na osobnoj iskaznici',
            LAST_NAME_PLACEHOLDER: 'Enter Last Name',
            LAST_NAME_INFO: 'Provjerite da ovo odgovara imenu na Vašoj službenoj osobnoj iskaznici.',
            DATE_OF_BIRTH_SUBTITLE: 'Datum rođenja',
            DATE_OF_BIRTH: 'Datum rođenja',
            DATE_OF_BIRTH_PLACEHOLDER: 'Enter your birthdate',
            DATE_OF_BIRTH_INFO: 'Kako biste se registrirali na Teorem, morate imati najmanje 18 godina.',
            CONTACT_INFO_SUBTITLE: 'Kontaktni podaci',
            EMAIL: 'Email',
            EMAIL_PLACEHOLDER: 'Enter Email',
            PHONE_NUMBER: 'Broj telefona',
            PHONE_INFO:
                'Vaši kontakt podaci neće biti vidljivi javnosti. E-poštom ćemo Vam slati potvrde predavanja i račune.',
            PASSWORD_SUBTITLE: 'Lozinka',
            PASSWORD: 'Lozinka',
            PASSWORD_PLACEHOLDER: 'Type your password',
            CONFIRM_PASSWORD: 'Repeat Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Type your password',
            AGREE: 'Klikom na Slažem se i nastavi, slažem se s Uvjetima pružanja usluge i Politikom privatnosti Teorema.',
            SUBMIT_BUTTON: 'Slažem se i nastavi',
            MARKETING_DISCLAIMER:
                'Teorem će vam slati posebne ponude, marketinše e-poruke i obavijesti. Možete isključiti njihovo primanje u bilo kojem trenutku u postavkama svog računa ili izravno iz marketinške obavijesti.',
            MARKETING_DISCLAIMER_CHECKBOX: 'Ne želim primati marketinške poruke od Teorema.',
            CHILD_NAME: 'Ime djeteta',
            CHILD_NAME_PLACEHOLDER: 'Unesite ime djeteta',
            CHILD_DATE_OF_BIRTH: 'Datum rođenja djeteta*',
            USERNAME: 'Korisničko ime*',
            COUNTRY: 'Država',
            COUNTRY_PLACEHOLDER: 'Odaberite državu',
            EMAIL_CONFLICT: 'Korisnik s ovom email adresom već postoji',
            PROFILE_IMAGE: 'Profilna slika',
            TERMS_AND_CONDITIONS:
                '<div>Klikom na <strong>Slažem se i nastavi</strong>, slažem se s <a href="https://teorem.hr/hr/uvjeti-koristenja" target="_blank">Uvjetima pružanja usluge</a> i <a href="https://teorem.hr/hr/politika-privatnosti" target="_blank">Politikom privatnosti</a> Teorema.</div>',
        },
        CARD_DETAILS: {
            FIRST_NAME: 'Ime*',
            LAST_NAME: 'Prezime*',
            CARD_NUMBER: 'Broj kartice*',
            EXPIRY_DATE: 'Datum isteka*',
            EXPIRY_PLACEHOLDER: 'MM / GG',
            CVV: 'CVV*',
            ZIP_CODE: 'Poštanski broj*',
            ZIP_CODE_PLACEHOLDER: 'Unesite poštanski broj',
        },
        BACK_BUTTON: 'Natrag na odabir',
        NEXT_BUTTON: 'Dalje',
        BACK_TO_REGISTER: 'Natrag na registraciju',
        FINISH: 'Završi',
        BACK_TO_STEP_ONE: 'Natrag na korak 1',
        BACK_TO_STEP_TWO: 'Natrag na korak ',
        SAVE_BUTTON: 'Spremi',
        DELETE_BUTTON: 'Obriši',
        BACK_TO_LIST: 'Natrag na popis',
    },
    ADD_CHILD: {
        TITLE: 'Dodajte novo dijete',
        DESCRIPTION: 'Izaberite za dodavanje novog djeteta',
        SAVE: 'Spremi dijete',
        PART_1: 'Dodajte ',
        PART_2: 'dijete',
    },
    MY_BOOKINGS: {
        TITLE: 'Kalendar',
        NOTIFICATION_PART_1: 'Imate ',
        NOTIFICATION_PART_2: ' lekcija danas!',
        UNAVAILABLE: 'Nedostupno',
        UNAVAILABILITY: 'Nedostupnost',
        UNABLE_MESSAGE: 'Ne možete dodati događaj u prošlosti ili u iduća 3 sata',
        TIME_AFTER: 'Vrijeme početka ne može biti nakon vremena završetka',
        TIME_SAME: 'Vrijeme početka i vrijeme završetka ne mogu biti isti',
        CALENDAR: {
            TITLE: 'KALENDAR',
            TODAY: 'Danas',
        },
        ADDITIONAL_INFORMATION: {
            TITLE: 'Informacije o pridruživanju na sastanak',
        },
        MODAL: {
            DELETED_USER: 'Izbrisani korisnik',
            ACCEPT: 'Prihvati',
            PROPOSE: 'Predloži novo vrijeme',
            DELETE: 'Izbriši',
            DENY: 'Odbij',
            TUTOR_CALENDAR: 'Raspored instruktora',
            TIME: 'Vrijeme',
            DATE: 'Datum',
            WHOLE_DAY: 'Čitav dan',
            SET_UNAVAILABILITY: 'Postavi nedostupnost',
            CANCEL: 'Otkaži',
            CONFIRM_CANCEL_TITLE: 'Jeste li sigurni da želite otkazati rezervaciju?',
        },
        INFORMATION: {
            TITLE: 'INFORMACIJE O REZERVACIJAMA',
            CARD1: {
                TITLE: 'Kako se rade rezervacije?',
                DESC: 'Nakon odabira vremena koje vam odgovara i rezervacije, instruktor ima 24 sata da prihvati vaš zahtjev za rezervaciju. Obavijestit ćemo vas e-poštom kada vaša lekcija bude prihvaćena.',
            },
            CARD2: {
                TITLE: 'Što ako mi moj termin predavanja vise ne odgovara?',
                DESC: 'Možete otkazati bilo koju rezervaciju i dobiti puni povrat novca 24 sata od trenutka kada ste izvršili uplatu. Za više informacija posjetite naš <a href="https://intercom.help/teorem/hr/" target="_blank">centar za podršku</a>.',
            },
            CARD3: {
                TITLE: 'Kako rezervirati termin?',
                DESC: 'Možete rezervirati termin s instruktorom odlaskom na njegov/njezin profil i pritiskom na "Rezervirajte lekciju". Moći ćete vidjeti instruktorovu dostupnost i izabrati vrijeme koje odgovara za oboje.',
            },
            CARD4: {
                TITLE: 'Kako otkazati rezervaciju?',
                DESC: 'Možete otkazati rezervaciju u Vašem kalendaru klikom na rezervaciju i zatim na "Otkaži". Ako otkažete rezervaciju više od 24 sata unaprijed, dobit ćete puni povrat novca.',
            },
        },
        RESCHEDULE: {
            BUTTON: 'Novi termin',
            MODAL: {
                TITLE: 'Promjena termina',
                DESCRIPTION:
                    'Ako već niste, predlažemo da se dogovorite s drugom stranom o novom terminu i da budete sigurni da će prihvatiti novi termin.',
            },
        },
        CANCEL: {
            BUTTON: 'Otkaži',
            MODAL: {
                TITLE: 'Jeste li sigurni da želite otkazati termin?',
                STUDENT_DESCRIPTION: 'Povrat novca možete dobiti u obliku creditsa na Teorem platformi.',
                TUTOR_DESCRIPTION:
                    'U slučaju da otkazujete rezervaciju manje od 24h prije početka predavanja, biti ćete penalizirani tako što će se vaš profil manje prikazivati u pretrazi.',
                CONFIRM: 'Otkaži predavanje',
                DISMISS: 'Odustani',
            },
        },
    },
    BOOK: {
        TITLE: 'Rezervirajte termin',
        RESCHEDULE_TITLE: 'Ažuriraj rezervaciju',
        FORM: {
            LEVEL: 'Razina',
            LEVEL_PLACEHOLDER: 'Odaberite razinu',
            SUBJECT: 'Predmet',
            CHILD: 'Dijete',
            CHILD_PLACEHOLDER: 'Odabir djeteta',
            TIME: 'Vrijeme* (Lekcije traju 50min)',
            TIME_PLACEHOLDER: 'Vrijeme',
            SUBMIT: 'Rezerviraj i Plati',
            ADD_CARD: 'Dodaj karticu',
            UPDATE: 'Ažuriraj rezervaciju',
            CANCEL: 'Otkaži',
            TUTOR_DISABLED: 'Privremeno nedostupno',
            CANCEL_BOOKING: 'Otkaži rezervaciju',
            EDIT_OR_CANCEL_BOOKING: 'Izmijeni ili otkaži',
            EDIT_OR_DENY_BOOKING: 'Izmijeni ili odbij',
            DISMISS: 'Odustani',
            DATE_OF_BOOKING: 'Datum',
            TIME_OF_BOOKING: 'Vrijeme',
            ALREADY_IN_RESCHEDULE: 'U procesu promjene termina',
            USE_CREDITS: 'Iskoristi kredite',
        },
        JOIN: 'Pridruži se',
        JOIN_TOOLTIP: 'Sastanku se možete pridružiti najranije 5 minuta prije početka',
    },
    MY_REVIEWS: {
        TITLE: 'Moje recenzije',
        COUNT_TITLE: 'RECENZIJE',
        AVG_SCORE: 'Prosječna ocjena',
        PUBLISHED: 'Objavljeno',
        STAR: {
            1: '1 zvjezdica',
            2: '2 zvjezdice',
            3: '3 zvjezdice',
            4: '4 zvjezdice',
            5: '5 zvjezdica',
        },
        NO_RESULT: {
            TITLE: 'Bez rezultata',
            DESC: 'Još nema recenzija.',
            DESC_ADMIN: 'Još uvijek nema niti jedne recenzije.',
        },
        DATE_AGO: {
            BEFORE: ' prije ',
            YEAR: ' godina',
            YEARS: ' godina',
            MONTH: ' mjesec',
            MONTHS: ' mjeseci',
            WEEK: ' tjedan',
            WEEKS: ' tjedna',
            DAY: ' dan',
            DAYS: ' dana',
            TODAY: ' danas',
        },
    },
    COMPLETED_LESSONS: {
        TITLE: 'Završene lekcije',
        TUTORS_AVAILABLE: 'Dostupni instruktori',
        LESSONS_AVAILABLE: 'Dostupne lekcije',
        EMPTY_LESSONS_LIST: 'Nema dostupnih završenih lekcija',
        EMPTY_LESSONS_TITLE: 'Nema lekcija',
        LEAVE_REVIEW: 'Ostavite recenziju',
        VIEW_CALENDAR: 'Pregledajte kalendar',
        BOOK_LESSON: 'Rezerviraj predavanje',
        VIDEOS_TITLE: 'Preuzmite račune i video lekcije',
        EMPTY_VIDEOS: 'Nema dostupnih završenih lekcija za odabir',
        LINK: 'Idi na kalendar',
        COUNT_EXTENSION: 'Lekcija',
        TOOLTIP_DOWNLOAD_INVOICE: 'Preuzmi račun',
        TOOLTIP_DROPDOWN_LABEL: 'Prikaži video lekcije',
        TOOLTIP_PLAY_LESSON: 'Pokreni/preuzmi video lekciju',
        DATE_TITLE: 'Datum',
        DATE_AT_WORD: 'u',
        DOWNLOAD_INVOICE_SUCCESS: 'Preuzimanje računa uspješno!',
        DOWNLOAD_INVOICE_FAIL: 'Preuzimanje računa neuspješno!',
        NUMBER_OF_COMPLETED_LESSONS: ' završenih predavanja s ovim instruktorom',
    },
    WRITE_REVIEW: {
        SECTION_TITLE: 'Napišite recenziju',
        SUBTITLE:
            'Vaše mišljenje važno je za poboljšanje naše usluge instrukcija. Odvojite trenutak da podijelite svoje iskustvo i pomognete drugima poput Vas da pronađu savršenog instruktora!',
        OVERALL: 'Sveukupno',
        PUNCTUALITY: 'Točnost',
        COMMUNICATION: 'Komunikacija',
        KNOWLEDGE: 'Znanje',
        REVIEW: 'Recenzija',
        SUBMIT: 'Objavi',
        CANCEL: 'Odustani',
        CLOSE: 'Zatvori',
        FINISH: 'Završi',
        HEADING_PLACEHOLDER: 'Napiši naslov svoje recenzije',
        TEXT_PLACEHOLDER: 'Opišite Vaše iskustvo s ovim instruktorom',
        REVIEWS: 'recenzije',
        THANKS_MESSAGE: 'Hvala na recenziji!',
        NEW_TUTOR: 'Novi instruktor',
        COMPLETED_LESSONS: {
            PART_1: 'Završili ste ',
            PART_2: ' predavanja s ovim instruktorom.',
        },
        COMMENT_LABEL: 'Opišite Vaše iskustvo s ovim instruktorom.',
    },

    COMPLETE_PROFILE: {
        TITLE: 'Završi svoj profil',
        DESCRIPTION: 'Ispuni nedostajeće podatke kako bi završio svoj profil',
        PROFILE_SETTINGS: 'Postavke profila',
        GENERAL_AVAILABILITY: 'Dostupnost',
        MY_TEACHINGS: 'Predmeti',
        ABOUT_ME: 'O meni',
        ACCOUNT: 'Korisnički račun',
        CHILD_INFO: 'Informacije o djetetu',
        EARNINGS: 'Zarada',
    },
    COMPLETE_TUTOR_PROFILE_CARD: {
        TITLE: 'Završi svoj profil!',
        DESCRIPTION: 'Molimo ispunite ostatak podataka o svojem profilu kako bi počeli predavati.',
    },
    TUTOR_VERIFIED_NOTE: {
        TITLE: 'Vaš račun mora potvrditi administrator, prije nego što se može prikazivati u rezultatima pretraživanja!',
        DESCRIPTION: 'Primit ćete e-mail o statusu vašeg računa.',
    },
    CHILDLESS_PARENT_NOTE: {
        TITLE: 'Morate imati dodano dijete kako biste mogli zatražiti instrukcije!',
        DESCRIPTION: 'Dijete možete dodati na postavkama profila.',
    },
    ACCOUNT: {
        CHANGE_PASSWORD: {
            TITLE: 'Promijenite lozinku',
            DESCRIPTION: 'Potvrdite svoju trenutnu lozinku te nakon toga unesite novu.',
            CURRENT_PASSWORD: 'Trenutna lozinka',
            CURRENT_PASSWORD_PLACEHOLDER: 'Unesite trenutnu lozinku',
            NEW_PASSWORD: 'Nova lozinka',
            NEW_PASSWORD_PLACEHOLDER: 'Unesite novu lozinku',
            CONFIRM_PASSWORD: 'Potvrdi lozinku',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Potvrdi lozinku',
        },
        CARD_DETAILS: {
            TITLE: 'Plaćanje',
            DESCRIPTION: 'Odaberi osnovnu metodu plaćanja ili dodaj novu.',
            TITLE_TUTOR: 'Detalji plaćanja',
            DESCRIPTION_TUTOR: 'Odaberi kako zeliš biti plaćen za instrukcije.',
            ADD_NEW: 'Dodaj novu karticu',
            ADD_NEW_DESC: 'Izaberi za dodavanje nove kartice',
            NOTE: 'Nakon dodavanja kartice, slijedi proces verifikacije koji uključuje privremenu naplatu od €0.00.',
        },
        NEW_CARD: {
            ADD: 'DODAJTE NOVU KARTICU',
            NAME: 'Ime',
            NAME_PLACEHOLDER: 'Unesi ime',
            SURNAME: 'Prezime',
            SURNAME_PLACEHOLDER: 'Unesi prezime',
            CITY: 'Grad',
            CITY_PLACEHOLDER: 'Unesi ime grada',
            ADDRESS1: 'Adresa 1',
            ADDRESS1_PLACEHOLDER: 'Unesi prvu adresu',
            ADDRESS2: 'Adresa 2',
            ADDRESS2_PLACEHOLDER: 'Unesi drugu adresu',
            CARD_NUMBER: 'Broj kartice',
            CARD_NUMBER_PLACEHOLDER: '**** **** **** ****',
            EXPIRY: 'Datum isteka',
            EXPIRY_PLACEHOLDER: 'MM / GG',
            CVV: 'CVV*',
            CVV_PLACEHOLDER: '***',
            ADD_BUTTON: 'Dodaj novu karticu',
            CANCEL_BUTTON: 'Odustani',
            ZIP: 'Zip',
            ZIP_PLACEHOLDER: '12345',
            COUNTRY: 'Država',
            COUNTRY_PLACEHOLDER: 'Izaberite državu',
        },
        SUBMIT: 'Spremi',
    },
    MY_PROFILE: {
        TITLE: 'Moj profil',
        PREVIEW: 'Pregled profila',
        PAYOUTS: 'Gdje ti isplaćujemo zaradu?',
        IMAGE: 'Dodaj svoju sliku profila',
        ABOUT_ME: {
            OCCUPATION: 'Vaše trenutno zanimanje*',
            OCCUPATION_PLACEHOLDER: 'Unesite Vaše trenutno zanimanje',
            YEARS: 'Broj godina iskustva u podučavanju',
            YEARS_PLACEHOLDER: 'Koliko godina iskustva u podučavanju imate',
            ABOUT_YOURSELF: 'Recite nam više o sebi*',
            ABOUT_LESSON: 'Recite nam više o Vašem načinu predavanja*',
        },
        MY_TEACHINGS: {
            TITLE: 'Moji predmeti',
            DESCRIPTION: 'Izmijenite i ažurirajte svoje predmete',
            ADD_NEW: 'Dodajte novi predmet',
            ADD_DESC: 'Izaberite za dodavanje novog predmeta',
            DELETED: 'Predmet je obrisan',
            CREATED: 'Predmet je dodan',
            UPDATED: 'Predmeti ažurirani',
            EDIT_TITLE: 'IZMIJENITE PREDMET',
            ADD_TITLE: 'DODAJTE NOVI PREDMET',
            SUBJECT: 'Odaberite predmet koji predajete',
            LEVEL: 'Odaberite razine koje predajete',
            PRICING: 'Cijena ',
            PRICING_PLACEHOLDER: '10',
            HOUR_ABRV: 'h',
            SAVE: 'Spremi podatke',
            DELETE: 'Izbriši',
            CANCEL: 'Odustani',
            SUBJECT_ERROR: 'Predmet za tu razinu već postoji',
        },
        GENERAL_AVAILABILITY: {
            TITLE: 'Dostupnost',
            DESCRIPTION: 'Izmijenite i ažurirajte svoju dostupnost',
            UPDATED: 'Dostupnost ažurirana',
            CREATED: 'Dostupnost dodana',
            SELECT_ZONE: 'Odaberite svoju vremensku zonu: ',
            TIME_ZONE: 'Vremenska zona',
        },
        PROFILE_SETTINGS: {
            TITLE: 'Osobni podatci',
            CREDITS: 'Krediti',
            DESCRIPTION: 'Izmijenite i ažurirajte svoje osobne podatke',
            FIRST_NAME: 'Ime*',
            FIRST_NAME_PLACEHOLDER: 'Unesite svoje ime',
            LAST_NAME: 'Prezime*',
            LAST_NAME_PLACEHOLDER: 'Unesite svoje prezime',
            PHONE: 'Broj telefona*',
            COUNTRY: 'Država*',
            BIRTHDAY: 'Datum rođenja*',
            IBAN: 'IBAN',
            IBAN_PLACEHOLDER: 'Unesite svoj IBAN',
            COMPANY_NAME: 'Ime tvrtke',
            COMPANY_NAME_PLACEHOLDER: 'Unesite ime tvrtke',
            COMPANY_OIB: 'OIB tvrtke',
            COMPANY_OIB_PLACEHOLDER: 'Unesite OIB tvrtke',
            IMAGE: 'Profilna slika*',
            UPLOAD_IMAGE: 'Kliknite ili povucite i ispustite za učitavanje',
            CHOOSE_FILE: 'Odaberite datoteku',
            ADDRESS_PERSONAL_PLACEHOLDER: 'Unesite Vašu adresu',
            ADDRESS_BUSINESS_PLACEHOLDER: 'Unesite adresu Vaše tvrtke',
        },
        PROFILE_ACCOUNT: {
            STRIPE: 'Idi na Stripe',
            STRIPE_ALREADY_CONNECTED:
                'Stripe račun je već povezan,ukoliko želite povezati drugi račun, ponovo pritisnite tipku ispod',
            STRIPE_CONNECTED: 'Povezano',
            STRIPE_DISCONNECTED: 'Nije Povezano',
            STRIPE_CARD_DECLINED: 'Greška prilikom izrade Stripe računa',
            SUCCESS_PASSWORD: 'Lozinka je uspješno izmijenjena',
            STRIPE_DEFAULT_PAYMENT_METHOD_UPDATED: 'Primarna metoda plaćanja je izmijenjena',
        },
        CHILD: {
            ADD_TITLE: 'DODAJTE DIJETE',
            EDIT_TITLE: 'AŽURIRAJTE DIJETE',
            FIRST_NAME: 'Ime',
            FIRST_NAME_PLACEHOLDER: 'Unesite ime djeteta',
            LAST_NAME: 'Prezime',
            LAST_NAME_PLACEHOLDER: 'Unesite prezime djeteta',
            USERNAME: 'Korisničko ime',
            USERNAME_PLACEHOLDER: 'Unesite korisničko ime',
            PASSWORD: 'Unesite novu lozinku',
            PASSWORD_PLACEHOLDER: 'Upišite novu lozinku',
            PASSWORD_OPTIONAL:
                'Ovo je polje izborno, ako želite promijeniti djetetovu trenutnu lozinku možete to učiniti u polju ispod.',
            DELETE: 'Izbriši',
            CANCEL: 'Odustani',
        },
        TRANSLATION: {
            TITLE: 'Prijevod',
            SUBTITLE: 'Izaberite jezik aplikacije.',
        },
        SUBMIT: 'Spremi',
        TUTOR_DISABLE: {
            TITLE: 'Vidljivost profila',
            SUBTITLE: 'Profil se prikazuje',
            YES: 'Da',
            NO: 'Ne',
        },
    },
    DASHBOARD: {
        TITLE: 'Upravljačka ploča',
        REQUESTS: {
            TITLE: 'ZAHTJEVI ZA PREDAVANJIMA',
            EMPTY: 'Nema novih zahtjeva',
            RESCHEDULE: 'ZAHTJEVI ZA PROMJENU TERMINA',
            STATUS: {
                RESCHEDULE_REQUESTED: 'Izmjena',
                RESCHEDULE_DO_ACTION: 'Izmjena',
                NEW_BOOKING_REQUESTED: 'Čekanje potvrde',
                NEW_BOOKING_DO_ACTION: 'Nova rezervacija',
            },
            TOOLTIP: {
                PART_1: 'Imate još ',
                PART_2: ' h da prihvatite rezervaciju',
            },
        },
        SCHEDULE: {
            TITLE: 'DANAŠNJI RASPORED',
            BUTTON: 'Spajanje',
            EMPTY: 'Danas nema zakazanih predavanja',
        },
        MESSAGES: {
            TITLE: 'NEPROČITANE PORUKE',
            BUTTON: 'Razgovori',
            EMPTY: 'Nema nepročitanih poruka',
        },
        BOOKINGS: {
            TITLE: 'NADOLAZEĆI TERMINI',
            TOTAL: 'Ukupno',
            EMPTY: 'Nema zakazanih termina',
            EMPTY_SUBTITLE: 'Hmm, izgleda da za sada nemate zakazanih termina.',
            RECOMMENDED: 'PREPORUČENI INSTRUKTORI',
            SHOW_MORE: 'Prikaži više',
        },
        NOTIFICATIONS: {
            TITLE: 'OBAVIJESTI',
            CLEAR: 'Označi kao pročitano',
            ALL: 'Vidi sve',
            EMPTY: 'Nema nepročitanih obavijesti',
        },
        COMING_SOON: {
            TITLE: 'Stiže uskoro!',
            SUBTITLE: 'Naš tim marljivo radi kako bismo dodali ovu funkcionalnost',
        },
    },
    NOTIFICATIONS: {
        TITLE: 'Sve obavijesti',
        EMPTY: 'Nema obavijesti',
        BOOKING_CLASS_STARTING: {
            DESCRIPTION: 'Predavanje započinje uskoro',
            TITLE: 'Predavanje započinje uskoro',
        },
        CHAT_MISSED_CALL: {
            DESCRIPTION: 'Propušten videopoziv od',
            TITLE: 'Propušten videopoziv',
        },
        BOOKING: {
            STUDENT: 'Učenik',
            PARENT: 'Roditelj',
            TUTOR: 'Instruktor',
        },
        BOOKING_NEW_BOOKING: {
            TITLE: 'Nova rezervacija',
            DESCRIPTION: '',
            MADE_BOOKING_TEXT: 'je napravio novu rezervaciju za',
        },
        BOOKING_RESCHEDULE_PROPOSITION: {
            TITLE: 'Predloženo ponovno zakazivanje',
            DESCRIPTION: '',
            PROPOSING_NEW_TIME_TEXT: 'želi predložiti novo vrijeme za rezervaciju',
        },
        BOOKING_ACCEPTED: {
            TITLE: 'Rezervacija prihvaćena',
            DESCRIPTION: 'je prihvatio/la rezervaciju za',
        },
        BOOKING_CANCELED: {
            TITLE: 'Rezervacija otkazana',
            DESCRIPTION: '',
            CANCEL_TEXT: 'je otkazao/la',
        },
        BOOKING_DELETED: {
            TITLE: 'Neprihvaćena rezervacija uklonjena',
            DESCRIPTION: {
                PART1: 'Rezervacija je uklonjena jer ju',
                PART2: 'nije prihvatio/la.',
            },
        },
        CHAT_HAS_MISSED_CALL: ' je propustio Vaš poziv',
        HELP_CENTER: {
            TITLE: 'Centar za podršku',
            LINK: 'https://intercom.help/teorem/hr/',
        },
    },
    EARNINGS: {
        TITLE: 'Prihod',
        GENERAL: {
            TITLE: 'STATISTIKA',
            BOOKINGS: 'Ukupno rezervacija',
            STUDENTS: 'Ukupno učenika',
            REVIEWS: 'Ukupno recenzija',
            REVENUE: 'Ukupan prihod',
            CURRENCY: '€',
        },
        PAYOUTS: 'Isplata',
        WEEK_TITLE: 'Tjedan',
        WEEK: 'Zadnjih 7 dana',
        MONTH: 'Zadnjih 30 dana',
        YEAR: 'Zadnjih 12 mjeseci',
        ALLTIME: 'Oduvijek',
        REVENUE: {
            TITLE: 'PRIHOD',
            GRAPH_LEGEND: 'Prihod',
        },
        STUDENTS: {
            TITLE: 'UČENICI',
            GRAPH_LEGEND: 'Učenici',
        },
        BOOKINGS: {
            TITLE: 'REZERVACIJE',
            GRAPH_LEGEND: 'Rezervacije',
        },
        DETAILS: {
            TITLE: 'DETALJI',
            TABLE: {
                MONTH: 'Mjesec',
                BOOKINGS: 'Rezervacije',
                STUDENTS: 'Učenici',
                REVIEWS: 'Recenzije',
                REVENUE: 'Prihod',
                PROVISION: 'Provizija Teorema',
                PAYOUT: 'Isplata',
                EMPTY: 'Nema detalja',
            },
        },
    },
    ROUTER_MODAL: {
        TITLE: 'Spremiti promjene?',
        DESC: 'Želite li spremiti svoje promjene prije odlaska s ove stranice?',
        SAVE: 'Spremi',
        NO: 'Nemoj spremiti',
    },
    TUTOR_PROFILE: {
        AVAILABILITY_EMPTY: 'Instruktor nije ispunio tablicu dostupnosti',
        PRICING: 'Cijena',
        RATING_TITLE: 'Ocjena',
        COMPLETED_LESSONS: 'Odrađenih lekcija',
        BOOK: 'Rezerviraj lekciju',
        TUTOR_DISABLED: 'Privremeno nedostupno',
        SEND: 'Pošalji poruku',
        EMPTY: 'Korisnik nije pronađen',
        ID_VERIFIED: 'Potvrđen identitet',
        DOCUMENTS_UPLOADED: 'Dokumenti učitani',
        SUBJECTS: {
            TITLE: 'Predmeti',
            SUBJECT: 'Predmet',
            QUALIFICATION: 'Kvalifikacija',
            PRICE: 'Cijena',
            HOUR_ABRV: 'h',
            EMPTY: 'Nema ponuđenih predmeta',
        },
        RATING: {
            TITLE: 'Ocjene i recenzije',
            TOTAL: 'ukupno recenzija',
        },
        PRE12: '08-12h',
        ON12: '12-17h',
        AFTER5: '17-20h',
        REVIEWS: 'recenzija',
        TOOLTIP: {
            ID_VERIFIED: 'Instruktor ima potvrđen identitet',
        },
    },
    LANDING: {
        HEADER: {
            NAV_1: 'Kako radimo',
            NAV_2: 'Cijene',
            NAV_3: 'Postanite instruktor',
            SIGN_IN: 'Prijavite se',
            GET_STARTED: 'Započnite danas',
        },
        FOOTER: {
            TITLE_TEXT: 'Ako imate dodatnih pitanja, slobodno nas kontaktirajte putem emaila ',
            APP_NAME: 'Teorem Ltd',
            ADDRESS: '',
            MOBILE: '',
            EMAIL: 'info@teorem.co',
            LEGAL: {
                TITLE: 'O Teoremu',
                LEGAL: 'Uvjeti korištenja',
                PRIVACY: 'Politika privatnosti',
                TUTOR: 'Postanite instruktor',
            },
            PAYMENT: {
                TITLE: 'Metode plaćanja',
            },
        },
    },
    HOW_IT_WORKS: {
        HERO: {
            TITLE: 'Online instrukcije koje oslobađaju potencijal',
            SUBTITLE:
                'Privatna 1-na-1 online predavanja od 50 minuta jedinstveno skrojena za svakog učenika. Svi školski predmeti i dobne skupine pokrivene.',
        },
        SUBTITLE_1: 'Predstavljeno diljem svijeta',
        SUBTITLE_2: 'Često postavljena pitanja',
        TEXT_CARD_1: {
            TITLE: 'Velik krug provjerenih i visoko ocijenjenih instruktora',
            SUBTITLE:
                'Naši instruktori su provjereni kako bismo osigurali da imaju adekvatno iskustvo u predavanju. Uz to, ocijenjeni su od strane roditelja i učenika nakon svakog predavanja.',
        },
        TEXT_CARD_2: {
            TITLE: 'Razgovarajte sa svakim instruktorom besplatno prije rezervacije.',
            SUBTITLE:
                'Podijelite dokumente ili zadatke s instruktorom i razgovarajte putem besplatnog videopoziva kako biste osigurali da instruktor kojeg ste izabrali savršeno odgovara Vašim potrebama.',
        },
        TEXT_CARD_3: {
            TITLE: 'Naša virtualna učionica omogućava Vam ponovno gledanje predavanja.',
            SUBTITLE:
                'Naša su predavanja toliko više od videopoziva. Naša virtualna učionica sadrži digitalnu bijelu ploču, funkciju snimanja, dijeljenje zaslona i još mnogo toga.',
        },
        TEXT_CARD_4: {
            TITLE: 'Platite samo ono što iskoristite.',
            SUBTITLE:
                'Teorem Vam neće ništa naplatiti dok ne pronađete svog savršenog instruktora, porazgovarate s njim i rezervirate svoje prvo predavanje. Bez naknada za registraciju, bez pretplate, samo jednostavno plaćanje po satu. Sigurno i pouzdano plaćanje koje obuhvaća širok raspon opcija.',
        },
        REVIEW_1: {
            TEXT: 'Teorem je pomogao mom sinu popravit ocjene iz fizike! Oduševljena sam da je sve preko interneta i da ga ne moram više vozit na drugi kraj grada!',
            NAME: 'Sandra',
            OCCUPATION: 'Lukasova majka',
        },
        REVIEW_2: {
            TEXT: 'Mogućnost besplatnog videopoziva omogućila mi je da razgovaram s nekoliko instruktora prije nego što sam se odlučila za onoga koji najbolje odgovara potrebama mog djeteta. Ne mogu biti sretnija!',
            NAME: 'Renata',
            OCCUPATION: 'Mateina i Markova majka',
        },
        REVIEW_3: {
            TEXT: 'S Teoremovom virtualnom učionicom napokon mogu radit kroz interaktivne vježbe i zabavan dizajn. Jako mi se sviđa mogućnost ponovnog gledanja predavanja.',
            NAME: 'Ivan',
            OCCUPATION: 'Student ekonomije',
        },
        BUTTON_1: 'Započnite danas',
        FAQ: {
            QUESTION_1: 'Zašto 1-na-1 instrukcije?',
            ANSWER_1:
                '1-na-1 online instrukcije daju djeci mogućnost učenja brzinom koja njima najviše odgovara i načinom koji se najbolje poklapa s njihovim stilom učenja. Tinejdžeri su često presramežljivi da bi podigli ruku u razredu - posebice ako se muče s razumijevanjem. Sigurnost 1-na-1 instrukcija znači da imaju priliku postaviti sva pitanja koja žele i učiti o temama koliko im je god potrebno dok ih ne savladaju.',
            QUESTION_2: 'Koje su prednosti online instrukcija?',
            ANSWER_2:
                'Teoremove online instrukcije pomažu djeci osloboditi svoj puni potencijal. Naša digitalna bijela ploča omogućava instruktorima da objasne komplicirane koncepte na jednostavan i obazriv način. S čime god da Vaše dijete treba pomoć, naši instruktori će pronaći najbolji način za navoditi ga. Uz to, online model uklanja geografska ograničenja u pronalaženju vrhunskog instruktora.',
            QUESTION_3: 'Koje kvalifikacije imaju instruktori?',
            ANSWER_3:
                'Kanditati prolaze kroz više testova prije nego što postanu instruktori na našoj platformi. Provjerava im se znanje individualnih predmeta te osobne i nastavne vještina. Jako malen broj kandidata koji se prijave su izabrani za instruktore.',
            QUESTION_4: 'Kako funkcioniraju online predavanja?',
            ANSWER_4:
                'Imamo našu virtualnu učionicu koja uključuje videopoziv, poruke, i interaktivnu bijelu ploču što učenicima i instruktorima omogućava laku komunikaciju, diskutiranje o kompleksnim temama i zajedničko vježbanje zadataka. S videopozivima, imaju prirodni razgovor - baš kao na FaceTime-u, WhatsAppu i drugim aplikacijama koje tinejdžeri često koriste. Sve naše instrukcije traju 50 minuta.',
        },
    },
    PRICING: {
        HERO: {
            TITLE: 'Najveća vrijednost za Vaš novac',
            SUBTITLE: 'Teorem pomaže učenicima svih dobnih skupina ostvariti svoje akademske ciljeve.',
        },
        SUBTITLE_1: 'Naš proces predavanja',
        SUBTITLE_2: 'Prednosti Teorema',
        SUBTITLE_3: 'Često postavljena pitanja',
        BUTTON_1: 'Rezervirajte svoje besplatne konzultacije',
        TEXT_CARD_1: {
            TITLE: 'Koja je cijena online instrukcija?',
            SUBTITLE:
                'Teoremovi instruktori sami određuju svoje cijene, ovisno o njihovom iskustvu i dostupnosti. Cijene instrukcija kreću se od 10 EUR.',
        },
        FAQ: {
            QUESTION_1: 'Koja je cijena instruktora?',
            ANSWER_1:
                'Teoremovi instruktori sami određuju svoje cijene, ovisno o njihovom iskustvu i dostupnosti. Cijene instrukcija kreću se od 10€. Možete vidjeti sve instruktore koji odgovaraju Vašem budžetu koristeći filtriranje prema cijeni u sekciji “Pretraga instruktora”.',
            QUESTION_2: 'Kako pronaći instruktora?',
            ANSWER_2:
                'Pronaći instruktora je jednostavno. Nakon što otvorite korisnički račun na Teoremu, možete tražiti instruktore i filtrirati rezultate pretraživanja prema predmetu, razini, cijeni i dostupnosti.',
            QUESTION_3: 'Mogu li promijeniti instruktora ako nisam zadovoljan/-na?',
            ANSWER_3:
                'Naravno! Slobodni ste promijeniti instruktora kada god želite, a mi rješavamo administraciju za Vas.',
            QUESTION_4: 'Mogu li dobiti povrat novca?',
            ANSWER_4: 'Da, ako otkažete do 24 sata unaprijed, izdat ćemo Vam puni povrat novca za Vaše predavanje.',
        },
        STEP_1: {
            TITLE: 'Besplatne početne konzultacije',
            SUBTITLE:
                'Tijekom prvog, besplatnog razgovora, Teoremov instruktor utvrdit će potrebe djeteta i odrediti idealan plan učenja temeljen na osobnosti učenika, pristupu učenju i ostalim potrebama.',
        },
        STEP_2: {
            TITLE: 'Probno predavanje',
            SUBTITLE:
                'Probno predavanje daje Vam priliku vidjeti kako instruktor predaje i sviđa li Vam se Teorem platforma.',
        },
        STEP_3: {
            TITLE: 'Suradnja',
            SUBTITLE:
                'Teoremove instrukcije su jednostavne i bez muke. Nakon registracije, sve što trebate, pronaći ćete na našoj platformi. Možete nadgledati napredak, mijenjati vrijeme instrukcija, rezervirati nove instrukcije, otkazati ih ili kontaktirati instruktora, sve u samo nekoliko klikova.',
        },
        CARD_1: {
            TITLE: 'Fleksibilnost',
            SUBTITLE: 'Jednostavno i fleksibilno zakazivanje prilagođeno Vama.',
        },
        CARD_2: {
            TITLE: 'Virtualna učionica',
            SUBTITLE:
                'Dostupno na svim uređajima, s ugrađenom funkcijom snimanja, digitalnom bijelom pločom te dijeljenjem ekrana i dokumenata.',
        },
        CARD_3: {
            TITLE: 'Sigurno plaćanje',
            SUBTITLE:
                'Sigurno i pouzdano plaćanje u suradnji sa Stripe-om. Bez skrivenih naknada, plaćaju se samo satovi instrukcija, a moguć je i povrat novca.',
        },
        CARD_4: {
            TITLE: 'Podrška',
            SUBTITLE: '24/7 pristup podršci. Naš posvećeni tim podrške dostupan Vam je u svakom trenutku.',
        },
    },
    BECOME_A_TUTOR: {
        HERO: {
            TITLE: 'Počnite podučavati online',
            SUBTITLE: 'Držite instrukcije kada god Vi to želite, iz udobnosti vlastitog doma.',
        },
        TEXT_CARD_1: {
            TITLE: 'Teoremove online instrukcije olakšavaju Vaš život',
            SUBTITLE:
                'Pružit ćemo Vam sve što trebate za podučavanje online. Pomažemo Vam pronaći učenike i upravljati rasporedom.',
        },
        TEXT_CARD_2: {
            TITLE: 'Odredite svoju cijenu i dostupnost!',
            SUBTITLE: 'Koristite naš alat kako biste pratili koliko zarađujete i povećali bazu Vaših klijenata!',
        },
        REVIEW_1: {
            TEXT: 'Uz Teorem sam si stvorio stabilan mjesečni prihod i ne moram se više toliko brinut o svojim troškovima!',
            NAME: 'Ante',
            OCCUPATION: 'Instruktor fizike',
        },
        REVIEW_2: {
            TEXT: 'Zbog Teorema sam se uspjela preselit na Vis i nastavit davat instrukcije učenicima u Zagrebu!',
            NAME: 'Dunja',
            OCCUPATION: 'Instruktorica njemačkog',
        },
        SUBTITLE_1: 'Ovdje smo da Vam pomognemo napredovati!',
        SUBTITLE_2: 'Kako postati online instruktor na Teoremu',
        SUBTITLE_3: 'Često postavljena pitanja',
        BUTTON_1: 'Započnite danas',
        FAQ: {
            QUESTION_1: 'Kako postati online instruktor?',
            ANSWER_1:
                'Ako Vas zanima kako da započnete s davanjem instrukcija, došli ste na pravo mjesto! Samo stvorite korisnički račun, rezervirajte svoj intervju i mi ćemo Vam pomoći započeti!',
            QUESTION_2: 'Koji su zahtjevi za instruktore?',
            ANSWER_2:
                'Obično zahtijevamo da se naši instruktori odluče za najmanje 6 predavanja tjedno. Najniža razina kvalifikacije koju instruktori moraju imati je srednja škola. Instruktori moraju imati računalo i stabilnu internetsku vezu kako bi predavali online.',
            QUESTION_3: 'Koliko je dugo potrebno čekati kako bih započeo/la s održavanjem online predavanja?',
            ANSWER_3:
                'Budući da se registracijski proces sastoji od nekoliko jednostavnih koraka, obično treba samo 1 dan kako bi se izvršila verifikacija i krenulo s online predavanjem.',
            QUESTION_4: 'Kako izgleda radni odnos?',
            ANSWER_4:
                'Vi nudite nezavisne instrukcije koristeći Teorem platformu. Nema radnog odnosa te ste sami odgovorni za sve porezne kontribucije.',
            QUESTION_5: 'Što razlikuje Teorem od ostalih platformi?',
            ANSWER_5:
                'Mi Vam garantiramo stabilan dotok novih učenika te se brinemo o svemu tako da se Vi možete u potpunosti posvetiti predavanjima.',
            QUESTION_6: 'Kako Teorem zarađuje?',
            ANSWER_6: 'Teorem uzima proviziju od 15% od instruktora.',
        },
        CARD_1: {
            TITLE: '"Remote" rad',
            SUBTITLE: 'Sve što Vam treba su računalo i internet',
        },
        CARD_2: {
            TITLE: 'Alat za Vas',
            SUBTITLE: 'Sustav rezervacija, chat, videopozivi i više.',
        },
        CARD_3: {
            TITLE: 'Stabilan prihod',
            SUBTITLE: 'Mi osiguravamo da budete plaćeni.',
        },
        CARD_4: {
            TITLE: 'Odredite svoju cijenu',
            SUBTITLE: 'Nema ograničenja u pogledu onoga što možete zaraditi.',
        },
        CARD_5: {
            TITLE: 'Podrška 24/7',
            SUBTITLE: 'Mi brinemo da svi Vaši problemi budu riješeni.',
        },
        CARD_6: {
            TITLE: 'Poboljšajte svoje podučavanje',
            SUBTITLE: 'Mi brinemo o administraciji, a Vi se samo fokusirajte na predavanje.',
        },
        STEP_1: 'Stvorite svoj korisnički račun i ispunite podatke.',
        STEP_2: 'Učitajte kratak video da dokažete svoj identitet.',
        STEP_3: 'Dovršite svoj profil i počnite podučavati!',
    },
    TOKEN_NOT_VALID: {
        TITLE: 'Neispravan token!',
        DESCRIPTION: 'Molimo provjerite svoj sandučiċ i pokušajte ponovno',
        HINT: '(Ako ste već zatražili novi aktivacijski e-mail, provjerite jeste li koristili najnoviji link, odnosno zadnji e-mail koji ste primili.)',
        BUTTON: 'Prijava',
    },
    STRIPE_CONNECTED: {
        WELCOME: 'Uspjeh!',
        DESCRIPTION: 'Stripe je uspješno povezan.',
        BUTTON: 'Nastavi',
    },
    STRIPE_FAIL: {
        WELCOME: 'Nešto je pošlo po krivu.',
        DESCRIPTION: 'Stripe nije uspješno povezan. Molimo pokušajte ponovno.',
        BUTTON: 'Nastavi',
    },
    STRIPE_CONNECT: {
        TITLE: 'Isplate',
        ADDRESS: 'Adresa',
        LINE_1: 'Adresa linija 1',
        LINE_2: 'Adresa linija 2',
        POST_CODE: 'Poštanski broj',
        STATE: 'Država',
        LAST_4_SSN: 'Zadnje 4 znamenke SSN-a',
        CITY: 'Grad',
        ROUTING_NUMBER: 'Broj usmjeravanja',
        ACCOUNT_NUMBER: 'Broj računa',
        IBAN: 'IBAN',
        IBAN_CONFIRM: 'IBAN',
        SAVE: 'Spremi',
        CANCEL: 'Odustani',
        TERMS: '<div>Klikom na gumb slažete se s <a href="https://stripe.com/legal/connect-account" target="_blank">Uvjetima pružanja usluge</a>.</div>',

        SUCCESS: 'Račun za plaćanje povezan',
    },
    SUBJECTS: {
        'classical-greek': 'Klasični grčki jezik',
        'government-and-politics': 'Politika i gospodarstvo',
        biology: 'Biologija',
        chemistry: 'Kemija',
        economics: 'Ekonomija',
        english: 'Engleski jezik',
        croatian: 'Hrvatski jezik',
        geography: 'Geografija',
        german: 'Njemački jezik',
        history: 'Povijest',
        it: 'IT',
        italian: 'Talijanski jezik',
        maths: 'Matematika',
        music: 'Glazbena kultura',
        physics: 'Fizika',
        polish: 'Poljski jezik',
        psychology: 'Psihologija',
        religion: 'Vjeronauk',
        russian: 'Ruski jezik',
        sociology: 'Sociologija',
        spanish: 'Španjolski jezik',
        businessstudies: 'Poslovne studije',
        classicalgreek: 'Klasični grčki jezik',
        computing: 'Računarstvo',
        designandtechnology: 'Dizajn i tehnologija',
        drama: 'Drama',
        environmentalstudies: 'Zaštita okoliša',
        french: 'Francuski jezik',
        governmentandpolitics: 'Politika i gospodarstvo',
        latin: 'Latinski jezik',
        science: 'Znanost',
        art: 'Umjetnost',
        accounting: 'Računovodstvo',
        architecture: 'Arhitektura',
        chemicalengineering: 'Kemijsko inženjerstvo',
        law: 'Pravo',
        medicine: 'Medicina',
        philosophy: 'Filozofija',
        politics: 'Politika',
        statistics: 'Statistika',
        calculus: 'Kalkulus',
        economy: 'Ekonomija',
        precalculus: 'Prekalkulus',
        reading: 'Čitanje',
        writing: 'Pisanje',
    },
    SUBJECTS_GENITIVE: {
        biology: 'Biologije',
        chemistry: 'Kemije',
        economics: 'Ekonomije',
        english: 'Engleskog jezika',
        croatian: 'Hrvatskog jezika',
        geography: 'Geografije',
        german: 'Njemačkog jezika',
        history: 'Povijesti',
        it: 'IT-ja',
        italian: 'Talijanskog jezika',
        maths: 'Matematike',
        music: 'Glazbene kulture',
        physics: 'Fizike',
        polish: 'Poljskog jezika',
        psychology: 'Psihologije',
        religion: 'Vjeronauka',
        russian: 'Ruskog jezika',
        sociology: 'Sociologije',
        spanish: 'Španjolskog jezika',
        businessstudies: 'Poslovnih studija',
        classicalgreek: 'Klasičnog grčkog jezika',
        computing: 'Računarstva',
        designandtechnology: 'Dizajna i tehnologije',
        drama: 'Drame',
        environmentalstudies: 'Zaštite okoliša',
        french: 'Francuskog jezika',
        governmentandpolitics: 'Politike i gospodarstva',
        latin: 'Latinskog jezika',
        science: 'Znanosti',
        art: 'Umjetnosti',
        accounting: 'Računovodstva',
        architecture: 'Arhitekture',
        chemicalengineering: 'Kemijskog inženjerstva',
        law: 'Prava',
        medicine: 'Medicine',
        philosophy: 'Filozofije',
        politics: 'Politike',
        statistics: 'Statistike',
        calculus: 'Kalkulusa',
        economy: 'Ekonomije',
        precalculus: 'Prekalkulusa',
        reading: 'Čitanja',
        writing: 'Pisanja',
        'classical-greek': 'Klasičnog grčkog jezika',
        'government-and-politics': 'Politike i gospodarstva',
    },
    SEO_TITLE: {
        TUTOR_SEARCH: 'Online instruktori i učitelji :subject za privatne instrukcije :subject',
        TUTOR_PROFILE: ' | Uči s instruktorima ',
    },
    ROLES: {
        student: 'Učenik',
        parent: 'Roditelj',
        child: 'Dijete',
        tutor: 'Instruktor',
        admin: 'Administrator',
    },
    LEVELS: {
        primaryschool: 'Osnovna škola',
        highschool: 'Srednja škola',
        ib: 'IB',
        maturaprep: 'Priprema za maturu',
        university: 'Fakultet',
        'primary-school': 'Osnovna škola',
        'high-school': 'Srednja škola',
        'matura-prep': 'Priprema za maturu',
        elementary: 'Osnovna škola',
        middle: 'Srednja škola',
        high: 'Srednja škola',
        ap: 'Za napredne',
        sat: 'Priprema za SAT',
    },
    DEGREES: {
        bacc: 'preddiplomski',
        master: 'Magisterij',
        phd: 'Doktorat',
        mag: 'Magisterij',
        postdipl: 'Postdiplomski',
        other: 'Ostalo',
    },
    UNIVERSITIES: {
        AgronomskifakultetSveuiliteuZagrebu: 'Agronomski fakultet - Sveučilište u Zagrebu',
        AkademijadramskeumjetnostiSveuiliteuZagrebu: 'Akademija dramske umjetnosti - Sveučilište u Zagrebu',
        AkademijalikovnihumjetnostiSveuiliteuZagrebu: 'Akademija likovnih umjetnosti - Sveučilište u Zagrebu',
        AkademijaprimijenjenihumjetnostiSveuiliteuRijeci: 'Akademija primijenjenih umjetnosti - Sveučilište u Rijeci',
        ArhitektonskifakultetSveuiliteuZagrebu: 'Arhitektonski fakultet - Sveučilište u Zagrebu',
        BiotehnikiodjelSveuiliteuSlavonskomBrodu: 'Biotehnički odjel - Sveučilište u Slavonskom Brodu',
        EdukacijskorehabilitacijskifakultetSveuiliteuZagrebu:
            'Edukacijsko-rehabilitacijski fakultet - Sveučilište u Zagrebu',
        EdwardBernaysUniversityofAppliedSciences: 'Edward Bernays University of Applied Sciences',
        EffectusVeleuilite: 'Effectus Veleučilište',
        EkonomskifakultetSveuiliteuOsijeku: 'Ekonomski fakultet - Sveučilište u Osijeku',
        EkonomskifakultetSveuiliteuRijeci: 'Ekonomski fakultet - Sveučilište u Rijeci',
        EkonomskifakultetSveuiliteuSplitu: 'Ekonomski fakultet - Sveučilište u Splitu',
        EkonomskifakultetSveuiliteuZagrebu: 'Ekonomski fakultet - Sveučilište u Zagrebu',
        EuropskaposlovnakolaZagreb: 'Europska poslovna škola Zagreb',
        Evaneoskoteolokoveleuilite: 'Evanđeosko teološko veleučilište',
        FakultetagrobiotehnikihznanostiSveuiliteuOsijeku: 'Fakultet agrobiotehničkih znanosti - Sveučilište u Osijeku',
        FakultetbiotehnologijeirazvojalijekovaSveuiliteuRijeci:
            'Fakultet biotehnologije i razvoja lijekova - Sveučilište u Rijeci',
        FakultetdentalnemedicineSveuiliteuRijeci: 'Fakultet dentalne medicine - Sveučilište u Rijeci',
        FakultetelektrotehnikeiraunarstvaSveuiliteuZagrebu:
            'Fakultet elektrotehnike i računarstva - Sveučilište u Zagrebu',
        FakultetelektrotehnikestrojarstvaibrodogradnjeSveuiliteuSplitu:
            'Fakultet elektrotehnike, strojarstva i brodogradnje - Sveučilište u Splitu',
        FakultetfilozofijeireligijskihznanostiSveuiliteuZagrebu:
            'Fakultet filozofije i religijskih znanosti - Sveučilište u Zagrebu',
        FakultetgraevinarstvaarhitektureigeodezijeSveuiliteuSplitu:
            'Fakultet građevinarstva, arhitekture i geodezije - Sveučilište u Splitu',
        FakultethrvatskihstudijaSveuiliteuZagrebu: 'Fakultet hrvatskih studija - Sveučilište u Zagrebu',
        FakultetinformatikeidigitalnihtehnologijaSveuiliteuRijeci:
            'Fakultet informatike i digitalnih tehnologija - Sveučilište u Rijeci',
        FakultetkemijskoginenjerstvaitehnologijeSveuiliteuZagrebu:
            'Fakultet kemijskog inženjerstva i tehnologije - Sveučilište u Zagrebu',
        FakultetorganizacijeiinformatikeSveuiliteuZagrebu:
            'Fakultet organizacije i informatike - Sveučilište u Zagrebu',
        FakultetpolitikihznanostiSveuiliteuZagrebu: 'Fakultet političkih znanosti - Sveučilište u Zagrebu',
        FakultetprometnihznanostiSveuiliteuZagrebu: 'Fakultet prometnih znanosti - Sveučilište u Zagrebu',
        FakultetstrojarstvaibrodogradnjeSveuiliteuZagrebu:
            'Fakultet strojarstva i brodogradnje - Sveučilište u Zagrebu',
        FakultetumarstvaidrvnetehnologijeSveuiliteuZagrebu:
            'Fakultet šumarstva i drvne tehnologije - Sveučilište u Zagrebu',
        FakultetturizmairuralnograzvojauPoegiFTRRSveuiliteuOsijeku:
            'Fakultet turizma i ruralnog razvoja u Požegi (FTRR) - Sveučilište u Osijeku',
        FakultetzafizikuSveuiliteuRijeci: 'Fakultet za fiziku - Sveučilište u Rijeci',
        FakultetzamatematikuSveuiliteuRijeci: 'Fakultet za matematiku - Sveučilište u Rijeci',
        FakultetzamenadmentuturizmuiugostiteljstvuSveuiliteuRijeci:
            'Fakultet za menadžment u turizmu i ugostiteljstvu - Sveučilište u Rijeci',
        FakultetzdravstvenihstudijaSveuiliteuRijeci: 'Fakultet zdravstvenih studija - Sveučilište u Rijeci',
        FarmaceutskobiokemijskifakultetSveuiliteuZagrebu: 'Farmaceutsko-biokemijski fakultet - Sveučilište u Zagrebu',
        FilozofskifakultetSveuiliteJurjaDobrileuPuli: 'Filozofski fakultet - Sveučilište Jurja Dobrile u Puli',
        FilozofskifakultetSveuiliteuRijeci: 'Filozofski fakultet - Sveučilište u Rijeci',
        FilozofskifakultetSveuiliteuSplitu: 'Filozofski fakultet - Sveučilište u Splitu',
        FilozofskifakultetSveuiliteuZagrebu: 'Filozofski fakultet - Sveučilište u Zagrebu',
        GeodetskifakultetSveuiliteuZagrebu: 'Geodetski fakultet - Sveučilište u Zagrebu',
        Geotehnikifakultet: 'Geotehnički fakultet',
        GeotehnikifakultetSveuiliteuZagrebu: 'Geotehnički fakultet - Sveučilište u Zagrebu',
        GraevinskifakultetSveuiliteuRijeci: 'Građevinski fakultet - Sveučilište u Rijeci',
        GraevinskifakultetSveuiliteuZagrebu: 'Građevinski fakultet - Sveučilište u Zagrebu',
        GrafikifakultetSveuiliteuZagrebu: 'Grafički fakultet - Sveučilište u Zagrebu',
        Hrvatskokatolikosveuilite: 'Hrvatsko katoličko sveučilište',
        Istarskoveleuilite: 'Istarsko veleučilište',
        KatolikibogoslovnifakultetSveuiliteuSplitu: 'Katolički bogoslovni fakultet - Sveučilište u Splitu',
        KatolikibogoslovnifakultetSveuiliteuZagrebu: 'Katolički bogoslovni fakultet - Sveučilište u Zagrebu',
        KemijskotehnolokifakultetSveuiliteuSplitu: 'Kemijsko-tehnološki fakultet - Sveučilište u Splitu',
        KineziolokifakultetSveuiliteuSplitu: 'Kineziološki fakultet - Sveučilište u Splitu',
        KineziolokifakultetSveuiliteuZagrebu: 'Kineziološki fakultet - Sveučilište u Zagrebu',
        Libertasmeunarodnosveuilite: 'Libertas međunarodno sveučilište',
        MedicinskifakultetSveuiliteuRijeci: 'Medicinski fakultet - Sveučilište u Rijeci',
        MedicinskifakultetSveuiliteuSplitu: 'Medicinski fakultet - Sveučilište u Splitu',
        MedicinskifakultetSveuiliteuZagrebu: 'Medicinski fakultet - Sveučilište u Zagrebu',
        Meimurskoveleuiliteuakovcu: 'Međimursko veleučilište u Čakovcu',
        MetalurkifakultetSveuiliteuZagrebu: 'Metalurški fakultet - Sveučilište u Zagrebu',
        MuzikaakademijaSveuiliteuZagrebu: 'Muzička akademija - Sveučilište u Zagrebu',
        OdjeldrutvenohumanistikihznanostiSveuiliteuSlavonskomBrodu:
            'Odjel društveno-humanističkih znanosti - Sveučilište u Slavonskom Brodu',
        OdjelzaanglistikuSveuiliteuZadru: 'Odjel za anglistiku - Sveučilište u Zadru',
        OdjelzaarheologijuSveuiliteuZadru: 'Odjel za arheologiju - Sveučilište u Zadru',
        OdjelzaekologijuagronomijuiakvakulturuSveuiliteuZadru:
            'Odjel za ekologiju, agronomiju i akvakulturu - Sveučilište u Zadru',
        OdjelzaekonomijuSveuiliteuZadru: 'Odjel za ekonomiju - Sveučilište u Zadru',
        OdjelzaekonomijuiposlovnuekonomijuSveuiliteuDubrovniku:
            'Odjel za ekonomiju i poslovnu ekonomiju - Sveučilište u Dubrovniku',
        OdjelzaelektrotehnikuiraunarstvoSveuiliteuDubrovniku:
            'Odjel za elektrotehniku i računarstvo - Sveučilište u Dubrovniku',
        OdjelzaetnologijuikulturnuantropologijuSveuiliteuZadru:
            'Odjel za etnologiju i kulturnu antropologiju - Sveučilište u Zadru',
        OdjelzafilozofijuSveuiliteuZadru: 'Odjel za filozofiju - Sveučilište u Zadru',
        OdjelzafrancuzeifrankofonskestudijeSveuiliteuZadru:
            'Odjel za francuze i frankofonske studije - Sveučilište u Zadru',
        OdjelzageografijuSveuiliteuZadru: 'Odjel za geografiju - Sveučilište u Zadru',
        OdjelzagermanistikuSveuiliteuZadru: 'Odjel za germanistiku - Sveučilište u Zadru',
        OdjelzahispanikuiiberskestudijeSveuiliteuZadru: 'Odjel za hispaniku i iberske studije - Sveučilište u Zadru',
        OdjelzahumanistikeznanostiSveuiliteuDubrovniku: 'Odjel za humanističke znanosti - Sveučilište u Dubrovniku',
        OdjelzainformacijskeznanostiSveuiliteuZadru: 'Odjel za informacijske znanosti - Sveučilište u Zadru',
        OdjelzaizobrazbuuiteljaiodgajateljaSveuiliteuZadru:
            'Odjel za izobrazbu učitelja i odgajatelja - Sveučilište u Zadru',
        OdjelzaklasinufilologijuSveuiliteuZadru: 'Odjel za klasičnu filologiju - Sveučilište u Zadru',
        OdjelzakomunikologijuSveuiliteuDubrovniku: 'Odjel za komunikologiju - Sveučilište u Dubrovniku',
        OdjelzakroatistikuSveuiliteuZadru: 'Odjel za kroatistiku - Sveučilište u Zadru',
        OdjelzalingvistikuSveuiliteuZadru: 'Odjel za lingvistiku - Sveučilište u Zadru',
        OdjelzanastavnikestudijeuGospiuSveuiliteuZadru: 'Odjel za nastavničke studije u Gospiću - Sveučilište u Zadru',
        OdjelzapedagogijuSveuiliteuZadru: 'Odjel za pedagogiju - Sveučilište u Zadru',
        OdjelzapovijestSveuiliteuZadru: 'Odjel za povijest - Sveučilište u Zadru',
        OdjelzapovijestiumjetnostiSveuiliteuZadru: 'Odjel za povijesti umjetnosti - Sveučilište u Zadru',
        OdjelzaprimijenjenuekologijuSveuiliteuDubrovniku: 'Odjel za primijenjenu ekologiju - Sveučilište u Dubrovniku',
        OdjelzaprirodneizdravstevnestudijeSveuiliteJurjaDobrileuPuli:
            'Odjel za prirodne i zdravstevne studije - Sveučilište Jurja Dobrile u Puli',
        OdjelzapsihologijuSveuiliteuZadru: 'Odjel za psihologiju - Sveučilište u Zadru',
        OdjelzarusistikuSveuiliteuZadru: 'Odjel za rusistiku - Sveučilište u Zadru',
        OdjelzasociologijuSveuiliteuZadru: 'Odjel za sociologiju - Sveučilište u Zadru',
        OdjelzastrunestudijeSveuiliteuDubrovniku: 'Odjel za stručne studije - Sveučilište u Dubrovniku',
        OdjelzatalijanistikuSveuiliteuZadru: 'Odjel za talijanistiku - Sveučilište u Zadru',
        OdjelzaturizamikomunikacijskeznanostiSveuiliteuZadru:
            'Odjel za turizam i komunikacijske znanosti - Sveučilište u Zadru',
        OdjelzaumjetnostirestauracijuSveuiliteuDubrovniku:
            'Odjel za umjetnost i restauraciju - Sveučilište u Dubrovniku',
        OdjelzazdravstvenestudijeSveuiliteuZadru: 'Odjel za zdravstvene studije - Sveučilište u Zadru',
        Policijskaakademija: 'Policijska akademija',
        PomorskifakultetSveuiliteuRijeci: 'Pomorski fakultet - Sveučilište u Rijeci',
        PomorskifakultetSveuiliteuSplitu: 'Pomorski fakultet - Sveučilište u Splitu',
        PomorskiodjelSveuiliteuDubrovniku: 'Pomorski odjel - Sveučilište u Dubrovniku',
        PomorskiodjelSveuiliteuZadru: 'Pomorski odjel - Sveučilište u Zadru',
        PoslovnoveleuiliteZagreb: 'Poslovno veleučilište Zagreb',
        PravnifakultetSveuiliteuOsijeku: 'Pravni fakultet - Sveučilište u Osijeku',
        PravnifakultetSveuiliteuRijeci: 'Pravni fakultet - Sveučilište u Rijeci',
        PravnifakultetSveuiliteuSplitu: 'Pravni fakultet - Sveučilište u Splitu',
        PravnifakultetSveuiliteuZagrebu: 'Pravni fakultet - Sveučilište u Zagrebu',
        PrehrambenobiotehnolokifakultetSveuiliteuZagrebu: 'Prehrambeno-biotehnološki fakultet - Sveučilište u Zagrebu',
        PrirodoslovnomatematikifakultetSveuiliteuSplitu: 'Prirodoslovno-matematički fakultet - Sveučilište u Splitu',
        PrirodoslovnomatematikifakultetSveuiliteuZagrebu: 'Prirodoslovno-matematički fakultet - Sveučilište u Zagrebu',
        RITCroatia: 'RIT Croatia',
        RRiFVisokakolazafinancijskimenadment: 'RRiF Visoka škola za financijski menadžment',
        RudarskogeolokonaftnifakultetRNGFSveuiliteuZagrebu:
            'Rudarsko-geološko-naftni fakultet (RNGF) - Sveučilište u Zagrebu',
        StomatolokifakultetSveuiliteuZagrebu: 'Stomatološki fakultet - Sveučilište u Zagrebu',
        StrojarskifakultetSveuiliteuSlavonskomBrodu: 'Strojarski fakultet - Sveučilište u Slavonskom Brodu',
        SveuilinicentarzaprotestantskuteologijuMatijaVlaiIlirikSveuiliteuZagrebu:
            'Sveučilišni centar za protestantsku teologiju Matija Vlačić Ilirik - Sveučilište u Zagrebu',
        SveuiliniodjelzaforenzineznanostiSveuiliteuSplitu:
            'Sveučilišni odjel za forenzične znanosti - Sveučilište u Splitu',
        SveuiliniodjelzastrunestudijeSveuiliteuSplitu: 'Sveučilišni odjel za stručne studije - Sveučilište u Splitu',
        SveuiliniodjelzastudijemoraSveuiliteuSplitu: 'Sveučilišni odjel za studije mora - Sveučilište u Splitu',
        SveuiliniodjelzdravstvenihstudijaSveuiliteuSplitu:
            'Sveučilišni odjel zdravstvenih studija - Sveučilište u Splitu',
        SveuiliteAlgebra: 'Sveučilište Algebra',
        SveuiliteobraneisigurnostiDrFranjoTuman: 'Sveučilište obrane i sigurnosti "Dr. Franjo Tuđman"',
        SveuiliteSjever: 'Sveučilište Sjever',
        SveuiliteVERN: 'Sveučilište VERN',
        TehnikifakultetSveuiliteuRijeci: 'Tehnički fakultet - Sveučilište u Rijeci',
        TehnikiodjelSveuiliteuSlavonskomBrodu: 'Tehnički odjel - Sveučilište u Slavonskom Brodu',
        Tehnikoveleuilite: 'Tehničko veleučilište',
        TekstilnotehnolokifakultetTTFSveuiliteuZagrebu: 'Tekstilno-tehnološki fakultet (TTF) - Sveučilište u Zagrebu',
        TeolokokatehetskiodjelSveuiliteuZadru: 'Teološko-katehetski odjel - Sveučilište u Zadru',
        UiteljskifakultetSveuiliteuRijeci: 'Učiteljski fakultet - Sveučilište u Rijeci',
        UiteljskifakultetSveuiliteuZagrebu: 'Učiteljski fakultet - Sveučilište u Zagrebu',
        UmjetnikaakademijaSveuiliteuSplitu: 'Umjetnička akademija - Sveučilište u Splitu',
        UNITEDPOPakademija: 'UNITEDPOP akademija',
        VeleuiliteLavoslavRuika: 'Veleučilište "Lavoslav Ružička"',
        VeleuiliteMarkoMaruli: 'Veleučilište "Marko Marulić"',
        VeleuiliteNikolaTesla: 'Veleučilište "Nikola Tesla"',
        VeleuiliteARCA: 'Veleučilište ARCA',
        VeleuiliteAspira: 'Veleučilište Aspira',
        VeleuiliteBaltazarZaprei: 'Veleučilište Baltazar Zaprešić',
        VeleuiliteHrvatskozagorjeKrapina: 'Veleučilište Hrvatsko zagorje Krapina',
        VeleuiliteIvaniGrad: 'Veleučilište Ivanić-Grad',
        VeleuilitemenadmentaisigurnostiSECURUS: 'Veleučilište menadžmenta i sigurnosti SECURUS',
        VeleuilitePAR: 'Veleučilište PAR',
        VeleuilitespravomjavnostiBALTAZARZAPREI: 'Veleučilište s pravom javnosti BALTAZAR ZAPREŠIĆ',
        Veleuilitestudijasigurnosti: 'Veleučilište studija sigurnosti',
        Veleuilitesuvremenihinformacijskihtehnologija: 'Veleučilište suvremenih informacijskih tehnologija',
        VeleuiliteuBjelovaru: 'Veleučilište u Bjelovaru',
        VeleuiliteuKarlovcu: 'Veleučilište u Karlovcu',
        VeleuiliteuKrievcima: 'Veleučilište u Križevcima',
        VeleuiliteuRijeci: 'Veleučilište u Rijeci',
        Veleuiliteuibeniku: 'Veleučilište u Šibeniku',
        VeleuiliteuVirovitici: 'Veleučilište u Virovitici',
        VeleuiliteVelikaGorica: 'Veleučilište Velika Gorica',
        VeterinarskifakultetSveuiliteuZagrebu: 'Veterinarski fakultet - Sveučilište u Zagrebu',
        VSITEVisokakolazainformacijsketehnologijeuZagrebu: 'VSITE: Visoka škola za informacijske tehnologije u Zagrebu',
        ZagrebakakolaekonomijeimanagementaZEM: 'Zagrebačka škola ekonomije i managementa (ZŠEM)',
        ZdravstvenoveleuiliteuZagrebu: 'Zdravstveno veleučilište u Zagrebu',
        MarywoodUniversity: 'Marywood University',
        LindenwoodUniversity: 'Lindenwood University',
        SullivanUniversity: 'Sullivan University',
        FloridaStateCollegeatJacksonville: 'Florida State College at Jacksonville',
        XavierUniversity: 'Xavier University',
        TusculumCollege: 'Tusculum College',
        ClaremontSchoolofTheology: 'Claremont School of Theology',
        ColumbiaCollegeSC: 'Columbia College (SC)',
        ChabotLasPositasCommunityCollegeDistrict: 'Chabot-Las Positas Community College District',
        KellerGraduateSchoolofManagement: 'Keller Graduate School of Management',
        MonroeCollege: 'Monroe College',
        SanMateoCountyCommunityCollegeDistrict: 'San Mateo County Community College District',
        LosRiosCommunityCollegeDistrict: 'Los Rios Community College District',
        DigiPenInstituteofTechnology: 'DigiPen Institute of Technology',
        UniversityofPittsburghMedicalCenter: 'University of Pittsburgh Medical Center',
        ClaremontGraduateUniversity: 'Claremont Graduate University',
        TheUniversityofTexasatRioGrandeValley: 'The University of Texas at Rio Grande Valley',
        CollegeofMountSaintVincent: 'College of Mount Saint Vincent',
        UniversityofArkansasSystemeVersity: 'University of Arkansas System eVersity',
        ECPIUniversity: 'ECPI University',
        UniversityofMaryWashington: 'University of Mary Washington',
        BaldwinWallaceUniversity: 'Baldwin Wallace University',
        CaliforniaStateUniversityChannelIslands: 'California State University Channel Islands',
        BrandmanUniversity: 'Brandman University',
        UnitedStatesCoastGuardAcademy: 'United States Coast Guard Academy',
        AthensStateUniversity: 'Athens State University',
        TheScrippsResearchInstitute: 'The Scripps Research Institute',
        EasternFloridaStateCollege: 'Eastern Florida State College',
        WesternNewEnglandUniversity: 'Western New England University',
        KingUniversity: 'King University',
        GeorgiaGwinnettCollege: 'Georgia Gwinnett College',
        TridentUniversity: 'Trident University',
        AlliantInternationalUniversity: 'Alliant International University',
        MississippiValleyStateUniversity: 'Mississippi Valley State University',
        RooseveltUniversity: 'Roosevelt University',
        ITTTechnicalInstitute: 'ITT Technical Institute',
        IllinoisEasternCommunityColleges: 'Illinois Eastern Community Colleges',
        ParkUniversity: 'Park University',
        IcahnSchoolofMedicineatMountSinai: 'Icahn School of Medicine at Mount Sinai',
        UtahValleyUniversity: 'Utah Valley University',
        WisconsinLutheranCollege: 'Wisconsin Lutheran College',
        RiversideCommunityCollegeDistrict: 'Riverside Community College District',
        WakeForestBaptistHealth: 'Wake Forest Baptist Health',
        UniversityofMassachusettsBoston: 'University of Massachusetts Boston',
        FloridaPolytechnicUniversity: 'Florida Polytechnic University',
        WagnerCollege: 'Wagner College',
        WilmingtonUniversity: 'Wilmington University',
        InternationalTechnologicalUniversity: 'International Technological University',
        YoungHarrisCollege: 'Young Harris College',
        UniversityofFindlay: 'University of Findlay',
        PhiladelphiaCollegeofOsteopathicMedicine: 'Philadelphia College of Osteopathic Medicine',
        YosemiteCommunityCollegeDistrict: 'Yosemite Community College District',
        CoastalAlabamaCommunityCollege: 'Coastal Alabama Community College',
        PurdueUniversityNorthwest: 'Purdue University Northwest',
        ColumbiaBasinCollege: 'Columbia Basin College',
        SeattleColleges: 'Seattle Colleges',
        LipscombUniversity: 'Lipscomb University',
        TiffinUniversity: 'Tiffin University',
        TroyUniversity: 'Troy University',
        CaliforniaBaptistUniversity: 'California Baptist University',
        StevensonUniversity: 'Stevenson University',
        MarineBiologicalLaboratory: 'Marine Biological Laboratory',
        MaryvilleUniversity: 'Maryville University',
        TheArtInstitutes: 'The Art Institutes',
        UniversityofMississippiMedicalCenter: 'University of Mississippi Medical Center',
        UniversityofCentralOklahoma: 'University of Central Oklahoma',
        HallmarkUniversity: 'Hallmark University',
        KeiserUniversity: 'Keiser University',
        StJohnsRiverStateCollege: 'St. Johns River State College',
        IndianRiverStateCollege: 'Indian River State College',
        BarnardCollege: 'Barnard College',
        CaliforniaSouthernUniversity: 'California Southern University',
        UniversityofLaVerne: 'University of La Verne',
        WashingtonJeffersonCollege: 'Washington & Jefferson College',
        UniversityofthePeople: 'University of the People',
        TouroCollege: 'Touro College',
        NewJerseyCityUniversity: 'New Jersey City University',
        LanderUniversity: 'Lander University',
        SUNYMaritimeCollege: 'SUNY Maritime College',
        LesleyUniversity: 'Lesley University',
        LaurusCollege: 'Laurus College',
        NationalParkCollege: 'National Park College',
        OklahomaChristianUniversity: 'Oklahoma Christian University',
        SanDiegoChristianCollege: 'San Diego Christian College',
        SANSTechnologyInstitute: 'SANS Technology Institute',
        PascoHernandoStateCollege: 'Pasco-Hernando State College',
        NewCollegeofFlorida: 'New College of Florida',
        GrandCanyonUniversity: 'Grand Canyon University',
        ColdSpringHarborLaboratory: 'Cold Spring Harbor Laboratory',
        AlbanyStateUniversity: 'Albany State University',
        LasellCollege: 'Lasell College',
        ContraCostaCommunityCollegeDistrict: 'Contra Costa Community College District',
        MountStMarysUniversity: "Mount St. Mary's University",
        RobertMorrisUniversityIllinois: 'Robert Morris University Illinois',
        NorthwestFloridaStateCollege: 'Northwest Florida State College',
        WestVirginiaWesleyanCollege: 'West Virginia Wesleyan College',
        UticaCollege: 'Utica College',
        CareerCollegeofNorthernNevada: 'Career College of Northern Nevada',
        CollegeofSouthernNevada: 'College of Southern Nevada',
        UniversityofNorthGeorgia: 'University of North Georgia',
        ThePrincipia: 'The Principia',
        LynnUniversity: 'Lynn University',
        ColoradoCommunityCollegeSystem: 'Colorado Community College System',
        ValenciaCollege: 'Valencia College',
        StPetersburgCollege: 'St. Petersburg College',
        GlobeUniversityMinnesotaSchoolofBusiness: 'Globe University & Minnesota School of Business',
        EverestCollege: 'Everest College',
        SouthwestResearchInstitute: 'Southwest Research Institute',
        AmericanPublicUniversitySystem: 'American Public University System',
        FullSailUniversity: 'Full Sail University',
        YTICareerInstitute: 'YTI Career Institute',
        DefenseLanguageInstituteForeignLanguageCenter: 'Defense Language Institute Foreign Language Center',
        ColumbusStateUniversity: 'Columbus State University',
        MoreheadStateUniversity: 'Morehead State University',
        ColoradoStateUniversityGlobalCampus: 'Colorado State University - Global Campus',
        NorthDakotaUniversitySystem: 'North Dakota University System',
        UniversityofHoustonDowntown: 'University of Houston-Downtown',
        StratfordUniversity: 'Stratford University',
        CaliforniaPolytechnicStateUniversityPomona: 'California Polytechnic State University Pomona',
        BakerCollege: 'Baker College',
        TheCollegeofNewJersey: 'The College of New Jersey',
        AbileneChristianUniversity: 'Abilene Christian University',
        AdelphiUniversity: 'Adelphi University',
        AgnesScottCollege: 'Agnes Scott College',
        AikenTechnicalCollege: 'Aiken Technical College',
        AirForceInstituteofTechnology: 'Air Force Institute of Technology',
        AlabamaAMUniversity: 'Alabama A&M University',
        AlabamaStateUniversity: 'Alabama State University',
        AlamoColleges: 'Alamo Colleges',
        AlbertsonCollegeofIdaho: 'Albertson College of Idaho',
        AlbionCollege: 'Albion College',
        AlfredUniversity: 'Alfred University',
        AlleghenyCollege: 'Allegheny College',
        AllentownCollegeofSaintFrancisdeSales: 'Allentown College of Saint Francis de Sales',
        AlmaCollege: 'Alma College',
        AlvernoCollege: 'Alverno College',
        AmbassadorUniversity: 'Ambassador University',
        AmericanCoastlineUniversity: 'American Coastline University',
        AmericanInternationalCollege: 'American International College',
        AmericanUniversity: 'American University',
        AmherstCollege: 'Amherst College',
        AndrewsUniversity: 'Andrews University',
        AngeloStateUniversity: 'Angelo State University',
        AnneArundelCommunityCollege: 'Anne Arundel Community College',
        AntiochNewEngland: 'Antioch New England',
        AntiochUniversity: 'Antioch University',
        AntiochUniversityLosAngeles: 'Antioch University - Los Angeles',
        AntiochUniversitySeattle: 'Antioch University - Seattle',
        AppalachianStateUniversity: 'Appalachian State University',
        AquinasCollege: 'Aquinas College',
        ArcadiaCollege: 'Arcadia College',
        ArizonaStateUniversity: 'Arizona State University',
        ArizonaWesternCollege: 'Arizona Western College',
        ArkansasStateUniversity: 'Arkansas State University',
        ArkansasTechUniversity: 'Arkansas Tech University',
        ArmstrongStateCollege: 'Armstrong State College',
        AshlandUniversity: 'Ashland University',
        AssumptionCollege: 'Assumption College',
        AuburnUniversity: 'Auburn University',
        AuburnUniversityatMontgomery: 'Auburn University at Montgomery',
        AugsburgCollege: 'Augsburg College',
        AugustanaCollegeIL: 'Augustana College (IL)',
        AugustanaCollegeSD: 'Augustana College (SD)',
        AugustaUniversity: 'Augusta University',
        AuroraUniversity: 'Aurora University',
        AustinCollege: 'Austin College',
        AustinCommunityCollege: 'Austin Community College',
        AustinPeayStateUniversity: 'Austin Peay State University',
        AverettCollege: 'Averett College',
        AvilaCollege: 'Avila College',
        AzusaPacificUniversity: 'Azusa Pacific University',
        BabsonCollege: 'Babson College',
        BakerUniversity: 'Baker University',
        BallStateUniversity: 'Ball State University',
        BaptistBibleCollege: 'Baptist Bible College',
        BardCollege: 'Bard College',
        BarryUniversity: 'Barry University',
        BastyrUniversity: 'Bastyr University',
        BatesCollege: 'Bates College',
        BaylorCollegeofMedicine: 'Baylor College of Medicine',
        BaylorUniversity: 'Baylor University',
        BellevueUniversity: 'Bellevue University',
        BelmontUniversity: 'Belmont University',
        BeloitCollege: 'Beloit College',
        BemidjiStateUniversity: 'Bemidji State University',
        BenedictineCollege: 'Benedictine College',
        BenningtonCollege: 'Bennington College',
        BentleyCollege: 'Bentley College',
        BereaCollege: 'Berea College',
        BerkleeCollegeofMusic: 'Berklee College of Music',
        BethanyCollege: 'Bethany College',
        BethelCollegeKS: 'Bethel College (KS)',
        BethelUniversity: 'Bethel University',
        BiolaUniversity: 'Biola University',
        BirminghamSouthernCollege: 'Birmingham-Southern College',
        BlackHillsStateUniversity: 'Black Hills State University',
        BloomsburgUniversityofPennsylvania: 'Bloomsburg University of Pennsylvania',
        BlufftonCollege: 'Bluffton College',
        BobJonesUniversity: 'Bob Jones University',
        BoiseStateUniversity: 'Boise State University',
        BostonCollege: 'Boston College',
        BostonGraduateSchoolofPsychoanalysis: 'Boston Graduate School of Psychoanalysis',
        BostonUniversity: 'Boston University',
        BowdoinCollege: 'Bowdoin College',
        BowieStateUniversity: 'Bowie State University',
        BowlingGreenStateUniversity: 'Bowling Green State University',
        BradleyUniversity: 'Bradley University',
        BrandeisUniversity: 'Brandeis University',
        BrenauUniversity: 'Brenau University',
        BriarCliffCollege: 'Briar Cliff College',
        BridgewaterCollege: 'Bridgewater College',
        BrighamYoungUniversity: 'Brigham Young University',
        BrighamYoungUniversityIdaho: 'Brigham Young University - Idaho',
        BrighamYoungUniversityHawaii: 'Brigham Young University Hawaii',
        BrowardCollege: 'Broward College',
        BrownUniversity: 'Brown University',
        BryantCollege: 'Bryant College',
        BrynMawrCollege: 'Bryn Mawr College',
        BucknellUniversity: 'Bucknell University',
        BuenaVistaUniversity: 'Buena Vista University',
        ButlerUniversity: 'Butler University',
        CaliforniaCoastUniversity: 'California Coast University',
        CaliforniaInstituteofTechnology: 'California Institute of Technology',
        CaliforniaLutheranUniversity: 'California Lutheran University',
        CaliforniaMaritimeAcademy: 'California Maritime Academy',
        CaliforniaNationalUniversity: 'California National University',
        CaliforniaPolytechnicStateUniversitySanLuisObispo: 'California Polytechnic State University San Luis Obispo',
        CaliforniaStateUniversityBakersfield: 'California State University Bakersfield',
        CaliforniaStateUniversityChico: 'California State University Chico',
        CaliforniaStateUniversityDominguezHills: 'California State University Dominguez Hills',
        CaliforniaStateUniversityFresno: 'California State University Fresno',
        CaliforniaStateUniversityFullerton: 'California State University Fullerton',
        CaliforniaStateUniversityHayward: 'California State University Hayward',
        CaliforniaStateUniversityLongBeach: 'California State University Long Beach',
        CaliforniaStateUniversityLosAngeles: 'California State University Los Angeles',
        CaliforniaStateUniversityMontereyBay: 'California State University Monterey Bay',
        CaliforniaStateUniversityNorthridge: 'California State University Northridge',
        CaliforniaStateUniversitySacramento: 'California State University Sacramento',
        CaliforniaStateUniversitySanBernardino: 'California State University San Bernardino',
        CaliforniaStateUniversitySanMarcos: 'California State University San Marcos',
        CaliforniaStateUniversityStanislaus: 'California State University Stanislaus',
        CaliforniaStateUniversitySystem: 'California State University System',
        CaliforniaUniversityofPennsylvania: 'California University of Pennsylvania',
        CalvinCollege: 'Calvin College',
        CameronUniversity: 'Cameron University',
        CampbellsvilleCollege: 'Campbellsville College',
        CampbellUniversity: 'Campbell University',
        CanisiusCollege: 'Canisius College',
        CapellaUniversity: 'Capella University',
        CapitolTechnologyUniversity: 'Capitol Technology University',
        CarletonCollege: 'Carleton College',
        CarlowCollege: 'Carlow College',
        CarnegieMellonUniversity: 'Carnegie Mellon University',
        CarrollCollege: 'Carroll College',
        CarrollUniversity: 'Carroll University',
        CarsonNewmanCollege: 'Carson-Newman College',
        CarthageCollege: 'Carthage College',
        CaseWesternReserveUniversity: 'Case Western Reserve University',
        CastletonStateUniversity: 'Castleton State University',
        CatholicUniversityofAmerica: 'Catholic University of America',
        CedarvilleCollege: 'Cedarville College',
        CentenaryCollegeofLouisiana: 'Centenary College of Louisiana',
        CentralCollege: 'Central College',
        CentralConnecticutStateUniversity: 'Central Connecticut State University',
        CentralMethodistCollege: 'Central Methodist College',
        CentralMichiganUniversity: 'Central Michigan University',
        CentralWashingtonUniversity: 'Central Washington University',
        CentreCollege: 'Centre College',
        ChadronStateCollege: 'Chadron State College',
        ChamplainCollege: 'Champlain College',
        ChapmanUniversity: 'Chapman University',
        ChathamCollege: 'Chatham College',
        ChesapeakeCollege: 'Chesapeake College',
        CheyneyUniversity: 'Cheyney University',
        ChicagoSchoolofProfessionalPsychology: 'Chicago School of Professional Psychology',
        ChristianBrothersUniversity: 'Christian Brothers University',
        ChristianTheologicalSeminary: 'Christian Theological Seminary',
        ChristopherNewportUniversity: 'Christopher Newport University',
        CityUniversity: 'City University',
        CityUniversityofNewYork: 'City University of New York',
        ClaremontGraduateSchool: 'Claremont Graduate School',
        ClaremontMcKennaCollege: 'Claremont McKenna College',
        ClarionUniversityofPennsylvania: 'Clarion University of Pennsylvania',
        ClarkeCollege: 'Clarke College',
        ClarksonUniversity: 'Clarkson University',
        ClarkUniversity: 'Clark University',
        ClaytonStateCollege: 'Clayton State College',
        ClemsonUniversity: 'Clemson University',
        ClevelandStateUniversity: 'Cleveland State University',
        ClinchValleyCollege: 'Clinch Valley College',
        CoastalCarolinaUniversity: 'Coastal Carolina University',
        CoastColleges: 'Coast Colleges',
        CoeCollege: 'Coe College',
        CokerCollege: 'Coker College',
        ColbyCollege: 'Colby College',
        ColgateUniversity: 'Colgate University',
        CollegeofCharleston: 'College of Charleston',
        CollegeofEasternUtah: 'College of Eastern Utah',
        CollegeofNewRochelle: 'College of New Rochelle',
        CollegeofSaintBenedict: 'College of Saint Benedict',
        CollegeofSaintCatherine: 'College of Saint Catherine',
        CollegeofSaintRose: 'College of Saint Rose',
        CollegeofStFrancis: 'College of St. Francis',
        CollegeofStScholastica: 'College of St. Scholastica',
        CollegeoftheAtlantic: 'College of the Atlantic',
        CollegeoftheHolyCross: 'College of the Holy Cross',
        CollegeofWilliamandMary: 'College of William and Mary',
        CollegeofWooster: 'College of Wooster',
        ColoradoChristianUniversity: 'Colorado Christian University',
        ColoradoCollege: 'Colorado College',
        ColoradoMesaUniversity: 'Colorado Mesa University',
        ColoradoSchoolofMines: 'Colorado School of Mines',
        ColoradoStateUniversity: 'Colorado State University',
        ColoradoTechnicalUniversity: 'Colorado Technical University',
        UniversityofColoradoAnschutzMedicalCampus: 'University of Colorado Anschutz Medical Campus',
        ColumbiaCollegeChicago: 'Columbia College Chicago',
        ColumbiaSouthernUniversity: 'Columbia Southern University',
        ColumbiaUnionCollege: 'Columbia Union College',
        ColumbiaUniversity: 'Columbia University',
        CommunityCollegeofBaltimoreCounty: 'Community College of Baltimore County',
        ConcordiaCollegeAnnArbor: 'Concordia College - Ann Arbor',
        ConcordiaCollegeMoorhead: 'Concordia College - Moorhead',
        ConcordiaCollegeSeward: 'Concordia College - Seward',
        ConcordiaCollegeStPaul: 'Concordia College - St. Paul',
        ConnecticutCollege: 'Connecticut College',
        ConnecticutStateUniversitySystem: 'Connecticut State University System',
        CooperUnionfortheAdvancementofScienceandArt: 'Cooper Union for the Advancement of Science and Art',
        CoppinStateUniversity: 'Coppin State University',
        AllenCollege: 'Allen College',
        CornellUniversity: 'Cornell University',
        CorningCommunityCollege: 'Corning Community College',
        CreightonUniversity: 'Creighton University',
        CurryCollege: 'Curry College',
        DaemenCollege: 'Daemen College',
        DakotaStateUniversity: 'Dakota State University',
        DakotaWesleyanUniversity: 'Dakota Wesleyan University',
        DallasBaptistUniversity: 'Dallas Baptist University',
        DallasCountyCommunityCollege: 'Dallas County Community College',
        DanaCollege: 'Dana College',
        DanielWebsterCollege: 'Daniel Webster College',
        DartmouthCollege: 'Dartmouth College',
        DavenportUniversity: 'Davenport University',
        DavidsonCollege: 'Davidson College',
        DaytonaStateCollege: 'Daytona State College',
        DelawareStateUniversity: 'Delaware State University',
        DeltaStateUniversity: 'Delta State University',
        DenisonUniversity: 'Denison University',
        DePaulUniversity: 'DePaul University',
        DePauwUniversity: 'DePauw University',
        DeVryInstituteofTechnology: 'DeVry Institute of Technology',
        DickinsonCollege: 'Dickinson College',
        DickinsonStateUniversity: 'Dickinson State University',
        DillardUniversity: 'Dillard University',
        DominicanCollege: 'Dominican College',
        DordtCollege: 'Dordt College',
        DowlingCollege: 'Dowling College',
        DrakeUniversity: 'Drake University',
        DrewUniversity: 'Drew University',
        DrexelUniversity: 'Drexel University',
        DruryCollege: 'Drury College',
        DuquesneUniversity: 'Duquesne University',
        EarlhamCollege: 'Earlham College',
        EastCarolinaUniversity: 'East Carolina University',
        EastCentralUniversity: 'East Central University',
        EasternConnecticutStateUniversity: 'Eastern Connecticut State University',
        EasternIllinoisUniversity: 'Eastern Illinois University',
        EasternKentuckyUniversity: 'Eastern Kentucky University',
        EasternMennoniteUniversity: 'Eastern Mennonite University',
        EasternMichiganUniversity: 'Eastern Michigan University',
        EasternNewMexicoUniversity: 'Eastern New Mexico University',
        EasternOregonStateCollege: 'Eastern Oregon State College',
        EasternWashingtonUniversity: 'Eastern Washington University',
        EastStroudsburgStateUniversityofPennsylvania: 'East Stroudsburg State University of Pennsylvania',
        EastTennesseeStateUniversity: 'East Tennessee State University',
        EdgewoodCollege: 'Edgewood College',
        EdinboroUniversityofPennsylvania: 'Edinboro University of Pennsylvania',
        ElizabethCityStateUniversity: 'Elizabeth City State University',
        ElizabethtownCollege: 'Elizabethtown College',
        ElmhurstCollege: 'Elmhurst College',
        ElonCollege: 'Elon College',
        EmbryRiddleAeronauticalUniversity: 'Embry-Riddle Aeronautical University',
        EmersonCollege: 'Emerson College',
        EmmanuelCollege: 'Emmanuel College',
        EmoryUniversity: 'Emory University',
        EmoryHenryCollege: 'Emory & Henry College',
        EmporiaStateUniversity: 'Emporia State University',
        ErieCommunityCollege: 'Erie Community College',
        LakeErieCollegeofOsteopathicMedicine: 'Lake Erie College of Osteopathic Medicine',
        EastTexasBaptistUniversity: 'East Texas Baptist University',
        EvergreenStateCollege: 'Evergreen State College',
        FairleighDickinsonUniversity: 'Fairleigh Dickinson University',
        FairmontStateCollege: 'Fairmont State College',
        FayettevilleStateUniversity: 'Fayetteville State University',
        FerrisStateUniversity: 'Ferris State University',
        FieldingInstitute: 'Fielding Institute',
        FiskUniversity: 'Fisk University',
        FitchburgStateCollege: 'Fitchburg State College',
        FloridaAgriculturalandMechanicalUniversity: 'Florida Agricultural and Mechanical University',
        FloridaAtlanticUniversity: 'Florida Atlantic University',
        FloridaGulfCoastUniversity: 'Florida Gulf Coast University',
        FloridaInstituteofTechnology: 'Florida Institute of Technology',
        FloridaInternationalUniversity: 'Florida International University',
        FloridaStateUniversity: 'Florida State University',
        FontbonneCollege: 'Fontbonne College',
        FordhamUniversity: 'Fordham University',
        FortHaysStateUniversity: 'Fort Hays State University',
        FortLewisCollege: 'Fort Lewis College',
        FountainheadCollegeofTechnology: 'Fountainhead College of Technology',
        FranklinandMarshallCollege: 'Franklin and Marshall College',
        FranklinPierceLawCenter: 'Franklin Pierce Law Center',
        FranklinUniversity: 'Franklin University',
        FrederickCommunityCollege: 'Frederick Community College',
        FresnoPacificUniversity: 'Fresno Pacific University',
        FriendsUniversity: 'Friends University',
        FrostburgStateUniversity: 'Frostburg State University',
        FullerTheologicalSeminary: 'Fuller Theological Seminary',
        FurmanUniversity: 'Furman University',
        GallaudetUniversity: 'Gallaudet University',
        GannonUniversity: 'Gannon University',
        GenevaCollege: 'Geneva College',
        GeorgeFoxCollege: 'George Fox College',
        GeorgeMasonUniversity: 'George Mason University',
        GeorgetownUniversity: 'Georgetown University',
        GeorgeWashingtonUniversity: 'George Washington University',
        GeorgiaCollege: 'Georgia College',
        GeorgiaInstituteofTechnology: 'Georgia Institute of Technology',
        GeorgianCourtUniversity: 'Georgian Court University',
        GeorgiaSouthernUniversity: 'Georgia Southern University',
        GeorgiaSouthwesternStateUniversity: 'Georgia Southwestern State University',
        GeorgiaStateUniversity: 'Georgia State University',
        GettysburgCollege: 'Gettysburg College',
        GMIEngineeringandManagementInstitute: 'GMI Engineering and Management Institute',
        GoldenGateUniversity: 'Golden Gate University',
        GoldeyBeacomCollege: 'Goldey-Beacom College',
        GonzagaUniversity: 'Gonzaga University',
        GoshenCollege: 'Goshen College',
        GoucherCollege: 'Goucher College',
        GovernorsStateUniversity: 'Governors State University',
        GraceCollege: 'Grace College',
        GracelandCollege: 'Graceland College',
        GrandValleyStateUniversity: 'Grand Valley State University',
        GreenleafUniversity: 'Greenleaf University',
        GrinnellCollege: 'Grinnell College',
        GuilfordCollege: 'Guilford College',
        GustavusAdolphusCollege: 'Gustavus Adolphus College',
        HagerstownCommunityCollege: 'Hagerstown Community College',
        HamiltonCollege: 'Hamilton College',
        HamlineUniversity: 'Hamline University',
        HampdenSydneyCollege: 'Hampden-Sydney College',
        HampshireCollege: 'Hampshire College',
        HamptonUniversity: 'Hampton University',
        HanoverCollege: 'Hanover College',
        HardingUniversity: 'Harding University',
        HarfordCommunityCollege: 'Harford Community College',
        HartwickCollege: 'Hartwick College',
        HarvardUniversity: 'Harvard University',
        HarveyMuddCollege: 'Harvey Mudd College',
        HaskellIndianNationsUniversity: 'Haskell Indian Nations University',
        HastingsCollege: 'Hastings College',
        HaverfordCollegeinPennsylvania: 'Haverford College in Pennsylvania',
        HawaiiPacificUniversity: 'Hawaii Pacific University',
        HeidelbergCollege: 'Heidelberg College',
        HendrixCollege: 'Hendrix College',
        HesstonCollege: 'Hesston College',
        HighPointUniversity: 'High Point University',
        HillsdaleCollege: 'Hillsdale College',
        HiramCollege: 'Hiram College',
        HobartandWilliamSmithColleges: 'Hobart and William Smith Colleges',
        HofstraUniversity: 'Hofstra University',
        HollinsCollege: 'Hollins College',
        HoodCollege: 'Hood College',
        HopeCollege: 'Hope College',
        HowardCommunityCollege: 'Howard Community College',
        HowardUniversity: 'Howard University',
        HumboldtStateUniversity: 'Humboldt State University',
        HuntingdonCollege: 'Huntingdon College',
        HuntingtonUniversity: 'Huntington University',
        ICIUniversity: 'ICI University',
        IdahoStateUniversity: 'Idaho State University',
        IllinoisBenedictineCollege: 'Illinois Benedictine College',
        IllinoisInstituteofTechnology: 'Illinois Institute of Technology',
        IllinoisStateUniversity: 'Illinois State University',
        IndianaInstituteofTechnology: 'Indiana Institute of Technology',
        IndianaStateUniversity: 'Indiana State University',
        IndianaUniversity: 'Indiana University',
        IndianaUniversityBloomington: 'Indiana University - Bloomington',
        IndianaUniversityatSouthBend: 'Indiana University at South Bend',
        IndianaUniversityofPennsylvania: 'Indiana University of Pennsylvania',
        IndianaUniversityPurdueUniversityatColumbus: 'Indiana University/Purdue University at Columbus',
        IndianaUniversityPurdueUniversityatFortWayne: 'Indiana University/Purdue University at Fort Wayne',
        IndianaUniversityPurdueUniversityatIndianapolis: 'Indiana University/Purdue University at Indianapolis',
        IndianaUniversitySoutheast: 'Indiana University Southeast',
        IndianaWesleyanUniversityMarion: 'Indiana Wesleyan University Marion',
        InstituteforWorldPolitics: 'Institute for World Politics',
        IonaCollege: 'Iona College',
        IowaStateUniversity: 'Iowa State University',
        IrvineValleyCollege: 'Irvine Valley College',
        IthacaCollege: 'Ithaca College',
        JacksonStateUniversity: 'Jackson State University',
        JacksonvilleStateUniversity: 'Jacksonville State University',
        JacksonvilleUniversity: 'Jacksonville University',
        JamesMadisonUniversity: 'James Madison University',
        JohnBrownUniversity: 'John Brown University',
        JohnFKennedyUniversity: 'John F. Kennedy University',
        JohnsHopkinsUniversity: 'Johns Hopkins University',
        JohnsHopkinsUniversityAppliedPhysicsLaboratory: 'Johns Hopkins University Applied Physics Laboratory',
        JohnsonWalesUniversity: 'Johnson & Wales University',
        JohnsonBibleCollege: 'Johnson Bible College',
        JohnsonCSmithUniversity: 'Johnson C. Smith University',
        JonesCollege: 'Jones College',
        JudsonCollege: 'Judson College',
        JuniataCollege: 'Juniata College',
        KalamazooCollege: 'Kalamazoo College',
        KansasStateUniversity: 'Kansas State University',
        KansasWesleyanUniversity: 'Kansas Wesleyan University',
        KaplanUniversity: 'Kaplan University',
        KeanCollege: 'Kean College',
        KeeneStateCollege: 'Keene State College',
        KennesawStateUniversity: 'Kennesaw State University',
        KentStateUniversity: 'Kent State University',
        KentuckyCommunityTechnicalCollegeSystem: 'Kentucky Community & Technical College System',
        KenyonCollege: 'Kenyon College',
        KetteringUniversity: 'Kettering University',
        KingsCollege: "King's College",
        KnoxCollege: 'Knox College',
        KutztownUniversityofPennsylvania: 'Kutztown University of Pennsylvania',
        LafayetteCollege: 'Lafayette College',
        LaGrangeCollege: 'LaGrange College',
        LakeForestCollege: 'Lake Forest College',
        LakeSuperiorCollege: 'Lake Superior College',
        LakeSuperiorStateUniversity: 'Lake Superior State University',
        LamarUniversity: 'Lamar University',
        LangstonUniversity: 'Langston University',
        LaSalleUniversity: 'LaSalle University',
        LaSierraUniversity: 'La Sierra University',
        LawrenceTechnologicalUniversity: 'Lawrence Technological University',
        LawrenceUniversity: 'Lawrence University',
        LebanonValleyCollege: 'Lebanon Valley College',
        LehighUniversity: 'Lehigh University',
        LeMoyneCollege: 'Le Moyne College',
        LenoirCommunityCollege: 'Lenoir Community College',
        LenoirRhyneCollege: 'Lenoir-Rhyne College',
        LeTourneauUniversity: 'LeTourneau University',
        LewisClarkCollege: 'Lewis & Clark College',
        LewisClarkStateCollege: 'Lewis-Clark State College',
        LewisUniversity: 'Lewis University',
        LibertyUniversity: 'Liberty University',
        LincolnUniversity: 'Lincoln University',
        LinfieldCollege: 'Linfield College',
        LockHavenUniversity: 'Lock Haven University',
        LomaLindaUniversity: 'Loma Linda University',
        LongIslandUniversity: 'Long Island University',
        LongwoodUniversity: 'Longwood University',
        LorasCollege: 'Loras College',
        LosAngelesCommunityCollegeDistrict: 'Los Angeles Community College District',
        LouisianaCollege: 'Louisiana College',
        LouisianaStateUniversity: 'Louisiana State University',
        LouisianaStateUniversityatAlexandria: 'Louisiana State University at Alexandria',
        LouisianaTechUniversity: 'Louisiana Tech University',
        LoyolaCollege: 'Loyola College',
        LoyolaMarymountUniversity: 'Loyola Marymount University',
        LoyolaUniversityChicago: 'Loyola University Chicago',
        LutherCollege: 'Luther College',
        LutherSeminary: 'Luther Seminary',
        LycomingCollege: 'Lycoming College',
        LynchburgCollege: 'Lynchburg College',
        LyndonStateCollege: 'Lyndon State College',
        LyonCollege: 'Lyon College',
        MacalesterCollege: 'Macalester College',
        MaharishiUniversityofManagement: 'Maharishi University of Management',
        MaloneCollege: 'Malone College',
        ManhattanCollege: 'Manhattan College',
        MansfieldUniversityofPennsylvania: 'Mansfield University of Pennsylvania',
        MariettaCollege: 'Marietta College',
        MaristCollege: 'Marist College',
        MarlboroCollege: 'Marlboro College',
        MarquetteUniversity: 'Marquette University',
        MarshallUniversity: 'Marshall University',
        MaryBaldwinCollege: 'Mary Baldwin College',
        MarymountManhattanCollege: 'Marymount Manhattan College',
        MarymountUniversity: 'Marymount University',
        MaryWashingtonCollege: 'Mary Washington College',
        MassachusettsInstituteofTechnology: 'Massachusetts Institute of Technology',
        McDanielCollege: 'McDaniel College',
        McMurryUniversity: 'McMurry University',
        McNeeseStateUniversity: 'McNeese State University',
        MedicalCollegeofGeorgia: 'Medical College of Georgia',
        MedicalCollegeofWisconsin: 'Medical College of Wisconsin',
        MercerCountyCommunityCollege: 'Mercer County Community College',
        MercerUniversity: 'Mercer University',
        MercyCollege: 'Mercy College',
        MercyhurstUniversity: 'Mercyhurst University',
        MeredithCollege: 'Meredith College',
        MessiahCollege: 'Messiah College',
        MetropolitanCommunityCollegeMO: 'Metropolitan Community College (MO)',
        MetropolitanCommunityCollegeNE: 'Metropolitan Community College (NE)',
        MetropolitanStateCollegeofDenver: 'Metropolitan State College of Denver',
        DenverCollegeofNursing: 'Denver College of Nursing',
        MetropolitanStateUniversity: 'Metropolitan State University',
        MiamiUniversityofOhio: 'Miami University of Ohio',
        MichiganStateUniversity: 'Michigan State University',
        MichiganTechnologicalUniversity: 'Michigan Technological University',
        MidAmericaNazareneCollege: 'Mid-America Nazarene College',
        MiddleburyCollege: 'Middlebury College',
        MiddleGeorgiaStateCollege: 'Middle Georgia State College',
        MiddleTennesseeStateUniversity: 'Middle Tennessee State University',
        MidwesternStateUniversity: 'Midwestern State University',
        MillersvilleUniversityofPennsylvania: 'Millersville University of Pennsylvania',
        MilliganCollege: 'Milligan College',
        MillikinUniversity: 'Millikin University',
        MillsapsCollege: 'Millsaps College',
        MilwaukeeSchoolofEngineering: 'Milwaukee School of Engineering',
        MinneapolisCollegeofArtandDesign: 'Minneapolis College of Art and Design',
        MinnesotaStateUniversityMankato: 'Minnesota State University Mankato',
        MinnesotaStateUniversityMoorhead: 'Minnesota State University Moorhead',
        MinotStateUniversity: 'Minot State University',
        MississippiCollege: 'Mississippi College',
        MississippiStateUniversity: 'Mississippi State University',
        MississippiUniversityforWomen: 'Mississippi University for Women',
        MissouriSouthernStateCollege: 'Missouri Southern State College',
        MissouriUniversityofScienceandTechnology: 'Missouri University of Science and Technology',
        MissouriWesternStateCollege: 'Missouri Western State College',
        MolloyCollege: 'Molloy College',
        MonmouthCollege: 'Monmouth College',
        MonmouthUniversity: 'Monmouth University',
        MontanaStateUniversityBillings: 'Montana State University - Billings',
        MontanaStateUniversityBozeman: 'Montana State University - Bozeman',
        MontanaStateUniversityNorthern: 'Montana State University - Northern',
        MontanaTech: 'Montana Tech',
        MontclairStateUniversity: 'Montclair State University',
        MontgomeryCollege: 'Montgomery College',
        MontreatCollege: 'Montreat College',
        MoravianCollege: 'Moravian College',
        MorganStateUniversity: 'Morgan State University',
        MottCommunityCollege: 'Mott Community College',
        MountHolyokeCollege: 'Mount Holyoke College',
        MountSaintJosephCollege: 'Mount Saint Joseph College',
        MountSaintMaryCollege: 'Mount Saint Mary College',
        MountSenarioCollege: 'Mount Senario College',
        MountUnionCollege: 'Mount Union College',
        MurrayStateUniversity: 'Murray State University',
        MuskingumCollege: 'Muskingum College',
        NashvilleStateCommunityCollege: 'Nashville State Community College',
        NationalDefenseUniversity: 'National Defense University',
        NationalLouisUniversity: 'National-Louis University',
        NationalRadioAstronomyObservatory: 'National Radio Astronomy Observatory',
        NationalUniversity: 'National University',
        NavalPostgraduateSchool: 'Naval Postgraduate School',
        NazarethCollege: 'Nazareth College',
        NevadaSystemofHigherEducation: 'Nevada System of Higher Education',
        NewberryCollege: 'Newberry College',
        NewCollegeofCalifornia: 'New College of California',
        NewEnglandInstituteofTechnology: 'New England Institute of Technology',
        NewHampshireCollege: 'New Hampshire College',
        NewJerseyInstituteofTechnology: 'New Jersey Institute of Technology',
        NewMexicoHighlandsUniversity: 'New Mexico Highlands University',
        NewMexicoInstituteofMiningTechnology: 'New Mexico Institute of Mining & Technology',
        NewMexicoStateUniversity: 'New Mexico State University',
        NewYorkInstituteofTechnology: 'New York Institute of Technology',
        NewYorkUniversity: 'New York University',
        NiagaraUniversity: 'Niagara University',
        NichollsStateUniversity: 'Nicholls State University',
        NorfolkStateUniversity: 'Norfolk State University',
        NorthAmericanUniversity: 'North American University',
        NorthamptonCommunityCollege: 'Northampton Community College',
        NorthCarolinaATStateUniversity: 'North Carolina A&T State University',
        NorthCarolinaCentralUniversity: 'North Carolina Central University',
        NorthCarolinaStateUniversity: 'North Carolina State University',
        NorthCarolinaWesleyanCollege: 'North Carolina Wesleyan College',
        NorthCentralBibleCollege: 'North Central Bible College',
        NorthDakotaStateUniversity: 'North Dakota State University',
        NortheasternLouisianaUniversity: 'Northeastern Louisiana University',
        NortheasternStateUniversity: 'Northeastern State University',
        NortheasternUniversity: 'Northeastern University',
        NorthernArizonaUniversity: 'Northern Arizona University',
        NorthernIllinoisUniversity: 'Northern Illinois University',
        NorthernKentuckyUniversity: 'Northern Kentucky University',
        NorthernMichiganUniversity: 'Northern Michigan University',
        NorthernStateUniversity: 'Northern State University',
        NorthlandCollege: 'Northland College',
        NorthParkCollegeandTheologicalSeminary: 'North Park College and Theological Seminary',
        NorthwesternCollegeofIowa: 'Northwestern College of Iowa',
        NorthwesternStateUniversity: 'Northwestern State University',
        NorthwesternUniversity: 'Northwestern University',
        NorthwestMissouriStateUniversity: 'Northwest Missouri State University',
        NorthwestNazareneCollege: 'Northwest Nazarene College',
        NorwichUniversity: 'Norwich University',
        NovaSoutheasternUniversity: 'Nova Southeastern University',
        OaklandUniversity: 'Oakland University',
        OakwoodUniversity: 'Oakwood University',
        OberlinCollege: 'Oberlin College',
        OccidentalCollege: 'Occidental College',
        OhioDominicanCollege: 'Ohio Dominican College',
        OhioNorthernUniversity: 'Ohio Northern University',
        OhioStateUniversityColumbus: 'Ohio State University - Columbus',
        OhioUniversity: 'Ohio University',
        OhioWesleyanUniversity: 'Ohio Wesleyan University',
        OklahomaBaptistUniversity: 'Oklahoma Baptist University',
        OklahomaCityUniversity: 'Oklahoma City University',
        OklahomaStateUniversity: 'Oklahoma State University',
        OldDominionUniversity: 'Old Dominion University',
        OlivetNazareneUniversity: 'Olivet Nazarene University',
        OralRobertsUniversity: 'Oral Roberts University',
        OregonGraduateInstituteofScienceandTechnology: 'Oregon Graduate Institute of Science and Technology',
        OregonHealthSciencesUniversity: 'Oregon Health Sciences University',
        OregonInstituteofTechnology: 'Oregon Institute of Technology',
        OregonStateUniversity: 'Oregon State University',
        OtterbeinCollege: 'Otterbein College',
        OurLadyoftheLakeUniversity: 'Our Lady of the Lake University',
        PaceUniversity: 'Pace University',
        PacificLutheranUniversity: 'Pacific Lutheran University',
        PacificUnionCollege: 'Pacific Union College',
        PacificUniversity: 'Pacific University',
        PacificWesternUniversity: 'Pacific Western University',
        PalmBeachAtlanticCollege: 'Palm Beach Atlantic College',
        PalmBeachStateCollege: 'Palm Beach State College',
        PeaceCollege: 'Peace College',
        PembrokeStateUniversity: 'Pembroke State University',
        PennsylvaniaCollegeofTechnology: 'Pennsylvania College of Technology',
        PennsylvaniaStateUniversity: 'Pennsylvania State University',
        PennsylvaniaStateUniversitySchuylkillCampus: 'Pennsylvania State University - Schuylkill Campus',
        PensacolaChristianCollege: 'Pensacola Christian College',
        PensacolaStateCollege: 'Pensacola State College',
        PepperdineUniversity: 'Pepperdine University',
        PeruStateCollege: 'Peru State College',
        PurdueUniversityFortWayne: 'Purdue University Fort Wayne',
        PhiladelphiaUniversity: 'Philadelphia University',
        PhillipsUniversity: 'Phillips University',
        PittsburgStateUniversity: 'Pittsburg State University',
        PitzerCollege: 'Pitzer College',
        PlattCollege: 'Platt College',
        PlymouthStateCollege: 'Plymouth State College',
        PolytechnicUniversityofNewYork: 'Polytechnic University of New York',
        PolytechnicUniversityofPuertoRico: 'Polytechnic University of Puerto Rico',
        PomonaCollege: 'Pomona College',
        PortlandCommunityCollege: 'Portland Community College',
        PortlandStateUniversity: 'Portland State University',
        PrairieViewAMUniversity: 'Prairie View A&M University',
        PrattInstitute: 'Pratt Institute',
        PresbyterianCollege: 'Presbyterian College',
        PrescottCollege: 'Prescott College',
        PrincetonUniversity: 'Princeton University',
        ProvidenceCollege: 'Providence College',
        PurdueUniversity: 'Purdue University',
        PurdueUniversityCalumet: 'Purdue University Calumet',
        PurdueUniversityNorthCentral: 'Purdue University North Central',
        QuincyUniversity: 'Quincy University',
        QuinnipiacCollege: 'Quinnipiac College',
        RadfordUniversity: 'Radford University',
        RamapoCollege: 'Ramapo College',
        RandolphMaconCollege: 'Randolph-Macon College',
        RandolphMaconWomansCollege: "Randolph-Macon Woman's College",
        ReedCollege: 'Reed College',
        RegentUniversity: 'Regent University',
        RegisUniversity: 'Regis University',
        RensselaerPolytechnicInstitute: 'Rensselaer Polytechnic Institute',
        RhodeIslandCollege: 'Rhode Island College',
        RhodesCollege: 'Rhodes College',
        RiceUniversity: 'Rice University',
        RichardStocktonCollegeofNewJersey: 'Richard Stockton College of New Jersey',
        RiderUniversity: 'Rider University',
        RiponCollege: 'Ripon College',
        RivierCollege: 'Rivier College',
        RoanokeCollege: 'Roanoke College',
        RobertMorrisUniversity: 'Robert Morris University',
        RochesterInstituteofTechnology: 'Rochester Institute of Technology',
        RockefellerUniversity: 'Rockefeller University',
        RockfordCollege: 'Rockford College',
        RockhurstCollege: 'Rockhurst College',
        RockyMountainCollege: 'Rocky Mountain College',
        RogerWilliamsUniversity: 'Roger Williams University',
        RollinsCollege: 'Rollins College',
        RosaryCollege: 'Rosary College',
        RoseHulmanInstituteofTechnology: 'Rose-Hulman Institute of Technology',
        RowanCollege: 'Rowan College',
        RutgersUniversity: 'Rutgers University',
        SacredHeartUniversity: 'Sacred Heart University',
        SageColleges: 'Sage Colleges',
        SaginawValleyStateUniversity: 'Saginaw Valley State University',
        SaintAnselmCollege: 'Saint Anselm College',
        SaintCloudStateUniversity: 'Saint Cloud State University',
        SaintEdwardsUniversity: "Saint Edward's University",
        StFrancisCollege: 'St. Francis College',
        SaintJohnsUniversityNY: "Saint John's University (NY)",
        SaintJosephsCollegeIN: "Saint Joseph's College (IN)",
        SaintJosephsUniversity: "Saint Joseph's University",
        SaintLeoUniversity: 'Saint Leo University',
        SaintLouisUniversity: 'Saint Louis University',
        SaintMaryCollege: 'Saint Mary College',
        SaintMarysCollegeIN: "Saint Mary's College (IN)",
        SaintMarysCollegeofCalifornia: "Saint Mary's College of California",
        SaintMarysUniversityofMinnesota: "Saint Mary's University of Minnesota",
        SaintMichaelsCollege: "Saint Michael's College",
        SaintOlafCollege: 'Saint Olaf College',
        SaintVincentCollege: 'Saint Vincent College',
        SaintXavierUniversity: 'Saint Xavier University',
        SalisburyUniversity: 'Salisbury University',
        SalishKootenaiCollege: 'Salish Kootenai College',
        SamfordUniversity: 'Samford University',
        SamHoustonStateUniversity: 'Sam Houston State University',
        SanDiegoStateUniversity: 'San Diego State University',
        SanFranciscoStateUniversity: 'San Francisco State University',
        SanJoseStateUniversity: 'San Jose State University',
        SantaClaraUniversity: 'Santa Clara University',
        SarahLawrenceCollege: 'Sarah Lawrence College',
        SchooloftheArtInstituteofChicago: 'School of the Art Institute of Chicago',
        SeattleCentralCollege: 'Seattle Central College',
        SeattlePacificUniversity: 'Seattle Pacific University',
        SeattleUniversity: 'Seattle University',
        SetonHallUniversity: 'Seton Hall University',
        SewaneeUniversityoftheSouth: 'Sewanee University of the South',
        ShawneeStateUniversity: 'Shawnee State University',
        ShenandoahUniversity: 'Shenandoah University',
        ShippensburgUniversityofPennsylvania: 'Shippensburg University of Pennsylvania',
        ShorterCollege: 'Shorter College',
        SimmonsCollege: 'Simmons College',
        SimonsRockCollege: "Simon's Rock College",
        SimpsonCollege: 'Simpson College',
        SinclairCommunityCollege: 'Sinclair Community College',
        SkidmoreCollege: 'Skidmore College',
        SlipperyRockUniversityofPennsylvania: 'Slippery Rock University of Pennsylvania',
        SmithCollege: 'Smith College',
        SonomaStateUniversity: 'Sonoma State University',
        SouthDakotaSchoolofMinesandTechnology: 'South Dakota School of Mines and Technology',
        SouthDakotaStateUniversity: 'South Dakota State University',
        SoutheasternLouisianaUniversity: 'Southeastern Louisiana University',
        SoutheastMissouriStateUniversity: 'Southeast Missouri State University',
        SouthernCollege: 'Southern College',
        SouthernCollegeofTechnology: 'Southern College of Technology',
        SouthernConnecticutStateUniversity: 'Southern Connecticut State University',
        SouthernIllinoisUniversityCarbondale: 'Southern Illinois University-Carbondale',
        SouthernIllinoisUniversityEdwardsville: 'Southern Illinois University-Edwardsville',
        SouthernMethodistUniversity: 'Southern Methodist University',
        SouthernNazareneUniversity: 'Southern Nazarene University',
        SouthernNewHampshireUniversity: 'Southern New Hampshire University',
        SouthernOregonStateCollege: 'Southern Oregon State College',
        SouthernUniversityandAMCollege: 'Southern University and A&M College',
        SouthernUtahUniversity: 'Southern Utah University',
        SouthTexasCollegeofLaw: 'South Texas College of Law',
        SouthwestBaptistUniversity: 'Southwest Baptist University',
        SouthwesternAdventistCollege: 'Southwestern Adventist College',
        SouthwesternAssembliesofGodUniversity: 'Southwestern Assemblies of God University',
        SouthwesternCollegeKS: 'Southwestern College (KS)',
        SouthwesternUniversity: 'Southwestern University',
        SouthwestMissouriStateUniversity: 'Southwest Missouri State University',
        SouthwestTexasStateUniversity: 'Southwest Texas State University',
        SpelmanCollege: 'Spelman College',
        SpringArborCollege: 'Spring Arbor College',
        SpringHillCollege: 'Spring Hill College',
        StAmbroseUniversity: 'St. Ambrose University',
        StAndrewsPresbyterianCollege: 'St. Andrews Presbyterian College',
        StanfordUniversity: 'Stanford University',
        StateUniversityofNewYorkatAlbany: 'State University of New York at Albany',
        StateUniversityofNewYorkatBinghamton: 'State University of New York at Binghamton',
        StateUniversityofNewYorkatBuffalo: 'State University of New York at Buffalo',
        StateUniversityofNewYorkatFarmingdale: 'State University of New York at Farmingdale',
        StateUniversityofNewYorkatFredonia: 'State University of New York at Fredonia',
        StateUniversityofNewYorkatOswego: 'State University of New York at Oswego',
        StateUniversityofNewYorkatPlattsburgh: 'State University of New York at Plattsburgh',
        StateUniversityofNewYorkatStonyBrook: 'State University of New York at Stony Brook',
        AcademicmedicalcenteratStateUniversityofNewYorkatStonyBrook:
            'Academic medical center at State University of New York at Stony Brook',
        StateUniversityofNewYorkCollegeatBrockport: 'State University of New York College at Brockport',
        StateUniversityofNewYorkCollegeatBuffalo: 'State University of New York College at Buffalo',
        StateUniversityofNewYorkCollegeatCortland: 'State University of New York College at Cortland',
        StateUniversityofNewYorkCollegeatGeneseo: 'State University of New York College at Geneseo',
        StateUniversityofNewYorkCollegeatNewPaltz: 'State University of New York College at New Paltz',
        StateUniversityofNewYorkCollegeatPotsdam: 'State University of New York College at Potsdam',
        StateUniversityofNewYorkCollegeofAgricultureandTechnologyatCobleskill:
            'State University of New York College of Agriculture and Technology at Cobleskill',
        StateUniversityofNewYorkCollegeofEnvironmentalScienceandForestry:
            'State University of New York College of Environmental Science and Forestry',
        StateUniversityofNewYorkCollegeofTechnologyatAlfred:
            'State University of New York College of Technology at Alfred',
        StateUniversityofNewYorkPolytechnicInstitute: 'State University of New York Polytechnic Institute',
        StateUniversityofNewYorkSystem: 'State University of New York System',
        StBonaventureUniversity: 'St. Bonaventure University',
        StephenFAustinStateUniversity: 'Stephen F. Austin State University',
        StephensCollege: 'Stephens College',
        StetsonUniversity: 'Stetson University',
        StevensInstituteofTechnology: 'Stevens Institute of Technology',
        StJohnsCollegeAnnapolis: "St. John's College - Annapolis",
        StJohnsCollegeSantaFe: "St. John's College - Santa Fe",
        StJosephCollegeCT: 'St. Joseph College (CT)',
        StJosephsCollegeME: "St. Joseph's College (ME)",
        StLawrenceUniversity: 'St. Lawrence University',
        StLouisCollegeofPharmacy: 'St. Louis College of Pharmacy',
        StMartinsCollege: "St. Martin's College",
        StMarysCollegeofMaryland: "St. Mary's College of Maryland",
        StrayerCollege: 'Strayer College',
        StThomasUniversityFL: 'St. Thomas University (FL)',
        SuffolkUniversity: 'Suffolk University',
        SulRossStateUniversity: 'Sul Ross State University',
        SummitUniversityofLouisiana: 'Summit University of Louisiana',
        SusquehannaUniversity: 'Susquehanna University',
        SwarthmoreCollege: 'Swarthmore College',
        SweetBriarCollege: 'Sweet Briar College',
        SyracuseUniversity: 'Syracuse University',
        TaborCollege: 'Tabor College',
        TarletonStateUniversity: 'Tarleton State University',
        TaylorUniversity: 'Taylor University',
        TempleUniversity: 'Temple University',
        TennesseeStateUniversity: 'Tennessee State University',
        TennesseeTechnologicalUniversity: 'Tennessee Technological University',
        TarrantCountyCollege: 'Tarrant County College',
        TexasAMInternationalUniversity: 'Texas A&M International University',
        TexasAMUniversityCollegeStation: 'Texas A&M University - College Station',
        TexasAMUniversityCommerce: 'Texas A&M University - Commerce',
        TexasAMUniversityCorpusChristi: 'Texas A&M University - Corpus Christi',
        TexasAMUniversityKingsville: 'Texas A&M University - Kingsville',
        TexasChristianUniversity: 'Texas Christian University',
        TexasSouthernUniversity: 'Texas Southern University',
        TexasStateUniversity: 'Texas State University',
        TexasTechUniversity: 'Texas Tech University',
        TexasTechUniversityHealthSciencesCenter: 'Texas Tech University-Health Sciences Center',
        TexasWomansUniversity: "Texas Woman's University",
        TheCitadel: 'The Citadel',
        TheJewishTheologicalSeminary: 'The Jewish Theological Seminary',
        ThomasCollege: 'Thomas College',
        ThomasEdisonStateUniversity: 'Thomas Edison State University',
        ThomasMoreCollege: 'Thomas More College',
        ThunderbirdSchoolofGlobalManagement: 'Thunderbird School of Global Management',
        TowsonUniversity: 'Towson University',
        TransylvaniaUniversity: 'Transylvania University',
        TrentonStateCollege: 'Trenton State College',
        TrineUniversity: 'Trine University',
        TrinityCollegeCT: 'Trinity College (CT)',
        TrinityCollegeDC: 'Trinity College (DC)',
        TrinityUniversity: 'Trinity University',
        TroyStateUniversity: 'Troy State University',
        TrumanStateUniversity: 'Truman State University',
        TucsonUniversity: 'Tucson University',
        TuftsUniversity: 'Tufts University',
        TulaneUniversity: 'Tulane University',
        TuskegeeUniversity: 'Tuskegee University',
        UniformedServicesUniversityoftheHealthSciences: 'Uniformed Services University of the Health Sciences',
        UnionCollege: 'Union College',
        UnionInstitute: 'Union Institute',
        UnionUniversity: 'Union University',
        UnitedStatesAirForceAcademy: 'United States Air Force Academy',
        UnitedStatesInternationalUniversity: 'United States International University',
        UnitedStatesMerchantMarineAcademy: 'United States Merchant Marine Academy',
        UnitedStatesMilitaryAcademy: 'United States Military Academy',
        UnitedStatesNavalAcademy: 'United States Naval Academy',
        UniversityofAdvancingTechnology: 'University of Advancing Technology',
        UniversityofAkron: 'University of Akron',
        UniversityofAlabamaatBirmingham: 'University of Alabama at Birmingham',
        UniversityofAlabamaatHuntsville: 'University of Alabama at Huntsville',
        UniversityofAlaska: 'University of Alaska',
        UniversityofAlaskaAnchorage: 'University of Alaska - Anchorage',
        UniversityofAlaskaFairbanks: 'University of Alaska - Fairbanks',
        UniversityofAlaskaSoutheast: 'University of Alaska - Southeast',
        UniversityofArizona: 'University of Arizona',
        UniversityofArkansasFayetteville: 'University of Arkansas - Fayetteville',
        UniversityofArkansasFortSmith: 'University of Arkansas - Fort Smith',
        UniversityofArkansasforMedicalSciences: 'University of Arkansas for Medical Sciences',
        UniversityofArkansasLittleRock: 'University of Arkansas - Little Rock',
        UniversityofArkansasMonticello: 'University of Arkansas - Monticello',
        UniversityofBaltimore: 'University of Baltimore',
        UniversityofBridgeport: 'University of Bridgeport',
        UniversityofCaliforniaBerkeley: 'University of California Berkeley',
        UniversityofCaliforniaDavis: 'University of California Davis',
        UniversityofCaliforniaIrvine: 'University of California Irvine',
        UniversityofCaliforniaLosAngeles: 'University of California Los Angeles',
        UniversityofCaliforniaMerced: 'University of California Merced',
        UniversityofCaliforniaOfficeofthePresident: 'University of California Office of the President',
        UniversityofCaliforniaRiverside: 'University of California Riverside',
        UniversityofCaliforniaSanDiego: 'University of California San Diego',
        UniversityofCaliforniaSanFrancisco: 'University of California San Francisco',
        UniversityofCaliforniaSantaBarbara: 'University of California Santa Barbara',
        UniversityofCaliforniaSantaCruz: 'University of California Santa Cruz',
        UniversityofCentralArkansas: 'University of Central Arkansas',
        UniversityofCentralFlorida: 'University of Central Florida',
        UniversityofCentralMissouri: 'University of Central Missouri',
        UCHastingsCollegeoftheLaw: 'UC Hastings College of the Law',
        UniversityofCharleston: 'University of Charleston',
        UniversityofChicago: 'University of Chicago',
        UniversityofCincinnati: 'University of Cincinnati',
        UniversityofColoradoatBoulder: 'University of Colorado at Boulder',
        UniversityofColoradoatColoradoSprings: 'University of Colorado at Colorado Springs',
        UniversityofColoradoatDenver: 'University of Colorado at Denver',
        UniversityofColoradoHealthSciencesCenter: 'University of Colorado Health Sciences Center',
        UniversityofConnecticut: 'University of Connecticut',
        UniversityofDallas: 'University of Dallas',
        UniversityofDayton: 'University of Dayton',
        UniversityofDelaware: 'University of Delaware',
        UniversityofDenver: 'University of Denver',
        UniversityofDetroitMercy: 'University of Detroit Mercy',
        UniversityofDubuque: 'University of Dubuque',
        UniversityofEvansville: 'University of Evansville',
        UniversityofFlorida: 'University of Florida',
        UniversityofGeorgia: 'University of Georgia',
        UniversityofGreatFalls: 'University of Great Falls',
        UniversityofHartford: 'University of Hartford',
        UniversityofHawaiiatHilo: 'University of Hawaii at Hilo',
        UniversityofHawaiiatManoa: 'University of Hawaii at Manoa',
        UniversityofHawaiiSystem: 'University of Hawaii System',
        UniversityofHouston: 'University of Houston',
        UniversityofIdaho: 'University of Idaho',
        UniversityofIllinoisChicago: 'University of Illinois Chicago',
        UniversityofIllinoisSpringfield: 'University of Illinois Springfield',
        UniversityofIllinoisUrbanaChampaign: 'University of Illinois Urbana-Champaign',
        UniversityofIndianapolis: 'University of Indianapolis',
        UniversityofIowa: 'University of Iowa',
        UniversityofJamestown: 'University of Jamestown',
        UniversityofKansas: 'University of Kansas',
        UniversityofKansasSchoolofMedicine: 'University of Kansas School of Medicine',
        UniversityofKentucky: 'University of Kentucky',
        UniversityofLouisianaatLafeyette: 'University of Louisiana at Lafeyette',
        UniversityofLouisianaatMonroe: 'University of Louisiana at Monroe',
        UniversityofLouisville: 'University of Louisville',
        UniversityofMaine: 'University of Maine',
        UniversityofMaineatFarmington: 'University of Maine at Farmington',
        UniversityofMaineatFortKent: 'University of Maine at Fort Kent',
        UniversityofMaineatMachias: 'University of Maine at Machias',
        UniversityofMaineatPresqueIsland: 'University of Maine at Presque Island',
        UniversityofMaineSystem: 'University of Maine System',
        UniversityofMarylandBaltimore: 'University of Maryland Baltimore',
        MayoClinic: 'Mayo Clinic',
        UniversityofMarylandBaltimoreCounty: 'University of Maryland Baltimore County',
        UniversityofMarylandCollegePark: 'University of Maryland College Park',
        UniversityofMarylandEasternShore: 'University of Maryland Eastern Shore',
        UniversityofMarylandUniversityCollege: 'University of Maryland - University College',
        UniversityofMassachusettsatAmherst: 'University of Massachusetts at Amherst',
        UniversityofMassachusettsatDartmouth: 'University of Massachusetts at Dartmouth',
        UniversityofMassachusettsatLowell: 'University of Massachusetts at Lowell',
        UniversityofMemphis: 'University of Memphis',
        UniversityofMiami: 'University of Miami',
        UniversityofMichiganAnnArbor: 'University of Michigan - Ann Arbor',
        UniversityofMichiganDearborn: 'University of Michigan - Dearborn',
        UniversityofMinnesota: 'University of Minnesota',
        UniversityofMinnesotaCrookston: 'University of Minnesota - Crookston',
        UniversityofMinnesotaDuluth: 'University of Minnesota - Duluth',
        UniversityofMinnesotaMorris: 'University of Minnesota - Morris',
        UniversityofMississippi: 'University of Mississippi',
        UniversityofMissouri: 'University of Missouri',
        UniversityofMissouriKansasCity: 'University of Missouri - Kansas City',
        UniversityofMissouriRolla: 'University of Missouri - Rolla',
        UniversityofMissouriSaintLouis: 'University of Missouri - Saint Louis',
        UniversityofMontana: 'University of Montana',
        UniversityofNebraskaKearney: 'University of Nebraska Kearney',
        UniversityofNebraskaLincoln: 'University of Nebraska Lincoln',
        UniversityofNebraskaOmaha: 'University of Nebraska Omaha',
        UniversityofNevadaLasVegas: 'University of Nevada Las Vegas',
        UniversityofNevadaReno: 'University of Nevada Reno',
        UniversityofNewEngland: 'University of New England',
        UniversityofNewHampshire: 'University of New Hampshire',
        UniversityofNewHaven: 'University of New Haven',
        UniversityofNewMexico: 'University of New Mexico',
        UniversityofNewOrleans: 'University of New Orleans',
        UniversityofNorthCarolinaatAsheville: 'University of North Carolina at Asheville',
        UniversityofNorthCarolinaatChapelHill: 'University of North Carolina at Chapel Hill',
        UniversityofNorthCarolinaatCharlotte: 'University of North Carolina at Charlotte',
        TheUniversityofNorthCarolinaatGreensboro: 'The University of North Carolina at Greensboro',
        UniversityofNorthCarolinaatWilmington: 'University of North Carolina at Wilmington',
        UniversityofNorthDakota: 'University of North Dakota',
        UniversityofNorthernColorado: 'University of Northern Colorado',
        UniversityofNorthernIowa: 'University of Northern Iowa',
        UniversityofNorthFlorida: 'University of North Florida',
        UniversityofNorthTexas: 'University of North Texas',
        UniversityofNorthTexasHealthScienceCenter: 'University of North Texas Health Science Center',
        UniversityofNotreDame: 'University of Notre Dame',
        UniversityofOklahoma: 'University of Oklahoma',
        UniversityofOregon: 'University of Oregon',
        UniversityofPennsylvania: 'University of Pennsylvania',
        UniversityofPhoenix: 'University of Phoenix',
        UniversityofPittsburgh: 'University of Pittsburgh',
        UniversityofPittsburghatJohnstown: 'University of Pittsburgh at Johnstown',
        UniversityofPittsburghatBradford: 'University of Pittsburgh at Bradford',
        UniversityofPortland: 'University of Portland',
        UniversityofPuertoRico: 'University of Puerto Rico',
        UniversityofPugetSound: 'University of Puget Sound',
        UniversityofRedlands: 'University of Redlands',
        UniversityofRhodeIsland: 'University of Rhode Island',
        UniversityofRichmond: 'University of Richmond',
        UniversityofRochester: 'University of Rochester',
        UniversityofSaintThomasMN: 'University of Saint Thomas (MN)',
        UniversityofSaintThomasTX: 'University of Saint Thomas (TX)',
        UniversityofSanDiego: 'University of San Diego',
        UniversityofSanFrancisco: 'University of San Francisco',
        UniversityofScienceArtsofOklahoma: 'University of Science & Arts of Oklahoma',
        UniversityofScranton: 'University of Scranton',
        UniversityofSiouxFalls: 'University of Sioux Falls',
        UniversityofSouthAlabama: 'University of South Alabama',
        UniversityofSouthCarolina: 'University of South Carolina',
        AndersonUniversity: 'Anderson University',
        UniversityofSouthCarolinaAiken: 'University of South Carolina - Aiken',
        UniversityofSouthDakota: 'University of South Dakota',
        UniversityofSouthernCalifornia: 'University of Southern California',
        UniversityofSouthernIndiana: 'University of Southern Indiana',
        UniversityofSouthernMaine: 'University of Southern Maine',
        UniversityofSouthernMississippi: 'University of Southern Mississippi',
        UniversityofSouthFlorida: 'University of South Florida',
        UniversityofSouthwesternLouisiana: 'University of Southwestern Louisiana',
        UniversityofTampa: 'University of Tampa',
        UniversityofTennesseeatChattanooga: 'University of Tennessee at Chattanooga',
        UniversityofTennesseeKnoxville: 'University of Tennessee Knoxville',
        UniversityofTennesseeMartin: 'University of Tennessee Martin',
        UniversityofTexasatArlington: 'University of Texas at Arlington',
        TheUniversityofTexasatAustin: 'The University of Texas at Austin',
        UniversityofTexasatBrownsville: 'University of Texas at Brownsville',
        UniversityofTexasatDallas: 'University of Texas at Dallas',
        UniversityofTexasatElPaso: 'University of Texas at El Paso',
        TheUniversityofTexasatSanAntonio: 'The University of Texas at San Antonio',
        UniversityofTexasatTyler: 'University of Texas at Tyler',
        UniversityofTexasMedicalBranch: 'University of Texas Medical Branch',
        UniversityofTexasPanAmerican: 'University of Texas - Pan American',
        UniversityofTexasSouthwesternMedicalCenter: 'University of Texas Southwestern Medical Center',
        UniversityofTexasPermianBasin: 'University of Texas Permian Basin',
        UniversityoftheDistrictofColumbia: 'University of the District of Columbia',
        UniversityoftheIncarnateWord: 'University of the Incarnate Word',
        UniversityoftheOzarks: 'University of the Ozarks',
        UniversityofthePacific: 'University of the Pacific',
        UniversityofToledo: 'University of Toledo',
        UniversityofTulsa: 'University of Tulsa',
        UniversityofUtah: 'University of Utah',
        UniversityofVermont: 'University of Vermont',
        UniversityofVirginiaCharlottesville: 'University of Virginia Charlottesville',
        UniversityofWashington: 'University of Washington',
        UniversityofWestAlabama: 'University of West Alabama',
        UniversityofWestFlorida: 'University of West Florida',
        UniversityofWisconsinEauClaire: 'University of Wisconsin - Eau Claire',
        UniversityofWisconsinGreenBay: 'University of Wisconsin - Green Bay',
        UniversityofWisconsinLaCrosse: 'University of Wisconsin - LaCrosse',
        UniversityofWisconsinMadison: 'University of Wisconsin - Madison',
        UniversityofWisconsinMilwaukee: 'University of Wisconsin - Milwaukee',
        UniversityofWisconsinOshkosh: 'University of Wisconsin - Oshkosh',
        UniversityofWisconsinParkside: 'University of Wisconsin - Parkside',
        UniversityofWisconsinPlatteville: 'University of Wisconsin - Platteville',
        UniversityofWisconsinRiverFalls: 'University of Wisconsin - River Falls',
        UniversityofWisconsinStevensPoint: 'University of Wisconsin - Stevens Point',
        UniversityofWisconsinStout: 'University of Wisconsin - Stout',
        UniversityofWisconsinSuperior: 'University of Wisconsin - Superior',
        UniversityofWisconsinSystem: 'University of Wisconsin System',
        UniversityofWisconsinWhitewater: 'University of Wisconsin - Whitewater',
        UniversityofWyoming: 'University of Wyoming',
        UpperIowaUniversity: 'Upper Iowa University',
        UrsinusCollege: 'Ursinus College',
        UtahStateUniversity: 'Utah State University',
        UtahValleyStateCollege: 'Utah Valley State College',
        ValdostaStateUniversity: 'Valdosta State University',
        ValleyCityStateUniversity: 'Valley City State University',
        ValparaisoUniversity: 'Valparaiso University',
        VanceGranvilleCommunityCollege: 'Vance-Granville Community College',
        VanderbiltUniversity: 'Vanderbilt University',
        VassarCollege: 'Vassar College',
        VermontTechnicalCollege: 'Vermont Technical College',
        VillaJulieCollege: 'Villa Julie College',
        VillanovaUniversity: 'Villanova University',
        VirginiaCommonwealthUniversity: 'Virginia Commonwealth University',
        VirginiaCommunityCollegeSystem: 'Virginia Community College System',
        VirginiaIntermontCollege: 'Virginia Intermont College',
        VirginiaMilitaryInstitute: 'Virginia Military Institute',
        VirginiaTech: 'Virginia Tech',
        VirginiaStateUniversity: 'Virginia State University',
        VirginiaWesleyanCollege: 'Virginia Wesleyan College',
        WabashCollege: 'Wabash College',
        WakeForestUniversity: 'Wake Forest University',
        WaldenUniversity: 'Walden University',
        WallaWallaUniversity: 'Walla Walla University',
        WalshCollege: 'Walsh College',
        WarrenWilsonCollege: 'Warren Wilson College',
        WartburgCollege: 'Wartburg College',
        WashburnUniversity: 'Washburn University',
        WashingtonLeeUniversity: 'Washington & Lee University',
        WashingtonBibleCollegeCapitalBibleSeminary: 'Washington Bible College/Capital Bible Seminary',
        WashingtonCollege: 'Washington College',
        WashingtonStateUniversity: 'Washington State University',
        WashingtonStateUniversityatVancouver: 'Washington State University at Vancouver',
        WashingtonUniversitySaintLouis: 'Washington University Saint Louis',
        WaubonseeCommunityCollege: 'Waubonsee Community College',
        WaynesburgCollege: 'Waynesburg College',
        WayneStateUniversity: 'Wayne State University',
        WeberStateUniversity: 'Weber State University',
        WebsterUniversity: 'Webster University',
        WellesleyCollege: 'Wellesley College',
        WellsCollege: 'Wells College',
        WentworthInstituteofTechnology: 'Wentworth Institute of Technology',
        WesleyanUniversity: 'Wesleyan University',
        WesleyCollege: 'Wesley College',
        WestChesterUniversityofPennsylvania: 'West Chester University of Pennsylvania',
        WestCoastUniversity: 'West Coast University',
        WesternCarolinaUniversity: 'Western Carolina University',
        WesternConnecticutStateUniversity: 'Western Connecticut State University',
        WesternGovernorsUniversity: 'Western Governors University',
        WesternIllinoisUniversity: 'Western Illinois University',
        WesternKentuckyUniversity: 'Western Kentucky University',
        WesternMichiganUniversity: 'Western Michigan University',
        WesternMontanaCollege: 'Western Montana College',
        WesternNewEnglandCollege: 'Western New England College',
        WesternNewMexicoUniversity: 'Western New Mexico University',
        WesternStateColoradoUniversity: 'Western State Colorado University',
        WesternWashingtonUniversity: 'Western Washington University',
        WestfieldStateCollege: 'Westfield State College',
        UniversityofWestGeorgia: 'University of West Georgia',
        WestLibertyStateCollege: 'West Liberty State College',
        WestminsterCollegeofNewWilmington: 'Westminster College of New Wilmington',
        WestminsterCollegeofFulton: 'Westminster College of Fulton',
        WestminsterCollegeofSaltLakeCity: 'Westminster College of Salt Lake City',
        WestminsterTheologicalSeminary: 'Westminster Theological Seminary',
        WestmontCollege: 'Westmont College',
        WestTexasAMUniversity: 'West Texas A&M University',
        WestVirginiaUniversity: 'West Virginia University',
        WestVirginiaUniversityatParkersburg: 'West Virginia University at Parkersburg',
        WheatonCollege: 'Wheaton College',
        WheatonCollegeNortonMA: 'Wheaton College Norton MA',
        WheelingJesuitCollege: 'Wheeling Jesuit College',
        WhitmanCollege: 'Whitman College',
        WhittierCollege: 'Whittier College',
        WhitworthCollege: 'Whitworth College',
        WichitaStateUniversity: 'Wichita State University',
        WidenerUniversity: 'Widener University',
        WilberforceUniversity: 'Wilberforce University',
        WilkesUniversity: 'Wilkes University',
        WillametteUniversity: 'Willamette University',
        WilliamHowardTaftUniversity: 'William Howard Taft University',
        WilliamJewellCollege: 'William Jewell College',
        WilliamMitchellCollegeofLaw: 'William Mitchell College of Law',
        WilliamPatersonUniversity: 'William Paterson University',
        WilliamPennCollege: 'William Penn College',
        WilliamsCollege: 'Williams College',
        WilliamWoodsUniversity: 'William Woods University',
        WilmingtonCollege: 'Wilmington College',
        WinonaStateUniversity: 'Winona State University',
        WinthropUniversity: 'Winthrop University',
        WittenbergUniversity: 'Wittenberg University',
        WoffordCollege: 'Wofford College',
        WoodburyUniversity: 'Woodbury University',
        WorcesterPolytechnicInstitute: 'Worcester Polytechnic Institute',
        WrightStateUniversity: 'Wright State University',
        XavierUniversityofLouisiana: 'Xavier University of Louisiana',
        YaleUniversity: 'Yale University',
        YeshivaUniversity: 'Yeshiva University',
        YorkCollegeofPennsylvania: 'York College of Pennsylvania',
        YoungstownStateUniversity: 'Youngstown State University',
        HoustonBaptistUniversity: 'Houston Baptist University',
        BrookingsInstitution: 'Brookings Institution',
        WoodsHoleOceanographicInstitution: 'Woods Hole Oceanographic Institution',
        AlabamaSouthernCommunityCollege: 'Alabama Southern Community College',
        BevillStateCommunityCollege: 'Bevill State Community College',
        BishopStateCommunityCollege: 'Bishop State Community College',
        CentralAlabamaCommunityCollege: 'Central Alabama Community College',
        ChattahoocheeValleyCommunityCollege: 'Chattahoochee Valley Community College',
        DukeUniversity: 'Duke University',
        EnterpriseStateCommunityCollege: 'Enterprise State Community College',
        GadsdenStateCommunityCollege: 'Gadsden State Community College',
        GeorgeCWallaceStateCommunityCollegeDothan: 'George C Wallace State Community College-Dothan',
        GeorgeCWallaceStateCommunityCollegeHanceville: 'George C Wallace State Community College-Hanceville',
        GeorgeCWallaceStateCommunityCollegeSelma: 'George C Wallace State Community College-Selma',
        HCouncillTrenholmStateTechnicalCollege: 'H Councill Trenholm State Technical College',
        JamesHFaulknerStateCommunityCollege: 'James H Faulkner State Community College',
        JeffersonDavisCommunityCollege: 'Jefferson Davis Community College',
        JeffersonStateCommunityCollege: 'Jefferson State Community College',
        JFDrakeStateCommunityandTechnicalCollege: 'J F Drake State Community and Technical College',
        CalhounCommunityCollege: 'Calhoun Community College',
        LurleenBWallaceCommunityCollege: 'Lurleen B Wallace Community College',
        MarionMilitaryInstitute: 'Marion Military Institute',
        NortheastAlabamaCommunityCollege: 'Northeast Alabama Community College',
        NorthwestShoalsCommunityCollege: 'Northwest-Shoals Community College',
        SheltonStateCommunityCollege: 'Shelton State Community College',
        SneadStateCommunityCollege: 'Snead State Community College',
        SouthernUnionStateCommunityCollege: 'Southern Union State Community College',
        IlisagvikCollege: 'Ilisagvik College',
        PrinceWilliamSoundCollege: 'Prince William Sound College',
        CentralArizonaCollege: 'Central Arizona College',
        ChandlerGilbertCommunityCollege: 'Chandler-Gilbert Community College',
        CochiseCollege: 'Cochise College',
        CoconinoCommunityCollege: 'Coconino Community College',
        EasternArizonaCollege: 'Eastern Arizona College',
        GateWayCommunityCollege: 'GateWay Community College',
        MesaCommunityCollege: 'Mesa Community College',
        MohaveCommunityCollege: 'Mohave Community College',
        NorthlandPioneerCollege: 'Northland Pioneer College',
        ParadiseValleyCommunityCollege: 'Paradise Valley Community College',
        PhoenixCollege: 'Phoenix College',
        PimaCommunityCollege: 'Pima Community College',
        RioSaladoCollege: 'Rio Salado College',
        ScottsdaleCommunityCollege: 'Scottsdale Community College',
        SouthMountainCommunityCollege: 'South Mountain Community College',
        TohonoOOdhamCommunityCollege: "Tohono O'Odham Community College",
        YavapaiCollege: 'Yavapai College',
        ArkansasNortheasternCollege: 'Arkansas Northeastern College',
        ArkansasStateUniversityBeebe: 'Arkansas State University-Beebe',
        ArkansasStateUniversityMountainHome: 'Arkansas State University-Mountain Home',
        ArkansasStateUniversityNewport: 'Arkansas State University-Newport',
        BlackRiverTechnicalCollege: 'Black River Technical College',
        CollegeoftheOuachitas: 'College of the Ouachitas',
        CossatotCommunityCollegeoftheUniversityofArkansas: 'Cossatot Community College of the University of Arkansas',
        EastArkansasCommunityCollege: 'East Arkansas Community College',
        MidSouthCommunityCollege: 'Mid-South Community College',
        NationalParkCommunityCollege: 'National Park Community College',
        NorthArkansasCollege: 'North Arkansas College',
        NorthWestArkansasCommunityCollege: 'NorthWest Arkansas Community College',
        OzarkaCollege: 'Ozarka College',
        PhillipsCommunityCollegeoftheUniversityofArkansas: 'Phillips Community College of the University of Arkansas',
        PulaskiTechnicalCollege: 'Pulaski Technical College',
        RichMountainCommunityCollege: 'Rich Mountain Community College',
        SouthArkansasCommunityCollege: 'South Arkansas Community College',
        SoutheastArkansasCollege: 'Southeast Arkansas College',
        SouthernArkansasUniversityTech: 'Southern Arkansas University Tech',
        UniversityofArkansasCommunityCollegeBatesville: 'University of Arkansas Community College-Batesville',
        UniversityofArkansasCommunityCollegeHope: 'University of Arkansas Community College-Hope',
        UniversityofArkansasCommunityCollegeMorrilton: 'University of Arkansas Community College-Morrilton',
        AllanHancockCollege: 'Allan Hancock College',
        AmericanRiverCollege: 'American River College',
        AntelopeValleyCollege: 'Antelope Valley College',
        BakersfieldCollege: 'Bakersfield College',
        BarstowCommunityCollege: 'Barstow Community College',
        BerkeleyCityCollege: 'Berkeley City College',
        ButteCollege: 'Butte College',
        CabrilloCollege: 'Cabrillo College',
        CanadaCollege: 'Canada College',
        CarringtonCollegeCaliforniaSacramento: 'Carrington College California-Sacramento',
        CerritosCollege: 'Cerritos College',
        CerroCosoCommunityCollege: 'Cerro Coso Community College',
        ChabotCollege: 'Chabot College',
        ChaffeyCollege: 'Chaffey College',
        CitrusCollege: 'Citrus College',
        CityCollegeofSanFrancisco: 'City College of San Francisco',
        CoastlineCommunityCollege: 'Coastline Community College',
        CollegeofAlameda: 'College of Alameda',
        CollegeofMarin: 'College of Marin',
        CollegeofSanMateo: 'College of San Mateo',
        CollegeoftheCanyons: 'College of the Canyons',
        CollegeoftheDesert: 'College of the Desert',
        CollegeoftheRedwoods: 'College of the Redwoods',
        CollegeoftheSequoias: 'College of the Sequoias',
        CollegeoftheSiskiyous: 'College of the Siskiyous',
        ColumbiaCollegeMO: 'Columbia College (MO)',
        ColumbiaCollegeCA: 'Columbia College (CA)',
        ContraCostaCollege: 'Contra Costa College',
        CopperMountainCommunityCollege: 'Copper Mountain Community College',
        CosumnesRiverCollege: 'Cosumnes River College',
        CraftonHillsCollege: 'Crafton Hills College',
        CuestaCollege: 'Cuesta College',
        CuyamacaCollege: 'Cuyamaca College',
        CypressCollege: 'Cypress College',
        DeAnzaCollege: 'De Anza College',
        DiabloValleyCollege: 'Diablo Valley College',
        EastLosAngelesCollege: 'East Los Angeles College',
        ElCaminoCollegeComptonCenter: 'El Camino College-Compton Center',
        ElCaminoCommunityCollegeDistrict: 'El Camino Community College District',
        EvergreenValleyCollege: 'Evergreen Valley College',
        FeatherRiverCommunityCollegeDistrict: 'Feather River Community College District',
        FolsomLakeCollege: 'Folsom Lake College',
        FoothillCollege: 'Foothill College',
        FresnoCityCollege: 'Fresno City College',
        FullertonCollege: 'Fullerton College',
        GavilanCollege: 'Gavilan College',
        GlendaleCommunityCollege: 'Glendale Community College',
        GoldenWestCollege: 'Golden West College',
        GrossmontCollege: 'Grossmont College',
        HartnellCollege: 'Hartnell College',
        ImperialValleyCollege: 'Imperial Valley College',
        LakeTahoeCommunityCollege: 'Lake Tahoe Community College',
        LaneyCollege: 'Laney College',
        LasPositasCollege: 'Las Positas College',
        LassenCommunityCollege: 'Lassen Community College',
        LongBeachCityCollege: 'Long Beach City College',
        LosAngelesCityCollege: 'Los Angeles City College',
        LosAngelesCountyCollegeofNursingandAlliedHealth: 'Los Angeles County College of Nursing and Allied Health',
        LosAngelesHarborCollege: 'Los Angeles Harbor College',
        LosAngelesMissionCollege: 'Los Angeles Mission College',
        LosAngelesPierceCollege: 'Los Angeles Pierce College',
        LosAngelesSouthwestCollege: 'Los Angeles Southwest College',
        LosAngelesTradeTechnicalCollege: 'Los Angeles Trade Technical College',
        LosAngelesValleyCollege: 'Los Angeles Valley College',
        LosMedanosCollege: 'Los Medanos College',
        MendocinoCollege: 'Mendocino College',
        MercedCollege: 'Merced College',
        MerrittCollege: 'Merritt College',
        MiraCostaCollege: 'MiraCosta College',
        MissionCollege: 'Mission College',
        ModestoJuniorCollege: 'Modesto Junior College',
        MontereyPeninsulaCollege: 'Monterey Peninsula College',
        MoorparkCollege: 'Moorpark College',
        MorenoValleyCollege: 'Moreno Valley College',
        MTICollege: 'MTI College',
        MtSanAntonioCollege: 'Mt. San Antonio College',
        MtSanJacintoCommunityCollegeDistrict: 'Mt. San Jacinto Community College District',
        NapaValleyCollege: 'Napa Valley College',
        OhloneCollege: 'Ohlone College',
        OrangeCoastCollege: 'Orange Coast College',
        OxnardCollege: 'Oxnard College',
        PalomarCollege: 'Palomar College',
        PaloVerdeCollege: 'Palo Verde College',
        PasadenaCityCollege: 'Pasadena City College',
        PortervilleCollege: 'Porterville College',
        ReedleyCollege: 'Reedley College',
        RioHondoCollege: 'Rio Hondo College',
        RiversideCityCollege: 'Riverside City College',
        SacramentoCityCollege: 'Sacramento City College',
        SaddlebackCollege: 'Saddleback College',
        SanBernardinoValleyCollege: 'San Bernardino Valley College',
        SanDiegoCityCollege: 'San Diego City College',
        SanDiegoMesaCollege: 'San Diego Mesa College',
        SanDiegoMiramarCollege: 'San Diego Miramar College',
        SanJoaquinDeltaCollege: 'San Joaquin Delta College',
        SanJoaquinValleyCollegeVisalia: 'San Joaquin Valley College-Visalia',
        SanJoseCityCollege: 'San Jose City College',
        SantaAnaCollege: 'Santa Ana College',
        SantaBarbaraCityCollege: 'Santa Barbara City College',
        SantaMonicaCollege: 'Santa Monica College',
        SantaRosaJuniorCollege: 'Santa Rosa Junior College',
        SantiagoCanyonCollege: 'Santiago Canyon College',
        ShastaCollege: 'Shasta College',
        SierraCollege: 'Sierra College',
        SkylineCollege: 'Skyline College',
        SolanoCommunityCollege: 'Solano Community College',
        SouthwesternCollegeCA: 'Southwestern College (CA)',
        TaftCollege: 'Taft College',
        VenturaCollege: 'Ventura College',
        VictorValleyCollege: 'Victor Valley College',
        WestHillsCollegeCoalinga: 'West Hills College-Coalinga',
        WestLosAngelesCollege: 'West Los Angeles College',
        WestValleyCollege: 'West Valley College',
        WoodlandCommunityCollege: 'Woodland Community College',
        YubaCollege: 'Yuba College',
        AimsCommunityCollege: 'Aims Community College',
        ArapahoeCommunityCollege: 'Arapahoe Community College',
        ColoradoNorthwesternCommunityCollege: 'Colorado Northwestern Community College',
        CommunityCollegeofAurora: 'Community College of Aurora',
        CommunityCollegeofDenver: 'Community College of Denver',
        FrontRangeCommunityCollege: 'Front Range Community College',
        LamarCommunityCollege: 'Lamar Community College',
        MorganCommunityCollege: 'Morgan Community College',
        NortheasternJuniorCollege: 'Northeastern Junior College',
        OteroJuniorCollege: 'Otero Junior College',
        PikesPeakCommunityCollege: 'Pikes Peak Community College',
        PuebloCommunityCollege: 'Pueblo Community College',
        RedRocksCommunityCollege: 'Red Rocks Community College',
        TrinidadStateJuniorCollege: 'Trinidad State Junior College',
        AsnuntuckCommunityCollege: 'Asnuntuck Community College',
        CapitalCommunityCollege: 'Capital Community College',
        GatewayCommunityCollege: 'Gateway Community College',
        HousatonicCommunityCollege: 'Housatonic Community College',
        MiddlesexCommunityCollege: 'Middlesex Community College',
        NaugatuckValleyCommunityCollege: 'Naugatuck Valley Community College',
        NorthwesternConnecticutCommunityCollege: 'Northwestern Connecticut Community College',
        NorwalkCommunityCollege: 'Norwalk Community College',
        QuinebaugValleyCommunityCollege: 'Quinebaug Valley Community College',
        TunxisCommunityCollege: 'Tunxis Community College',
        DelawareCollegeofArtandDesign: 'Delaware College of Art and Design',
        DelawareTechnicalCommunityCollege: 'Delaware Technical Community College',
        FloridaKeysCommunityCollege: 'Florida Keys Community College',
        HillsboroughCommunityCollege: 'Hillsborough Community College',
        NorthFloridaCommunityCollege: 'North Florida Community College',
        PascoHernandoCommunityCollege: 'Pasco-Hernando Community College',
        TallahasseeCommunityCollege: 'Tallahassee Community College',
        AlbanyTechnicalCollege: 'Albany Technical College',
        AndrewCollege: 'Andrew College',
        AthensTechnicalCollege: 'Athens Technical College',
        AtlantaTechnicalCollege: 'Atlanta Technical College',
        AugustaTechnicalCollege: 'Augusta Technical College',
        BainbridgeStateCollege: 'Bainbridge State College',
        CentralGeorgiaTechnicalCollege: 'Central Georgia Technical College',
        ChattahoocheeTechnicalCollege: 'Chattahoochee Technical College',
        ColumbusTechnicalCollege: 'Columbus Technical College',
        DartonStateCollege: 'Darton State College',
        GeorgiaHighlandsCollege: 'Georgia Highlands College',
        GeorgiaMilitaryCollege: 'Georgia Military College',
        GeorgiaNorthwesternTechnicalCollege: 'Georgia Northwestern Technical College',
        GeorgiaPerimeterCollege: 'Georgia Perimeter College',
        GeorgiaPiedmontTechnicalCollege: 'Georgia Piedmont Technical College',
        GwinnettTechnicalCollege: 'Gwinnett Technical College',
        LanierTechnicalCollege: 'Lanier Technical College',
        MoultrieTechnicalCollege: 'Moultrie Technical College',
        NorthGeorgiaTechnicalCollege: 'North Georgia Technical College',
        OgeecheeTechnicalCollege: 'Ogeechee Technical College',
        CoastalPinesTechnicalCollege: 'Coastal Pines Technical College',
        SavannahTechnicalCollege: 'Savannah Technical College',
        SoutheasternTechnicalCollege: 'Southeastern Technical College',
        SouthernCrescentTechnicalCollege: 'Southern Crescent Technical College',
        SouthGeorgiaTechnicalCollege: 'South Georgia Technical College',
        SouthwestGeorgiaTechnicalCollege: 'Southwest Georgia Technical College',
        WestGeorgiaTechnicalCollege: 'West Georgia Technical College',
        WiregrassGeorgiaTechnicalCollege: 'Wiregrass Georgia Technical College',
        HawaiiCommunityCollege: 'Hawaii Community College',
        HonoluluCommunityCollege: 'Honolulu Community College',
        KapiolaniCommunityCollege: 'Kapiolani Community College',
        KauaiCommunityCollege: 'Kauai Community College',
        LeewardCommunityCollege: 'Leeward Community College',
        WindwardCommunityCollege: 'Windward Community College',
        CollegeofSouthernIdaho: 'College of Southern Idaho',
        CollegeofWesternIdaho: 'College of Western Idaho',
        EasternIdahoTechnicalCollege: 'Eastern Idaho Technical College',
        NorthIdahoCollege: 'North Idaho College',
        BlackHawkCollege: 'Black Hawk College',
        CarlSandburgCollege: 'Carl Sandburg College',
        CityCollegesofChicago: 'City Colleges of Chicago',
        CollegeofDuPage: 'College of DuPage',
        CollegeofLakeCounty: 'College of Lake County',
        DanvilleAreaCommunityCollege: 'Danville Area Community College',
        ElginCommunityCollege: 'Elgin Community College',
        FoxCollege: 'Fox College',
        HarperCollege: 'Harper College',
        HeartlandCommunityCollege: 'Heartland Community College',
        IllinoisCentralCollege: 'Illinois Central College',
        IllinoisValleyCommunityCollege: 'Illinois Valley Community College',
        JohnALoganCollege: 'John A Logan College',
        JohnWoodCommunityCollege: 'John Wood Community College',
        JolietJuniorCollege: 'Joliet Junior College',
        KankakeeCommunityCollege: 'Kankakee Community College',
        KaskaskiaCollege: 'Kaskaskia College',
        KishwaukeeCollege: 'Kishwaukee College',
        LakeLandCollege: 'Lake Land College',
        LeCordonBleuCollegeofCulinaryArtsChicago: 'Le Cordon Bleu College of Culinary Arts-Chicago',
        LewisandClarkCommunityCollege: 'Lewis and Clark Community College',
        LincolnLandCommunityCollege: 'Lincoln Land Community College',
        MacCormacCollege: 'MacCormac College',
        McHenryCountyCollege: 'McHenry County College',
        MoraineValleyCommunityCollege: 'Moraine Valley Community College',
        MortonCollege: 'Morton College',
        NorthwesternCollegeChicagoCampus: 'Northwestern College-Chicago Campus',
        OaktonCommunityCollege: 'Oakton Community College',
        ParklandCollege: 'Parkland College',
        PrairieStateCollege: 'Prairie State College',
        RendLakeCollege: 'Rend Lake College',
        RichlandCommunityCollege: 'Richland Community College',
        RockValleyCollege: 'Rock Valley College',
        SaukValleyCommunityCollege: 'Sauk Valley Community College',
        ShawneeCommunityCollege: 'Shawnee Community College',
        SoutheasternIllinoisCollege: 'Southeastern Illinois College',
        SouthSuburbanCollege: 'South Suburban College',
        SouthwesternIllinoisCollege: 'Southwestern Illinois College',
        SpoonRiverCollege: 'Spoon River College',
        TritonCollege: 'Triton College',
        AncillaCollege: 'Ancilla College',
        IvyTechCommunityCollege: 'Ivy Tech Community College',
        DesMoinesAreaCommunityCollege: 'Des Moines Area Community College',
        EasternIowaCommunityCollegeDistrict: 'Eastern Iowa Community College District',
        HawkeyeCommunityCollege: 'Hawkeye Community College',
        IndianHillsCommunityCollege: 'Indian Hills Community College',
        IowaCentralCommunityCollege: 'Iowa Central Community College',
        IowaLakesCommunityCollege: 'Iowa Lakes Community College',
        IowaWesternCommunityCollege: 'Iowa Western Community College',
        KirkwoodCommunityCollege: 'Kirkwood Community College',
        MarshalltownCommunityCollege: 'Marshalltown Community College',
        NortheastIowaCommunityCollege: 'Northeast Iowa Community College',
        NorthIowaAreaCommunityCollege: 'North Iowa Area Community College',
        NorthwestIowaCommunityCollege: 'Northwest Iowa Community College',
        SoutheasternCommunityCollege: 'Southeastern Community College',
        SouthwesternCommunityCollege: 'Southwestern Community College',
        WesternIowaTechCommunityCollege: 'Western Iowa Tech Community College',
        AllenCountyCommunityCollege: 'Allen County Community College',
        BartonCountyCommunityCollege: 'Barton County Community College',
        ButlerCommunityCollege: 'Butler Community College',
        CloudCountyCommunityCollege: 'Cloud County Community College',
        CoffeyvilleCommunityCollege: 'Coffeyville Community College',
        ColbyCommunityCollege: 'Colby Community College',
        CowleyCountyCommunityCollege: 'Cowley County Community College',
        DodgeCityCommunityCollege: 'Dodge City Community College',
        FlintHillsTechnicalCollege: 'Flint Hills Technical College',
        FortScottCommunityCollege: 'Fort Scott Community College',
        GardenCityCommunityCollege: 'Garden City Community College',
        HighlandCommunityCollege: 'Highland Community College',
        HutchinsonCommunityCollege: 'Hutchinson Community College',
        IndependenceCommunityCollege: 'Independence Community College',
        JohnsonCountyCommunityCollege: 'Johnson County Community College',
        KansasCityKansasCommunityCollege: 'Kansas City Kansas Community College',
        LabetteCommunityCollege: 'Labette Community College',
        ManhattanAreaTechnicalCollege: 'Manhattan Area Technical College',
        NeoshoCountyCommunityCollege: 'Neosho County Community College',
        NorthCentralKansasTechnicalCollege: 'North Central Kansas Technical College',
        NorthwestKansasTechnicalCollege: 'Northwest Kansas Technical College',
        PrattCommunityCollege: 'Pratt Community College',
        SewardCountyCommunityCollegeandAreaTechnicalSchool: 'Seward County Community College and Area Technical School',
        WichitaAreaTechnicalCollege: 'Wichita Area Technical College',
        AshlandCommunityandTechnicalCollege: 'Ashland Community and Technical College',
        BigSandyCommunityandTechnicalCollege: 'Big Sandy Community and Technical College',
        BluegrassCommunityandTechnicalCollege: 'Bluegrass Community and Technical College',
        ElizabethtownCommunityandTechnicalCollege: 'Elizabethtown Community and Technical College',
        GalenCollegeofNursingLouisville: 'Galen College of Nursing-Louisville',
        GatewayCommunityandTechnicalCollege: 'Gateway Community and Technical College',
        HazardCommunityandTechnicalCollege: 'Hazard Community and Technical College',
        HendersonCommunityCollege: 'Henderson Community College',
        HopkinsvilleCommunityCollege: 'Hopkinsville Community College',
        JeffersonCommunityandTechnicalCollege: 'Jefferson Community and Technical College',
        MadisonvilleCommunityCollege: 'Madisonville Community College',
        MaysvilleCommunityandTechnicalCollege: 'Maysville Community and Technical College',
        OwensboroCommunityandTechnicalCollege: 'Owensboro Community and Technical College',
        SomersetCommunityCollege: 'Somerset Community College',
        SouthcentralKentuckyCommunityandTechnicalCollege: 'Southcentral Kentucky Community and Technical College',
        SoutheastKentuckyCommunityandTechnicalCollege: 'Southeast Kentucky Community and Technical College',
        WestKentuckyCommunityandTechnicalCollege: 'West Kentucky Community and Technical College',
        BatonRougeCommunityCollege: 'Baton Rouge Community College',
        BossierParishCommunityCollege: 'Bossier Parish Community College',
        DelgadoCommunityCollege: 'Delgado Community College',
        LEFletcherTechnicalCommunityCollege: 'L E Fletcher Technical Community College',
        LouisianaDeltaCommunityCollege: 'Louisiana Delta Community College',
        LouisianaStateUniversityEunice: 'Louisiana State University-Eunice',
        NunezCommunityCollege: 'Nunez Community College',
        RiverParishesCommunityCollege: 'River Parishes Community College',
        SouthernUniversityatShreveport: 'Southern University at Shreveport',
        SouthLouisianaCommunityCollege: 'South Louisiana Community College',
        SOWELATechnicalCommunityCollege: 'SOWELA Technical Community College',
        CentralMaineCommunityCollege: 'Central Maine Community College',
        CentralMaineMedicalCenterCollegeofNursingandHealthProfessions:
            'Central Maine Medical Center College of Nursing and Health Professions',
        EasternMaineCommunityCollege: 'Eastern Maine Community College',
        KennebecValleyCommunityCollege: 'Kennebec Valley Community College',
        NorthernMaineCommunityCollege: 'Northern Maine Community College',
        SouthernMaineCommunityCollege: 'Southern Maine Community College',
        WashingtonCountyCommunityCollege: 'Washington County Community College',
        YorkCountyCommunityCollege: 'York County Community College',
        AlleganyCollegeofMaryland: 'Allegany College of Maryland',
        BaltimoreCityCommunityCollege: 'Baltimore City Community College',
        CarrollCommunityCollege: 'Carroll Community College',
        CecilCollege: 'Cecil College',
        CollegeofSouthernMaryland: 'College of Southern Maryland',
        GarrettCollege: 'Garrett College',
        PrinceGeorgesCommunityCollege: "Prince George's Community College",
        WorWicCommunityCollege: 'Wor-Wic Community College',
        BerkshireCommunityCollege: 'Berkshire Community College',
        BristolCommunityCollege: 'Bristol Community College',
        BunkerHillCommunityCollege: 'Bunker Hill Community College',
        CapeCodCommunityCollege: 'Cape Cod Community College',
        GreenfieldCommunityCollege: 'Greenfield Community College',
        HolyokeCommunityCollege: 'Holyoke Community College',
        MassachusettsBayCommunityCollege: 'Massachusetts Bay Community College',
        MassasoitCommunityCollege: 'Massasoit Community College',
        MountWachusettCommunityCollege: 'Mount Wachusett Community College',
        NorthernEssexCommunityCollege: 'Northern Essex Community College',
        NorthShoreCommunityCollege: 'North Shore Community College',
        QuincyCollege: 'Quincy College',
        QuinsigamondCommunityCollege: 'Quinsigamond Community College',
        RoxburyCommunityCollege: 'Roxbury Community College',
        SpringfieldTechnicalCommunityCollege: 'Springfield Technical Community College',
        UrbanCollegeofBoston: 'Urban College of Boston',
        AlpenaCommunityCollege: 'Alpena Community College',
        BaydeNocCommunityCollege: 'Bay de Noc Community College',
        BayMillsCommunityCollege: 'Bay Mills Community College',
        DeltaCollege: 'Delta College',
        GlenOaksCommunityCollege: 'Glen Oaks Community College',
        GogebicCommunityCollege: 'Gogebic Community College',
        GrandRapidsCommunityCollege: 'Grand Rapids Community College',
        HenryFordCollege: 'Henry Ford College',
        JacksonCollege: 'Jackson College',
        KalamazooValleyCommunityCollege: 'Kalamazoo Valley Community College',
        KelloggCommunityCollege: 'Kellogg Community College',
        KeweenawBayOjibwaCommunityCollege: 'Keweenaw Bay Ojibwa Community College',
        KirtlandCommunityCollege: 'Kirtland Community College',
        LakeMichiganCollege: 'Lake Michigan College',
        LansingCommunityCollege: 'Lansing Community College',
        MacombCommunityCollege: 'Macomb Community College',
        MidMichiganCommunityCollege: 'Mid Michigan Community College',
        MonroeCountyCommunityCollege: 'Monroe County Community College',
        MontcalmCommunityCollege: 'Montcalm Community College',
        MuskegonCommunityCollege: 'Muskegon Community College',
        NorthCentralMichiganCollege: 'North Central Michigan College',
        OaklandCommunityCollege: 'Oakland Community College',
        SaginawChippewaTribalCollege: 'Saginaw Chippewa Tribal College',
        SchoolcraftCollege: 'Schoolcraft College',
        SouthwesternMichiganCollege: 'Southwestern Michigan College',
        StClairCountyCommunityCollege: 'St. Clair County Community College',
        WashtenawCommunityCollege: 'Washtenaw Community College',
        WayneCountyCommunityCollegeDistrict: 'Wayne County Community College District',
        WestShoreCommunityCollege: 'West Shore Community College',
        AlexandriaTechnicalCommunityCollege: 'Alexandria Technical & Community College',
        AnokaRamseyCommunityCollege: 'Anoka-Ramsey Community College',
        AnokaTechnicalCollege: 'Anoka Technical College',
        CentralLakesCollegeBrainerd: 'Central Lakes College-Brainerd',
        CenturyCollege: 'Century College',
        DakotaCountyTechnicalCollege: 'Dakota County Technical College',
        FondduLacTribalandCommunityCollege: 'Fond du Lac Tribal and Community College',
        HennepinTechnicalCollege: 'Hennepin Technical College',
        HibbingCommunityCollege: 'Hibbing Community College',
        InverHillsCommunityCollege: 'Inver Hills Community College',
        ItascaCommunityCollege: 'Itasca Community College',
        LeechLakeTribalCollege: 'Leech Lake Tribal College',
        MesabiRangeCommunityandTechnicalCollege: 'Mesabi Range Community and Technical College',
        MinneapolisCommunityandTechnicalCollege: 'Minneapolis Community and Technical College',
        MinnesotaStateCollegeSoutheastTechnical: 'Minnesota State College-Southeast Technical',
        MinnesotaStateCommunityandTechnicalCollege: 'Minnesota State Community and Technical College',
        MinnesotaWestCommunityandTechnicalCollege: 'Minnesota West Community and Technical College',
        NormandaleCommunityCollege: 'Normandale Community College',
        NorthHennepinCommunityCollege: 'North Hennepin Community College',
        NorthlandCommunityandTechnicalCollege: 'Northland Community and Technical College',
        NorthwestTechnicalCollege: 'Northwest Technical College',
        PineTechnicalCollege: 'Pine Technical College',
        RainyRiverCommunityCollege: 'Rainy River Community College',
        RidgewaterCollege: 'Ridgewater College',
        RiverlandCommunityCollege: 'Riverland Community College',
        RochesterCommunityandTechnicalCollege: 'Rochester Community and Technical College',
        SaintPaulCollege: 'Saint Paul College',
        SouthCentralCollege: 'South Central College',
        StCloudTechnicalandCommunityCollege: 'St Cloud Technical and Community College',
        VermilionCommunityCollege: 'Vermilion Community College',
        WhiteEarthTribalandCommunityCollege: 'White Earth Tribal and Community College',
        CoahomaCommunityCollege: 'Coahoma Community College',
        CopiahLincolnCommunityCollege: 'Copiah-Lincoln Community College',
        OlinCollegeofEngineering: 'Olin College of Engineering',
        EastCentralCommunityCollege: 'East Central Community College',
        EastMississippiCommunityCollege: 'East Mississippi Community College',
        HindsCommunityCollege: 'Hinds Community College',
        HolmesCommunityCollege: 'Holmes Community College',
        ItawambaCommunityCollege: 'Itawamba Community College',
        JonesCountyJuniorCollege: 'Jones County Junior College',
        MeridianCommunityCollege: 'Meridian Community College',
        MississippiDeltaCommunityCollege: 'Mississippi Delta Community College',
        MississippiGulfCoastCommunityCollege: 'Mississippi Gulf Coast Community College',
        NortheastMississippiCommunityCollege: 'Northeast Mississippi Community College',
        NorthwestMississippiCommunityCollege: 'Northwest Mississippi Community College',
        PearlRiverCommunityCollege: 'Pearl River Community College',
        SouthwestMississippiCommunityCollege: 'Southwest Mississippi Community College',
        CrowderCollege: 'Crowder College',
        EastCentralCollege: 'East Central College',
        JeffersonCollege: 'Jefferson College',
        LinnStateTechnicalCollege: 'Linn State Technical College',
        MineralAreaCollege: 'Mineral Area College',
        MissouriStateUniversity: 'Missouri State University',
        MissouriStateUniversityWestPlains: 'Missouri State University-West Plains',
        MoberlyAreaCommunityCollege: 'Moberly Area Community College',
        NorthCentralMissouriCollege: 'North Central Missouri College',
        OzarksTechnicalCommunityCollege: 'Ozarks Technical Community College',
        SoutheastMissouriHospitalCollegeofNursingandHealthSciences:
            'Southeast Missouri Hospital College of Nursing and Health Sciences',
        StateFairCommunityCollege: 'State Fair Community College',
        StCharlesCommunityCollege: 'St Charles Community College',
        ThreeRiversCommunityCollege: 'Three Rivers Community College',
        WentworthMilitaryAcademyJuniorCollege: 'Wentworth Military Academy & Junior College',
        AaniiihNakodaCollege: 'Aaniiih Nakoda College',
        BlackfeetCommunityCollege: 'Blackfeet Community College',
        ChiefDullKnifeCollege: 'Chief Dull Knife College',
        DawsonCommunityCollege: 'Dawson Community College',
        FlatheadValleyCommunityCollege: 'Flathead Valley Community College',
        FortPeckCommunityCollege: 'Fort Peck Community College',
        GreatFallsCollegeMontanaStateUniversity: 'Great Falls College Montana State University',
        HelenaCollegeUniversityofMontana: 'Helena College University of Montana',
        LittleBigHornCollege: 'Little Big Horn College',
        MilesCommunityCollege: 'Miles Community College',
        StoneChildCollege: 'Stone Child College',
        CentralCommunityCollege: 'Central Community College',
        LittlePriestTribalCollege: 'Little Priest Tribal College',
        MidPlainsCommunityCollege: 'Mid-Plains Community College',
        NebraskaCollegeofTechnicalAgriculture: 'Nebraska College of Technical Agriculture',
        NebraskaIndianCommunityCollege: 'Nebraska Indian Community College',
        NortheastCommunityCollege: 'Northeast Community College',
        SoutheastCommunityCollegeArea: 'Southeast Community College Area',
        WesternNebraskaCommunityCollege: 'Western Nebraska Community College',
        TruckeeMeadowsCommunityCollege: 'Truckee Meadows Community College',
        GreatBayCommunityCollege: 'Great Bay Community College',
        LakesRegionCommunityCollege: 'Lakes Region Community College',
        ManchesterCommunityCollege: 'Manchester Community College',
        NashuaCommunityCollege: 'Nashua Community College',
        NHTIConcordsCommunityCollege: "NHTI-Concord's Community College",
        RiverValleyCommunityCollege: 'River Valley Community College',
        WhiteMountainsCommunityCollege: 'White Mountains Community College',
        AssumptionCollegeforSisters: 'Assumption College for Sisters',
        AtlanticCapeCommunityCollege: 'Atlantic Cape Community College',
        BergenCommunityCollege: 'Bergen Community College',
        BrookdaleCommunityCollege: 'Brookdale Community College',
        CamdenCountyCollege: 'Camden County College',
        CountyCollegeofMorris: 'County College of Morris',
        CumberlandCountyCollege: 'Cumberland County College',
        EssexCountyCollege: 'Essex County College',
        GloucesterCountyCollege: 'Gloucester County College',
        HudsonCountyCommunityCollege: 'Hudson County Community College',
        MiddlesexCountyCollege: 'Middlesex County College',
        OceanCountyCollege: 'Ocean County College',
        PassaicCountyCommunityCollege: 'Passaic County Community College',
        RaritanValleyCommunityCollege: 'Raritan Valley Community College',
        SalemCommunityCollege: 'Salem Community College',
        SussexCountyCommunityCollege: 'Sussex County Community College',
        UnionCountyCollege: 'Union County College',
        WarrenCountyCommunityCollege: 'Warren County Community College',
        CentralNewMexicoCommunityCollege: 'Central New Mexico Community College',
        ClovisCommunityCollege: 'Clovis Community College',
        EasternNewMexicoUniversityRoswellCampus: 'Eastern New Mexico University-Roswell Campus',
        LunaCommunityCollege: 'Luna Community College',
        MesalandsCommunityCollege: 'Mesalands Community College',
        NewMexicoJuniorCollege: 'New Mexico Junior College',
        NewMexicoMilitaryInstitute: 'New Mexico Military Institute',
        NewMexicoStateUniversityAlamogordo: 'New Mexico State University-Alamogordo',
        NewMexicoStateUniversityCarlsbad: 'New Mexico State University-Carlsbad',
        NewMexicoStateUniversityDonaAna: 'New Mexico State University-Dona Ana',
        SanJuanCollege: 'San Juan College',
        SantaFeCommunityCollege: 'Santa Fe Community College',
        SouthwesternIndianPolytechnicInstitute: 'Southwestern Indian Polytechnic Institute',
        AdirondackCommunityCollege: 'Adirondack Community College',
        AmericanAcademyofDramaticArtsNewYork: 'American Academy of Dramatic Arts-New York',
        ASACollege: 'ASA College',
        CayugaCountyCommunityCollege: 'Cayuga County Community College',
        ClintonCommunityCollege: 'Clinton Community College',
        ColumbiaGreeneCommunityCollege: 'Columbia-Greene Community College',
        CUNYBoroughofManhattanCommunityCollege: 'CUNY Borough of Manhattan Community College',
        CUNYBronxCommunityCollege: 'CUNY Bronx Community College',
        CUNYHostosCommunityCollege: 'CUNY Hostos Community College',
        CUNYKingsboroughCommunityCollege: 'CUNY Kingsborough Community College',
        CUNYLaGuardiaCommunityCollege: 'CUNY LaGuardia Community College',
        CUNYQueensboroughCommunityCollege: 'CUNY Queensborough Community College',
        CUNYBaruchCollege: 'CUNY Baruch College',
        CUNYQueensCollege: 'CUNY Queens College',
        CUNYCityTech: 'CUNY City Tech',
        CUNYCityCollegeofNY: 'CUNY City College of NY',
        CUNYBrooklynCollege: 'CUNY Brooklyn College',
        CUNYMedgarEversCollege: 'CUNY Medgar Evers College',
        CUNYCollegeofStatenIsland: 'CUNY College of Staten Island',
        CUNYMacaulyHonorsCollege: 'CUNY Macauly Honors College',
        DutchessCommunityCollege: 'Dutchess Community College',
        FingerLakesCommunityCollege: 'Finger Lakes Community College',
        FultonMontgomeryCommunityCollege: 'Fulton-Montgomery Community College',
        GeneseeCommunityCollege: 'Genesee Community College',
        HerkimerCountyCommunityCollege: 'Herkimer County Community College',
        HudsonValleyCommunityCollege: 'Hudson Valley Community College',
        JamestownCommunityCollege: 'Jamestown Community College',
        JeffersonCommunityCollege: 'Jefferson Community College',
        MohawkValleyCommunityCollege: 'Mohawk Valley Community College',
        MonroeCommunityCollege: 'Monroe Community College',
        NassauCommunityCollege: 'Nassau Community College',
        NiagaraCountyCommunityCollege: 'Niagara County Community College',
        NorthCountryCommunityCollege: 'North Country Community College',
        OnondagaCommunityCollege: 'Onondaga Community College',
        OrangeCountyCommunityCollege: 'Orange County Community College',
        RocklandCommunityCollege: 'Rockland Community College',
        SaintElizabethCollegeofNursing: 'Saint Elizabeth College of Nursing',
        SchenectadyCountyCommunityCollege: 'Schenectady County Community College',
        SuffolkCountyCommunityCollege: 'Suffolk County Community College',
        SullivanCountyCommunityCollege: 'Sullivan County Community College',
        SUNYBroomeCommunityCollege: 'SUNY Broome Community College',
        SUNYWestchesterCommunityCollege: 'SUNY Westchester Community College',
        TechnicalCareerInstitutes: 'Technical Career Institutes',
        TompkinsCortlandCommunityCollege: 'Tompkins Cortland Community College',
        UlsterCountyCommunityCollege: 'Ulster County Community College',
        AlamanceCommunityCollege: 'Alamance Community College',
        AshevilleBuncombeTechnicalCommunityCollege: 'Asheville-Buncombe Technical Community College',
        BeaufortCountyCommunityCollege: 'Beaufort County Community College',
        BladenCommunityCollege: 'Bladen Community College',
        BlueRidgeCommunityCollege: 'Blue Ridge Community College',
        BrunswickCommunityCollege: 'Brunswick Community College',
        CaldwellCommunityCollegeandTechnicalInstitute: 'Caldwell Community College and Technical Institute',
        CapeFearCommunityCollege: 'Cape Fear Community College',
        CarolinasCollegeofHealthSciences: 'Carolinas College of Health Sciences',
        CarteretCommunityCollege: 'Carteret Community College',
        CatawbaValleyCommunityCollege: 'Catawba Valley Community College',
        CentralCarolinaCommunityCollege: 'Central Carolina Community College',
        CentralPiedmontCommunityCollege: 'Central Piedmont Community College',
        ClevelandCommunityCollege: 'Cleveland Community College',
        CoastalCarolinaCommunityCollege: 'Coastal Carolina Community College',
        CollegeoftheAlbemarle: 'College of the Albemarle',
        CravenCommunityCollege: 'Craven Community College',
        DavidsonCountyCommunityCollege: 'Davidson County Community College',
        DurhamTechnicalCommunityCollege: 'Durham Technical Community College',
        EdgecombeCommunityCollege: 'Edgecombe Community College',
        FayettevilleTechnicalCommunityCollege: 'Fayetteville Technical Community College',
        ForsythTechnicalCommunityCollege: 'Forsyth Technical Community College',
        GastonCollege: 'Gaston College',
        GuilfordTechnicalCommunityCollege: 'Guilford Technical Community College',
        HalifaxCommunityCollege: 'Halifax Community College',
        HaywoodCommunityCollege: 'Haywood Community College',
        IsothermalCommunityCollege: 'Isothermal Community College',
        JamesSpruntCommunityCollege: 'James Sprunt Community College',
        JohnstonCommunityCollege: 'Johnston Community College',
        LouisburgCollege: 'Louisburg College',
        MartinCommunityCollege: 'Martin Community College',
        MaylandCommunityCollege: 'Mayland Community College',
        McDowellTechnicalCommunityCollege: 'McDowell Technical Community College',
        MitchellCommunityCollege: 'Mitchell Community College',
        MontgomeryCommunityCollege: 'Montgomery Community College',
        NashCommunityCollege: 'Nash Community College',
        PamlicoCommunityCollege: 'Pamlico Community College',
        PiedmontCommunityCollege: 'Piedmont Community College',
        PittCommunityCollege: 'Pitt Community College',
        RandolphCommunityCollege: 'Randolph Community College',
        RichmondCommunityCollege: 'Richmond Community College',
        RoanokeChowanCommunityCollege: 'Roanoke-Chowan Community College',
        RobesonCommunityCollege: 'Robeson Community College',
        RockinghamCommunityCollege: 'Rockingham Community College',
        RowanCabarrusCommunityCollege: 'Rowan-Cabarrus Community College',
        SampsonCommunityCollege: 'Sampson Community College',
        SandhillsCommunityCollege: 'Sandhills Community College',
        SouthPiedmontCommunityCollege: 'South Piedmont Community College',
        StanlyCommunityCollege: 'Stanly Community College',
        SurryCommunityCollege: 'Surry Community College',
        TriCountyCommunityCollege: 'Tri-County Community College',
        WakeTechnicalCommunityCollege: 'Wake Technical Community College',
        WayneCommunityCollege: 'Wayne Community College',
        WesternPiedmontCommunityCollege: 'Western Piedmont Community College',
        WilkesCommunityCollege: 'Wilkes Community College',
        WilsonCommunityCollege: 'Wilson Community College',
        CankdeskaCikanaCommunityCollege: 'Cankdeska Cikana Community College',
        DakotaCollegeatBottineau: 'Dakota College at Bottineau',
        LakeRegionStateCollege: 'Lake Region State College',
        NorthDakotaStateCollegeofScience: 'North Dakota State College of Science',
        WillistonStateCollege: 'Williston State College',
        BelmontCollege: 'Belmont College',
        CentralOhioTechnicalCollege: 'Central Ohio Technical College',
        ChatfieldCollege: 'Chatfield College',
        CincinnatiStateTechnicalandCommunityCollege: 'Cincinnati State Technical and Community College',
        ClarkStateCommunityCollege: 'Clark State Community College',
        ColumbusStateCommunityCollege: 'Columbus State Community College',
        CuyahogaCommunityCollegeDistrict: 'Cuyahoga Community College District',
        DavisCollege: 'Davis College',
        EasternGatewayCommunityCollege: 'Eastern Gateway Community College',
        EdisonStateCommunityCollege: 'Edison State Community College',
        HockingCollege: 'Hocking College',
        JamesARhodesStateCollege: 'James A Rhodes State College',
        LakelandCommunityCollege: 'Lakeland Community College',
        LorainCountyCommunityCollege: 'Lorain County Community College',
        MarionTechnicalCollege: 'Marion Technical College',
        NorthCentralStateCollege: 'North Central State College',
        NorthwestStateCommunityCollege: 'Northwest State Community College',
        OhioStateUniversityAgriculturalTechnicalInstitute: 'Ohio State University Agricultural Technical Institute',
        OwensCommunityCollege: 'Owens Community College',
        SouthernStateCommunityCollege: 'Southern State Community College',
        StarkStateCollege: 'Stark State College',
        TerraStateCommunityCollege: 'Terra State Community College',
        UniversityofAkronWayneCollege: 'University of Akron Wayne College',
        WashingtonStateCommunityCollege: 'Washington State Community College',
        ZaneStateCollege: 'Zane State College',
        CarlAlbertStateCollege: 'Carl Albert State College',
        ConnorsStateCollege: 'Connors State College',
        EasternOklahomaStateCollege: 'Eastern Oklahoma State College',
        MurrayStateCollege: 'Murray State College',
        NortheasternOklahomaAMCollege: 'Northeastern Oklahoma A&M College',
        NorthernOklahomaCollege: 'Northern Oklahoma College',
        OklahomaCityCommunityCollege: 'Oklahoma City Community College',
        RedlandsCommunityCollege: 'Redlands Community College',
        RoseStateCollege: 'Rose State College',
        SeminoleStateCollege: 'Seminole State College',
        TulsaCommunityCollege: 'Tulsa Community College',
        WesternOklahomaStateCollege: 'Western Oklahoma State College',
        BlueMountainCommunityCollege: 'Blue Mountain Community College',
        CentralOregonCommunityCollege: 'Central Oregon Community College',
        ChemeketaCommunityCollege: 'Chemeketa Community College',
        ClackamasCommunityCollege: 'Clackamas Community College',
        ClatsopCommunityCollege: 'Clatsop Community College',
        ColumbiaGorgeCommunityCollege: 'Columbia Gorge Community College',
        KlamathCommunityCollege: 'Klamath Community College',
        LaneCommunityCollege: 'Lane Community College',
        LinnBentonCommunityCollege: 'Linn-Benton Community College',
        MtHoodCommunityCollege: 'Mt. Hood Community College',
        RogueCommunityCollege: 'Rogue Community College',
        SouthwesternOregonCommunityCollege: 'Southwestern Oregon Community College',
        TillamookBayCommunityCollege: 'Tillamook Bay Community College',
        TreasureValleyCommunityCollege: 'Treasure Valley Community College',
        UmpquaCommunityCollege: 'Umpqua Community College',
        BucksCountyCommunityCollege: 'Bucks County Community College',
        ButlerCountyCommunityCollege: 'Butler County Community College',
        CommunityCollegeofAlleghenyCounty: 'Community College of Allegheny County',
        CommunityCollegeofBeaverCounty: 'Community College of Beaver County',
        CommunityCollegeofPhiladelphia: 'Community College of Philadelphia',
        DelawareCountyCommunityCollege: 'Delaware County Community College',
        HarcumCollege: 'Harcum College',
        HarrisburgAreaCommunityCollegeHarrisburg: 'Harrisburg Area Community College-Harrisburg',
        LackawannaCollege: 'Lackawanna College',
        LehighCarbonCommunityCollege: 'Lehigh Carbon Community College',
        LuzerneCountyCommunityCollege: 'Luzerne County Community College',
        ManorCollege: 'Manor College',
        MontgomeryCountyCommunityCollege: 'Montgomery County Community College',
        PennsylvaniaHighlandsCommunityCollege: 'Pennsylvania Highlands Community College',
        PennsylvaniaInstituteofTechnology: 'Pennsylvania Institute of Technology',
        PittsburghTechnicalInstitute: 'Pittsburgh Technical Institute',
        ReadingAreaCommunityCollege: 'Reading Area Community College',
        ThaddeusStevensCollegeofTechnology: 'Thaddeus Stevens College of Technology',
        UniversityofPittsburghTitusville: 'University of Pittsburgh-Titusville',
        ValleyForgeMilitaryAcademyandCollege: 'Valley Forge Military Academy and College',
        WestmorelandCountyCommunityCollege: 'Westmoreland County Community College',
        CommunityCollegeofRhodeIsland: 'Community College of Rhode Island',
        CentralCarolinaTechnicalCollege: 'Central Carolina Technical College',
        DenmarkTechnicalCollege: 'Denmark Technical College',
        FlorenceDarlingtonTechnicalCollege: 'Florence-Darlington Technical College',
        GreenvilleTechnicalCollege: 'Greenville Technical College',
        HorryGeorgetownTechnicalCollege: 'Horry-Georgetown Technical College',
        MidlandsTechnicalCollege: 'Midlands Technical College',
        NortheasternTechnicalCollege: 'Northeastern Technical College',
        OrangeburgCalhounTechnicalCollege: 'Orangeburg Calhoun Technical College',
        PiedmontTechnicalCollege: 'Piedmont Technical College',
        SpartanburgCommunityCollege: 'Spartanburg Community College',
        SpartanburgMethodistCollege: 'Spartanburg Methodist College',
        TechnicalCollegeoftheLowcountry: 'Technical College of the Lowcountry',
        TriCountyTechnicalCollege: 'Tri-County Technical College',
        TridentTechnicalCollege: 'Trident Technical College',
        WilliamsburgTechnicalCollege: 'Williamsburg Technical College',
        YorkTechnicalCollege: 'York Technical College',
        KilianCommunityCollege: 'Kilian Community College',
        LakeAreaTechnicalInstitute: 'Lake Area Technical Institute',
        MitchellTechnicalInstitute: 'Mitchell Technical Institute',
        SissetonWahpetonCollege: 'Sisseton Wahpeton College',
        SoutheastTechnicalInstitute: 'Southeast Technical Institute',
        WesternDakotaTechnicalInstitute: 'Western Dakota Technical Institute',
        ChattanoogaStateCommunityCollege: 'Chattanooga State Community College',
        ClevelandStateCommunityCollege: 'Cleveland State Community College',
        ColumbiaStateCommunityCollege: 'Columbia State Community College',
        DyersburgStateCommunityCollege: 'Dyersburg State Community College',
        JacksonStateCommunityCollege: 'Jackson State Community College',
        JohnAGuptonCollege: 'John A Gupton College',
        MotlowStateCommunityCollege: 'Motlow State Community College',
        NortheastStateCommunityCollege: 'Northeast State Community College',
        PellissippiStateCommunityCollege: 'Pellissippi State Community College',
        RoaneStateCommunityCollege: 'Roane State Community College',
        SouthwestTennesseeCommunityCollege: 'Southwest Tennessee Community College',
        VolunteerStateCommunityCollege: 'Volunteer State Community College',
        WaltersStateCommunityCollege: 'Walters State Community College',
        AlvinCommunityCollege: 'Alvin Community College',
        AmarilloCollege: 'Amarillo College',
        AngelinaCollege: 'Angelina College',
        BlinnCollege: 'Blinn College',
        BrookhavenCollege: 'Brookhaven College',
        CedarValleyCollege: 'Cedar Valley College',
        CentralTexasCollege: 'Central Texas College',
        SchreinerUniversity: 'Schreiner University',
        CiscoCollege: 'Cisco College',
        ClarendonCollege: 'Clarendon College',
        CoastalBendCollege: 'Coastal Bend College',
        CollegeoftheMainland: 'College of the Mainland',
        CollinCountyCommunityCollegeDistrict: 'Collin County Community College District',
        DelMarCollege: 'Del Mar College',
        EastfieldCollege: 'Eastfield College',
        ElCentroCollege: 'El Centro College',
        ElPasoCommunityCollege: 'El Paso Community College',
        FrankPhillipsCollege: 'Frank Phillips College',
        GalvestonCollege: 'Galveston College',
        GraysonCollege: 'Grayson College',
        HillCollege: 'Hill College',
        HoustonCommunityCollege: 'Houston Community College',
        HowardCollege: 'Howard College',
        JacksonvilleCollegeMainCampus: 'Jacksonville College-Main Campus',
        KilgoreCollege: 'Kilgore College',
        LamarInstituteofTechnology: 'Lamar Institute of Technology',
        LamarStateCollegeOrange: 'Lamar State College-Orange',
        LamarStateCollegePortArthur: 'Lamar State College-Port Arthur',
        LaredoCommunityCollege: 'Laredo Community College',
        LeeCollege: 'Lee College',
        LoneStarCollegeSystem: 'Lone Star College System',
        McLennanCommunityCollege: 'McLennan Community College',
        NavarroCollege: 'Navarro College',
        NorthCentralTexasCollege: 'North Central Texas College',
        NortheastTexasCommunityCollege: 'Northeast Texas Community College',
        NorthLakeCollege: 'North Lake College',
        OdessaCollege: 'Odessa College',
        AlamoCollegesDistrict: 'Alamo Colleges District',
        PanolaCollege: 'Panola College',
        ParisJuniorCollege: 'Paris Junior College',
        RangerCollege: 'Ranger College',
        RichlandCollege: 'Richland College',
        SouthPlainsCollege: 'South Plains College',
        SouthwestTexasJuniorCollege: 'Southwest Texas Junior College',
        TempleCollege: 'Temple College',
        TexarkanaCollege: 'Texarkana College',
        TexasStateTechnicalCollegeHarlingen: 'Texas State Technical College-Harlingen',
        TexasStateTechnicalCollegeMarshall: 'Texas State Technical College-Marshall',
        TexasStateTechnicalCollegeWaco: 'Texas State Technical College-Waco',
        TexasStateTechnicalCollegeWestTexas: 'Texas State Technical College-West Texas',
        TrinityValleyCommunityCollege: 'Trinity Valley Community College',
        TylerJuniorCollege: 'Tyler Junior College',
        VernonCollege: 'Vernon College',
        VictoriaCollege: 'Victoria College',
        WeatherfordCollege: 'Weatherford College',
        WesternTexasCollege: 'Western Texas College',
        WhartonCountyJuniorCollege: 'Wharton County Junior College',
        LatterdaySaintsBusinessCollege: 'Latter-day Saints Business College',
        SaltLakeCommunityCollege: 'Salt Lake Community College',
        CommunityCollegeofVermont: 'Community College of Vermont',
        CentralVirginiaCommunityCollege: 'Central Virginia Community College',
        DabneySLancasterCommunityCollege: 'Dabney S Lancaster Community College',
        DanvilleCommunityCollege: 'Danville Community College',
        EasternShoreCommunityCollege: 'Eastern Shore Community College',
        GermannaCommunityCollege: 'Germanna Community College',
        JohnTylerCommunityCollege: 'John Tyler Community College',
        JSargeantReynoldsCommunityCollege: 'J Sargeant Reynolds Community College',
        LordFairfaxCommunityCollege: 'Lord Fairfax Community College',
        MountainEmpireCommunityCollege: 'Mountain Empire Community College',
        NewRiverCommunityCollege: 'New River Community College',
        NorthernVirginiaCommunityCollege: 'Northern Virginia Community College',
        PatrickHenryCommunityCollege: 'Patrick Henry Community College',
        PaulDCampCommunityCollege: 'Paul D Camp Community College',
        PiedmontVirginiaCommunityCollege: 'Piedmont Virginia Community College',
        RappahannockCommunityCollege: 'Rappahannock Community College',
        RichardBlandCollegeoftheCollegeofWilliamandMary: 'Richard Bland College of the College of William and Mary',
        SouthsideVirginiaCommunityCollege: 'Southside Virginia Community College',
        SouthwestVirginiaCommunityCollege: 'Southwest Virginia Community College',
        ThomasNelsonCommunityCollege: 'Thomas Nelson Community College',
        TidewaterCommunityCollege: 'Tidewater Community College',
        VirginiaHighlandsCommunityCollege: 'Virginia Highlands Community College',
        VirginiaWesternCommunityCollege: 'Virginia Western Community College',
        WythevilleCommunityCollege: 'Wytheville Community College',
        BatesTechnicalCollege: 'Bates Technical College',
        BellinghamTechnicalCollege: 'Bellingham Technical College',
        BigBendCommunityCollege: 'Big Bend Community College',
        CascadiaCommunityCollege: 'Cascadia Community College',
        ClarkCollege: 'Clark College',
        CloverParkTechnicalCollege: 'Clover Park Technical College',
        EdmondsCommunityCollege: 'Edmonds Community College',
        EverettCommunityCollege: 'Everett Community College',
        GraysHarborCollege: 'Grays Harbor College',
        GreenRiverCommunityCollege: 'Green River Community College',
        HighlineCommunityCollege: 'Highline Community College',
        LowerColumbiaCollege: 'Lower Columbia College',
        PierceCollegeatPuyallup: 'Pierce College at Puyallup',
        RentonTechnicalCollege: 'Renton Technical College',
        ShorelineCommunityCollege: 'Shoreline Community College',
        SkagitValleyCollege: 'Skagit Valley College',
        SouthPugetSoundCommunityCollege: 'South Puget Sound Community College',
        SpokaneCommunityCollege: 'Spokane Community College',
        SpokaneFallsCommunityCollege: 'Spokane Falls Community College',
        TacomaCommunityCollege: 'Tacoma Community College',
        WallaWallaCommunityCollege: 'Walla Walla Community College',
        WenatcheeValleyCollege: 'Wenatchee Valley College',
        WhatcomCommunityCollege: 'Whatcom Community College',
        YakimaValleyCommunityCollege: 'Yakima Valley Community College',
        BlueRidgeCommunityandTechnicalCollege: 'Blue Ridge Community and Technical College',
        BridgemontCommunityandTechnicalCollege: 'Bridgemont Community and Technical College',
        EasternWestVirginiaCommunityandTechnicalCollege: 'Eastern West Virginia Community and Technical College',
        HuntingtonJuniorCollege: 'Huntington Junior College',
        KanawhaValleyCommunityandTechnicalCollege: 'Kanawha Valley Community and Technical College',
        MountwestCommunityandTechnicalCollege: 'Mountwest Community and Technical College',
        NewRiverCommunityandTechnicalCollege: 'New River Community and Technical College',
        PierpontCommunityandTechnicalCollege: 'Pierpont Community and Technical College',
        SouthernWestVirginiaCommunityandTechnicalCollege: 'Southern West Virginia Community and Technical College',
        WestVirginiaNorthernCommunityCollege: 'West Virginia Northern Community College',
        BlackhawkTechnicalCollege: 'Blackhawk Technical College',
        ChippewaValleyTechnicalCollege: 'Chippewa Valley Technical College',
        FoxValleyTechnicalCollege: 'Fox Valley Technical College',
        GatewayTechnicalCollege: 'Gateway Technical College',
        LacCourteOreillesOjibwaCommunityCollege: 'Lac Courte Oreilles Ojibwa Community College',
        LakeshoreTechnicalCollege: 'Lakeshore Technical College',
        MadisonAreaTechnicalCollege: 'Madison Area Technical College',
        MidStateTechnicalCollege: 'Mid-State Technical College',
        MilwaukeeAreaTechnicalCollege: 'Milwaukee Area Technical College',
        MoraineParkTechnicalCollege: 'Moraine Park Technical College',
        NicoletAreaTechnicalCollege: 'Nicolet Area Technical College',
        NorthcentralTechnicalCollege: 'Northcentral Technical College',
        NortheastWisconsinTechnicalCollege: 'Northeast Wisconsin Technical College',
        SouthwestWisconsinTechnicalCollege: 'Southwest Wisconsin Technical College',
        UniversityofWisconsinColleges: 'University of Wisconsin Colleges',
        WaukeshaCountyTechnicalCollege: 'Waukesha County Technical College',
        WesternTechnicalCollege: 'Western Technical College',
        WisconsinIndianheadTechnicalCollege: 'Wisconsin Indianhead Technical College',
        CasperCollege: 'Casper College',
        CentralWyomingCollege: 'Central Wyoming College',
        EasternWyomingCollege: 'Eastern Wyoming College',
        LaramieCountyCommunityCollege: 'Laramie County Community College',
        NorthwestCollege: 'Northwest College',
        SheridanCollege: 'Sheridan College',
        WesternWyomingCommunityCollege: 'Western Wyoming Community College',
        TrinityBibleCollegeGraduateSchool: 'Trinity Bible College & Graduate School',
        UniversityofMichiganFlint: 'University of Michigan-Flint',
        KenaiPeninsulaCollege: 'Kenai Peninsula College',
        MatSuCollege: 'Mat-Su College',
        KodiakCollege: 'Kodiak College',
        AlaskaPacificUniversity: 'Alaska Pacific University',
        WaylandBaptistUniversity: 'Wayland Baptist University',
        PolkStateCollege: 'Polk State College',
        BresciaUniversity: 'Brescia University',
        RogersStateUniversity: 'Rogers State University',
        NeumannUniversity: 'Neumann University',
        SavannahCollegeofArtandDesign: 'Savannah College of Art and Design',
        AshfordUniversity: 'Ashford University',
        McPhersonCollege: 'McPherson College',
        CommunityCollegeSystemofNewHampshire: 'Community College System of New Hampshire',
        DefianceCollege: 'Defiance College',
        MedicalUniversityofSouthCarolina: 'Medical University of South Carolina',
        GulfCoastStateCollege: 'Gulf Coast State College',
        AcademyofArtUniversity: 'Academy of Art University',
        LenoirRhyneUniversity: 'Lenoir-Rhyne University',
        PointParkUniversity: 'Point Park University',
        NorthwoodUniversity: 'Northwood University',
        ClarkAtlantaUniversity: 'Clark Atlanta University',
        Morehouse: 'Morehouse',
        UniversityofStAugustineforHealthSciences: 'University of St. Augustine for Health Sciences',
        GwyneddMercyUniversity: 'Gwynedd Mercy University',
        CaliforniaInstituteoftheArts: 'California Institute of the Arts',
        DesMoinesUniversity: 'Des Moines University',
        UtahTechUniversity: 'Utah Tech University',
        MidwesternUniversity: 'Midwestern University',
        IllinoisWesleyanUniversity: 'Illinois Wesleyan University',
        MaryvilleCollege: 'Maryville College',
        EndicottCollege: 'Endicott College',
        TheUniversityofAlabama: 'The University of Alabama',
        GreatBasinCollege: 'Great Basin College',
        Einsteinmed: 'Einsteinmed',
        MiamiDadeCollege: 'Miami Dade College',
        FloridaSouthWesternStateCollege: 'Florida SouthWestern State College',
        BishopMcNamaraHighSchool: 'Bishop McNamara High School',
        ChaminadeUniversityofHonolulu: 'Chaminade University of Honolulu',
        DaltonStateCollege: 'Dalton State College',
        OjaiUnifiedSchoolDistrict: 'Ojai Unified School District',
        BismarkStateCollege: 'Bismark State College',
        QuadratAcademy: 'Quadrat Academy',
        UniversityofNorthAlabama: 'University of North Alabama',
        BellevueCollege: 'Bellevue College',
        CUNYLehmanCollege: 'CUNY Lehman College',
        CUNYJohnJayCollegeofCriminalJustice: 'CUNY John Jay College of Criminal Justice',
        HunterCollegeCUNY: 'Hunter College CUNY',
        CUNYYorkCollege: 'CUNY York College',
        PennsylvaniaStateUniversityHarrisburg: 'Pennsylvania State University - Harrisburg',
        AlcornStateUniversity: 'Alcorn State University',
        AllenUniversity: 'Allen University',
        AmericanBaptistCollege: 'American Baptist College',
        ArkansasatPineBluffUniversityof: 'Arkansas at Pine Bluff University of',
        ArkansasBaptistCollege: 'Arkansas Baptist College',
        BarberScotiaCollege: 'Barber-Scotia College',
        BenedictCollege: 'Benedict College',
        BennettCollege: 'Bennett College',
        BethuneCookmanUniversity: 'Bethune-Cookman University',
        BluefieldStateCollege: 'Bluefield State College',
        CarverCollege: 'Carver College',
        CentralStateUniversity: 'Central State University',
        ClaflinUniversity: 'Claflin University',
        ClintonCollege: 'Clinton College',
        EdwardWatersUniversity: 'Edward Waters University',
        FloridaMemorialUniversity: 'Florida Memorial University',
        FortValleyStateUniversity: 'Fort Valley State University',
        GasdenStateCommunityCollege: 'Gasden State Community College',
        GramblingStateUniversity: 'Grambling State University',
        HarrisStoweStateUniversity: 'Harris-Stowe State University',
        HoodTheological: 'Hood Theological',
        HustonTillotsonUniversity: 'Huston-Tillotson University',
        InterdenominationalTheologicalCenter: 'Interdenominational Theological Center',
        JarvisChristianCollege: 'Jarvis Christian College',
        JohnsonCSmithTheologicalSeminary: 'Johnson C Smith Theological Seminary *',
        KentuckyStateUniversity: 'Kentucky State University',
        KnoxvilleCollege: 'Knoxville College',
        LaneCollege: 'Lane College',
        LawsonStateCommunityCollege: 'Lawson State Community College',
        LeMoyneOwenCollege: 'LeMoyne-Owen College',
        LivingstoneCollege: 'Livingstone College',
        MeharryMedicalCollege: 'Meharry Medical College',
        MilesCollege: 'Miles College',
        MilesSchoolofLaw: 'Miles School of Law',
        MorehouseSchoolofMedicine: 'Morehouse School of Medicine',
        MorrisBrownCollege: 'Morris Brown College',
        MorrisCollege: 'Morris College',
        PaineCollege: 'Paine College',
        PaulQuinnCollege: 'Paul Quinn College',
        PhilanderSmithCollege: 'Philander Smith College',
        RustCollege: 'Rust College',
        SavannahStateUniversity: 'Savannah State University',
        SelmaUniversity: 'Selma University',
        ShawUniversity: 'Shaw University',
        SimmonsCollegeofKentucky: 'Simmons College of Kentucky',
        SouthCarolinaStateUniversity: 'South Carolina State University',
        SouthernUniversityatNewOrleans: 'Southern University at New Orleans',
        SouthwesternChristianCollege: 'Southwestern Christian College',
        StAugustinesUniversity: "St. Augustine's University",
        StillmanCollege: 'Stillman College',
        TalladegaCollege: 'Talladega College',
        TexasCollege: 'Texas College',
        TougalooCollege: 'Tougaloo College',
        VirginiaUnionUniversity: 'Virginia Union University',
        VirginiaUniversityofLynchburg: 'Virginia University of Lynchburg',
        VoorheesUniversity: 'Voorhees University',
        WestVirginiaStateUniversity: 'West Virginia State University',
        WileyCollege: 'Wiley College',
        WinstonSalemStateUniversity: 'Winston-Salem State University',
        UnityEnvironmentalCollege: 'Unity Environmental College',
        CatawbaCollege: 'Catawba College',
        TexasAMUniversityTexarkana: 'Texas A&M University-Texarkana',
        BelhavenUniversity: 'Belhaven University',
        BerryCollege: 'Berry College',
        BloomfieldCollege: 'Bloomfield College',
        BridgewaterStateUniversity: 'Bridgewater State University',
        CabriniUniversity: 'Cabrini University',
        CaldwellUniversity: 'Caldwell University',
        CornellCollege: 'Cornell College',
        CovenantCollege: 'Covenant College',
        ChicagoStateUniversity: 'Chicago State University',
        CaliforniaStateUniversity: 'California State University',
        ColoradoStateUniversityPueblo: 'Colorado State University-Pueblo',
        BayAtlanticUniversity: 'Bay Atlantic University',
        SienaCollege: 'Siena College',
        TexasWesleyanUniversity: 'Texas Wesleyan University',
        IslandDraftingTechnicalInstitute: 'Island Drafting & Technical Institute',
        BayPathUniversity: 'Bay Path University',
        SnowCollege: 'Snow College',
        StateCenterCommunityCollegeDistrict: 'State Center Community College District',
        DavidsonDavieCommunityCollege: 'Davidson-Davie Community College',
        BerkeleyCollege: 'Berkeley College',
        SchillerInternationalUniversity: 'Schiller International University',
        GreensboroCollege: 'Greensboro College',
        VerdeValleySchool: 'Verde Valley School',
        OglethorpeUniversity: 'Oglethorpe University',
        DetroitSchoolofTechnology: 'Detroit School of Technology',
        NorthCentralCollege: 'North Central College',
        TheUniversityofMontanaWestern: 'The University of Montana Western',
        QueensUniversityofCharlotte: 'Queens University of Charlotte',
        LincolnMemorialUniversity: 'Lincoln Memorial University',
        GreenvilleUniversity: 'Greenville University',
        StateUniversityofNewYorkatOneonta: 'State University of New York at Oneonta',
        NorthwesternMichiganCollege: 'Northwestern Michigan College',
        AdiranCollge: 'Adiran Collge',
        ConcordiaUniversityWisconsin: 'Concordia University Wisconsin',
        DelawareValleyUniversity: 'Delaware Valley University',
        DavisElkinsCollege: 'Davis & Elkins College',
        EckerdCollege: 'Eckerd College',
        UniversityofSouthCarolinaUpstate: 'University of South Carolina Upstate',
        FairfieldUniversity: 'Fairfield University',
        FoothillDeAnzaCommunityCollegeDistrict: 'Foothill-De Anza Community College District',
        FrancisMarionUniversity: 'Francis Marion University',
        GeorgeFoxUniversity: 'George Fox University',
        GrandViewUniversity: 'Grand View University',
        AbrahamBaldwinAgriculturalCollege: 'Abraham Baldwin Agricultural College',
        CapitalUniversity: 'Capital University',
        StateUniversityofNewYorkCollegeatOneonta: 'State University of New York College at Oneonta',
        UniversityofMountOlive: 'University of Mount Olive',
        TheUniversityofOlivet: 'The University of Olivet',
        WestminsterUniversity: 'Westminster University',
        DominicanUniversity: 'Dominican University',
        ClaremontColleges: 'Claremont Colleges',
        RowanCollegeatBurlingtonCounty: 'Rowan College at Burlington County',
        ThomasJeffersonUniversity: 'Thomas Jefferson University',
        AspenUniversity: 'Aspen University',
        CaliforniaUniversityofScienceandMedicine: 'California University of Science and Medicine',
        CUNYSchoolofLaw: 'CUNY School of Law',
        DoaneUniversity: 'Doane University',
        EasternVirginiaMedicalSchool: 'Eastern Virginia Medical School',
        ElmsCollege: 'Elms College',
        EstrellaMountainCommunityCollege: 'Estrella Mountain Community College',
        FloridaSouthernCollege: 'Florida Southern College',
        FranklinPierceUniversity: 'Franklin Pierce University',
        KansasCityUniversity: 'Kansas City University',
        LoyolaUniversityNewOrleans: 'Loyola University New Orleans',
        MarylandInstituteCollegeofArts: 'Maryland Institute College of Arts',
        MerrimackCollege: 'Merrimack College',
        NebraskaWesleyanUniversity: 'Nebraska Wesleyan University',
        NewYorkMedicalCollege: 'New York Medical College',
        PacificNorthwestUniversityofHealthSciences: 'Pacific Northwest University of Health Sciences',
        PurdueGlobal: 'Purdue Global',
        RowanCollegeofSouthJersey: 'Rowan College of South Jersey',
        SofiaUniversity: 'Sofia University',
        SUNYDownstateHealthSciencesUniversity: 'SUNY Downstate Health Sciences University',
        UniversityofSouthCarolinaBeaufort: 'University of South Carolina Beaufort',
        VermontLawSchool: 'Vermont Law School',
        WesternNevadaCollege: 'Western Nevada College',
        WesternUniversityofHealthSciences: 'Western University of Health Sciences',
        StMarysUniversity: "St. Mary's University",
        SouthFloridaStateCollege: 'South Florida State College',
    },
    CONSTANTS: {
        TIME_OF_DAY: {
            BEFORE_NOON: '07:00 - 12:00',
            NOON_TO_FIVE: '12:00 - 17:00',
            AFTER_FIVE: '17:00 - 22:00',
        },
        MONTHS_SHORT: {
            JAN: 'SIJ',
            FEB: 'VELJ',
            MAR: 'OŽU',
            APR: 'TRA',
            MAY: 'SVI',
            JUN: 'LIP',
            JUL: 'SRP',
            AUG: 'KOL',
            SEP: 'RUJ',
            OCT: 'LIS',
            NOV: 'STU',
            DEC: 'PRO',
        },
        MONTHS_LONG: {
            JAN: 'Siječanj',
            FEB: 'Veljača',
            MAR: 'Ožujak',
            APR: 'Travanj',
            MAY: 'Svibanj',
            JUN: 'Lipanj',
            JUL: 'Srpanj',
            AUG: 'Kolovoz',
            SEP: 'Rujan',
            OCT: 'Listopad',
            NOV: 'Studeni',
            DEC: 'Prosinac',
        },
        DAYS_SHORT: {
            MON: 'Pon',
            TUE: 'Uto',
            WED: 'Sri',
            THU: 'Čet',
            FRI: 'Pet',
            SAT: 'Sub',
            SUN: 'Ned',
        },
        DAYS_LONG: {
            MON: 'Ponedjeljak',
            TUE: 'Utorak',
            WED: 'Srijeda',
            THU: 'Četvrtak',
            FRI: 'Petak',
            SAT: 'Subota',
            SUN: 'Nedjelja',
            '1': 'Ponedjeljak',
            '2': 'Utorak',
            '3': 'Srijeda',
            '4': 'Četvrtak',
            '5': 'Petak',
            '6': 'Subota',
            '7': 'Nedjelja',
        },
    },
    PATHS: {
        ADD_CHILD: '/hr/dodaj-dijete',
        FORGOT_PASSWORD: '/hr/zaboravljena-lozinka',
        RESET_PASSWORD: '/hr/reset-password',
        MY_BOOKINGS: '/hr/kalendar',
        SEARCH_TUTORS: '/hr/pretraga-instruktora',
        SEARCH_TUTORS_TUTOR_PROFILE: '/hr/profil/:tutorSlug',
        STUDENT_PROFILE: '/hr/student-profil/:userId',
        PROFILE: '/hr/pretraga-instruktora/profil/:tutorSlug',
        SEARCH_TUTORS_TUTOR_BOOKINGS: '/hr/pretraga-instruktora/kalendar/:tutorSlug',
        MY_REVIEWS: '/hr/moje-recenzije',
        COMPLETED_LESSONS: '/hr/zavrsene-lekcije',
        CHAT: '/hr/razgovor',
        DASHBOARD: '/hr/dashboard',
        NOTIFICATIONS: '/hr/glavna-ploca/obavijesti',
        EARNINGS: '/hr/prihod',
        TERMS: '/hr/uvjeti-koristenja',
        PRIVACY: '/hr/politika-privatnosti',
        TUTOR_MANAGMENT: '/hr/upravljanje-instruktorima',
        STUDENT_MANAGEMENT: '/hr/upravljanje-studentima',
        BOOKING_MANAGEMENT: '/hr/sve-rezervacije',
        TUTOR_MANAGMENT_TUTOR_PROFILE: '/hr/upravljanje-instruktorima/profil/:tutorSlug',
        ONBOARDING_PATHS: {
            ONBOARDING: '/hr/onboarding',
            TUTOR_ONBOARDING: '/hr/onboarding/tutor',
            STUDENT_ONBOARDING: '/hr/onboarding/student',
            PARENT_ONBOARDING: '/hr/onboarding/parent',
        },
        LANDING_PATHS: {
            HOW_IT_WORKS: '/hr/online-instrukcije',
            BECOME_TUTOR: '/hr/postani-instruktor',
            PRICING: '/hr/cijene',
            TERMS: '/hr/uvjeti-koristenja',
            PRIVACY: '/hr/politika-privatnosti',
        },
        PROFILE_PATHS: {
            MY_PROFILE: '/hr/moj-profil',
            MY_PROFILE_INFO: '/hr/moj-profil/informacije',
            MY_PROFILE_INFO_PERSONAL: '/hr/moj-profil/informacije/postavke-profila',
            MY_PROFILE_INFO_AVAILABILITY: '/hr/moj-profil/informacije/dostupnost',
            MY_PROFILE_INFO_TEACHINGS: '/hr/moj-profil/informacije/predmeti',
            MY_PROFILE_INFO_ADDITIONAL: '/hr/moj-profil/informacije/o-meni',
            MY_PROFILE_ACCOUNT: '/hr/moj-profil/korisnicki-racun',
            MY_PROFILE_CHILD_INFO: '/hr/moj-profil/djeca',
        },
        TOKEN_NOT_VALID: '/hr/invalid-token',
        STRIPE_CONNECTED: '/hr/racun-povezan',
        STRIPE_FAIL: '/hr/racun-pogreska',
        RESEND_ACTIVATION_TOKEN: '/hr/ponovna-aktivacija',
        TUTOR_VIDEOS: '/hr/tutor-video',
    },
    SEO: {
        FALLBACK: {
            TITLE: 'Teorem',
            META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
        HOW_IT_WORKS: {
            TITLE: 'Najbolje 1-na-1 online instrukcije i pomoć s učenjem | Teorem',
            META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
        PRICING: {
            TITLE: 'Cijene visokokvalitetnih online instrukcija | Teorem',
            META: 'Cijene za 1-na-1 50-minutne instrukcije počinju od 10€, s prosječnom cijenom od 12€, ovisno o instruktoru i planu učenja. Saznajte više o cijenama tako da kontaktirate naše instruktore. ',
        },
        BECOME_TUTOR: {
            TITLE: 'Postani online instruktor | Teorem',
            META: 'Kako postati online instruktor na Teoremu - 1. Registriraj se da postaneš instruktor - 2. Završi kviz o svom predmetu - 3. Dokaži svoj identitet - 4. Započni davat instrukcije',
        },
        TERMS: {
            TITLE: 'Uvjeti korištenja | Teorem',
            META: 'Dobrodošli na Teorem! Ovi uvjeti korištenja definiraju pravila korištenja Teoremove webstranice.',
        },
        PRIVACY: {
            TITLE: 'Politika privatnosti | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
    },
    LANGUAGE_MODAL: {
        WELCOME: 'Dobrodošli na Teorem!',
        ABOUT: 'Molimo odaberite Vašu lokaciju.',
    },
    TOKEN_EXPIRED: {
        RESEND_ACTIVATION_MESSAGE: 'RESEND_ACTIVATION_MESSAGE',
        WELCOME: 'WELCOME',
        DESCRIPTION: 'DESCRIPTION',
    },
    BOOKING: {
        SUCCESS: 'Rezervacija uspješna!',
        FAILURE: 'Rezervacija neuspješna!',
        TOAST_CANT_BOOK: 'Ne možete rezervirati lekciju u odabrano vrijeme!',
        CANT_BOOK_MESSAGE: 'Molimo rezervirajte minimalno 3 sata unaprijed',
    },
    TUTOR_INTRO: {
        MODAL: {
            TITLE: 'Želite li proći tutorijal?',
            BODY: 'Tutorijal će vas ukratko upoznati s osnovnim funkcionalnostima Teorema',
            BUTTON_SKIP: 'Preskoči',
            BUTTON_START: 'Pokreni',
        },
        DASHBOARD: {
            STEP1: {
                TITLE: 'Zahtjevi za predavanja',
                BODY: 'Ovdje možete vidjeti i upravljati svojim zahtjevima za instrukcije.',
            },
            STEP2: {
                TITLE: 'Pridruživanje instrukcijama',
                BODY: 'Instrukcijama se možete pridružiti izravno iz kartice "Današnji raspored" (također se možete pridružiti putem svog kalendara). Nakon što kliknete \'Završi\', automatski će se pokrenuti testni sastanak gdje možete isprobati ploču!',
            },
        },
        BUTTON_NEXT: 'Sljedeći',
        BUTTON_PREVIOUS: 'Prethodni',
        BUTTON_FINISH: 'Završi',
        BUTTON_RESTART: 'Pokreni tutorijal',
    },
    STUDENT_INTRO: {
        DASHBOARD: {
            STEP1: {
                TITLE: 'Današnji raspored',
                BODY: 'Ovdje ćete vidjeti predavanja koja imate danas i moći ćete se spojiti na predavanja. (Predavanja također možete vidjeti na kalendaru)',
            },
            STEP2: {
                TITLE: 'Nepročitane poruke',
                BODY: "Ovdje ćete vidjeti sve poruke koje vam istruktor šalje. (Isto to možete vidjeti na kartici 'Razgovori')",
            },
            STEP3: {
                TITLE: 'Preporuke instruktora',
                BODY: 'Ovdje se nalaze tri instruktora koji najviše odgovaraju vašim potrebama (ovisno o onome što ste odabrali pri registraciji). Koristecći filtere možete odabrati predmet, razinu ili vrijeme kada je instruktor dostupan.',
            },
        },
        BUTTON_NEXT: 'Next',
        BUTTON_PREVIOUS: 'Previous',
        BUTTON_FINISH: 'Finish',
        BUTTON_RESTART: 'Restart tutorial',
    },
    BOOKING_POPUP: {
        TITLE: 'Plaćanje uspješno',
        DESCRIPTION:
            'Vaš instruktor sada ima 24 sata da prihvati Vašu rezervaciju. Za najbolje iskustvo učenja, molimo podijelite uvide u gradivo na koje se želite usredotočiti.',
        CURRICULUM_PLACEHOLDER: 'S kojim školskim gradivom trebate pomoć?',
        TEXTBOOK_PLACEHOLDER: 'Koji se udžbenik koristi?',
        GRADE_PLACEHOLDER: 'Trenutna ocjena iz predmeta',
        NOTES_PLACEHOLDER: 'Napomene ili posebni zahtjevi',
        BUTTON: {
            COMPLETE: 'Završi',
            SKIP: 'Preskoči za sad',
        },
    },
    CREDITS: {
        TITLE: 'Krediti',
    },
    RESCHEDULE: {
        ACCEPT: 'Izmjena prihvaćena',
        DENIED: 'Izmjena odbijena',
    },
    CHECKOUT: {
        PRICE: 'Cijena:',
        DISCOUNT: 'Popust:',
        TOTAL: 'Ukupno za platiti:',
        AVAILABLE_CREDITS: 'Dostupni krediti:',
        NEW_CREDITS_BALANCE: 'Novo stanje kredita:',
    },

    ID_VERIFICATION: {
        ID_SECTION: 'Dokaz o identitetu',
        ID_SECTION_DESCRIPTION:
            'U svrhu sprječavanja pranja novca i financiranja terorizma, molimo potvrdite Vaš identitet.',
        BANNER: 'OBAVIJEST: U svrhu sprječavanja pranja novca i financiranja terorizma, molimo potvrdite Vaš identitet kako bi nastavili zaprimati isplate.',
        ACCOUNT_VERIFIED: 'Hvala! Vaš račun je verificiran.',
        DOCUMENTS_PROVIDED: 'Hvala! Predali ste dokumente, čeka se verifikacija.',
        DISABLED_PAYOUTS: {
            PART_1: 'Vaše isplate su obustavljene. Molimo potvrdite Vaš identitet kako biste nastavili zaprimati isplate.',
            SOON: 'Rok predaje: uskoro',
        },
        DAYS_REMAINING: {
            P_1: 'Imate još ',
            P_2: ' dan/a prije nego što Vaše isplate budu obustavljene. Molimo potvrdite Vaš identitet kako biste izbjegli obustavu isplata.',
        },
        FORM: {
            FRONT: 'Prednja strana osobne iskaznice',
            BACK: 'Stražnja strana osobne iskaznice',
            TIP_1: 'Slika mora biti u boji',
            TIP_2: 'Na dokumentu mora pisati ime koje ste koristili pri registraciji',
            SUBMIT: 'Pošalji',
        },
    },

    VIDEO_PREVIEW: {
        TITLE: 'Video pregled',
        NOT_APPROVED: 'Administrator još nije odobrio vaš video (neće se prikazivati na vašem profilu)',
        TRANSCODING: 'Vaš video se trenutno obrađuje. Obično traje nekoliko minuta...',
        RECORD_VIDEO: 'Snimi video',
        OR: 'ILI',
        UPLOAD_VIDEO: 'Učitaj video',
        START: 'Započni',
        DELETE: 'Obriši',
        ADD_VIDEO: 'Dodaj video',
        BROWSE: 'Pretraži',
        RECORD: 'Snimi',
        SUCCESS_TITLE: 'Uspjeh! Vaš video je učitan!',
        SUCCESS_DESCRIPTION: 'Dodajemo neke završne detalje. U međuvremenu možete nastaviti izradu profila!',
        RECORD_MODAL: {
            TITLE: 'Snimite video',
            SETTINGS: 'Audio i video postavke',
            CAMERA: 'Kamera',
            MICROPHONE: 'Mikrofon',
            START: 'Pokreni snimanje',
            STOP: 'Zaustavi snimanje',
            RESTART: 'Ponovno snimi',
            UPLOAD: 'Učitaj',
            REMAINING_TIME: {
                PART_1: 'Snimka će se automatski zaustaviti za ',
                PART_2: ' s',
            },
            RECORDING_TIME: 'Vrijeme snimanja:',
            PERMISSIONS_ERROR_MESSAGE:
                'Niste dozvolili korištenje kamere i mikrofona. Morat ćete dozvoliti kako bi mogli snimiti video, a i održavati instukcije',
        },
        LOADING: {
            PREPARING: 'Priprema prijenosa...',
            UPLOADING: 'Prijenos',
            SUCCESS: 'Uspješno ste učitali video',
        },
        CONFIRMATION: {
            TITLE: 'Jeste li sigurni da želite obrisati video?',
            DESCRIPTION: 'Ovime će vaš video biti obrisan i morat ćete snimiti novi!',
            CONFIRM: 'Obriši',
            CANCEL: 'Odustani',
        },
        FILE_UPLOAD: {
            FORMAT: 'MP4 format',
            SIZE_MESSAGE: 'Maksimalna veličina datoteke je 10MB!',
            DURATION_MESSAGE: 'Maksimalna duljina videa je 2 minute!',
        },
        TIPS: {
            DO: {
                TITLE: 'Što učiniti:',
                LIST: {
                    TIP_1: 'Između 30 sekundi i 2 minute',
                    TIP_2: 'Snimajte horizontalno i u razini očiju',
                    TIP_3: 'Koristite dobro osvjetljenje i neutralnu pozadinu',
                    TIP_4: 'Koristite stabilnu podlogu, kako video ne bi bio mutan',
                    TIP_5: 'Pobrinite se da se vidi cijelo lice i oči',
                    TIP_6: 'Istaknite svoje iskustvo u podučavanju',
                    TIP_7: 'Srdačno pozdravite svoje učenike i pozovite ih da rezerviraju sat',
                },
            },
            DONT: {
                TITLE: 'Nemojte:',
                LIST: {
                    TIP_1: 'Navoditi svoje prezime ili bilo kakve kontakt podatke',
                    TIP_2: 'Uključivati logo ili linkove',
                    TIP_3: 'Koristiti dijaprojekcije ili prezentacije',
                    TIP_4: 'Imati druge osobe vidljive u vašem videu',
                },
            },
        },
    },
    TUTOR_CARD: {
        LESSON: 'po lekciji',
    },
    COUNTRY: {
        PLACEHOLDER: 'Država',
        HR: 'Hrvatska',
        US: 'SAD',
    },
    CURRENCY: {
        EUR: '€',
        USD: '$',
    },
    BANNER: {
        REVIEW: {
            PART_1: 'Nedavno ste imali lekciju iz predmeta ',
            PART_2: 's instruktorom ',
            PART_3: '. Odvojite trenutak da podijelite svoje iskustvo ostavljajući recenziju.',
            TOOLTIP: 'Ostavljanje recenzije pomaže ostalima. Stvara učinkovito i transparentno okruženje.',
        },
        ADD_CHILD: {
            BUTTON: 'Dodaj dijete',
        },
        VIDEO_PREVIEW: {
            TEXT: 'Dodajte video na svoj profil kako biste povećali svoju vidljivost roditeljima. Od 30. lipnja 2024. videozapisi postat će obavezni kako biste zadržali svoj profil instruktora.',
            BUTTON: 'Dodaj video',
        },
    },
};
