export const EN_US = {
    MAIN_TITLE: 'Teorem',
    WATERMARK: '© Teorem',
    BIRTH_DATE_FORMAT: 'MM/DD/YYYY',
    DATE_FORMAT: 'Do MMM YYYY',
    ERROR_HANDLING: {
        UNHANDLED_ERROR: 'Unhandled error occured!',
        SUPPORT: 'Something gone wrong, please contact the support',
        CREDIT_CARD_MISSING: 'You are missing a credit card. Please add payment method before creating a booking.',
        DEFAULT_CARD_MISSING: 'You don`t have default payment method. Please select one of your credit cards.',
        NO_CHILD_USERNAME: 'There is no child with that username',
        REGISTERED_SUCCESSFULLY: 'You are registered successfully.',
    },
    TRIAL_POPUP: {
        WELCOME: 'Welcome to Teorem',
        DESCRIPTION:
            'Your profile is now ready to use. Fill out the agenda, sit back and relax, wait for students to reach out to you!',
        EXPIRATION: 'Your free trial will expire in 30 days!',
        REMIND: 'Don’t worry, we’ll remind you on time.',
        CONFIRM_BUTTON: 'Got it',
    },
    EMAIL_CONFIRMATION_POPUP: {
        WELCOME: 'Welcome to Teorem',
        DESCRIPTION: 'Please check your email and confirm your e-mail address by clicking the link we sent you.',
        EXPIRATION: 'Resend confirmation e-mail.',
        RESEND_TIMEOUT: 'Try again in',
        CONFIRM_BUTTON: 'Got it',
    },
    ONBOARDING: {
        BACK: 'Back',
        QUESTIONS: 'Questions?',
        NEXT: 'Next',
        GET_STARTED: 'Get started',
        PUBLISH: 'Publish',
        GET_QUICK_TIPS: 'Get quick tips',
        TUTOR: {
            START: {
                TITLE: "It's easy to get started on Teorem",
                POINT_1: {
                    TITLE: 'Tell us about your lessons',
                    DESCRIPTION: 'Set your availability, and select the subjects and levels you teach.',
                },
                POINT_2: {
                    TITLE: 'Make your profile stand out',
                    DESCRIPTION: 'Add a video, a photo, and some info, like your interests and experience.',
                },
                POINT_3: {
                    TITLE: 'Finish up and publish',
                    DESCRIPTION: "Set a price and publish your listing when you're ready.",
                },
            },
            LESSONS: {
                STEP_LABEL: 'Step 1',
                TITLE: 'Tell us about your lessons',
                DESCRIPTION:
                    "In this step, we'll ask you which subjects you tutor and what levels you are comfortable with. Then let us know when you are available to teach your lessons.",
            },
            SUBJECTS: {
                TITLE: 'Which subjects do you tutor?',
                SUBTITLE: 'You can add or remove subjects through your profile settings later.',
                LEVEL_LABEL: 'Level',
                SUBJECT_LABEL: 'Subject',
                ADD_SUBJECT: 'Add another subject',
                REMOVE_SUBJECT: 'Remove this level & subject',
            },
            AVAILABILITY: {
                TITLE: 'When are you available?',
                SUBTITLE: "Students can book lessons at these times. You'll have 24 hours to accept the bookings.",
                TIMEZONE_LABEL: 'Select your timezone',
            },
            PROFILE: {
                STEP_LABEL: 'Step 2',
                TITLE: 'Make your profile stand out',
                DESCRIPTION:
                    "In this step, you'll add your profile photo and some basic info about your interests and experience. Then you'll record or upload a brief video so that potential students can get to know you.",
            },
            PHOTO: {
                TITLE: 'Add your profile photo',
                SUBTITLE: 'Use a high quality portrait photo with your whole face visible.',
                DRAG_TITLE: 'Drag and drop',
                DRAG_DESCRIPTION: 'or browse for a photo',
                BROWSE: 'Browse',
                POINT_SMILE: 'Smile and look at the camera',
                POINT_FRAME: 'Frame your head and shoulders',
                POINT_FACE: 'Your face and eyes are fully visible',
                POINT_PERSON: 'You are the only person in the photo',
                POINT_LOGO: "Don't include logos or contact information",
            },
            EDUCATION: {
                TITLE: 'Tell us more about your education',
                SUBTITLE:
                    'Parents often search for tutors that studied at specific universities. Share more about your studies to increase your profile visibility.',
                CHECKBOX_NO_DEGREE: "I don't have a higher education degree",
                UNI_LABEL: 'Which university did you attend?',
                DEGREE_LABEL: 'Select your degree type',
                MAJOR_LABEL: 'Enter the name of your course or major',
                STARTED_LABEL: 'Started',
                FINISHED_LABEL: 'Finished',
                PRESENT: 'Present',
                ADD_DEGREE: 'Add another degree',
            },
            TITLE: {
                TITLE: 'Set your profile title',
                SUBTITLE: 'Keep it short. You can always change it later.',
            },
            DESCRIPTION: {
                TITLE: 'Create your description',
                SUBTITLE: 'Talk about your interests and teaching methods.',
            },
            VIDEO: {
                TITLE: "Now, let's add a video to your profile",
                SUBTITLE: 'Introduce yourself to students! You can watch and re-record before you submit.',
                ADD_VIDEO_LABEL: 'Add your video',
                RECORD_BUTTON: 'Record',
                OR: 'or',
                UPLOAD_BUTTON: 'Upload',
                POINT_TITLE: 'Please follow these guidelines to make sure your profile is approved',
                POINT_DEMO: 'Click here to watch an example video',
                POINT_LENGTH: 'Keep your video between 30 seconds and 2 minutes long',
                POINT_HORIZONTAL: 'Record in horizontal mode and at eye level',
                POINT_BACKGROUND: 'Use a neutral background and good lighting',
                POINT_SURFACE: 'Use a stable surface so that your video is not shaky',
                POINT_FACE: 'Fully visible face and eyes',
                POINT_EXPERIENCE: 'Highlight your teaching experience and knowledge',
                POINT_GREET: 'Greet your students warmly and invite them to book a lesson',
                POINT_SURNAME: "Don't include your surname or any contact details",
                POINT_LOGO: "Don't include logos or links",
                POINT_PEOPLE: "Don't have any other people visible in your video",
            },
            FINISHING: {
                STEP_LABEL: 'Step 3',
                TITLE: 'Finish up and publish',
                DESCRIPTION: "Finally, you'll set up pricing, payouts and publish your profile.",
            },
            NOTIFICATION: {
                TITLE: "Decide how you'll confirm bookings",
                SUBTITLE: 'You will be able to sync your Google calendar to avoid double bookings.',
                INSTANT_BOOK_TITLE: 'Use Instant Book',
                INSTANT_BOOK_SUBTITLE: 'Parents and students can book automatically.',
                MANUAL_APPROVE_TITLE: 'Approve or decline requests',
                MANUAL_APPROVE_SUBTITLE: "You'll have 24 hours to respond to a  booking request.",
            },
            PRICE: {
                CURRENCY: '€',
                TITLE: 'Now, set your price',
                SUBTITLE: 'You can change it anytime.',
                PRICE_BREAKDOWN_SHORT: 'You earn',
                PRICE_BREAKDOWN_BASE: 'Base price',
                PRICE_BREAKDOWN_FEE: 'Teorem fee',
                PRICE_BREAKDOWN_EARNED: 'You earn',
                PRICE_BREAKDOWN_PAID: 'Studens pay',
                SHOW_LESS: 'Show less',
                LEARN_MORE_LABEL: 'Learn more about pricing',
                LEARN_MORE: {
                    TITLE: 'More about pricing',
                    DESCRIPTION_1: 'You choose your price, and you can change it anytime. Bookings are not guaranteed.',
                    BULLET_1: 'Per lesson price',
                    DESCRIPTION_2:
                        'You are setting your price for 50-minute lessons. Please make sure to leave a few minutes between lessons to complete Lesson Reports.',
                    BULLET_2: 'Teorem fee',
                    DESCRIPTION_3:
                        "When you're setting a price and price breakdown is shown, The Teorem service fee will automatically be calculated as a 15% of your lesson price. Teorem reserves the right to make changes in commission rates.",
                },
                ERROR_LOW_TITLE: 'Oops, there is an issue with your price',
                ERROR_LOW_DESCRIPTION: 'Please enter a base price higher than',
            },
            ENTITY: {
                TITLE: 'How are you tutoring on Teorem?',
                PRIVATE_ENTITY_TITLE: "I'm tutoring as a private individual",
                PRIVATE_ENTITY_SUBTITLE: 'You want to receive payouts to your personal bank account.',
                COMPANY_ENTITY_TITLE: "I'm tutoring as a business",
                COMPANY_ENTITY_SUBTITLE: 'You have a registered company through which you want to receive payouts.',
                CHECKBOX_AGREE: "I agree to Stripe's",
                CHECKBOX_TERMS: 'Terms of Service',
            },
            LEGAL_INFO: {
                TITLE: 'Please provide the last 4 digits of your social security number',
                TITLE_HR: 'Please provide your OIB number',
                TITLE_COMPANY: "Please provide your company's information",
                SUBTITLE:
                    "We'll perform a know-your-customer check to make sure your name, SSN and other details match.",
                SUBTITLE_HR: 'We need your OIB number to process payouts. We will not share it publicly.',
                SUBTITLE_COMPANY:
                    "We'll perform a know-your-customer check to make sure your name and other details match.",
                SSN_LABEL: 'Last 4 SSN digits',
                OIB_LABEL: 'OIB number',
                OIB_LABEL_COMPANY: "Company's OIB number",
                NAME_LABEL_COMPANY: 'Company name',
            },
            PAYOUT_INFO: {
                TITLE: 'Where should we send your payouts?',
                SUBTITLE: 'Make sure to use a bank account registered in your name.',
                SUBTITLE_COMPANY: "Make sure to use a bank account registered in your company's name.",
                ROUTING_NUMBER_LABEL: 'Routing number',
                ACCOUNT_NUMBER_LABEL: 'Account number',
                IBAN_LABEL: 'IBAN',
            },
            ADDRESS: {
                TITLE: 'What is your address?',
                SUBTITLE: 'We need your address to process payouts. We will not share it publicly.',
                COUNTRY_LABEL: 'Country',
                STREET_LABEL: 'Street address',
                APARTMENT_LABEL: 'Apt, suite, unit (if applicable)',
                POSTAL_CODE_LABEL: 'Postal code',
                STATE_LABEL: 'State / province',
                CITY_LABEL: 'City / municipality',
            },
            PHONE: {
                TITLE: 'Before we wrap up, is this your phone number?',
                SUBTITLE: "We'll use this for secuirty purposes and payout verification.",
                PHONE_LABEL: 'Phone number',
            },
            PUBLISH: {
                TITLE: "Yay! It's time to publish.",
                SUBTITLE:
                    "Here's what we'll show to parents and students. Before you publish, make sure to review the details.",
                SHOW_PREVIEW_BUTTON: 'Show preview',
                STEPS_TITLE: "What's next?",
                STEP_1_TITLE: 'Sync your calendar',
                STEP_1_DESCRIPTION:
                    'Sync your Google (or other) calendar. This will make sure your availability is always up to date.',
                STEP_2_TITLE: 'Prepare for your first lesson',
                STEP_2_DESCRIPTION:
                    'Go through our interactive whiteboard tutorial and set up your notification preferences.',
                STEP_3_TITLE: 'Share your profile',
                STEP_3_DESCRIPTION:
                    'Share your profile  link on your social media channels to get your first booking faster.',
            },
        },
    },
    TUTOR_ONBOARDING: {
        TITLE: "Thank you for verifying your email address! Let's complete your tutor profile!",
        SUBTITLE: 'This usually takes 8 minutes.',
        IMAGE_NOTE: 'You can skip for now, but you will need to add profile image before you can start teaching.',
        ABOUT_ME_TIPS: {
            TIP_1: 'Describe in detail your way of teaching and yourself.',
            TIP_2: 'The most effective profiles contain 100+ words in the rubrics <u><i>more about me</i></u> and <u><i>more about my lectures</i></u>.',
        },
        TOOLTIPS: {
            AVAILABILITY_1: "Select when you're available for tutoring, based on times of day in the week.",
            AVAILABILITY_2: 'You can change your availability later at any time.',
            SUBJECTS: 'You can later add or delete subjects in Your profile settings.',
            SUBJECTS_TIP_1: 'Add the subjects you want to tutor in and define your price.',
            SUBJECTS_TIP_2: 'Keep in mind that Teorem charges a 15% commission.',
        },
        IMAGE_TIPS: {
            TITLE: 'Tips for image:',
            TIP_1: 'portrait form',
            TIP_2: 'serious attitude with a smile',
            TIP_3: 'high quality (min 500x500 pixels)',
        },
        PAYOUTS_BUTTON: {
            PRIVATE: 'Private',
            COMPANY: 'Company',
        },
    },
    FORM_VALIDATION: {
        INVALID_EMAIL: 'Invalid email',
        INVALID_IMAGE: 'Image has to be either jpg,png,jpeg or svg and less than 2MB in size.',
        IMAGE_TYPE: 'Image has to be either jpg,png,jpeg or svg',
        IMAGE_SIZE: 'Image has to be less than 5MB in size.',
        TOO_SHORT: 'Too Short!',
        TOO_LONG: 'Too Long!',
        EMAIL_EXISTS: 'This email already exists. Please log in.',
        MAX_LIMIT: 'Max number of characters is',
        MIN_LIMIT: 'Min number of words is',
        TOO_BIG: 'Number is too big',
        REQUIRED: 'Required',
        LOWERCASE: 'Include at least one lowercase letter (a-z)',
        UPPERCASE: 'Include at least one uppercase letter (A-Z)',
        NUMBER: 'Include at least one number (0-9)',
        MIN_CHARACTERS: 'Be a minimum of 8 characters',
        SPECIAL_CHAR: 'Include at least one special character (@, $, !, %, *, ?, &...)',
        PASSWORD_MUST: 'Password must',
        PASSWORD_MATCH: 'Passwords must match',
        PASSWORD_STRENGTH: 'Password must meet the conditions',
        PRICE: 'Minimum ',
        WHOLE_NUMBER: 'Not whole',
        FUTURE_DATE: 'Date can`t be in the future',
        VALID_DATE: 'Date is invalid',
        PHONE_NUMBER: 'Invalid phone number',
        MINIMUM_AGE: 'You must be more than 18 years old',
        MAXIMUM_AGE: 'You must be younger than 100 years old',
        TUTOR_AGE: 'Tutor must be more than 18 years old',
        WRONG_REQUIREMENTS: 'You didn`t fulfill all field requirements',
        NEGATIVE: 'Can`t be a negative number',
        USERNAME_EXIST: 'Username already exists',
        TOAST: {
            UPDATE: 'You successfully updated the child information',
            CREATE: 'You successfully created a child',
            DELETE: 'You successfully deleted a child',
        },
        LEVEL_REQUIRED: 'Level is required',
        SUBJECT_REQUIRED: 'Subject is required',
        CHILD_REQUIRED: 'Child is required',
        AGREE_TERMS_REQUIRED: 'You must agree to terms and conditions',
        CHILDLESS_CALENDAR_1: 'Please add a child in',
        CHILDLESS_CALENDAR_2: 'profile settings',
        INVALID_IBAN: 'Invalid IBAN',
        IBAN_WHITESPACES: 'IBAN must not contain whitespaces',
        IBAN_MATCH: 'IBANs must match',
        MIN_50_WORDS: 'Use at least 50 words',
        MAX_2500_CHARS: 'Maximum is 2500 characters',
        MAX_75_CHARS: 'Maximum is 75 characters',
        MAX_100_CHARS: 'Maximum is 100 characters',
        MIN_10_CHARS: 'Minimum is 10 characters',
        NAME_EMAIL_NOT_IN_PASSWORD: "Can't contain your name or email address",
        UNIQUE_SUBJECT: "You can't add the same subject and level combination twice",
        NO_EMPTY_SUBJECT: 'You must select a level and a subject',
        MIN_ONE: 'You must select at least one',
        AVAILABILITY: 'You must select at least one day and time of day',
        AVAILABILITY_TIME: 'You must select at least one period in a day',
        START_YEAR: 'Start year must be between 1950 and current year',
        END_YEAR: 'End year must be greater than start year',
        MIN_PRICE: "Price can't be lower than ",
        PHONE_SHORT: "Phone number can't be shorter than 7 digits",
    },
    NAVIGATION: {
        MY_BOOKINGS: 'Calendar',
        MY_REVIEWS: 'My reviews',
        SEARCH_TUTORS: 'Search tutors',
        TUTOR_MANAGMENT: 'Tutor managment',
        STUDENT_MANAGEMENT: 'Student managment',
        BOOKING_MANAGEMENT: 'Booking management',
        TUTOR_VIDEOS: 'Video previews',
        MY_PROFILE: 'My Profile',
        COMPLETED_LESSONS: 'Completed lessons',
        CHAT: 'Chat',
        DASHBOARD: 'Dashboard',
        EARNINGS: 'Earnings',
        SHARE_PROFILE: 'Share profile',
        TEXT_COPIED: 'Profile copied to clipboard!',
        LOGIN: 'Log in',
        REGISTER: 'Register',
    },
    SEARCH_TUTORS: {
        AVAILABILITY: {
            TIME_OF_DAY: {
                LABEL: 'Time of day',
                BEFORE_NOON: 'Pre 12 pm',
                NOON_TO_FIVE: '12 - 5 pm',
                AFTER_FIVE: 'After 5 pm',
            },
            DAY_OF_WEEK: {
                LABEL: 'Day of week',
                MON: 'mon',
                TUE: 'tue',
                WED: 'wed',
                THU: 'thu',
                FRI: 'fri',
                SAT: 'sat',
                SUN: 'sun',
            },
        },
        PLACEHOLDER: {
            LEVEL: 'Level',
            SUBJECT: 'Subject',
            AVAILABILITY: 'All availability',
        },
        NO_OPTIONS_MESSAGE: 'Please select level first',
        TITLE: 'Search tutors',
        VIEW_PROFILE: 'View profile',
        BOOK_LESSON: 'Book a lesson',
        RESET_FILTER: 'Reset search',
        TUTOR_AVAILABLE: 'Tutors Available',
        COMPLETED_LESSONS: 'completed lessons',
        SUBJECT_LIST: {
            MORE: 'more',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: ' Hmm, looks like there are no tutors for this specific search.',
        },
        NOT_FILLED: 'This information is not filled out yet',
        YEARS_OF_EXPERIENCE: 'Years of experience: ',
        NUMBER_OF_REVIEWS: 'reviews',
        NO_REVIEWS: 'No reviews yet',
        NO_COMPLETED_LESSONS: 'No completed lessons yet',
        NO_VIDEO: 'Tutor did not upload a video yet',
        TUTOR_PROFILE: {
            ABOUT_ME: 'About me',
            EMPTY_STATE_ABOUT: 'This tutor has not filled out about me section',
            EMPTY_STATE_LESSON: 'This tutor has not filled out his profile about subjects',
            ADDITIONAL_INFORMATION_TITLE: 'About me',
            ADDITIONAL_INFORMATION_DESC: 'Edit and update your about me section of the profile.',
            UPDATE_ADDITIONAL_INFO_SUCCESS: 'Additional information updated!',
            UPDATE_TEACHINGS_SUCCESS: 'Subjects updated!',
            LESSON_LENGTH: '50 min lesson',
            FORM: {
                ABOUT_TUTOR_LABEL: 'Tell us more about yourself*',
                ABOUT_TUTOR_PLACEHOLDER: 'What describes you best, what are your hobbies, approach...',
                ABOUT_TUTOR_TOOLTIP: 'In detail describe yourself, your interests, experience and hobbies.',
                SUBMIT_BTN: 'Save',
            },
            NO_PRICE: 'There is no price',
            HOUR: 'hr',
        },
        PRICE_SORT: 'Sort by',
        SORT_LOW: 'Low price',
        SORT_HIGH: 'High price',
        SORT_NONE: 'None',
        NEW_TUTOR: {
            PART_1: 'New',
            PART_2: 'tutor',
        },
    },
    TUTOR_MANAGMENT: {
        TITLE: 'Tutor list',
        UNPROCESSED: 'UNPROCESSED',
        APPROVED: 'APPROVED',
        DENIED: 'DENIED',
        SUBJECT_LIST: {
            MORE: 'more',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: ' Hmm, looks like there are no tutors for this specific search.',
        },
        TABLE: {
            FIRST_NAME: 'FIRST NAME',
            LAST_NAME: 'LAST NAME',
            EMAIL: 'EMAIL',
            COUNTRY: 'COUNTRY',
            DATE_OF_BIRTH: 'DATE OF BIRTH',
            PREVIEW_PROFILE: 'Preview profile',
            PHONE_NUMBER: 'PHONE NUMBER',
            CREATED_AT: 'CREATED AT',
            ROLE: 'ROLE',
            CREDITS: 'CREDITS',
            NUMBER_OF_LESSONS: 'LESSONS',
            ID_VERIFIED: 'ID VERIFIED',
            DOCUMENTS_UPLOADED: 'DOCUMENTS UPLOADED',
        },
        ACTIONS: {
            APPROVE: 'Approve',
            DECLINE: 'Decline',
            DELETE: 'Delete',
            APPROVE_TUTOR: 'Approve Tutor',
            DECLINE_TUTOR: 'Decline Tutor',
            DELETE_TUTOR: 'Delete Tutor',
            EDIT_NOTE: 'Edit note',
            CANCEL: 'Cancel',
            PLAY_VIDEO: 'Play video',
        },
        NOTE: 'Note',
        NOTE_PLACEHOLDER: 'Enter a note here...',
        DETAILS: ' DETAILS',
        SEARCH_PLACEHOLDER: 'Search tutors',
        VIDEO_PREVIEW: {
            DECLINE_AND_SEND_BUTTON: 'Decline and send',
            MESSAGE_TITLE: 'Reason for video decline',
        },
    },
    STUDENT_MANAGEMENT: {
        TITLE: 'Student/parent list',
        NO_RESULT: {
            TITLE: 'No results',
            DESC: ' Hmm, looks like there are no students/parents for this specific search.',
        },
        SEARCH_PLACEHOLDER: 'Search students/parents',
        TABLE: {
            FIRST_NAME: 'FIRST NAME',
            LAST_NAME: 'LAST NAME',
            EMAIL: 'EMAIL',
            PHONE_NUMBER: 'PHONE NUMBER',
            ROLE: 'ROLE',
            NUMBER_OF_LESSONS: 'LESSONS',
            CREDITS: 'CREDITS',
            STUDENT: 'STUDENT',
            LEVEL: 'LEVEL',
            SUBJECT: 'SUBJECT',
            TUTOR: 'TUTOR',
            TUTOR_EMAIL: 'TUTOR EMAIL',
            TUTOR_PHONE: 'TUTOR PHONE',
            START_TIME: 'START TIME',
            CREATED_AT: 'CREATED AT',
            PRICE: 'PRICE',
            STATE: 'STATE',
            ACCEPTED: 'ACCEPTED',
            DELETED: 'DELETED',
            RESCHEDULE: 'RESCHEDULE',
        },
    },
    CHAT: {
        PLACEHOLDER: 'Send a message to start a conversation',
        SEARCH_PLACEHOLDER: 'Search chat',
        BOOK_SESSION: 'Book a session',
        FREE_CONSULTATION: 'Start a free call',
        ACCEPT_CONSULTATION: 'Accept',
        DENY_CONSULTATION: 'Deny',
        DENY_FREE_CONSULTATION: 'Cancel call',
        CHAT_REQUEST_TITLE: 'Incoming video call',
        CHAT_MISSED_CALL: '<span class="chat-missed-incoming-message">Missed call from userInsert={user}</span>',
    },
    ROLE_SELECTION: {
        TITLE: 'Register',
        ACTION: 'I want to register as a',
        ACCOUNT: 'Already have an account?',
        LOG_IN: 'Log in',
        STUDENT_TITLE: 'Student',
        STUDENT_DESCRIPTION: 'Here to expand my knowledge.',
        PARENT_TITLE: 'Parent / Guardian',
        PARENT_DESCRIPTION: 'Here to help my child learn.',
        TUTOR_TITLE: 'Tutor',
        TUTOR_DESCRIPTION: 'Here to teach students what I know.',
    },
    RESET_PASSWORD: {
        TITLE: 'Reset password',
        FORM: {
            EMAIL: 'Email',
            PASSWORD: 'Password',
            REPEAT_PASSWORD: 'Repeat password',
            SUBMIT_BTN: 'Reset Password',
        },
        SUCCESS: 'Reset email has been sent.',
        BACK_BTN: 'Back to Login',
    },
    LOGIN: {
        TITLE: 'Log in',
        FORM: {
            EMAIL: 'Email',
            PASSWORD: 'Enter your Password',
            SUBMIT_BTN: 'Log in',
        },
        FORGOT_PASSWORD: 'Forgot Password?',
        ACCOUNT: 'Dont have an account?',
        REGISTER: 'Register',
    },
    BACKEND_ERRORS: {
        BASE: {
            UNAUTHORIZED: 'You are not authorized.',
            FORBIDDEN: 'You do not have permission.',
            NOT_FOUND: 'Resource not found',
            CONFLICT: 'Resource already exists',
            INTERNAL_ERROR: 'Server error',
            BAD_REQUEST: 'Bad request',
        },
        USER: {
            EMAIL_CONFLICT: 'User with this email already exists',
            LOGIN_WRONG_FORM_DATA: {
                TITLE: "Let's try that again",
                BODY: "It looks like you've entered the wrong email or password. Please try again.",
            },
            NOT_FOUND: 'User not found',
            PROFILE_NOT_ACTIVE: {
                SENT_AGAIN: 'Please confirm your e-mail address.',
            },
            PASSWORD_RESET: {
                TOKEN_EXPIRED: 'Your token has expired, please request new e-email',
                TOKEN_INVALID: 'Your token is invalid, please request new e-email',
                MISMATCH: 'Passwords are not matching',
            },
        },
        LEVEL: {
            NOT_FOUND: 'Level not found',
        },
        FILE: {
            NOT_FOUND: 'File not found',
        },
        SUBJECT: {
            NOT_FOUND: 'Subject not found',
        },
        TUTOR: {
            NOT_FOUND: 'Tutor not found',
        },
        TUTOR_AVAILABILITY: {
            CONFLICT: 'Tutor availability has already been set',
            NOT_FOUND: 'Tutor availability not found',
        },
        BOOKING: {
            NOT_FOUND: 'Booking not found',
        },
        ROLE: {
            NOT_FOUND: 'Role not found',
        },
        REVIEW: {
            CONFLICT: 'You have already written a review for this tutor',
        },
        INPUT_FAILED: 'Wrong input data',
        INVALID_IMAGE: 'Invalid image',
    },
    NOT_FOUND: {
        TITLE: 'Page not found',
        SUBTITLE: ' Sorry,looks like we sent you the wrong way. Let us guide you back!',
        BACK_BUTTON: 'Back to home',
    },
    UPCOMING_LESSONS: {
        TITLE: 'UPCOMING EVENTS',
        EMPTY: 'There are no upcoming lessons',
    },
    SKIP_FOR_NOW: 'Skip for now',
    REGISTER: {
        TITLE: 'Sign up',
        FORM: {
            BUTTONS_SUBTITLE: 'Account type',
            BUTTON_PARENT: 'Parent',
            BUTTON_STUDENT: 'Learner',
            BUTTON_TUTOR: 'Tutor',
            NAME_SUBTITLE: 'Legal name',
            FIRST_NAME: 'First Name on ID',
            FIRST_NAME_PLACEHOLDER: 'Enter First Name',
            LAST_NAME: 'Last Name on ID',
            LAST_NAME_PLACEHOLDER: 'Enter Last Name',
            LAST_NAME_INFO: 'Make sure this matches the name on your government ID.',
            DATE_OF_BIRTH_SUBTITLE: 'Date of birth',
            DATE_OF_BIRTH: 'Birthdate',
            DATE_OF_BIRTH_PLACEHOLDER: 'Enter your birthdate',
            DATE_OF_BIRTH_INFO: 'To sign up on Teorem, you need to be at least 18 years old.',
            CONTACT_INFO_SUBTITLE: 'Contact info',
            EMAIL: 'Email',
            EMAIL_PLACEHOLDER: 'Enter Email',
            PHONE_NUMBER: 'Phone number',
            PHONE_INFO:
                "Your contact details will not be visible to the public. We'll email you lesson confirmations and receipts.",
            PASSWORD_SUBTITLE: 'Password',
            PASSWORD: 'Password',
            PASSWORD_PLACEHOLDER: 'Type your password',
            CONFIRM_PASSWORD: 'Repeat Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Type your password',
            AGREE: "By clicking Agree and continue, I agree to Teorem's Terms of Service and Privacy Policy.",
            SUBMIT_BUTTON: 'Agree and continue',
            MARKETING_DISCLAIMER:
                'Teorem will send you special deals, marketing emails and push notifications. You can opt out of receiving these at any time in your account settings or directly from the marketing notification.',
            MARKETING_DISCLAIMER_CHECKBOX: "I don't want to receive marketing messages from Teorem.",

            COUNTRY: 'Country*',
            COUNTRY_PLACEHOLDER: 'Choose your country',
            PROFILE_IMAGE: 'Profile Image',
            CHILD_NAME: "Child's Name",
            CHILD_NAME_PLACEHOLDER: "Enter Child's Name",
            CHILD_DATE_OF_BIRTH: "Child's Date of Birth*",
            USERNAME: 'Username*',
            EMAIL_CONFLICT: 'Email already in use',
            TERMS_AND_CONDITIONS:
                '<div>By clicking <strong>Agree and continue</strong>, I agree to Teorem\'s <a href="https://teorem.co/en/terms" target="_blank">Terms of Service</a> and <a href="https://teorem.co/en/privacy-policy" target="_blank">Privacy Policy</a>.</div>',
        },
        CARD_DETAILS: {
            FIRST_NAME: 'First Name*',
            LAST_NAME: 'Last Name*',
            CARD_NUMBER: 'Card Number*',
            EXPIRY_DATE: 'Expiry date*',
            EXPIRY_PLACEHOLDER: 'MM / YY',
            CVV: 'CVV*',
            ZIP_CODE: 'ZIP / Postal Code*',
            ZIP_CODE_PLACEHOLDER: 'Enter ZIP / Postal Code',
        },
        BACK_BUTTON: 'Back to selection',
        NEXT_BUTTON: 'Next',
        BACK_TO_REGISTER: 'Back to register',
        FINISH: 'Finish',
        BACK_TO_STEP_ONE: 'Back to step 1',
        BACK_TO_STEP_TWO: 'Back to step ',
        SAVE_BUTTON: 'Save',
        DELETE_BUTTON: 'Delete',
        BACK_TO_LIST: 'Back to list',
    },
    ADD_CHILD: {
        TITLE: 'Add new Child',
        DESCRIPTION: 'Select to Add new Child',
        SAVE: 'Save Child',
        PART_1: 'Add a ',
        PART_2: 'child',
    },
    MY_BOOKINGS: {
        TITLE: 'Calendar',
        NOTIFICATION_PART_1: 'You have ',
        NOTIFICATION_PART_2: ' Lesson(s) today!',
        UNAVAILABLE: 'Unavailable',
        UNAVAILABILITY: 'Unavailability',
        UNABLE_MESSAGE: 'Can`t add event before current time and 3 hours after now',
        TIME_AFTER: 'Start time can`t be after end time',
        TIME_SAME: 'Start time and end time can`t be the same',
        CALENDAR: {
            TITLE: 'CALENDAR',
            TODAY: 'Today',
        },
        ADDITIONAL_INFORMATION: {
            TITLE: 'Information about joining meeting',
        },
        MODAL: {
            DELETED_USER: 'Deleted user',
            ACCEPT: 'Accept',
            PROPOSE: 'Propose a new time',
            DELETE: 'Delete',
            DENY: 'Deny',
            TUTOR_CALENDAR: 'Tutor calendar',
            TIME: 'Time',
            DATE: 'Date',
            WHOLE_DAY: 'Whole day',
            SET_UNAVAILABILITY: 'Set unavailability',
            CANCEL: 'Cancel',
            CONFIRM_CANCEL_TITLE: 'Are you sure you want to cancel booking?',
        },
        INFORMATION: {
            TITLE: 'BOOKING INFORMATION',
            CARD1: {
                TITLE: 'How does booking work?',
                DESC: 'After selecting a time that works for you, and booking, the tutor has 24 hours to accept your booking request. We will notify you via email once your lesson has been accepted.',
            },
            CARD2: {
                TITLE: 'What if I can’t make it to my lesson?',
                DESC: 'You can cancel any booking and get a full refund 24 hours from the moment you made the payment. For more information visit our <a href="https://intercom.help/teorem/en/" target="_blank">help center</a>.',
            },
            CARD3: {
                TITLE: 'How to book a slot?',
                DESC: 'You can book a slot with a tutor by visiting their profile and clicking "Book Now". You will be able to see the tutor`s availability and select a time that works for both of you.',
            },
            CARD4: {
                TITLE: 'How to cancel a booking?',
                DESC: 'You can cancel a booking in your calendar by clicking on the booking and clicking "Cancel". If you cancel a booking 24 hours in advance, you will receive a full refund.',
            },
        },
        RESCHEDULE: {
            BUTTON: 'Reschedule',
            MODAL: {
                TITLE: 'Reschedule Appointment',
                DESCRIPTION:
                    "If you haven't already, we suggest reaching out to the other party to arrange a new date and ensure they will accept the new schedule.",
            },
        },
        CANCEL: {
            BUTTON: 'Cancel',
            MODAL: {
                TITLE: 'Are you sure you want to cancel the appointment?',
                STUDENT_DESCRIPTION:
                    'You can receive a refund in the form of credits on the Teorem platform. Learn more about it at the provided link.',
                TUTOR_DESCRIPTION:
                    'In the event of canceling a reservation less than 24 hours before the start of the lecture, you will be penalized by having your profile appear less frequently in search results.',
                CONFIRM: 'Cancel Appointment',
                DISMISS: 'Dismiss',
            },
        },
    },
    BOOK: {
        TITLE: 'Book a Slot',
        RESCHEDULE_TITLE: 'Reschedule booking',
        FORM: {
            LEVEL: 'Level',
            LEVEL_PLACEHOLDER: 'Select Level',
            SUBJECT: 'Subject',
            CHILD: 'Child',
            CHILD_PLACEHOLDER: 'Select a child',
            TIME: 'Time* (Session length is 50min)',
            TIME_PLACEHOLDER: 'Time',
            SUBMIT: 'Book and Pay',
            ADD_CARD: 'Add card',
            UPDATE: 'Update booking',
            CANCEL: 'Cancel',
            TUTOR_DISABLED: 'Currently unavailable',
            CANCEL_BOOKING: 'Cancel booking',
            EDIT_OR_CANCEL_BOOKING: 'Edit or cancel',
            EDIT_OR_DENY_BOOKING: 'Edit or deny',
            DISMISS: 'Dismiss',
            DATE_OF_BOOKING: 'Date of booking',
            TIME_OF_BOOKING: 'Time',
            ALREADY_IN_RESCHEDULE: 'Booking already in reschedule',
            USE_CREDITS: 'Use credits',
        },
        JOIN: 'Join',
        JOIN_TOOLTIP: 'You can join the meeting 5 minutes before the start',
    },
    MY_REVIEWS: {
        TITLE: 'My Reviews',
        COUNT_TITLE: 'REVIEWS',
        AVG_SCORE: 'Review score',
        PUBLISHED: 'Published',
        STAR: {
            1: '1 Star',
            2: '2 Stars',
            3: '3 Stars',
            4: '4 Stars',
            5: '5 Stars',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: 'You don`t have any reviews yet.',
            DESC_ADMIN: 'No reviews yet.',
        },
        DATE_AGO: {
            BEFORE: '',
            YEAR: ' year ago',
            YEARS: ' years ago',
            MONTH: ' month ago',
            MONTHS: ' months ago',
            WEEK: ' week ago',
            WEEKS: ' weeks ago',
            DAY: ' day ago',
            DAYS: ' days ago',
            TODAY: ' today',
        },
    },
    COMPLETED_LESSONS: {
        TITLE: 'Completed Lessons',
        TUTORS_AVAILABLE: 'Tutors Available',
        LESSONS_AVAILABLE: 'Lessons available',
        EMPTY_LESSONS_LIST: 'There is no completed lessons available',
        EMPTY_LESSONS_TITLE: 'No Lessons',
        LEAVE_REVIEW: 'Leave review',
        VIEW_CALENDAR: 'View Calendar',
        BOOK_LESSON: 'Book a lesson',
        VIDEOS_TITLE: 'Download invoices and video lessons',
        EMPTY_VIDEOS: 'There is no completed lessons available to select',
        LINK: 'Go to Calendar',
        COUNT_EXTENSION: 'Lessons',
        TOOLTIP_DOWNLOAD_INVOICE: 'Download invoice',
        TOOLTIP_DROPDOWN_LABEL: 'Show video lessons',
        TOOLTIP_PLAY_LESSON: 'Play/download video lesson',
        DATE_TITLE: 'Date',
        DATE_AT_WORD: 'at',
        DOWNLOAD_INVOICE_SUCCESS: 'Invoice download succeeded!',
        DOWNLOAD_INVOICE_FAIL: 'Invoice download failed!',
        NUMBER_OF_COMPLETED_LESSONS: ' completed lessons with this tutor',
    },
    WRITE_REVIEW: {
        SECTION_TITLE: 'Write a Review',
        SUBTITLE:
            'Your feedback is important for improving our tutoring service. Take a moment to share your experience and help others like you find the perfect tutor!',
        OVERALL: 'Overall',
        PUNCTUALITY: 'Punctuality',
        COMMUNICATION: 'Communication',
        KNOWLEDGE: 'Knowledge',
        REVIEW: 'Review',
        SUBMIT: 'Post',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        FINISH: 'Finish',
        HEADING_PLACEHOLDER: 'Write title of your review',
        TEXT_PLACEHOLDER: 'Describe your overall experience with this tutor',
        REVIEWS: 'reviews',
        THANKS_MESSAGE: 'Thank you for your review!',
        NEW_TUTOR: 'New tutor',
        COMPLETED_LESSONS: {
            PART_1: 'You have ',
            PART_2: ' completed lessons with this tutor.',
        },
        COMMENT_LABEL: 'How was your overall experience with this tutor?',
    },
    COMPLETE_PROFILE: {
        TITLE: 'Complete my profile',
        DESCRIPTION: 'FIll out the missing information to make your profile complete',
        PROFILE_SETTINGS: 'Profile Settings',
        GENERAL_AVAILABILITY: 'Availability',
        MY_TEACHINGS: 'Subjects',
        ABOUT_ME: 'About me',
        ACCOUNT: 'Account',
        CHILD_INFO: 'Child information',
        EARNINGS: 'Earnings',
    },
    COMPLETE_TUTOR_PROFILE_CARD: {
        TITLE: 'Complete your profile!',
        DESCRIPTION: 'Please complete the rest of your profile data to start tutoring.',
    },
    TUTOR_VERIFIED_NOTE: {
        TITLE: 'Your account must be verified by the administrator before it can be displayed in search results!',
        DESCRIPTION: 'You will receive an email with updates on your account status.',
    },
    CHILDLESS_PARENT_NOTE: {
        TITLE: 'You must add child to request a booking!',
        DESCRIPTION: 'You can add a child on your profile settings.',
    },
    ACCOUNT: {
        CHANGE_PASSWORD: {
            TITLE: 'Change password',
            DESCRIPTION: 'Confirm your current password, then enter a new one.',
            CURRENT_PASSWORD: 'Current Password',
            CURRENT_PASSWORD_PLACEHOLDER: 'Enter your current password',
            NEW_PASSWORD: 'Enter your new password',
            NEW_PASSWORD_PLACEHOLDER: 'Enter your new password',
            CONFIRM_PASSWORD: 'Confirm Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Enter your new password',
        },
        CARD_DETAILS: {
            TITLE: 'Card details',
            DESCRIPTION: 'Select default payment method or add new one.',
            TITLE_TUTOR: 'Payment details',
            DESCRIPTION_TUTOR: 'Select how you would like to get paid for tutoring.',
            ADD_NEW: 'Add new Card',
            ADD_NEW_DESC: 'Select to add new Card',
            NOTE: 'After adding your card, a verification process will occur which includes a temporary €0.00 charge.',
        },
        NEW_CARD: {
            ADD: 'ADD NEW CARD',
            NAME: 'First Name*',
            NAME_PLACEHOLDER: 'Enter First Name',
            SURNAME: 'Last Name*',
            SURNAME_PLACEHOLDER: 'Enter Last Name',
            CITY: 'City*',
            CITY_PLACEHOLDER: 'Enter City Name',
            ADDRESS1: 'Address 1*',
            ADDRESS1_PLACEHOLDER: 'Enter first address',
            ADDRESS2: 'Address 2',
            ADDRESS2_PLACEHOLDER: 'Enter second address',
            CARD_NUMBER: 'Card Number*',
            CARD_NUMBER_PLACEHOLDER: '**** **** **** ****',
            EXPIRY: 'Expiry date*',
            EXPIRY_PLACEHOLDER: 'MM / YY',
            CVV: 'CVV*',
            CVV_PLACEHOLDER: '***',
            ADD_BUTTON: 'Add New Card',
            CANCEL_BUTTON: 'Cancel',
            ZIP: 'Zip*',
            ZIP_PLACEHOLDER: '12345',
            COUNTRY: 'Country',
            COUNTRY_PLACEHOLDER: 'Choose your country',
        },
        SUBMIT: 'Save',
    },
    MY_PROFILE: {
        TITLE: 'My Profile',
        PREVIEW: 'Preview profile',
        PAYOUTS: 'Where do we send your payouts?',
        IMAGE: 'Add profile image',
        ABOUT_ME: {
            OCCUPATION: 'Your current Occupation*',
            OCCUPATION_PLACEHOLDER: 'What’s your current Occupation',
            YEARS: 'Years of tutoring experience',
            YEARS_PLACEHOLDER: 'How many years of tutoring experience you have',
            ABOUT_YOURSELF: 'Tell us more about yourself*',
            ABOUT_LESSON: 'Tell us more about your lessons*',
        },
        MY_TEACHINGS: {
            TITLE: 'My Subjects',
            DESCRIPTION: 'Edit and update your subjects information',
            ADD_NEW: 'Add new Subject',
            ADD_DESC: 'Select to add new Subject',
            DELETED: 'Subject is deleted',
            CREATED: 'Subject is created',
            UPDATED: 'Subjects updated',
            EDIT_TITLE: 'EDIT SUBJECT DETAILS',
            ADD_TITLE: 'ADD NEW SUBJECT',
            SUBJECT: 'Select subject you teach',
            LEVEL: 'Select levels that you are able to teach',
            PRICING: 'Pricing',
            PRICING_PLACEHOLDER: '10',
            HOUR_ABRV: 'hr',
            SAVE: 'Save information',
            DELETE: 'Delete',
            CANCEL: 'Cancel',
            SUBJECT_ERROR: 'Subject for that level already exists',
        },
        GENERAL_AVAILABILITY: {
            TITLE: 'Availability',
            DESCRIPTION: 'Edit and update your availability information',
            UPDATED: 'Availability updated',
            CREATED: 'Availability created',
            SELECT_ZONE: 'Select your time zone: ',
            TIME_ZONE: 'Time zone',
        },
        PROFILE_SETTINGS: {
            TITLE: 'Personal Information',
            CREDITS: 'Credits',
            DESCRIPTION: 'Edit and update your personal information',
            FIRST_NAME: 'First Name*',
            FIRST_NAME_PLACEHOLDER: 'Enter your first name',
            LAST_NAME: 'Last Name*',
            LAST_NAME_PLACEHOLDER: 'Enter your first name',
            PHONE: 'Phone Number*',
            COUNTRY: 'Country*',
            BIRTHDAY: 'Date of Birth*',
            IBAN: 'IBAN',
            IBAN_PLACEHOLDER: 'Enter your IBAN',
            COMPANY_NAME: 'Company name',
            COMPANY_NAME_PLACEHOLDER: 'Enter company name',
            COMPANY_OIB: 'Company PIN',
            COMPANY_OIB_PLACEHOLDER: 'Enter company PIN',
            IMAGE: 'Profile Image*',
            UPLOAD_IMAGE: 'Click or drag and drop to upload',
            CHOOSE_FILE: 'Choose file',
            ADDRESS_PERSONAL_PLACEHOLDER: 'Enter your address',
            ADDRESS_BUSINESS_PLACEHOLDER: "Enter your company's address",
        },
        PROFILE_ACCOUNT: {
            STRIPE: 'Go to Stripe',
            STRIPE_ALREADY_CONNECTED:
                'Stripe account  has already been connected, if you wish to connect to other account, then press again',
            STRIPE_CONNECTED: 'Connected',
            STRIPE_DISCONNECTED: 'Disconnected',
            STRIPE_CARD_DECLINED: 'Error creating Stripe account',
            SUCCESS_PASSWORD: 'You successfully changed a password',
            STRIPE_DEFAULT_PAYMENT_METHOD_UPDATED: 'Default payment method is updated',
        },
        CHILD: {
            ADD_TITLE: 'ADD NEW CHILD',
            EDIT_TITLE: 'EDIT A CHILD',
            FIRST_NAME: 'First Name',
            FIRST_NAME_PLACEHOLDER: 'Enter Child Name',
            LAST_NAME: 'Last Name',
            LAST_NAME_PLACEHOLDER: 'Enter Child Last name',
            USERNAME: 'Username',
            USERNAME_PLACEHOLDER: 'Enter username',
            PASSWORD: 'Enter a new password',
            PASSWORD_PLACEHOLDER: 'Type your password',
            PASSWORD_OPTIONAL:
                'This field is optional, if you want to change current child`s password you can do it in field below.',
            DELETE: 'Delete',
            CANCEL: 'Cancel',
        },
        TRANSLATION: {
            TITLE: 'Translation',
            SUBTITLE: 'Choose your language for the application.',
        },
        SUBMIT: 'Save',
        TUTOR_DISABLE: {
            TITLE: 'Profile visibility',
            SUBTITLE: 'Profile is visible',
            YES: 'Yes',
            NO: 'No',
        },
    },
    DASHBOARD: {
        TITLE: 'Dashboard',
        REQUESTS: {
            TITLE: 'LESSON REQUESTS',
            EMPTY: 'No new requests',
            RESCHEDULE: 'RESCHEDULE REQUESTS',
            STATUS: {
                RESCHEDULE_REQUESTED: 'Modification',
                RESCHEDULE_DO_ACTION: 'Modification',
                NEW_BOOKING_REQUESTED: 'Waiting for confirmation',
                NEW_BOOKING_DO_ACTION: 'New booking',
            },
            TOOLTIP: {
                PART_1: 'You have ',
                PART_2: ' h to accept request',
            },
        },
        SCHEDULE: {
            TITLE: 'TODAY`S SCHEDULE',
            BUTTON: 'Join',
            EMPTY: 'There are no scheduled bookings today',
        },
        MESSAGES: {
            TITLE: 'UNREAD MESSAGES',
            BUTTON: 'Chat',
            EMPTY: 'No unread messages',
        },
        BOOKINGS: {
            TITLE: 'UPCOMING BOOKINGS',
            TOTAL: 'Total',
            EMPTY: 'No bookings',
            EMPTY_SUBTITLE: 'Hmm, looks like there are no bookings yet.',
            RECOMMENDED: 'RECOMMENDED TUTORS',
            SHOW_MORE: 'Show more',
        },
        NOTIFICATIONS: {
            TITLE: 'NOTIFICATIONS',
            CLEAR: 'Mark as read',
            ALL: 'See all',
            EMPTY: 'There is no unread notifications',
        },
        COMING_SOON: {
            TITLE: 'Coming soon',
            SUBTITLE: 'We are working hard to get this up and running',
        },
    },
    NOTIFICATIONS: {
        TITLE: 'All Notifications',
        EMPTY: 'There are no notifications',
        BOOKING_CLASS_STARTING: {
            DESCRIPTION: 'Class starting soon',
            TITLE: 'Booking starting soon',
        },
        CHAT_MISSED_CALL: {
            DESCRIPTION: 'Missed call from',
            TITLE: 'Missed call',
        },
        BOOKING: {
            STUDENT: 'Student',
            PARENT: 'Parent',
            TUTOR: 'Tutor',
        },
        BOOKING_NEW_BOOKING: {
            TITLE: 'New booking',
            DESCRIPTION: '',
            MADE_BOOKING_TEXT: 'made new booking for',
        },
        BOOKING_RESCHEDULE_PROPOSITION: {
            TITLE: 'Reschedule proposition',
            DESCRIPTION: '',
            PROPOSING_NEW_TIME_TEXT: 'wants to propose new time for booking',
        },
        BOOKING_ACCEPTED: {
            TITLE: 'Booking accepted',
            DESCRIPTION: 'accepted booking for',
        },
        BOOKING_CANCELED: {
            TITLE: 'Booking canceled',
            DESCRIPTION: '',
            CANCEL_TEXT: 'canceled booking for',
        },
        BOOKING_DELETED: {
            TITLE: 'Deleted unaccepted booking',
            DESCRIPTION: {
                PART1: 'Booking is deleted because',
                PART2: "didn't accept it.",
            },
        },
        CHAT_HAS_MISSED_CALL: ' has missed your call',
        HELP_CENTER: {
            TITLE: 'Help center',
            LINK: 'https://intercom.help/teorem/en/',
        },
    },
    EARNINGS: {
        TITLE: 'Earnings',
        GENERAL: {
            TITLE: 'YOUR STATS',
            BOOKINGS: 'Total bookings',
            STUDENTS: 'Total students',
            REVIEWS: 'Total reviews',
            REVENUE: 'Total revenue',
            CURRENCY: '€',
        },
        PAYOUTS: 'Payout',
        WEEK_TITLE: 'Week',
        WEEK: 'Last 7 days',
        MONTH: 'Last 30 days',
        YEAR: 'Last 12 months',
        ALLTIME: 'All time',
        REVENUE: {
            TITLE: 'REVENUE',
            GRAPH_LEGEND: 'Income',
        },
        STUDENTS: {
            TITLE: 'STUDENTS',
            GRAPH_LEGEND: 'Students',
        },
        BOOKINGS: {
            TITLE: 'BOOKINGS',
            GRAPH_LEGEND: 'Bookings',
        },
        DETAILS: {
            TITLE: 'DETAILS',
            TABLE: {
                MONTH: 'Month',
                BOOKINGS: 'Bookings',
                STUDENTS: 'Students',
                REVIEWS: 'Reviews',
                REVENUE: 'Revenue',
                PROVISION: 'Teorem Provision',
                PAYOUT: 'Paid',
                EMPTY: 'There is no details',
            },
        },
    },
    ROUTER_MODAL: {
        TITLE: 'Save changes?',
        DESC: 'Do you want to save your changes before leaving the page?',
        SAVE: 'Save',
        NO: 'No',
    },
    TUTOR_PROFILE: {
        AVAILABILITY_EMPTY: 'Tutor did not fill out the availability table',
        PRICING: 'Pricing',
        RATING_TITLE: 'Rating',
        COMPLETED_LESSONS: 'Completed lessons',
        BOOK: 'Book a lesson',
        TUTOR_DISABLED: 'Currently unavailable',
        SEND: 'Send a message',
        EMPTY: 'User not found',
        ID_VERIFIED: 'ID verified',
        DOCUMENTS_UPLOADED: 'Documents uploaded',
        SUBJECTS: {
            TITLE: 'Subjects',
            SUBJECT: 'Subject',
            QUALIFICATION: 'Qualification',
            PRICE: 'Price',
            HOUR_ABRV: 'hr',
            EMPTY: 'There are no subjects offered',
        },
        RATING: {
            TITLE: 'Ratings & reviews',
            TOTAL: 'reviews total',
        },
        PRE12: 'Pre 12 pm',
        ON12: '12 - 5 pm',
        AFTER5: 'After 5 pm',
        REVIEWS: 'reviews',
        TOOLTIP: {
            ID_VERIFIED: 'Tutor has a verified ID',
        },
    },
    LANDING: {
        HEADER: {
            NAV_1: 'How it works',
            NAV_2: 'Pricing',
            NAV_3: 'Become a tutor',
            SIGN_IN: 'Sign in',
            GET_STARTED: 'Get started',
        },
        FOOTER: {
            TITLE_TEXT: 'If you have additional questions, feel free to contact us via email ',
            APP_NAME: 'Teorem Ltd',
            ADDRESS: '',
            MOBILE: '',
            EMAIL: 'info@teorem.co',
            LEGAL: {
                TITLE: 'About Teorem',
                LEGAL: 'Terms & Conditions',
                PRIVACY: 'Privacy policy',
                TUTOR: 'Become a tutor',
            },
            PAYMENT: {
                TITLE: 'Payment methods',
            },
        },
    },
    HOW_IT_WORKS: {
        HERO: {
            TITLE: 'Online tutoring that releases potential',
            SUBTITLE:
                'Private One-on-One 50-minute online lessons uniquely tailored for each student. All school subjects and age groups covered.',
        },
        SUBTITLE_1: 'As featured around the world',
        SUBTITLE_2: 'Frequently asked questions',
        TEXT_CARD_1: {
            TITLE: 'Large circle of vetted and reviewed high-quality tutors',
            SUBTITLE:
                'Our tutors are all vetted to ensure they have experience in tutoring. They are reviewed by parents and students after each lesson.',
        },
        TEXT_CARD_2: {
            TITLE: 'Chat with any tutor for free before you book.',
            SUBTITLE:
                'Share any files or assignments with the tutor and have a free video call to make sure the tutor you cohose is the perfect fit for you.',
        },
        TEXT_CARD_3: {
            TITLE: 'Our virtual classroom lets you rewatch lessons.',
            SUBTITLE:
                'Our lessons are so much more than just video calls. Our virtual classroom contains a digital whiteboard, recording function, screen sharing and much more.',
        },
        TEXT_CARD_4: {
            TITLE: 'Only pay for what you use.',
            SUBTITLE:
                'Teorem won’t charge you anything until you’ve found your perfect tutor, had a chat with them and booked your first lesson. No sign-up fees, no subscriptions, just plain pay-as-you-go. Safe and secure payment gateway accepting a wide range of options.',
        },
        REVIEW_1: {
            TEXT: "Teorem helped my son improve his physics marks! I love that it's all online and that I no longer need to drive him to the other side of the city!",
            NAME: 'Sandra',
            OCCUPATION: "Luke's mother",
        },
        REVIEW_2: {
            TEXT: 'The complimentary video call has allowed me to chat with several tutors before deciding on one that best fits my child’s needs. I couldn’t be happier!',
            NAME: 'Renata',
            OCCUPATION: "Thea and Mark's mother",
        },
        REVIEW_3: {
            TEXT: 'With Teorem’s virtual classroom I can finally learn through interactive exercises and fun design. I love the fact that I can rewatch the lessons!',
            NAME: 'John',
            OCCUPATION: 'Economics student',
        },
        BUTTON_1: 'Get started',
        FAQ: {
            QUESTION_1: 'Why One-to-one tutoring?',
            ANSWER_1:
                'One-to-one online tutoring gives kids the opportunity to learn at their own pace and in a way that matches their learning style. Teens are often too shy to put their hand up in class – especially if they’re struggling. The reassurance of one-to-one tutoring means they can ask all the questions they want, and go over topics as much as they need until they get it.',
            QUESTION_2: 'What are the benefits of online tutoring?',
            ANSWER_2:
                'Teorem’s online tutoring lets kids unleash their full potential. Our digital whiteboard allows tutors to explain complex concepts in an easy and thoughtful manner. Whatever your child needs help with, their tutor will guide them through. In addition, the online model removes geographic constraints of finding a high-quality tutor.',
            QUESTION_3: 'What qualifications do the tutors have?',
            ANSWER_3:
                'Applicants undergo multiple tests before becoming tutors on our platform. They are tested on their subject knowledge and personal and teaching skills. A very small number of applicants who apply are selected.',
            QUESTION_4: 'How do online lessons work?',
            ANSWER_4:
                'We have our own virtual lesson space including video chat, messaging and an interactive whiteboard which makes it easy for students and tutors to communicate, discuss tricky concepts and do practice questions together. With the live video chat, they can have a natural back-and-forth conversation – just like on FaceTime, WhatsApp and other apps teens use all the time. All our lessons last 50 minutes.',
        },
    },
    PRICING: {
        HERO: {
            TITLE: 'Get the most value for your money',
            SUBTITLE: 'Teorem helps students of all ages to achieve their academic goals.',
        },
        SUBTITLE_1: 'Our tutoring process',
        SUBTITLE_2: 'Teorem benefits',
        SUBTITLE_3: 'Frequently asked questions',
        BUTTON_1: 'Book Your Complementary Lesson',
        TEXT_CARD_1: {
            TITLE: 'How much does online tutoring cost?',
            SUBTITLE:
                'Teorem’s tutors set their own prices, based on their experience and availability. Lesson prices start from EUR 10.',
        },
        FAQ: {
            QUESTION_1: 'How much does a tutor cost?',
            ANSWER_1:
                'Our tutors set their own prices based on their experience, qualifications and availability. Prices start at $XX. You can see all the tutors who match your budget with the handy price filter on our Tutor Search.',
            QUESTION_2: 'How to find a tutor?',
            ANSWER_2:
                'Finding a tutor is easy. After you register an account with Teorem, you can search and filter by subject, level, price, experience and more.',
            QUESTION_3: 'Can I change a tutor if I am unhappy?',
            ANSWER_3:
                'Of course! You are free to change a tutor anytime. We’ll take care of the admin, so you don’t have to.',
            QUESTION_4: 'Can I get a refund?',
            ANSWER_4: 'Yes, if you cancel up to 24 hours in advance, we will issue you a full refund for your lesson.',
        },
        STEP_1: {
            TITLE: 'Initial Assessment Complimentary',
            SUBTITLE:
                'During an initial, complimentary call, Teorem’s tutor will determine the student’s needs and choose the ideal study based on the student’s personality, learning style and requirements.',
        },
        STEP_2: {
            TITLE: 'Trial Lesson',
            SUBTITLE:
                'A trial lesson gives you a chance to see how the tutor teaches and how the Teorem platform works.',
        },
        STEP_3: {
            TITLE: 'Collaboration',
            SUBTITLE:
                'Teorem’s tutoring is straightforward and hassle-free. Once you’re registered, you’ll find everything you need on our platform. You can monitor performance, change lesson times, book new lessons, cancel them, or even contact your tutor, all in just a few clicks.',
        },
        CARD_1: {
            TITLE: 'Flexible',
            SUBTITLE: 'Easy, flexible, scheduling adjusted to you.',
        },
        CARD_2: {
            TITLE: 'Virtual Classroom',
            SUBTITLE:
                'Available on all devices, with a built-in recording function, digital whiteboard, and screen and file sharing.',
        },
        CARD_3: {
            TITLE: 'Secure Payment',
            SUBTITLE:
                'Secure, safe payment gateway provided by Stripe. No hidden fees, just pay for lessons. Refunds provided.',
        },
        CARD_4: {
            TITLE: 'Support',
            SUBTITLE: '24/7 access to support. Our dedicated support team is available to you at a moment’s notice.',
        },
    },
    BECOME_A_TUTOR: {
        HERO: {
            TITLE: 'Start tutoring online',
            SUBTITLE: 'Give lessons whenever you want, from the comfort of your home.',
        },
        TEXT_CARD_1: {
            TITLE: 'Teorem’s online tutoring makes your life easier',
            SUBTITLE:
                'We’ll provide you with everything you need to teach online. We help you find students and manage lessons.',
        },
        TEXT_CARD_2: {
            TITLE: 'Set your own price and availability!',
            SUBTITLE: 'Use our tools to track how much you’re making and increase your client base!',
        },
        REVIEW_1: {
            TEXT: "With Teorem I created a stable monthly income for myself and don't need to worry about all of my expenses anymore!",
            NAME: 'Anthony',
            OCCUPATION: 'Physics tutor',
        },
        REVIEW_2: {
            TEXT: 'Teorem has allowed me to move to a completely remote location and still tutor my students living in the city!',
            NAME: 'Sarah',
            OCCUPATION: 'German tutor',
        },
        SUBTITLE_1: 'We are here to help you grow!',
        SUBTITLE_2: 'How to become an online tutor on Teorem',
        SUBTITLE_3: 'Frequently asked questions',
        BUTTON_1: 'Get started',
        FAQ: {
            QUESTION_1: 'How do I become an online tutor?',
            ANSWER_1:
                'If you want to know how to get into tutoring, you’ve come to the right place! Just create an account, book your interview and we’ll help you get set up!',
            QUESTION_2: 'What are the requirements for tutors?',
            ANSWER_2:
                'We ideally require our tutors to commit a minimum of 6 lessons per week. You must be qualified at high school diploma level or above. You need to have a laptop (or PC) and a stable internet connection to teach online.',
            QUESTION_3: 'How long does it take before I can start teaching?',
            ANSWER_3:
                'As the registration process consists of a few simple steps, it usually only takes a day until you are verified and can begin teaching.',
            QUESTION_4: 'What does the employment relationship look like?',
            ANSWER_4:
                'You offer independent tutoring via Teorem’s platform. There is no employment relationship. You are responsible for any taxes and National Insurance contributions.',
            QUESTION_5: 'What makes Teorem different from other portals?',
            ANSWER_5:
                'We guarantee you a steady stream of students. We also take care of everything so that you can focus 100% on teaching.',
            QUESTION_6: 'How does Teorem make money?',
            ANSWER_6: 'Teorem charges a 15% commission to the tutor.',
        },
        CARD_1: {
            TITLE: 'Fully remote',
            SUBTITLE: 'All you need is a laptop and wifi',
        },
        CARD_2: {
            TITLE: 'Tools you need',
            SUBTITLE: 'Booking system, chat, video calls & more.',
        },
        CARD_3: {
            TITLE: 'Secure Income',
            SUBTITLE: 'We make sure you get paid.',
        },
        CARD_4: {
            TITLE: 'Set Your Price',
            SUBTITLE: 'No limitations on what you can earn.',
        },
        CARD_5: {
            TITLE: '24/7 Support',
            SUBTITLE: 'We make sure all your issues are solved.',
        },
        CARD_6: {
            TITLE: 'Improve Your Teaching',
            SUBTITLE: 'We take care of administration, you just focus on the lessons.',
        },
        STEP_1: 'Create your account and fill in the information.',
        STEP_2: 'Upload a short video to prove your identity.',
        STEP_3: 'Complete your profile and start tutoring!',
    },
    TOKEN_NOT_VALID: {
        TITLE: 'Token not valid!',
        DESCRIPTION: 'Please check your email and try again',
        HINT: '(If you have already requested a new activation email, make sure you used the latest link, i.e. the last email you received.)',
        BUTTON: 'Log in',
    },
    STRIPE_CONNECTED: {
        WELCOME: 'Success!',
        DESCRIPTION: 'Stripe connected successfully.',
        BUTTON: 'Continue',
    },
    STRIPE_FAIL: {
        WELCOME: 'Something went wrong.',
        DESCRIPTION: 'Stripe connection failed, please try again.',
        BUTTON: 'Continue',
    },
    STRIPE_CONNECT: {
        TITLE: 'Payments',
        ADDRESS: 'Address',
        LINE_1: 'Address line 1',
        LINE_2: 'Address line 2',
        POST_CODE: 'Postal code',
        STATE: 'State',
        LAST_4_SSN: 'Last 4 digits of Social Security Number (SSN)',
        CITY: 'City',
        IBAN: 'IBAN',
        ROUTING_NUMBER: 'Routing number',
        ACCOUNT_NUMBER: 'Account number',
        IBAN_CONFIRM: 'IBAN confirmation',
        SAVE: 'Submit',
        CANCEL: 'Cancel',
        TERMS: '<div>By clicking on the "Submit" button, you agree to the <a href="https://stripe.com/legal/connect-account" target="_blank">Terms of Service</a>.</div>',
        SUCCESS: 'Payment account connected',
    },
    SUBJECTS: {
        chemistry: 'Chemistry',
        economics: 'Economics',
        english: 'English',
        croatian: 'Croatian',
        geography: 'Geography',
        german: 'German',
        history: 'History',
        biology: 'Biology',
        sociology: 'Sociology',
        it: 'IT',
        italian: 'Italian',
        maths: 'Maths',
        music: 'Music',
        physics: 'Physics',
        psychology: 'Psychology',
        designandtechnology: 'Design and Technology',
        polish: 'Polish',
        religion: 'Religion',
        russian: 'Russian',
        spanish: 'Spanish',
        businessstudies: 'Business Studies',
        classicalgreek: 'Classical Greek',
        'classical-greek': 'Classical Greek',
        computing: 'Computing',
        drama: 'Drama',
        environmentalstudies: 'Environmental Studies',
        french: 'French',
        governmentandpolitics: 'Government and Politics',
        'government-and-politics': 'Government and Politics',
        latin: 'Latin',
        science: 'Science',
        art: 'Art',
        accounting: 'Accounting',
        architecture: 'Architecture',
        chemicalengineering: 'Chemical Engineering',
        law: 'Law',
        medicine: 'Medicine',
        philosophy: 'Philosophy',
        politics: 'Politics',
        statistics: 'Statistics',
        calculus: 'Calculus',
        precalculus: 'Precalculus',
        economy: 'Economy',
        reading: 'Reading',
        writing: 'Writing',
    },
    SUBJECTS_GENITIVE: {
        biology: 'Biology',
        psychology: 'Psychology',
        sociology: 'Sociology',
        designandtechnology: 'Design and Technology',
        chemistry: 'Chemistry',
        economics: 'Economics',
        english: 'English',
        croatian: 'Croatian',
        geography: 'Geography',
        german: 'German',
        history: 'History',
        it: 'IT',
        italian: 'Italian',
        maths: 'Maths',
        music: 'Music',
        physics: 'Physics',
        polish: 'Polish',
        religion: 'Religion',
        russian: 'Russian',
        spanish: 'Spanish',
        businessstudies: 'Business Studies',
        classicalgreek: 'Classical Greek',
        computing: 'Computing',
        drama: 'Drama',
        environmentalstudies: 'Environmental Studies',
        french: 'French',
        governmentandpolitics: 'Government and Politics',
        latin: 'Latin',
        science: 'Science',
        art: 'Art',
        accounting: 'Accounting',
        architecture: 'Architecture',
        chemicalengineering: 'Chemical Engineering',
        law: 'Law',
        medicine: 'Medicine',
        philosophy: 'Philosophy',
        politics: 'Politics',
        statistics: 'Statistics',
        calculus: 'Kalkulus',
        economy: 'Ekonomija',
        precalculus: 'Prekalkulus',
        reading: 'Čitanje',
        writing: 'Pisanje',
        'classical-greek': 'Classical Greek',
        'government-and-politics': 'Government and Politics',
    },
    SEO_TITLE: {
        TUTOR_SEARCH: 'Online :subject instructors and tutors for private :subject lessons',
        TUTOR_PROFILE: ' | Study with tutors of',
    },
    ROLES: {
        student: 'Student',
        parent: 'Parent',
        child: 'Child',
        tutor: 'Tutor',
        admin: 'Admin',
    },
    LEVELS: {
        primaryschool: 'Primary School',
        highschool: 'High School',
        ib: 'IB',
        maturaprep: 'Matura Prep',
        university: 'University',
        'primary-school': 'Primary School',
        'high-school': 'High School',
        'matura-prep': 'Matura Prep',
        elementary: 'Elementary School',
        middle: 'Middle School',
        high: 'High School',
        ap: 'AP',
        sat: 'SAT',
    },
    DEGREES: {
        bacc: 'Bachelor',
        master: 'Master',
        phd: 'PhD',
        mag: 'Master',
        postdipl: 'Postgraduate',
        other: 'Other',
    },
    UNIVERSITIES: {
        AgronomskifakultetSveuiliteuZagrebu: 'Faculty of Agriculture - University of Zagreb',
        AkademijadramskeumjetnostiSveuiliteuZagrebu: 'Academy of Dramatic Art - University of Zagreb',
        AkademijalikovnihumjetnostiSveuiliteuZagrebu: 'Academy of Fine Arts - University of Zagreb',
        AkademijaprimijenjenihumjetnostiSveuiliteuRijeci: 'Academy of Applied Arts - University of Rijeka',
        ArhitektonskifakultetSveuiliteuZagrebu: 'Faculty of Architecture - University of Zagreb',
        BiotehnikiodjelSveuiliteuSlavonskomBrodu: 'Biotechnical Department - University of Slavonski Brod',
        EdukacijskorehabilitacijskifakultetSveuiliteuZagrebu:
            'Faculty of Education and Rehabilitation - University of Zagreb',
        EdwardBernaysUniversityofAppliedSciences: 'Edward Bernays University of Applied Sciences',
        EffectusVeleuilite: 'Effectus Polytechnic',
        EkonomskifakultetSveuiliteuOsijeku: 'Faculty of Economics - University of Osijek',
        EkonomskifakultetSveuiliteuRijeci: 'Faculty of Economics - University of Rijeka',
        EkonomskifakultetSveuiliteuSplitu: 'Faculty of Economics - University of Split',
        EkonomskifakultetSveuiliteuZagrebu: 'Faculty of Economics - University of Zagreb',
        EuropskaposlovnakolaZagreb: 'European Business School Zagreb',
        Evaneoskoteolokoveleuilite: 'Evangelical Theological Polytechnic',
        FakultetagrobiotehnikihznanostiSveuiliteuOsijeku: 'Faculty of Agrobiotechnical Sciences - University of Osijek',
        FakultetbiotehnologijeirazvojalijekovaSveuiliteuRijeci:
            'Faculty of Biotechnology and Drug Development - University of Rijeka',
        FakultetdentalnemedicineSveuiliteuRijeci: 'Faculty of Dental Medicine - University of Rijeka',
        FakultetelektrotehnikeiraunarstvaSveuiliteuZagrebu:
            'Faculty of Electrical Engineering and Computing - University of Zagreb',
        FakultetelektrotehnikestrojarstvaibrodogradnjeSveuiliteuSplitu:
            'Faculty of Electrical Engineering, Mechanical Engineering and Shipbuilding - University of Split',
        FakultetfilozofijeireligijskihznanostiSveuiliteuZagrebu:
            'Faculty of Philosophy and Religious Sciences - University of Zagreb',
        FakultetgraevinarstvaarhitektureigeodezijeSveuiliteuSplitu:
            'Faculty of Civil Engineering, Architecture and Geodesy - University of Split',
        FakultethrvatskihstudijaSveuiliteuZagrebu: 'Faculty of Croatian Studies - University of Zagreb',
        FakultetinformatikeidigitalnihtehnologijaSveuiliteuRijeci:
            'Faculty of Informatics and Digital Technologies - University of Rijeka',
        FakultetkemijskoginenjerstvaitehnologijeSveuiliteuZagrebu:
            'Faculty of Chemical Engineering and Technology - University of Zagreb',
        FakultetorganizacijeiinformatikeSveuiliteuZagrebu:
            'Faculty of Organization and Informatics - University of Zagreb',
        FakultetpolitikihznanostiSveuiliteuZagrebu: 'Faculty of Political Sciences - University of Zagreb',
        FakultetprometnihznanostiSveuiliteuZagrebu: 'Faculty of Traffic Sciences - University of Zagreb',
        FakultetstrojarstvaibrodogradnjeSveuiliteuZagrebu:
            'Faculty of Mechanical Engineering and Shipbuilding - University of Zagreb',
        FakultetumarstvaidrvnetehnologijeSveuiliteuZagrebu:
            'Faculty of Forestry and Wood Technology - University of Zagreb',
        FakultetturizmairuralnograzvojauPoegiFTRRSveuiliteuOsijeku:
            'Faculty of Tourism and Rural Development in Požega (FTRR) - University of Osijek',
        FakultetzafizikuSveuiliteuRijeci: 'Faculty of Physics - University of Rijeka',
        FakultetzamatematikuSveuiliteuRijeci: 'Faculty of Mathematics - University of Rijeka',
        FakultetzamenadmentuturizmuiugostiteljstvuSveuiliteuRijeci:
            'Faculty of Tourism and Hospitality Management - University of Rijeka',
        FakultetzdravstvenihstudijaSveuiliteuRijeci: 'Faculty of Health Studies - University of Rijeka',
        FarmaceutskobiokemijskifakultetSveuiliteuZagrebu: 'Faculty of Pharmacy and Biochemistry - University of Zagreb',
        FilozofskifakultetSveuiliteJurjaDobrileuPuli: 'Faculty of Philosophy - Juraj Dobrila University in Pula',
        FilozofskifakultetSveuiliteuRijeci: 'Faculty of Philosophy - University of Rijeka',
        FilozofskifakultetSveuiliteuSplitu: 'Faculty of Philosophy - University of Split',
        FilozofskifakultetSveuiliteuZagrebu: 'Faculty of Philosophy - University of Zagreb',
        GeodetskifakultetSveuiliteuZagrebu: 'Faculty of Geodesy - University of Zagreb',
        Geotehnikifakultet: 'Geotechnical faculty',
        GeotehnikifakultetSveuiliteuZagrebu: 'Geotechnical Faculty - University of Zagreb',
        GraevinskifakultetSveuiliteuRijeci: 'Faculty of Civil Engineering - University of Rijeka',
        GraevinskifakultetSveuiliteuZagrebu: 'Faculty of Civil Engineering - University of Zagreb',
        GrafikifakultetSveuiliteuZagrebu: 'Faculty of Graphics - University of Zagreb',
        Hrvatskokatolikosveuilite: 'Croatian Catholic University',
        Istarskoveleuilite: 'Polytechnic of Istria',
        KatolikibogoslovnifakultetSveuiliteuSplitu: 'Catholic Faculty of Theology - University of Split',
        KatolikibogoslovnifakultetSveuiliteuZagrebu: 'Catholic Faculty of Theology - University of Zagreb',
        KemijskotehnolokifakultetSveuiliteuSplitu: 'Faculty of Chemical Technology - University of Split',
        KineziolokifakultetSveuiliteuSplitu: 'Faculty of Kinesiology - University of Split',
        KineziolokifakultetSveuiliteuZagrebu: 'Faculty of Kinesiology - University of Zagreb',
        Libertasmeunarodnosveuilite: 'Libertas International University',
        MedicinskifakultetSveuiliteuRijeci: 'Faculty of Medicine - University of Rijeka',
        MedicinskifakultetSveuiliteuSplitu: 'Faculty of Medicine - University of Split',
        MedicinskifakultetSveuiliteuZagrebu: 'Faculty of Medicine - University of Zagreb',
        Meimurskoveleuiliteuakovcu: 'Međimurje Polytechnic in Čakovec',
        MetalurkifakultetSveuiliteuZagrebu: 'Faculty of Metallurgy - University of Zagreb',
        MuzikaakademijaSveuiliteuZagrebu: 'Academy of Music - University of Zagreb',
        OdjeldrutvenohumanistikihznanostiSveuiliteuSlavonskomBrodu:
            'Department of Social and Humanities - University of Slavonski Brod',
        OdjelzaanglistikuSveuiliteuZadru: 'Department of English Studies - University of Zadar',
        OdjelzaarheologijuSveuiliteuZadru: 'Department of Archeology - University of Zadar',
        OdjelzaekologijuagronomijuiakvakulturuSveuiliteuZadru:
            'Department of Ecology, Agronomy and Aquaculture - University of Zadar',
        OdjelzaekonomijuSveuiliteuZadru: 'Department of Economics - University of Zadar',
        OdjelzaekonomijuiposlovnuekonomijuSveuiliteuDubrovniku:
            'Department of Economics and Business Economics - University of Dubrovnik',
        OdjelzaelektrotehnikuiraunarstvoSveuiliteuDubrovniku:
            'Department of Electrical Engineering and Computing - University of Dubrovnik',
        OdjelzaetnologijuikulturnuantropologijuSveuiliteuZadru:
            'Department of Ethnology and Cultural Anthropology - University of Zadar',
        OdjelzafilozofijuSveuiliteuZadru: 'Department of Philosophy - University of Zadar',
        OdjelzafrancuzeifrankofonskestudijeSveuiliteuZadru:
            'Department of French and Francophone Studies - University of Zadar',
        OdjelzageografijuSveuiliteuZadru: 'Department of Geography - University of Zadar',
        OdjelzagermanistikuSveuiliteuZadru: 'Department of German Studies - University of Zadar',
        OdjelzahispanikuiiberskestudijeSveuiliteuZadru:
            'Department of Hispanic and Iberian Studies - University of Zadar',
        OdjelzahumanistikeznanostiSveuiliteuDubrovniku: 'Department of Humanities - University of Dubrovnik',
        OdjelzainformacijskeznanostiSveuiliteuZadru: 'Department of Information Sciences - University of Zadar',
        OdjelzaizobrazbuuiteljaiodgajateljaSveuiliteuZadru:
            'Department for the training of teachers and educators - University of Zadar',
        OdjelzaklasinufilologijuSveuiliteuZadru: 'Department of Classical Philology - University of Zadar',
        OdjelzakomunikologijuSveuiliteuDubrovniku: 'Department of Communication - University of Dubrovnik',
        OdjelzakroatistikuSveuiliteuZadru: 'Department of Croatian Studies - University of Zadar',
        OdjelzalingvistikuSveuiliteuZadru: 'Department of Linguistics - University of Zadar',
        OdjelzanastavnikestudijeuGospiuSveuiliteuZadru: 'Department of Teacher Studies in Gospić - University of Zadar',
        OdjelzapedagogijuSveuiliteuZadru: 'Department of Pedagogy - University of Zadar',
        OdjelzapovijestSveuiliteuZadru: 'Department of History - University of Zadar',
        OdjelzapovijestiumjetnostiSveuiliteuZadru: 'Department of Art History - University of Zadar',
        OdjelzaprimijenjenuekologijuSveuiliteuDubrovniku: 'Department of Applied Ecology - University of Dubrovnik',
        OdjelzaprirodneizdravstevnestudijeSveuiliteJurjaDobrileuPuli:
            'Department of Natural and Health Studies - Jurja Dobrila University in Pula',
        OdjelzapsihologijuSveuiliteuZadru: 'Department of Psychology - University of Zadar',
        OdjelzarusistikuSveuiliteuZadru: 'Department of Russian Studies - University of Zadar',
        OdjelzasociologijuSveuiliteuZadru: 'Department of Sociology - University of Zadar',
        OdjelzastrunestudijeSveuiliteuDubrovniku: 'Department of Professional Studies - University of Dubrovnik',
        OdjelzatalijanistikuSveuiliteuZadru: 'Department of Italian Studies - University of Zadar',
        OdjelzaturizamikomunikacijskeznanostiSveuiliteuZadru:
            'Department of Tourism and Communication Sciences - University of Zadar',
        OdjelzaumjetnostirestauracijuSveuiliteuDubrovniku:
            'Department of Art and Restoration - University of Dubrovnik',
        OdjelzazdravstvenestudijeSveuiliteuZadru: 'Department of Health Studies - University of Zadar',
        Policijskaakademija: 'Police Academy',
        PomorskifakultetSveuiliteuRijeci: 'Maritime Faculty - University of Rijeka',
        PomorskifakultetSveuiliteuSplitu: 'Maritime Faculty - University of Split',
        PomorskiodjelSveuiliteuDubrovniku: 'Maritime Department - University of Dubrovnik',
        PomorskiodjelSveuiliteuZadru: 'Maritime Department - University of Zadar',
        PoslovnoveleuiliteZagreb: 'Business Polytechnic Zagreb',
        PravnifakultetSveuiliteuOsijeku: 'Faculty of Law - University of Osijek',
        PravnifakultetSveuiliteuRijeci: 'Faculty of Law - University of Rijeka',
        PravnifakultetSveuiliteuSplitu: 'Faculty of Law - University of Split',
        PravnifakultetSveuiliteuZagrebu: 'Faculty of Law - University of Zagreb',
        PrehrambenobiotehnolokifakultetSveuiliteuZagrebu: 'Faculty of Food and Biotechnology - University of Zagreb',
        PrirodoslovnomatematikifakultetSveuiliteuSplitu: 'Faculty of Science and Mathematics - University of Split',
        PrirodoslovnomatematikifakultetSveuiliteuZagrebu: 'Faculty of Science and Mathematics - University of Zagreb',
        RITCroatia: 'RIT Croatia',
        RRiFVisokakolazafinancijskimenadment: 'RRiF College of Financial Management',
        RudarskogeolokonaftnifakultetRNGFSveuiliteuZagrebu:
            'Faculty of Mining, Geology and Petroleum (RNGF) - University of Zagreb',
        StomatolokifakultetSveuiliteuZagrebu: 'Faculty of Dentistry - University of Zagreb',
        StrojarskifakultetSveuiliteuSlavonskomBrodu: 'Faculty of Mechanical Engineering - University of Slavonski Brod',
        SveuilinicentarzaprotestantskuteologijuMatijaVlaiIlirikSveuiliteuZagrebu:
            'Matija Vlačić Ilirik University Center for Protestant Theology - University of Zagreb',
        SveuiliniodjelzaforenzineznanostiSveuiliteuSplitu:
            'University Department of Forensic Sciences - University of Split',
        SveuiliniodjelzastrunestudijeSveuiliteuSplitu:
            'University department for professional studies - University of Split',
        SveuiliniodjelzastudijemoraSveuiliteuSplitu: 'University Department of Marine Studies - University of Split',
        SveuiliniodjelzdravstvenihstudijaSveuiliteuSplitu:
            'University Department of Health Studies - University of Split',
        SveuiliteAlgebra: 'Algebra University',
        SveuiliteobraneisigurnostiDrFranjoTuman: 'University of Defense and Security "Dr. Franjo Tuđman"',
        SveuiliteSjever: 'University of the North',
        SveuiliteVERN: 'University of VERN',
        TehnikifakultetSveuiliteuRijeci: 'Faculty of Engineering - University of Rijeka',
        TehnikiodjelSveuiliteuSlavonskomBrodu: 'Technical Department - University of Slavonski Brod',
        Tehnikoveleuilite: 'Technical Polytechnic',
        TekstilnotehnolokifakultetTTFSveuiliteuZagrebu: 'Faculty of Textile Technology (TTF) - University of Zagreb',
        TeolokokatehetskiodjelSveuiliteuZadru: 'Theological and catechetical department - University of Zadar',
        UiteljskifakultetSveuiliteuRijeci: 'Faculty of Teachers - University of Rijeka',
        UiteljskifakultetSveuiliteuZagrebu: 'Faculty of Teachers - University of Zagreb',
        UmjetnikaakademijaSveuiliteuSplitu: 'Art Academy - University of Split',
        UNITEDPOPakademija: 'UNITEDPOP Academy',
        VeleuiliteLavoslavRuika: 'Polytechnic "Lavoslav Ružička"',
        VeleuiliteMarkoMaruli: 'Polytechnic "Marko Marulić"',
        VeleuiliteNikolaTesla: 'Polytechnic "Nikola Tesla"',
        VeleuiliteARCA: 'ARCA Polytechnic',
        VeleuiliteAspira: 'Aspira Polytechnic',
        VeleuiliteBaltazarZaprei: 'Polytechnic Baltazar Zaprešić',
        VeleuiliteHrvatskozagorjeKrapina: 'Croatian Zagorje Polytechnic Krapina',
        VeleuiliteIvaniGrad: 'Polytechnic Ivanić-Grad',
        VeleuilitemenadmentaisigurnostiSECURUS: 'SECURUS University of Management and Security',
        VeleuilitePAR: 'PAR Polytechnic',
        VeleuilitespravomjavnostiBALTAZARZAPREI: 'Polytechnic with public rights BALTAZAR ZAPREŠIĆ',
        Veleuilitestudijasigurnosti: 'University of Security Studies',
        Veleuilitesuvremenihinformacijskihtehnologija: 'Polytechnic of modern information technologies',
        VeleuiliteuBjelovaru: 'Polytechnic in Bjelovar',
        VeleuiliteuKarlovcu: 'Polytechnic in Karlovac',
        VeleuiliteuKrievcima: 'Polytechnic in Križevci',
        VeleuiliteuRijeci: 'Polytechnic in Rijeka',
        Veleuiliteuibeniku: 'Polytechnic in Šibenik',
        VeleuiliteuVirovitici: 'Polytechnic in Virovitica',
        VeleuiliteVelikaGorica: 'University of Velika Gorica',
        VeterinarskifakultetSveuiliteuZagrebu: 'Faculty of Veterinary Medicine - University of Zagreb',
        VSITEVisokakolazainformacijsketehnologijeuZagrebu: 'VSITE: College of Information Technologies in Zagreb',
        ZagrebakakolaekonomijeimanagementaZEM: 'Zagreb School of Economics and Management (ZŠEM)',
        ZdravstvenoveleuiliteuZagrebu: 'University of Health in Zagreb',
        MarywoodUniversity: 'Marywood University',
        LindenwoodUniversity: 'Lindenwood University',
        SullivanUniversity: 'Sullivan University',
        FloridaStateCollegeatJacksonville: 'Florida State College at Jacksonville',
        XavierUniversity: 'Xavier University',
        TusculumCollege: 'Tusculum College',
        ClaremontSchoolofTheology: 'Claremont School of Theology',
        ColumbiaCollegeSC: 'Columbia College (SC)',
        ChabotLasPositasCommunityCollegeDistrict: 'Chabot-Las Positas Community College District',
        KellerGraduateSchoolofManagement: 'Keller Graduate School of Management',
        MonroeCollege: 'Monroe College',
        SanMateoCountyCommunityCollegeDistrict: 'San Mateo County Community College District',
        LosRiosCommunityCollegeDistrict: 'Los Rios Community College District',
        DigiPenInstituteofTechnology: 'DigiPen Institute of Technology',
        UniversityofPittsburghMedicalCenter: 'University of Pittsburgh Medical Center',
        ClaremontGraduateUniversity: 'Claremont Graduate University',
        TheUniversityofTexasatRioGrandeValley: 'The University of Texas at Rio Grande Valley',
        CollegeofMountSaintVincent: 'College of Mount Saint Vincent',
        UniversityofArkansasSystemeVersity: 'University of Arkansas System eVersity',
        ECPIUniversity: 'ECPI University',
        UniversityofMaryWashington: 'University of Mary Washington',
        BaldwinWallaceUniversity: 'Baldwin Wallace University',
        CaliforniaStateUniversityChannelIslands: 'California State University Channel Islands',
        BrandmanUniversity: 'Brandman University',
        UnitedStatesCoastGuardAcademy: 'United States Coast Guard Academy',
        AthensStateUniversity: 'Athens State University',
        TheScrippsResearchInstitute: 'The Scripps Research Institute',
        EasternFloridaStateCollege: 'Eastern Florida State College',
        WesternNewEnglandUniversity: 'Western New England University',
        KingUniversity: 'King University',
        GeorgiaGwinnettCollege: 'Georgia Gwinnett College',
        TridentUniversity: 'Trident University',
        AlliantInternationalUniversity: 'Alliant International University',
        MississippiValleyStateUniversity: 'Mississippi Valley State University',
        RooseveltUniversity: 'Roosevelt University',
        ITTTechnicalInstitute: 'ITT Technical Institute',
        IllinoisEasternCommunityColleges: 'Illinois Eastern Community Colleges',
        ParkUniversity: 'Park University',
        IcahnSchoolofMedicineatMountSinai: 'Icahn School of Medicine at Mount Sinai',
        UtahValleyUniversity: 'Utah Valley University',
        WisconsinLutheranCollege: 'Wisconsin Lutheran College',
        RiversideCommunityCollegeDistrict: 'Riverside Community College District',
        WakeForestBaptistHealth: 'Wake Forest Baptist Health',
        UniversityofMassachusettsBoston: 'University of Massachusetts Boston',
        FloridaPolytechnicUniversity: 'Florida Polytechnic University',
        WagnerCollege: 'Wagner College',
        WilmingtonUniversity: 'Wilmington University',
        InternationalTechnologicalUniversity: 'International Technological University',
        YoungHarrisCollege: 'Young Harris College',
        UniversityofFindlay: 'University of Findlay',
        PhiladelphiaCollegeofOsteopathicMedicine: 'Philadelphia College of Osteopathic Medicine',
        YosemiteCommunityCollegeDistrict: 'Yosemite Community College District',
        CoastalAlabamaCommunityCollege: 'Coastal Alabama Community College',
        PurdueUniversityNorthwest: 'Purdue University Northwest',
        ColumbiaBasinCollege: 'Columbia Basin College',
        SeattleColleges: 'Seattle Colleges',
        LipscombUniversity: 'Lipscomb University',
        TiffinUniversity: 'Tiffin University',
        TroyUniversity: 'Troy University',
        CaliforniaBaptistUniversity: 'California Baptist University',
        StevensonUniversity: 'Stevenson University',
        MarineBiologicalLaboratory: 'Marine Biological Laboratory',
        MaryvilleUniversity: 'Maryville University',
        TheArtInstitutes: 'The Art Institutes',
        UniversityofMississippiMedicalCenter: 'University of Mississippi Medical Center',
        UniversityofCentralOklahoma: 'University of Central Oklahoma',
        HallmarkUniversity: 'Hallmark University',
        KeiserUniversity: 'Keiser University',
        StJohnsRiverStateCollege: 'St. Johns River State College',
        IndianRiverStateCollege: 'Indian River State College',
        BarnardCollege: 'Barnard College',
        CaliforniaSouthernUniversity: 'California Southern University',
        UniversityofLaVerne: 'University of La Verne',
        WashingtonJeffersonCollege: 'Washington & Jefferson College',
        UniversityofthePeople: 'University of the People',
        TouroCollege: 'Touro College',
        NewJerseyCityUniversity: 'New Jersey City University',
        LanderUniversity: 'Lander University',
        SUNYMaritimeCollege: 'SUNY Maritime College',
        LesleyUniversity: 'Lesley University',
        LaurusCollege: 'Laurus College',
        NationalParkCollege: 'National Park College',
        OklahomaChristianUniversity: 'Oklahoma Christian University',
        SanDiegoChristianCollege: 'San Diego Christian College',
        SANSTechnologyInstitute: 'SANS Technology Institute',
        PascoHernandoStateCollege: 'Pasco-Hernando State College',
        NewCollegeofFlorida: 'New College of Florida',
        GrandCanyonUniversity: 'Grand Canyon University',
        ColdSpringHarborLaboratory: 'Cold Spring Harbor Laboratory',
        AlbanyStateUniversity: 'Albany State University',
        LasellCollege: 'Lasell College',
        ContraCostaCommunityCollegeDistrict: 'Contra Costa Community College District',
        MountStMarysUniversity: "Mount St. Mary's University",
        RobertMorrisUniversityIllinois: 'Robert Morris University Illinois',
        NorthwestFloridaStateCollege: 'Northwest Florida State College',
        WestVirginiaWesleyanCollege: 'West Virginia Wesleyan College',
        UticaCollege: 'Utica College',
        CareerCollegeofNorthernNevada: 'Career College of Northern Nevada',
        CollegeofSouthernNevada: 'College of Southern Nevada',
        UniversityofNorthGeorgia: 'University of North Georgia',
        ThePrincipia: 'The Principia',
        LynnUniversity: 'Lynn University',
        ColoradoCommunityCollegeSystem: 'Colorado Community College System',
        ValenciaCollege: 'Valencia College',
        StPetersburgCollege: 'St. Petersburg College',
        GlobeUniversityMinnesotaSchoolofBusiness: 'Globe University & Minnesota School of Business',
        EverestCollege: 'Everest College',
        SouthwestResearchInstitute: 'Southwest Research Institute',
        AmericanPublicUniversitySystem: 'American Public University System',
        FullSailUniversity: 'Full Sail University',
        YTICareerInstitute: 'YTI Career Institute',
        DefenseLanguageInstituteForeignLanguageCenter: 'Defense Language Institute Foreign Language Center',
        ColumbusStateUniversity: 'Columbus State University',
        MoreheadStateUniversity: 'Morehead State University',
        ColoradoStateUniversityGlobalCampus: 'Colorado State University - Global Campus',
        NorthDakotaUniversitySystem: 'North Dakota University System',
        UniversityofHoustonDowntown: 'University of Houston-Downtown',
        StratfordUniversity: 'Stratford University',
        CaliforniaPolytechnicStateUniversityPomona: 'California Polytechnic State University Pomona',
        BakerCollege: 'Baker College',
        TheCollegeofNewJersey: 'The College of New Jersey',
        AbileneChristianUniversity: 'Abilene Christian University',
        AdelphiUniversity: 'Adelphi University',
        AgnesScottCollege: 'Agnes Scott College',
        AikenTechnicalCollege: 'Aiken Technical College',
        AirForceInstituteofTechnology: 'Air Force Institute of Technology',
        AlabamaAMUniversity: 'Alabama A&M University',
        AlabamaStateUniversity: 'Alabama State University',
        AlamoColleges: 'Alamo Colleges',
        AlbertsonCollegeofIdaho: 'Albertson College of Idaho',
        AlbionCollege: 'Albion College',
        AlfredUniversity: 'Alfred University',
        AlleghenyCollege: 'Allegheny College',
        AllentownCollegeofSaintFrancisdeSales: 'Allentown College of Saint Francis de Sales',
        AlmaCollege: 'Alma College',
        AlvernoCollege: 'Alverno College',
        AmbassadorUniversity: 'Ambassador University',
        AmericanCoastlineUniversity: 'American Coastline University',
        AmericanInternationalCollege: 'American International College',
        AmericanUniversity: 'American University',
        AmherstCollege: 'Amherst College',
        AndrewsUniversity: 'Andrews University',
        AngeloStateUniversity: 'Angelo State University',
        AnneArundelCommunityCollege: 'Anne Arundel Community College',
        AntiochNewEngland: 'Antioch New England',
        AntiochUniversity: 'Antioch University',
        AntiochUniversityLosAngeles: 'Antioch University - Los Angeles',
        AntiochUniversitySeattle: 'Antioch University - Seattle',
        AppalachianStateUniversity: 'Appalachian State University',
        AquinasCollege: 'Aquinas College',
        ArcadiaCollege: 'Arcadia College',
        ArizonaStateUniversity: 'Arizona State University',
        ArizonaWesternCollege: 'Arizona Western College',
        ArkansasStateUniversity: 'Arkansas State University',
        ArkansasTechUniversity: 'Arkansas Tech University',
        ArmstrongStateCollege: 'Armstrong State College',
        AshlandUniversity: 'Ashland University',
        AssumptionCollege: 'Assumption College',
        AuburnUniversity: 'Auburn University',
        AuburnUniversityatMontgomery: 'Auburn University at Montgomery',
        AugsburgCollege: 'Augsburg College',
        AugustanaCollegeIL: 'Augustana College (IL)',
        AugustanaCollegeSD: 'Augustana College (SD)',
        AugustaUniversity: 'Augusta University',
        AuroraUniversity: 'Aurora University',
        AustinCollege: 'Austin College',
        AustinCommunityCollege: 'Austin Community College',
        AustinPeayStateUniversity: 'Austin Peay State University',
        AverettCollege: 'Averett College',
        AvilaCollege: 'Avila College',
        AzusaPacificUniversity: 'Azusa Pacific University',
        BabsonCollege: 'Babson College',
        BakerUniversity: 'Baker University',
        BallStateUniversity: 'Ball State University',
        BaptistBibleCollege: 'Baptist Bible College',
        BardCollege: 'Bard College',
        BarryUniversity: 'Barry University',
        BastyrUniversity: 'Bastyr University',
        BatesCollege: 'Bates College',
        BaylorCollegeofMedicine: 'Baylor College of Medicine',
        BaylorUniversity: 'Baylor University',
        BellevueUniversity: 'Bellevue University',
        BelmontUniversity: 'Belmont University',
        BeloitCollege: 'Beloit College',
        BemidjiStateUniversity: 'Bemidji State University',
        BenedictineCollege: 'Benedictine College',
        BenningtonCollege: 'Bennington College',
        BentleyCollege: 'Bentley College',
        BereaCollege: 'Berea College',
        BerkleeCollegeofMusic: 'Berklee College of Music',
        BethanyCollege: 'Bethany College',
        BethelCollegeKS: 'Bethel College (KS)',
        BethelUniversity: 'Bethel University',
        BiolaUniversity: 'Biola University',
        BirminghamSouthernCollege: 'Birmingham-Southern College',
        BlackHillsStateUniversity: 'Black Hills State University',
        BloomsburgUniversityofPennsylvania: 'Bloomsburg University of Pennsylvania',
        BlufftonCollege: 'Bluffton College',
        BobJonesUniversity: 'Bob Jones University',
        BoiseStateUniversity: 'Boise State University',
        BostonCollege: 'Boston College',
        BostonGraduateSchoolofPsychoanalysis: 'Boston Graduate School of Psychoanalysis',
        BostonUniversity: 'Boston University',
        BowdoinCollege: 'Bowdoin College',
        BowieStateUniversity: 'Bowie State University',
        BowlingGreenStateUniversity: 'Bowling Green State University',
        BradleyUniversity: 'Bradley University',
        BrandeisUniversity: 'Brandeis University',
        BrenauUniversity: 'Brenau University',
        BriarCliffCollege: 'Briar Cliff College',
        BridgewaterCollege: 'Bridgewater College',
        BrighamYoungUniversity: 'Brigham Young University',
        BrighamYoungUniversityIdaho: 'Brigham Young University - Idaho',
        BrighamYoungUniversityHawaii: 'Brigham Young University Hawaii',
        BrowardCollege: 'Broward College',
        BrownUniversity: 'Brown University',
        BryantCollege: 'Bryant College',
        BrynMawrCollege: 'Bryn Mawr College',
        BucknellUniversity: 'Bucknell University',
        BuenaVistaUniversity: 'Buena Vista University',
        ButlerUniversity: 'Butler University',
        CaliforniaCoastUniversity: 'California Coast University',
        CaliforniaInstituteofTechnology: 'California Institute of Technology',
        CaliforniaLutheranUniversity: 'California Lutheran University',
        CaliforniaMaritimeAcademy: 'California Maritime Academy',
        CaliforniaNationalUniversity: 'California National University',
        CaliforniaPolytechnicStateUniversitySanLuisObispo: 'California Polytechnic State University San Luis Obispo',
        CaliforniaStateUniversityBakersfield: 'California State University Bakersfield',
        CaliforniaStateUniversityChico: 'California State University Chico',
        CaliforniaStateUniversityDominguezHills: 'California State University Dominguez Hills',
        CaliforniaStateUniversityFresno: 'California State University Fresno',
        CaliforniaStateUniversityFullerton: 'California State University Fullerton',
        CaliforniaStateUniversityHayward: 'California State University Hayward',
        CaliforniaStateUniversityLongBeach: 'California State University Long Beach',
        CaliforniaStateUniversityLosAngeles: 'California State University Los Angeles',
        CaliforniaStateUniversityMontereyBay: 'California State University Monterey Bay',
        CaliforniaStateUniversityNorthridge: 'California State University Northridge',
        CaliforniaStateUniversitySacramento: 'California State University Sacramento',
        CaliforniaStateUniversitySanBernardino: 'California State University San Bernardino',
        CaliforniaStateUniversitySanMarcos: 'California State University San Marcos',
        CaliforniaStateUniversityStanislaus: 'California State University Stanislaus',
        CaliforniaStateUniversitySystem: 'California State University System',
        CaliforniaUniversityofPennsylvania: 'California University of Pennsylvania',
        CalvinCollege: 'Calvin College',
        CameronUniversity: 'Cameron University',
        CampbellsvilleCollege: 'Campbellsville College',
        CampbellUniversity: 'Campbell University',
        CanisiusCollege: 'Canisius College',
        CapellaUniversity: 'Capella University',
        CapitolTechnologyUniversity: 'Capitol Technology University',
        CarletonCollege: 'Carleton College',
        CarlowCollege: 'Carlow College',
        CarnegieMellonUniversity: 'Carnegie Mellon University',
        CarrollCollege: 'Carroll College',
        CarrollUniversity: 'Carroll University',
        CarsonNewmanCollege: 'Carson-Newman College',
        CarthageCollege: 'Carthage College',
        CaseWesternReserveUniversity: 'Case Western Reserve University',
        CastletonStateUniversity: 'Castleton State University',
        CatholicUniversityofAmerica: 'Catholic University of America',
        CedarvilleCollege: 'Cedarville College',
        CentenaryCollegeofLouisiana: 'Centenary College of Louisiana',
        CentralCollege: 'Central College',
        CentralConnecticutStateUniversity: 'Central Connecticut State University',
        CentralMethodistCollege: 'Central Methodist College',
        CentralMichiganUniversity: 'Central Michigan University',
        CentralWashingtonUniversity: 'Central Washington University',
        CentreCollege: 'Centre College',
        ChadronStateCollege: 'Chadron State College',
        ChamplainCollege: 'Champlain College',
        ChapmanUniversity: 'Chapman University',
        ChathamCollege: 'Chatham College',
        ChesapeakeCollege: 'Chesapeake College',
        CheyneyUniversity: 'Cheyney University',
        ChicagoSchoolofProfessionalPsychology: 'Chicago School of Professional Psychology',
        ChristianBrothersUniversity: 'Christian Brothers University',
        ChristianTheologicalSeminary: 'Christian Theological Seminary',
        ChristopherNewportUniversity: 'Christopher Newport University',
        CityUniversity: 'City University',
        CityUniversityofNewYork: 'City University of New York',
        ClaremontGraduateSchool: 'Claremont Graduate School',
        ClaremontMcKennaCollege: 'Claremont McKenna College',
        ClarionUniversityofPennsylvania: 'Clarion University of Pennsylvania',
        ClarkeCollege: 'Clarke College',
        ClarksonUniversity: 'Clarkson University',
        ClarkUniversity: 'Clark University',
        ClaytonStateCollege: 'Clayton State College',
        ClemsonUniversity: 'Clemson University',
        ClevelandStateUniversity: 'Cleveland State University',
        ClinchValleyCollege: 'Clinch Valley College',
        CoastalCarolinaUniversity: 'Coastal Carolina University',
        CoastColleges: 'Coast Colleges',
        CoeCollege: 'Coe College',
        CokerCollege: 'Coker College',
        ColbyCollege: 'Colby College',
        ColgateUniversity: 'Colgate University',
        CollegeofCharleston: 'College of Charleston',
        CollegeofEasternUtah: 'College of Eastern Utah',
        CollegeofNewRochelle: 'College of New Rochelle',
        CollegeofSaintBenedict: 'College of Saint Benedict',
        CollegeofSaintCatherine: 'College of Saint Catherine',
        CollegeofSaintRose: 'College of Saint Rose',
        CollegeofStFrancis: 'College of St. Francis',
        CollegeofStScholastica: 'College of St. Scholastica',
        CollegeoftheAtlantic: 'College of the Atlantic',
        CollegeoftheHolyCross: 'College of the Holy Cross',
        CollegeofWilliamandMary: 'College of William and Mary',
        CollegeofWooster: 'College of Wooster',
        ColoradoChristianUniversity: 'Colorado Christian University',
        ColoradoCollege: 'Colorado College',
        ColoradoMesaUniversity: 'Colorado Mesa University',
        ColoradoSchoolofMines: 'Colorado School of Mines',
        ColoradoStateUniversity: 'Colorado State University',
        ColoradoTechnicalUniversity: 'Colorado Technical University',
        UniversityofColoradoAnschutzMedicalCampus: 'University of Colorado Anschutz Medical Campus',
        ColumbiaCollegeChicago: 'Columbia College Chicago',
        ColumbiaSouthernUniversity: 'Columbia Southern University',
        ColumbiaUnionCollege: 'Columbia Union College',
        ColumbiaUniversity: 'Columbia University',
        CommunityCollegeofBaltimoreCounty: 'Community College of Baltimore County',
        ConcordiaCollegeAnnArbor: 'Concordia College - Ann Arbor',
        ConcordiaCollegeMoorhead: 'Concordia College - Moorhead',
        ConcordiaCollegeSeward: 'Concordia College - Seward',
        ConcordiaCollegeStPaul: 'Concordia College - St. Paul',
        ConnecticutCollege: 'Connecticut College',
        ConnecticutStateUniversitySystem: 'Connecticut State University System',
        CooperUnionfortheAdvancementofScienceandArt: 'Cooper Union for the Advancement of Science and Art',
        CoppinStateUniversity: 'Coppin State University',
        AllenCollege: 'Allen College',
        CornellCollege: 'Cornell College',
        CornellUniversity: 'Cornell University',
        CorningCommunityCollege: 'Corning Community College',
        CreightonUniversity: 'Creighton University',
        CurryCollege: 'Curry College',
        DaemenCollege: 'Daemen College',
        DakotaStateUniversity: 'Dakota State University',
        DakotaWesleyanUniversity: 'Dakota Wesleyan University',
        DallasBaptistUniversity: 'Dallas Baptist University',
        DallasCountyCommunityCollege: 'Dallas County Community College',
        DanaCollege: 'Dana College',
        DanielWebsterCollege: 'Daniel Webster College',
        DartmouthCollege: 'Dartmouth College',
        DavenportUniversity: 'Davenport University',
        DavidsonCollege: 'Davidson College',
        DavisElkinsCollege: 'Davis & Elkins College',
        DaytonaStateCollege: 'Daytona State College',
        DelawareStateUniversity: 'Delaware State University',
        DeltaStateUniversity: 'Delta State University',
        DenisonUniversity: 'Denison University',
        DePaulUniversity: 'DePaul University',
        DePauwUniversity: 'DePauw University',
        DeVryInstituteofTechnology: 'DeVry Institute of Technology',
        DickinsonCollege: 'Dickinson College',
        DickinsonStateUniversity: 'Dickinson State University',
        DillardUniversity: 'Dillard University',
        DominicanCollege: 'Dominican College',
        DordtCollege: 'Dordt College',
        DowlingCollege: 'Dowling College',
        DrakeUniversity: 'Drake University',
        DrewUniversity: 'Drew University',
        DrexelUniversity: 'Drexel University',
        DruryCollege: 'Drury College',
        DuquesneUniversity: 'Duquesne University',
        EarlhamCollege: 'Earlham College',
        EastCarolinaUniversity: 'East Carolina University',
        EastCentralUniversity: 'East Central University',
        EasternConnecticutStateUniversity: 'Eastern Connecticut State University',
        EasternIllinoisUniversity: 'Eastern Illinois University',
        EasternKentuckyUniversity: 'Eastern Kentucky University',
        EasternMennoniteUniversity: 'Eastern Mennonite University',
        EasternMichiganUniversity: 'Eastern Michigan University',
        EasternNewMexicoUniversity: 'Eastern New Mexico University',
        EasternOregonStateCollege: 'Eastern Oregon State College',
        EasternWashingtonUniversity: 'Eastern Washington University',
        EastStroudsburgStateUniversityofPennsylvania: 'East Stroudsburg State University of Pennsylvania',
        EastTennesseeStateUniversity: 'East Tennessee State University',
        EdgewoodCollege: 'Edgewood College',
        EdinboroUniversityofPennsylvania: 'Edinboro University of Pennsylvania',
        ElizabethCityStateUniversity: 'Elizabeth City State University',
        ElizabethtownCollege: 'Elizabethtown College',
        ElmhurstCollege: 'Elmhurst College',
        ElonCollege: 'Elon College',
        EmbryRiddleAeronauticalUniversity: 'Embry-Riddle Aeronautical University',
        EmersonCollege: 'Emerson College',
        EmmanuelCollege: 'Emmanuel College',
        EmoryUniversity: 'Emory University',
        EmoryHenryCollege: 'Emory & Henry College',
        EmporiaStateUniversity: 'Emporia State University',
        ErieCommunityCollege: 'Erie Community College',
        LakeErieCollegeofOsteopathicMedicine: 'Lake Erie College of Osteopathic Medicine',
        EastTexasBaptistUniversity: 'East Texas Baptist University',
        EvergreenStateCollege: 'Evergreen State College',
        FairleighDickinsonUniversity: 'Fairleigh Dickinson University',
        FairmontStateCollege: 'Fairmont State College',
        FayettevilleStateUniversity: 'Fayetteville State University',
        FerrisStateUniversity: 'Ferris State University',
        FieldingInstitute: 'Fielding Institute',
        FiskUniversity: 'Fisk University',
        FitchburgStateCollege: 'Fitchburg State College',
        FloridaAgriculturalandMechanicalUniversity: 'Florida Agricultural and Mechanical University',
        FloridaAtlanticUniversity: 'Florida Atlantic University',
        FloridaGulfCoastUniversity: 'Florida Gulf Coast University',
        FloridaInstituteofTechnology: 'Florida Institute of Technology',
        FloridaInternationalUniversity: 'Florida International University',
        FloridaStateUniversity: 'Florida State University',
        FontbonneCollege: 'Fontbonne College',
        FordhamUniversity: 'Fordham University',
        FortHaysStateUniversity: 'Fort Hays State University',
        FortLewisCollege: 'Fort Lewis College',
        FountainheadCollegeofTechnology: 'Fountainhead College of Technology',
        FranklinandMarshallCollege: 'Franklin and Marshall College',
        FranklinPierceLawCenter: 'Franklin Pierce Law Center',
        FranklinUniversity: 'Franklin University',
        FrederickCommunityCollege: 'Frederick Community College',
        FresnoPacificUniversity: 'Fresno Pacific University',
        FriendsUniversity: 'Friends University',
        FrostburgStateUniversity: 'Frostburg State University',
        FullerTheologicalSeminary: 'Fuller Theological Seminary',
        FurmanUniversity: 'Furman University',
        GallaudetUniversity: 'Gallaudet University',
        GannonUniversity: 'Gannon University',
        GenevaCollege: 'Geneva College',
        GeorgeFoxCollege: 'George Fox College',
        GeorgeMasonUniversity: 'George Mason University',
        GeorgetownUniversity: 'Georgetown University',
        GeorgeWashingtonUniversity: 'George Washington University',
        GeorgiaCollege: 'Georgia College',
        GeorgiaInstituteofTechnology: 'Georgia Institute of Technology',
        GeorgianCourtUniversity: 'Georgian Court University',
        GeorgiaSouthernUniversity: 'Georgia Southern University',
        GeorgiaSouthwesternStateUniversity: 'Georgia Southwestern State University',
        GeorgiaStateUniversity: 'Georgia State University',
        GettysburgCollege: 'Gettysburg College',
        GMIEngineeringandManagementInstitute: 'GMI Engineering and Management Institute',
        GoldenGateUniversity: 'Golden Gate University',
        GoldeyBeacomCollege: 'Goldey-Beacom College',
        GonzagaUniversity: 'Gonzaga University',
        GoshenCollege: 'Goshen College',
        GoucherCollege: 'Goucher College',
        GovernorsStateUniversity: 'Governors State University',
        GraceCollege: 'Grace College',
        GracelandCollege: 'Graceland College',
        GrandValleyStateUniversity: 'Grand Valley State University',
        GreenleafUniversity: 'Greenleaf University',
        GrinnellCollege: 'Grinnell College',
        GuilfordCollege: 'Guilford College',
        GustavusAdolphusCollege: 'Gustavus Adolphus College',
        HagerstownCommunityCollege: 'Hagerstown Community College',
        HamiltonCollege: 'Hamilton College',
        HamlineUniversity: 'Hamline University',
        HampdenSydneyCollege: 'Hampden-Sydney College',
        HampshireCollege: 'Hampshire College',
        HamptonUniversity: 'Hampton University',
        HanoverCollege: 'Hanover College',
        HardingUniversity: 'Harding University',
        HarfordCommunityCollege: 'Harford Community College',
        HartwickCollege: 'Hartwick College',
        HarvardUniversity: 'Harvard University',
        HarveyMuddCollege: 'Harvey Mudd College',
        HaskellIndianNationsUniversity: 'Haskell Indian Nations University',
        HastingsCollege: 'Hastings College',
        HaverfordCollegeinPennsylvania: 'Haverford College in Pennsylvania',
        HawaiiPacificUniversity: 'Hawaii Pacific University',
        HeidelbergCollege: 'Heidelberg College',
        HendrixCollege: 'Hendrix College',
        HesstonCollege: 'Hesston College',
        HighPointUniversity: 'High Point University',
        HillsdaleCollege: 'Hillsdale College',
        HiramCollege: 'Hiram College',
        HobartandWilliamSmithColleges: 'Hobart and William Smith Colleges',
        HofstraUniversity: 'Hofstra University',
        HollinsCollege: 'Hollins College',
        HoodCollege: 'Hood College',
        HopeCollege: 'Hope College',
        HowardCommunityCollege: 'Howard Community College',
        HowardUniversity: 'Howard University',
        HumboldtStateUniversity: 'Humboldt State University',
        HuntingdonCollege: 'Huntingdon College',
        HuntingtonUniversity: 'Huntington University',
        ICIUniversity: 'ICI University',
        IdahoStateUniversity: 'Idaho State University',
        IllinoisBenedictineCollege: 'Illinois Benedictine College',
        IllinoisInstituteofTechnology: 'Illinois Institute of Technology',
        IllinoisStateUniversity: 'Illinois State University',
        IndianaInstituteofTechnology: 'Indiana Institute of Technology',
        IndianaStateUniversity: 'Indiana State University',
        IndianaUniversity: 'Indiana University',
        IndianaUniversityBloomington: 'Indiana University - Bloomington',
        IndianaUniversityatSouthBend: 'Indiana University at South Bend',
        IndianaUniversityofPennsylvania: 'Indiana University of Pennsylvania',
        IndianaUniversityPurdueUniversityatColumbus: 'Indiana University/Purdue University at Columbus',
        IndianaUniversityPurdueUniversityatFortWayne: 'Indiana University/Purdue University at Fort Wayne',
        IndianaUniversityPurdueUniversityatIndianapolis: 'Indiana University/Purdue University at Indianapolis',
        IndianaUniversitySoutheast: 'Indiana University Southeast',
        IndianaWesleyanUniversityMarion: 'Indiana Wesleyan University Marion',
        InstituteforWorldPolitics: 'Institute for World Politics',
        IonaCollege: 'Iona College',
        IowaStateUniversity: 'Iowa State University',
        IrvineValleyCollege: 'Irvine Valley College',
        IthacaCollege: 'Ithaca College',
        JacksonStateUniversity: 'Jackson State University',
        JacksonvilleStateUniversity: 'Jacksonville State University',
        JacksonvilleUniversity: 'Jacksonville University',
        JamesMadisonUniversity: 'James Madison University',
        JohnBrownUniversity: 'John Brown University',
        JohnFKennedyUniversity: 'John F. Kennedy University',
        JohnsHopkinsUniversity: 'Johns Hopkins University',
        JohnsHopkinsUniversityAppliedPhysicsLaboratory: 'Johns Hopkins University Applied Physics Laboratory',
        JohnsonWalesUniversity: 'Johnson & Wales University',
        JohnsonBibleCollege: 'Johnson Bible College',
        JohnsonCSmithUniversity: 'Johnson C. Smith University',
        JonesCollege: 'Jones College',
        JudsonCollege: 'Judson College',
        JuniataCollege: 'Juniata College',
        KalamazooCollege: 'Kalamazoo College',
        KansasStateUniversity: 'Kansas State University',
        KansasWesleyanUniversity: 'Kansas Wesleyan University',
        KaplanUniversity: 'Kaplan University',
        KeanCollege: 'Kean College',
        KeeneStateCollege: 'Keene State College',
        KennesawStateUniversity: 'Kennesaw State University',
        KentStateUniversity: 'Kent State University',
        KentuckyCommunityTechnicalCollegeSystem: 'Kentucky Community & Technical College System',
        KenyonCollege: 'Kenyon College',
        KetteringUniversity: 'Kettering University',
        KingsCollege: "King's College",
        KnoxCollege: 'Knox College',
        KutztownUniversityofPennsylvania: 'Kutztown University of Pennsylvania',
        LafayetteCollege: 'Lafayette College',
        LaGrangeCollege: 'LaGrange College',
        LakeForestCollege: 'Lake Forest College',
        LakeSuperiorCollege: 'Lake Superior College',
        LakeSuperiorStateUniversity: 'Lake Superior State University',
        LamarUniversity: 'Lamar University',
        LangstonUniversity: 'Langston University',
        LaSalleUniversity: 'LaSalle University',
        LaSierraUniversity: 'La Sierra University',
        LawrenceTechnologicalUniversity: 'Lawrence Technological University',
        LawrenceUniversity: 'Lawrence University',
        LebanonValleyCollege: 'Lebanon Valley College',
        LehighUniversity: 'Lehigh University',
        LeMoyneCollege: 'Le Moyne College',
        LenoirCommunityCollege: 'Lenoir Community College',
        LenoirRhyneCollege: 'Lenoir-Rhyne College',
        LeTourneauUniversity: 'LeTourneau University',
        LewisClarkCollege: 'Lewis & Clark College',
        LewisClarkStateCollege: 'Lewis-Clark State College',
        LewisUniversity: 'Lewis University',
        LibertyUniversity: 'Liberty University',
        LincolnUniversity: 'Lincoln University',
        LinfieldCollege: 'Linfield College',
        LockHavenUniversity: 'Lock Haven University',
        LomaLindaUniversity: 'Loma Linda University',
        LongIslandUniversity: 'Long Island University',
        LongwoodUniversity: 'Longwood University',
        LorasCollege: 'Loras College',
        LosAngelesCommunityCollegeDistrict: 'Los Angeles Community College District',
        LouisianaCollege: 'Louisiana College',
        LouisianaStateUniversity: 'Louisiana State University',
        LouisianaStateUniversityatAlexandria: 'Louisiana State University at Alexandria',
        LouisianaTechUniversity: 'Louisiana Tech University',
        LoyolaCollege: 'Loyola College',
        LoyolaMarymountUniversity: 'Loyola Marymount University',
        LoyolaUniversityChicago: 'Loyola University Chicago',
        LutherCollege: 'Luther College',
        LutherSeminary: 'Luther Seminary',
        LycomingCollege: 'Lycoming College',
        LynchburgCollege: 'Lynchburg College',
        LyndonStateCollege: 'Lyndon State College',
        LyonCollege: 'Lyon College',
        MacalesterCollege: 'Macalester College',
        MaharishiUniversityofManagement: 'Maharishi University of Management',
        MaloneCollege: 'Malone College',
        ManhattanCollege: 'Manhattan College',
        MansfieldUniversityofPennsylvania: 'Mansfield University of Pennsylvania',
        MariettaCollege: 'Marietta College',
        MaristCollege: 'Marist College',
        MarlboroCollege: 'Marlboro College',
        MarquetteUniversity: 'Marquette University',
        MarshallUniversity: 'Marshall University',
        MaryBaldwinCollege: 'Mary Baldwin College',
        MarymountManhattanCollege: 'Marymount Manhattan College',
        MarymountUniversity: 'Marymount University',
        MaryWashingtonCollege: 'Mary Washington College',
        MassachusettsInstituteofTechnology: 'Massachusetts Institute of Technology',
        McDanielCollege: 'McDaniel College',
        McMurryUniversity: 'McMurry University',
        McNeeseStateUniversity: 'McNeese State University',
        MedicalCollegeofGeorgia: 'Medical College of Georgia',
        MedicalCollegeofWisconsin: 'Medical College of Wisconsin',
        MercerCountyCommunityCollege: 'Mercer County Community College',
        MercerUniversity: 'Mercer University',
        MercyCollege: 'Mercy College',
        MercyhurstUniversity: 'Mercyhurst University',
        MeredithCollege: 'Meredith College',
        MessiahCollege: 'Messiah College',
        MetropolitanCommunityCollegeMO: 'Metropolitan Community College (MO)',
        MetropolitanCommunityCollegeNE: 'Metropolitan Community College (NE)',
        MetropolitanStateCollegeofDenver: 'Metropolitan State College of Denver',
        DenverCollegeofNursing: 'Denver College of Nursing',
        MetropolitanStateUniversity: 'Metropolitan State University',
        MiamiUniversityofOhio: 'Miami University of Ohio',
        MichiganStateUniversity: 'Michigan State University',
        MichiganTechnologicalUniversity: 'Michigan Technological University',
        MidAmericaNazareneCollege: 'Mid-America Nazarene College',
        MiddleburyCollege: 'Middlebury College',
        MiddleGeorgiaStateCollege: 'Middle Georgia State College',
        MiddleTennesseeStateUniversity: 'Middle Tennessee State University',
        MidwesternStateUniversity: 'Midwestern State University',
        MillersvilleUniversityofPennsylvania: 'Millersville University of Pennsylvania',
        MilliganCollege: 'Milligan College',
        MillikinUniversity: 'Millikin University',
        MillsapsCollege: 'Millsaps College',
        MilwaukeeSchoolofEngineering: 'Milwaukee School of Engineering',
        MinneapolisCollegeofArtandDesign: 'Minneapolis College of Art and Design',
        MinnesotaStateUniversityMankato: 'Minnesota State University Mankato',
        MinnesotaStateUniversityMoorhead: 'Minnesota State University Moorhead',
        MinotStateUniversity: 'Minot State University',
        MississippiCollege: 'Mississippi College',
        MississippiStateUniversity: 'Mississippi State University',
        MississippiUniversityforWomen: 'Mississippi University for Women',
        MissouriSouthernStateCollege: 'Missouri Southern State College',
        MissouriUniversityofScienceandTechnology: 'Missouri University of Science and Technology',
        MissouriWesternStateCollege: 'Missouri Western State College',
        MolloyCollege: 'Molloy College',
        MonmouthCollege: 'Monmouth College',
        MonmouthUniversity: 'Monmouth University',
        MontanaStateUniversityBillings: 'Montana State University - Billings',
        MontanaStateUniversityBozeman: 'Montana State University - Bozeman',
        MontanaStateUniversityNorthern: 'Montana State University - Northern',
        MontanaTech: 'Montana Tech',
        MontclairStateUniversity: 'Montclair State University',
        MontgomeryCollege: 'Montgomery College',
        MontreatCollege: 'Montreat College',
        MoravianCollege: 'Moravian College',
        MorganStateUniversity: 'Morgan State University',
        MottCommunityCollege: 'Mott Community College',
        MountHolyokeCollege: 'Mount Holyoke College',
        MountSaintJosephCollege: 'Mount Saint Joseph College',
        MountSaintMaryCollege: 'Mount Saint Mary College',
        MountSenarioCollege: 'Mount Senario College',
        MountUnionCollege: 'Mount Union College',
        MurrayStateUniversity: 'Murray State University',
        MuskingumCollege: 'Muskingum College',
        NashvilleStateCommunityCollege: 'Nashville State Community College',
        NationalDefenseUniversity: 'National Defense University',
        NationalLouisUniversity: 'National-Louis University',
        NationalRadioAstronomyObservatory: 'National Radio Astronomy Observatory',
        NationalUniversity: 'National University',
        NavalPostgraduateSchool: 'Naval Postgraduate School',
        NazarethCollege: 'Nazareth College',
        NevadaSystemofHigherEducation: 'Nevada System of Higher Education',
        NewberryCollege: 'Newberry College',
        NewCollegeofCalifornia: 'New College of California',
        NewEnglandInstituteofTechnology: 'New England Institute of Technology',
        NewHampshireCollege: 'New Hampshire College',
        NewJerseyInstituteofTechnology: 'New Jersey Institute of Technology',
        NewMexicoHighlandsUniversity: 'New Mexico Highlands University',
        NewMexicoInstituteofMiningTechnology: 'New Mexico Institute of Mining & Technology',
        NewMexicoStateUniversity: 'New Mexico State University',
        NewYorkInstituteofTechnology: 'New York Institute of Technology',
        NewYorkUniversity: 'New York University',
        NiagaraUniversity: 'Niagara University',
        NichollsStateUniversity: 'Nicholls State University',
        NorfolkStateUniversity: 'Norfolk State University',
        NorthAmericanUniversity: 'North American University',
        NorthamptonCommunityCollege: 'Northampton Community College',
        NorthCarolinaATStateUniversity: 'North Carolina A&T State University',
        NorthCarolinaCentralUniversity: 'North Carolina Central University',
        NorthCarolinaStateUniversity: 'North Carolina State University',
        NorthCarolinaWesleyanCollege: 'North Carolina Wesleyan College',
        NorthCentralBibleCollege: 'North Central Bible College',
        NorthDakotaStateUniversity: 'North Dakota State University',
        NortheasternLouisianaUniversity: 'Northeastern Louisiana University',
        NortheasternStateUniversity: 'Northeastern State University',
        NortheasternUniversity: 'Northeastern University',
        NorthernArizonaUniversity: 'Northern Arizona University',
        NorthernIllinoisUniversity: 'Northern Illinois University',
        NorthernKentuckyUniversity: 'Northern Kentucky University',
        NorthernMichiganUniversity: 'Northern Michigan University',
        NorthernStateUniversity: 'Northern State University',
        NorthlandCollege: 'Northland College',
        NorthParkCollegeandTheologicalSeminary: 'North Park College and Theological Seminary',
        NorthwesternCollegeofIowa: 'Northwestern College of Iowa',
        NorthwesternStateUniversity: 'Northwestern State University',
        NorthwesternUniversity: 'Northwestern University',
        NorthwestMissouriStateUniversity: 'Northwest Missouri State University',
        NorthwestNazareneCollege: 'Northwest Nazarene College',
        NorwichUniversity: 'Norwich University',
        NovaSoutheasternUniversity: 'Nova Southeastern University',
        OaklandUniversity: 'Oakland University',
        OakwoodUniversity: 'Oakwood University',
        OberlinCollege: 'Oberlin College',
        OccidentalCollege: 'Occidental College',
        OhioDominicanCollege: 'Ohio Dominican College',
        OhioNorthernUniversity: 'Ohio Northern University',
        OhioStateUniversityColumbus: 'Ohio State University - Columbus',
        OhioUniversity: 'Ohio University',
        OhioWesleyanUniversity: 'Ohio Wesleyan University',
        OklahomaBaptistUniversity: 'Oklahoma Baptist University',
        OklahomaCityUniversity: 'Oklahoma City University',
        OklahomaStateUniversity: 'Oklahoma State University',
        OldDominionUniversity: 'Old Dominion University',
        OlivetNazareneUniversity: 'Olivet Nazarene University',
        OralRobertsUniversity: 'Oral Roberts University',
        OregonGraduateInstituteofScienceandTechnology: 'Oregon Graduate Institute of Science and Technology',
        OregonHealthSciencesUniversity: 'Oregon Health Sciences University',
        OregonInstituteofTechnology: 'Oregon Institute of Technology',
        OregonStateUniversity: 'Oregon State University',
        OtterbeinCollege: 'Otterbein College',
        OurLadyoftheLakeUniversity: 'Our Lady of the Lake University',
        PaceUniversity: 'Pace University',
        PacificLutheranUniversity: 'Pacific Lutheran University',
        PacificUnionCollege: 'Pacific Union College',
        PacificUniversity: 'Pacific University',
        PacificWesternUniversity: 'Pacific Western University',
        PalmBeachAtlanticCollege: 'Palm Beach Atlantic College',
        PalmBeachStateCollege: 'Palm Beach State College',
        PeaceCollege: 'Peace College',
        PembrokeStateUniversity: 'Pembroke State University',
        PennsylvaniaCollegeofTechnology: 'Pennsylvania College of Technology',
        PennsylvaniaStateUniversity: 'Pennsylvania State University',
        PennsylvaniaStateUniversitySchuylkillCampus: 'Pennsylvania State University - Schuylkill Campus',
        PensacolaChristianCollege: 'Pensacola Christian College',
        PensacolaStateCollege: 'Pensacola State College',
        PepperdineUniversity: 'Pepperdine University',
        PeruStateCollege: 'Peru State College',
        PurdueUniversityFortWayne: 'Purdue University Fort Wayne',
        PhiladelphiaUniversity: 'Philadelphia University',
        PhillipsUniversity: 'Phillips University',
        PittsburgStateUniversity: 'Pittsburg State University',
        PitzerCollege: 'Pitzer College',
        PlattCollege: 'Platt College',
        PlymouthStateCollege: 'Plymouth State College',
        PolytechnicUniversityofNewYork: 'Polytechnic University of New York',
        PolytechnicUniversityofPuertoRico: 'Polytechnic University of Puerto Rico',
        PomonaCollege: 'Pomona College',
        PortlandCommunityCollege: 'Portland Community College',
        PortlandStateUniversity: 'Portland State University',
        PrairieViewAMUniversity: 'Prairie View A&M University',
        PrattInstitute: 'Pratt Institute',
        PresbyterianCollege: 'Presbyterian College',
        PrescottCollege: 'Prescott College',
        PrincetonUniversity: 'Princeton University',
        ProvidenceCollege: 'Providence College',
        PurdueUniversity: 'Purdue University',
        PurdueUniversityCalumet: 'Purdue University Calumet',
        PurdueUniversityNorthCentral: 'Purdue University North Central',
        QuincyUniversity: 'Quincy University',
        QuinnipiacCollege: 'Quinnipiac College',
        RadfordUniversity: 'Radford University',
        RamapoCollege: 'Ramapo College',
        RandolphMaconCollege: 'Randolph-Macon College',
        RandolphMaconWomansCollege: "Randolph-Macon Woman's College",
        ReedCollege: 'Reed College',
        RegentUniversity: 'Regent University',
        RegisUniversity: 'Regis University',
        RensselaerPolytechnicInstitute: 'Rensselaer Polytechnic Institute',
        RhodeIslandCollege: 'Rhode Island College',
        RhodesCollege: 'Rhodes College',
        RiceUniversity: 'Rice University',
        RichardStocktonCollegeofNewJersey: 'Richard Stockton College of New Jersey',
        RiderUniversity: 'Rider University',
        RiponCollege: 'Ripon College',
        RivierCollege: 'Rivier College',
        RoanokeCollege: 'Roanoke College',
        RobertMorrisUniversity: 'Robert Morris University',
        RochesterInstituteofTechnology: 'Rochester Institute of Technology',
        RockefellerUniversity: 'Rockefeller University',
        RockfordCollege: 'Rockford College',
        RockhurstCollege: 'Rockhurst College',
        RockyMountainCollege: 'Rocky Mountain College',
        RogerWilliamsUniversity: 'Roger Williams University',
        RollinsCollege: 'Rollins College',
        RosaryCollege: 'Rosary College',
        RoseHulmanInstituteofTechnology: 'Rose-Hulman Institute of Technology',
        RowanCollege: 'Rowan College',
        RutgersUniversity: 'Rutgers University',
        SacredHeartUniversity: 'Sacred Heart University',
        SageColleges: 'Sage Colleges',
        SaginawValleyStateUniversity: 'Saginaw Valley State University',
        SaintAnselmCollege: 'Saint Anselm College',
        SaintCloudStateUniversity: 'Saint Cloud State University',
        SaintEdwardsUniversity: "Saint Edward's University",
        StFrancisCollege: 'St. Francis College',
        SaintJohnsUniversityNY: "Saint John's University (NY)",
        SaintJosephsCollegeIN: "Saint Joseph's College (IN)",
        SaintJosephsUniversity: "Saint Joseph's University",
        SaintLeoUniversity: 'Saint Leo University',
        SaintLouisUniversity: 'Saint Louis University',
        SaintMaryCollege: 'Saint Mary College',
        SaintMarysCollegeIN: "Saint Mary's College (IN)",
        SaintMarysCollegeofCalifornia: "Saint Mary's College of California",
        SaintMarysUniversityofMinnesota: "Saint Mary's University of Minnesota",
        SaintMichaelsCollege: "Saint Michael's College",
        SaintOlafCollege: 'Saint Olaf College',
        SaintVincentCollege: 'Saint Vincent College',
        SaintXavierUniversity: 'Saint Xavier University',
        SalisburyUniversity: 'Salisbury University',
        SalishKootenaiCollege: 'Salish Kootenai College',
        SamfordUniversity: 'Samford University',
        SamHoustonStateUniversity: 'Sam Houston State University',
        SanDiegoStateUniversity: 'San Diego State University',
        SanFranciscoStateUniversity: 'San Francisco State University',
        SanJoseStateUniversity: 'San Jose State University',
        SantaClaraUniversity: 'Santa Clara University',
        SarahLawrenceCollege: 'Sarah Lawrence College',
        SchooloftheArtInstituteofChicago: 'School of the Art Institute of Chicago',
        SeattleCentralCollege: 'Seattle Central College',
        SeattlePacificUniversity: 'Seattle Pacific University',
        SeattleUniversity: 'Seattle University',
        SetonHallUniversity: 'Seton Hall University',
        SewaneeUniversityoftheSouth: 'Sewanee University of the South',
        ShawneeStateUniversity: 'Shawnee State University',
        ShenandoahUniversity: 'Shenandoah University',
        ShippensburgUniversityofPennsylvania: 'Shippensburg University of Pennsylvania',
        ShorterCollege: 'Shorter College',
        SimmonsCollege: 'Simmons College',
        SimonsRockCollege: "Simon's Rock College",
        SimpsonCollege: 'Simpson College',
        SinclairCommunityCollege: 'Sinclair Community College',
        SkidmoreCollege: 'Skidmore College',
        SlipperyRockUniversityofPennsylvania: 'Slippery Rock University of Pennsylvania',
        SmithCollege: 'Smith College',
        SonomaStateUniversity: 'Sonoma State University',
        SouthDakotaSchoolofMinesandTechnology: 'South Dakota School of Mines and Technology',
        SouthDakotaStateUniversity: 'South Dakota State University',
        SoutheasternLouisianaUniversity: 'Southeastern Louisiana University',
        SoutheastMissouriStateUniversity: 'Southeast Missouri State University',
        SouthernCollege: 'Southern College',
        SouthernCollegeofTechnology: 'Southern College of Technology',
        SouthernConnecticutStateUniversity: 'Southern Connecticut State University',
        SouthernIllinoisUniversityCarbondale: 'Southern Illinois University-Carbondale',
        SouthernIllinoisUniversityEdwardsville: 'Southern Illinois University-Edwardsville',
        SouthernMethodistUniversity: 'Southern Methodist University',
        SouthernNazareneUniversity: 'Southern Nazarene University',
        SouthernNewHampshireUniversity: 'Southern New Hampshire University',
        SouthernOregonStateCollege: 'Southern Oregon State College',
        SouthernUniversityandAMCollege: 'Southern University and A&M College',
        SouthernUtahUniversity: 'Southern Utah University',
        SouthTexasCollegeofLaw: 'South Texas College of Law',
        SouthwestBaptistUniversity: 'Southwest Baptist University',
        SouthwesternAdventistCollege: 'Southwestern Adventist College',
        SouthwesternAssembliesofGodUniversity: 'Southwestern Assemblies of God University',
        SouthwesternCollegeKS: 'Southwestern College (KS)',
        SouthwesternUniversity: 'Southwestern University',
        SouthwestMissouriStateUniversity: 'Southwest Missouri State University',
        SouthwestTexasStateUniversity: 'Southwest Texas State University',
        SpelmanCollege: 'Spelman College',
        SpringArborCollege: 'Spring Arbor College',
        SpringHillCollege: 'Spring Hill College',
        StAmbroseUniversity: 'St. Ambrose University',
        StAndrewsPresbyterianCollege: 'St. Andrews Presbyterian College',
        StanfordUniversity: 'Stanford University',
        StateUniversityofNewYorkatAlbany: 'State University of New York at Albany',
        StateUniversityofNewYorkatBinghamton: 'State University of New York at Binghamton',
        StateUniversityofNewYorkatBuffalo: 'State University of New York at Buffalo',
        StateUniversityofNewYorkatFarmingdale: 'State University of New York at Farmingdale',
        StateUniversityofNewYorkatFredonia: 'State University of New York at Fredonia',
        StateUniversityofNewYorkatOswego: 'State University of New York at Oswego',
        StateUniversityofNewYorkatPlattsburgh: 'State University of New York at Plattsburgh',
        StateUniversityofNewYorkatStonyBrook: 'State University of New York at Stony Brook',
        AcademicmedicalcenteratStateUniversityofNewYorkatStonyBrook:
            'Academic medical center at State University of New York at Stony Brook',
        StateUniversityofNewYorkCollegeatBrockport: 'State University of New York College at Brockport',
        StateUniversityofNewYorkCollegeatBuffalo: 'State University of New York College at Buffalo',
        StateUniversityofNewYorkCollegeatCortland: 'State University of New York College at Cortland',
        StateUniversityofNewYorkCollegeatGeneseo: 'State University of New York College at Geneseo',
        StateUniversityofNewYorkCollegeatNewPaltz: 'State University of New York College at New Paltz',
        StateUniversityofNewYorkCollegeatPotsdam: 'State University of New York College at Potsdam',
        StateUniversityofNewYorkCollegeofAgricultureandTechnologyatCobleskill:
            'State University of New York College of Agriculture and Technology at Cobleskill',
        StateUniversityofNewYorkCollegeofEnvironmentalScienceandForestry:
            'State University of New York College of Environmental Science and Forestry',
        StateUniversityofNewYorkCollegeofTechnologyatAlfred:
            'State University of New York College of Technology at Alfred',
        StateUniversityofNewYorkPolytechnicInstitute: 'State University of New York Polytechnic Institute',
        StateUniversityofNewYorkSystem: 'State University of New York System',
        StBonaventureUniversity: 'St. Bonaventure University',
        StephenFAustinStateUniversity: 'Stephen F. Austin State University',
        StephensCollege: 'Stephens College',
        StetsonUniversity: 'Stetson University',
        StevensInstituteofTechnology: 'Stevens Institute of Technology',
        StJohnsCollegeAnnapolis: "St. John's College - Annapolis",
        StJohnsCollegeSantaFe: "St. John's College - Santa Fe",
        StJosephCollegeCT: 'St. Joseph College (CT)',
        StJosephsCollegeME: "St. Joseph's College (ME)",
        StLawrenceUniversity: 'St. Lawrence University',
        StLouisCollegeofPharmacy: 'St. Louis College of Pharmacy',
        StMartinsCollege: "St. Martin's College",
        StMarysCollegeofMaryland: "St. Mary's College of Maryland",
        StrayerCollege: 'Strayer College',
        StThomasUniversityFL: 'St. Thomas University (FL)',
        SuffolkUniversity: 'Suffolk University',
        SulRossStateUniversity: 'Sul Ross State University',
        SummitUniversityofLouisiana: 'Summit University of Louisiana',
        SusquehannaUniversity: 'Susquehanna University',
        SwarthmoreCollege: 'Swarthmore College',
        SweetBriarCollege: 'Sweet Briar College',
        SyracuseUniversity: 'Syracuse University',
        TaborCollege: 'Tabor College',
        TarletonStateUniversity: 'Tarleton State University',
        TaylorUniversity: 'Taylor University',
        TempleUniversity: 'Temple University',
        TennesseeStateUniversity: 'Tennessee State University',
        TennesseeTechnologicalUniversity: 'Tennessee Technological University',
        TarrantCountyCollege: 'Tarrant County College',
        TexasAMInternationalUniversity: 'Texas A&M International University',
        TexasAMUniversityCollegeStation: 'Texas A&M University - College Station',
        TexasAMUniversityCommerce: 'Texas A&M University - Commerce',
        TexasAMUniversityCorpusChristi: 'Texas A&M University - Corpus Christi',
        TexasAMUniversityKingsville: 'Texas A&M University - Kingsville',
        TexasChristianUniversity: 'Texas Christian University',
        TexasSouthernUniversity: 'Texas Southern University',
        TexasStateUniversity: 'Texas State University',
        TexasTechUniversity: 'Texas Tech University',
        TexasTechUniversityHealthSciencesCenter: 'Texas Tech University-Health Sciences Center',
        TexasWomansUniversity: "Texas Woman's University",
        TheCitadel: 'The Citadel',
        TheJewishTheologicalSeminary: 'The Jewish Theological Seminary',
        ThomasCollege: 'Thomas College',
        ThomasEdisonStateUniversity: 'Thomas Edison State University',
        ThomasJeffersonUniversity: 'Thomas Jefferson University',
        ThomasMoreCollege: 'Thomas More College',
        ThunderbirdSchoolofGlobalManagement: 'Thunderbird School of Global Management',
        TowsonUniversity: 'Towson University',
        TransylvaniaUniversity: 'Transylvania University',
        TrentonStateCollege: 'Trenton State College',
        TrineUniversity: 'Trine University',
        TrinityCollegeCT: 'Trinity College (CT)',
        TrinityCollegeDC: 'Trinity College (DC)',
        TrinityUniversity: 'Trinity University',
        TroyStateUniversity: 'Troy State University',
        TrumanStateUniversity: 'Truman State University',
        TucsonUniversity: 'Tucson University',
        TuftsUniversity: 'Tufts University',
        TulaneUniversity: 'Tulane University',
        TuskegeeUniversity: 'Tuskegee University',
        UniformedServicesUniversityoftheHealthSciences: 'Uniformed Services University of the Health Sciences',
        UnionCollege: 'Union College',
        UnionInstitute: 'Union Institute',
        UnionUniversity: 'Union University',
        UnitedStatesAirForceAcademy: 'United States Air Force Academy',
        UnitedStatesInternationalUniversity: 'United States International University',
        UnitedStatesMerchantMarineAcademy: 'United States Merchant Marine Academy',
        UnitedStatesMilitaryAcademy: 'United States Military Academy',
        UnitedStatesNavalAcademy: 'United States Naval Academy',
        UniversityofAdvancingTechnology: 'University of Advancing Technology',
        UniversityofAkron: 'University of Akron',
        UniversityofAlabamaatBirmingham: 'University of Alabama at Birmingham',
        UniversityofAlabamaatHuntsville: 'University of Alabama at Huntsville',
        UniversityofAlaska: 'University of Alaska',
        UniversityofAlaskaAnchorage: 'University of Alaska - Anchorage',
        UniversityofAlaskaFairbanks: 'University of Alaska - Fairbanks',
        UniversityofAlaskaSoutheast: 'University of Alaska - Southeast',
        UniversityofArizona: 'University of Arizona',
        UniversityofArkansasFayetteville: 'University of Arkansas - Fayetteville',
        UniversityofArkansasFortSmith: 'University of Arkansas - Fort Smith',
        UniversityofArkansasforMedicalSciences: 'University of Arkansas for Medical Sciences',
        UniversityofArkansasLittleRock: 'University of Arkansas - Little Rock',
        UniversityofArkansasMonticello: 'University of Arkansas - Monticello',
        UniversityofBaltimore: 'University of Baltimore',
        UniversityofBridgeport: 'University of Bridgeport',
        UniversityofCaliforniaBerkeley: 'University of California Berkeley',
        UniversityofCaliforniaDavis: 'University of California Davis',
        UniversityofCaliforniaIrvine: 'University of California Irvine',
        UniversityofCaliforniaLosAngeles: 'University of California Los Angeles',
        UniversityofCaliforniaMerced: 'University of California Merced',
        UniversityofCaliforniaOfficeofthePresident: 'University of California Office of the President',
        UniversityofCaliforniaRiverside: 'University of California Riverside',
        UniversityofCaliforniaSanDiego: 'University of California San Diego',
        UniversityofCaliforniaSanFrancisco: 'University of California San Francisco',
        UniversityofCaliforniaSantaBarbara: 'University of California Santa Barbara',
        UniversityofCaliforniaSantaCruz: 'University of California Santa Cruz',
        UniversityofCentralArkansas: 'University of Central Arkansas',
        UniversityofCentralFlorida: 'University of Central Florida',
        UniversityofCentralMissouri: 'University of Central Missouri',
        UCHastingsCollegeoftheLaw: 'UC Hastings College of the Law',
        UniversityofCharleston: 'University of Charleston',
        UniversityofChicago: 'University of Chicago',
        UniversityofCincinnati: 'University of Cincinnati',
        UniversityofColoradoatBoulder: 'University of Colorado at Boulder',
        UniversityofColoradoatColoradoSprings: 'University of Colorado at Colorado Springs',
        UniversityofColoradoatDenver: 'University of Colorado at Denver',
        UniversityofColoradoHealthSciencesCenter: 'University of Colorado Health Sciences Center',
        UniversityofConnecticut: 'University of Connecticut',
        UniversityofDallas: 'University of Dallas',
        UniversityofDayton: 'University of Dayton',
        UniversityofDelaware: 'University of Delaware',
        UniversityofDenver: 'University of Denver',
        UniversityofDetroitMercy: 'University of Detroit Mercy',
        UniversityofDubuque: 'University of Dubuque',
        UniversityofEvansville: 'University of Evansville',
        UniversityofFlorida: 'University of Florida',
        UniversityofGeorgia: 'University of Georgia',
        UniversityofGreatFalls: 'University of Great Falls',
        UniversityofHartford: 'University of Hartford',
        UniversityofHawaiiatHilo: 'University of Hawaii at Hilo',
        UniversityofHawaiiatManoa: 'University of Hawaii at Manoa',
        UniversityofHawaiiSystem: 'University of Hawaii System',
        UniversityofHouston: 'University of Houston',
        UniversityofIdaho: 'University of Idaho',
        UniversityofIllinoisChicago: 'University of Illinois Chicago',
        UniversityofIllinoisSpringfield: 'University of Illinois Springfield',
        UniversityofIllinoisUrbanaChampaign: 'University of Illinois Urbana-Champaign',
        UniversityofIndianapolis: 'University of Indianapolis',
        UniversityofIowa: 'University of Iowa',
        UniversityofJamestown: 'University of Jamestown',
        UniversityofKansas: 'University of Kansas',
        UniversityofKansasSchoolofMedicine: 'University of Kansas School of Medicine',
        UniversityofKentucky: 'University of Kentucky',
        UniversityofLouisianaatLafeyette: 'University of Louisiana at Lafeyette',
        UniversityofLouisianaatMonroe: 'University of Louisiana at Monroe',
        UniversityofLouisville: 'University of Louisville',
        UniversityofMaine: 'University of Maine',
        UniversityofMaineatFarmington: 'University of Maine at Farmington',
        UniversityofMaineatFortKent: 'University of Maine at Fort Kent',
        UniversityofMaineatMachias: 'University of Maine at Machias',
        UniversityofMaineatPresqueIsland: 'University of Maine at Presque Island',
        UniversityofMaineSystem: 'University of Maine System',
        UniversityofMarylandBaltimore: 'University of Maryland Baltimore',
        MayoClinic: 'Mayo Clinic',
        UniversityofMarylandBaltimoreCounty: 'University of Maryland Baltimore County',
        UniversityofMarylandCollegePark: 'University of Maryland College Park',
        UniversityofMarylandEasternShore: 'University of Maryland Eastern Shore',
        UniversityofMarylandUniversityCollege: 'University of Maryland - University College',
        UniversityofMassachusettsatAmherst: 'University of Massachusetts at Amherst',
        UniversityofMassachusettsatDartmouth: 'University of Massachusetts at Dartmouth',
        UniversityofMassachusettsatLowell: 'University of Massachusetts at Lowell',
        UniversityofMemphis: 'University of Memphis',
        UniversityofMiami: 'University of Miami',
        UniversityofMichiganAnnArbor: 'University of Michigan - Ann Arbor',
        UniversityofMichiganDearborn: 'University of Michigan - Dearborn',
        UniversityofMinnesota: 'University of Minnesota',
        UniversityofMinnesotaCrookston: 'University of Minnesota - Crookston',
        UniversityofMinnesotaDuluth: 'University of Minnesota - Duluth',
        UniversityofMinnesotaMorris: 'University of Minnesota - Morris',
        UniversityofMississippi: 'University of Mississippi',
        UniversityofMissouri: 'University of Missouri',
        UniversityofMissouriKansasCity: 'University of Missouri - Kansas City',
        UniversityofMissouriRolla: 'University of Missouri - Rolla',
        UniversityofMissouriSaintLouis: 'University of Missouri - Saint Louis',
        UniversityofMontana: 'University of Montana',
        UniversityofNebraskaKearney: 'University of Nebraska Kearney',
        UniversityofNebraskaLincoln: 'University of Nebraska Lincoln',
        UniversityofNebraskaOmaha: 'University of Nebraska Omaha',
        UniversityofNevadaLasVegas: 'University of Nevada Las Vegas',
        UniversityofNevadaReno: 'University of Nevada Reno',
        UniversityofNewEngland: 'University of New England',
        UniversityofNewHampshire: 'University of New Hampshire',
        UniversityofNewHaven: 'University of New Haven',
        UniversityofNewMexico: 'University of New Mexico',
        UniversityofNewOrleans: 'University of New Orleans',
        UniversityofNorthCarolinaatAsheville: 'University of North Carolina at Asheville',
        UniversityofNorthCarolinaatChapelHill: 'University of North Carolina at Chapel Hill',
        UniversityofNorthCarolinaatCharlotte: 'University of North Carolina at Charlotte',
        TheUniversityofNorthCarolinaatGreensboro: 'The University of North Carolina at Greensboro',
        UniversityofNorthCarolinaatWilmington: 'University of North Carolina at Wilmington',
        UniversityofNorthDakota: 'University of North Dakota',
        UniversityofNorthernColorado: 'University of Northern Colorado',
        UniversityofNorthernIowa: 'University of Northern Iowa',
        UniversityofNorthFlorida: 'University of North Florida',
        UniversityofNorthTexas: 'University of North Texas',
        UniversityofNorthTexasHealthScienceCenter: 'University of North Texas Health Science Center',
        UniversityofNotreDame: 'University of Notre Dame',
        UniversityofOklahoma: 'University of Oklahoma',
        UniversityofOregon: 'University of Oregon',
        UniversityofPennsylvania: 'University of Pennsylvania',
        UniversityofPhoenix: 'University of Phoenix',
        UniversityofPittsburgh: 'University of Pittsburgh',
        UniversityofPittsburghatJohnstown: 'University of Pittsburgh at Johnstown',
        UniversityofPittsburghatBradford: 'University of Pittsburgh at Bradford',
        UniversityofPortland: 'University of Portland',
        UniversityofPuertoRico: 'University of Puerto Rico',
        UniversityofPugetSound: 'University of Puget Sound',
        UniversityofRedlands: 'University of Redlands',
        UniversityofRhodeIsland: 'University of Rhode Island',
        UniversityofRichmond: 'University of Richmond',
        UniversityofRochester: 'University of Rochester',
        UniversityofSaintThomasMN: 'University of Saint Thomas (MN)',
        UniversityofSaintThomasTX: 'University of Saint Thomas (TX)',
        UniversityofSanDiego: 'University of San Diego',
        UniversityofSanFrancisco: 'University of San Francisco',
        UniversityofScienceArtsofOklahoma: 'University of Science & Arts of Oklahoma',
        UniversityofScranton: 'University of Scranton',
        UniversityofSiouxFalls: 'University of Sioux Falls',
        UniversityofSouthAlabama: 'University of South Alabama',
        UniversityofSouthCarolina: 'University of South Carolina',
        AndersonUniversity: 'Anderson University',
        UniversityofSouthCarolinaAiken: 'University of South Carolina - Aiken',
        UniversityofSouthDakota: 'University of South Dakota',
        UniversityofSouthernCalifornia: 'University of Southern California',
        UniversityofSouthernIndiana: 'University of Southern Indiana',
        UniversityofSouthernMaine: 'University of Southern Maine',
        UniversityofSouthernMississippi: 'University of Southern Mississippi',
        UniversityofSouthFlorida: 'University of South Florida',
        UniversityofSouthwesternLouisiana: 'University of Southwestern Louisiana',
        UniversityofTampa: 'University of Tampa',
        UniversityofTennesseeatChattanooga: 'University of Tennessee at Chattanooga',
        UniversityofTennesseeKnoxville: 'University of Tennessee Knoxville',
        UniversityofTennesseeMartin: 'University of Tennessee Martin',
        UniversityofTexasatArlington: 'University of Texas at Arlington',
        TheUniversityofTexasatAustin: 'The University of Texas at Austin',
        UniversityofTexasatBrownsville: 'University of Texas at Brownsville',
        UniversityofTexasatDallas: 'University of Texas at Dallas',
        UniversityofTexasatElPaso: 'University of Texas at El Paso',
        TheUniversityofTexasatSanAntonio: 'The University of Texas at San Antonio',
        UniversityofTexasatTyler: 'University of Texas at Tyler',
        UniversityofTexasMedicalBranch: 'University of Texas Medical Branch',
        UniversityofTexasPanAmerican: 'University of Texas - Pan American',
        UniversityofTexasSouthwesternMedicalCenter: 'University of Texas Southwestern Medical Center',
        UniversityofTexasPermianBasin: 'University of Texas Permian Basin',
        UniversityoftheDistrictofColumbia: 'University of the District of Columbia',
        UniversityoftheIncarnateWord: 'University of the Incarnate Word',
        UniversityoftheOzarks: 'University of the Ozarks',
        UniversityofthePacific: 'University of the Pacific',
        UniversityofToledo: 'University of Toledo',
        UniversityofTulsa: 'University of Tulsa',
        UniversityofUtah: 'University of Utah',
        UniversityofVermont: 'University of Vermont',
        UniversityofVirginiaCharlottesville: 'University of Virginia Charlottesville',
        UniversityofWashington: 'University of Washington',
        UniversityofWestAlabama: 'University of West Alabama',
        UniversityofWestFlorida: 'University of West Florida',
        UniversityofWisconsinEauClaire: 'University of Wisconsin - Eau Claire',
        UniversityofWisconsinGreenBay: 'University of Wisconsin - Green Bay',
        UniversityofWisconsinLaCrosse: 'University of Wisconsin - LaCrosse',
        UniversityofWisconsinMadison: 'University of Wisconsin - Madison',
        UniversityofWisconsinMilwaukee: 'University of Wisconsin - Milwaukee',
        UniversityofWisconsinOshkosh: 'University of Wisconsin - Oshkosh',
        UniversityofWisconsinParkside: 'University of Wisconsin - Parkside',
        UniversityofWisconsinPlatteville: 'University of Wisconsin - Platteville',
        UniversityofWisconsinRiverFalls: 'University of Wisconsin - River Falls',
        UniversityofWisconsinStevensPoint: 'University of Wisconsin - Stevens Point',
        UniversityofWisconsinStout: 'University of Wisconsin - Stout',
        UniversityofWisconsinSuperior: 'University of Wisconsin - Superior',
        UniversityofWisconsinSystem: 'University of Wisconsin System',
        UniversityofWisconsinWhitewater: 'University of Wisconsin - Whitewater',
        UniversityofWyoming: 'University of Wyoming',
        UpperIowaUniversity: 'Upper Iowa University',
        UrsinusCollege: 'Ursinus College',
        UtahStateUniversity: 'Utah State University',
        UtahValleyStateCollege: 'Utah Valley State College',
        ValdostaStateUniversity: 'Valdosta State University',
        ValleyCityStateUniversity: 'Valley City State University',
        ValparaisoUniversity: 'Valparaiso University',
        VanceGranvilleCommunityCollege: 'Vance-Granville Community College',
        VanderbiltUniversity: 'Vanderbilt University',
        VassarCollege: 'Vassar College',
        VermontTechnicalCollege: 'Vermont Technical College',
        VillaJulieCollege: 'Villa Julie College',
        VillanovaUniversity: 'Villanova University',
        VirginiaCommonwealthUniversity: 'Virginia Commonwealth University',
        VirginiaCommunityCollegeSystem: 'Virginia Community College System',
        VirginiaIntermontCollege: 'Virginia Intermont College',
        VirginiaMilitaryInstitute: 'Virginia Military Institute',
        VirginiaTech: 'Virginia Tech',
        VirginiaStateUniversity: 'Virginia State University',
        VirginiaWesleyanCollege: 'Virginia Wesleyan College',
        WabashCollege: 'Wabash College',
        WakeForestUniversity: 'Wake Forest University',
        WaldenUniversity: 'Walden University',
        WallaWallaUniversity: 'Walla Walla University',
        WalshCollege: 'Walsh College',
        WarrenWilsonCollege: 'Warren Wilson College',
        WartburgCollege: 'Wartburg College',
        WashburnUniversity: 'Washburn University',
        WashingtonLeeUniversity: 'Washington & Lee University',
        WashingtonBibleCollegeCapitalBibleSeminary: 'Washington Bible College/Capital Bible Seminary',
        WashingtonCollege: 'Washington College',
        WashingtonStateUniversity: 'Washington State University',
        WashingtonStateUniversityatVancouver: 'Washington State University at Vancouver',
        WashingtonUniversitySaintLouis: 'Washington University Saint Louis',
        WaubonseeCommunityCollege: 'Waubonsee Community College',
        WaynesburgCollege: 'Waynesburg College',
        WayneStateUniversity: 'Wayne State University',
        WeberStateUniversity: 'Weber State University',
        WebsterUniversity: 'Webster University',
        WellesleyCollege: 'Wellesley College',
        WellsCollege: 'Wells College',
        WentworthInstituteofTechnology: 'Wentworth Institute of Technology',
        WesleyanUniversity: 'Wesleyan University',
        WesleyCollege: 'Wesley College',
        WestChesterUniversityofPennsylvania: 'West Chester University of Pennsylvania',
        WestCoastUniversity: 'West Coast University',
        WesternCarolinaUniversity: 'Western Carolina University',
        WesternConnecticutStateUniversity: 'Western Connecticut State University',
        WesternGovernorsUniversity: 'Western Governors University',
        WesternIllinoisUniversity: 'Western Illinois University',
        WesternKentuckyUniversity: 'Western Kentucky University',
        WesternMichiganUniversity: 'Western Michigan University',
        WesternMontanaCollege: 'Western Montana College',
        WesternNewEnglandCollege: 'Western New England College',
        WesternNewMexicoUniversity: 'Western New Mexico University',
        WesternStateColoradoUniversity: 'Western State Colorado University',
        WesternWashingtonUniversity: 'Western Washington University',
        WestfieldStateCollege: 'Westfield State College',
        UniversityofWestGeorgia: 'University of West Georgia',
        WestLibertyStateCollege: 'West Liberty State College',
        WestminsterCollegeofNewWilmington: 'Westminster College of New Wilmington',
        WestminsterCollegeofFulton: 'Westminster College of Fulton',
        WestminsterCollegeofSaltLakeCity: 'Westminster College of Salt Lake City',
        WestminsterTheologicalSeminary: 'Westminster Theological Seminary',
        WestmontCollege: 'Westmont College',
        WestTexasAMUniversity: 'West Texas A&M University',
        WestVirginiaUniversity: 'West Virginia University',
        WestVirginiaUniversityatParkersburg: 'West Virginia University at Parkersburg',
        WheatonCollege: 'Wheaton College',
        WheatonCollegeNortonMA: 'Wheaton College Norton MA',
        WheelingJesuitCollege: 'Wheeling Jesuit College',
        WhitmanCollege: 'Whitman College',
        WhittierCollege: 'Whittier College',
        WhitworthCollege: 'Whitworth College',
        WichitaStateUniversity: 'Wichita State University',
        WidenerUniversity: 'Widener University',
        WilberforceUniversity: 'Wilberforce University',
        WilkesUniversity: 'Wilkes University',
        WillametteUniversity: 'Willamette University',
        WilliamHowardTaftUniversity: 'William Howard Taft University',
        WilliamJewellCollege: 'William Jewell College',
        WilliamMitchellCollegeofLaw: 'William Mitchell College of Law',
        WilliamPatersonUniversity: 'William Paterson University',
        WilliamPennCollege: 'William Penn College',
        WilliamsCollege: 'Williams College',
        WilliamWoodsUniversity: 'William Woods University',
        WilmingtonCollege: 'Wilmington College',
        WinonaStateUniversity: 'Winona State University',
        WinthropUniversity: 'Winthrop University',
        WittenbergUniversity: 'Wittenberg University',
        WoffordCollege: 'Wofford College',
        WoodburyUniversity: 'Woodbury University',
        WorcesterPolytechnicInstitute: 'Worcester Polytechnic Institute',
        WrightStateUniversity: 'Wright State University',
        XavierUniversityofLouisiana: 'Xavier University of Louisiana',
        YaleUniversity: 'Yale University',
        YeshivaUniversity: 'Yeshiva University',
        YorkCollegeofPennsylvania: 'York College of Pennsylvania',
        YoungstownStateUniversity: 'Youngstown State University',
        HoustonBaptistUniversity: 'Houston Baptist University',
        BrookingsInstitution: 'Brookings Institution',
        WoodsHoleOceanographicInstitution: 'Woods Hole Oceanographic Institution',
        AlabamaSouthernCommunityCollege: 'Alabama Southern Community College',
        BevillStateCommunityCollege: 'Bevill State Community College',
        BishopStateCommunityCollege: 'Bishop State Community College',
        CentralAlabamaCommunityCollege: 'Central Alabama Community College',
        ChattahoocheeValleyCommunityCollege: 'Chattahoochee Valley Community College',
        DukeUniversity: 'Duke University',
        EnterpriseStateCommunityCollege: 'Enterprise State Community College',
        GadsdenStateCommunityCollege: 'Gadsden State Community College',
        GeorgeCWallaceStateCommunityCollegeDothan: 'George C Wallace State Community College-Dothan',
        GeorgeCWallaceStateCommunityCollegeHanceville: 'George C Wallace State Community College-Hanceville',
        GeorgeCWallaceStateCommunityCollegeSelma: 'George C Wallace State Community College-Selma',
        HCouncillTrenholmStateTechnicalCollege: 'H Councill Trenholm State Technical College',
        JamesHFaulknerStateCommunityCollege: 'James H Faulkner State Community College',
        JeffersonDavisCommunityCollege: 'Jefferson Davis Community College',
        JeffersonStateCommunityCollege: 'Jefferson State Community College',
        JFDrakeStateCommunityandTechnicalCollege: 'J F Drake State Community and Technical College',
        CalhounCommunityCollege: 'Calhoun Community College',
        LurleenBWallaceCommunityCollege: 'Lurleen B Wallace Community College',
        MarionMilitaryInstitute: 'Marion Military Institute',
        NortheastAlabamaCommunityCollege: 'Northeast Alabama Community College',
        NorthwestShoalsCommunityCollege: 'Northwest-Shoals Community College',
        SheltonStateCommunityCollege: 'Shelton State Community College',
        SneadStateCommunityCollege: 'Snead State Community College',
        SouthernUnionStateCommunityCollege: 'Southern Union State Community College',
        IlisagvikCollege: 'Ilisagvik College',
        PrinceWilliamSoundCollege: 'Prince William Sound College',
        CentralArizonaCollege: 'Central Arizona College',
        ChandlerGilbertCommunityCollege: 'Chandler-Gilbert Community College',
        CochiseCollege: 'Cochise College',
        CoconinoCommunityCollege: 'Coconino Community College',
        EasternArizonaCollege: 'Eastern Arizona College',
        GateWayCommunityCollege: 'GateWay Community College',
        GlendaleCommunityCollege: 'Glendale Community College',
        MesaCommunityCollege: 'Mesa Community College',
        MohaveCommunityCollege: 'Mohave Community College',
        NorthlandPioneerCollege: 'Northland Pioneer College',
        ParadiseValleyCommunityCollege: 'Paradise Valley Community College',
        PhoenixCollege: 'Phoenix College',
        PimaCommunityCollege: 'Pima Community College',
        RioSaladoCollege: 'Rio Salado College',
        ScottsdaleCommunityCollege: 'Scottsdale Community College',
        SouthMountainCommunityCollege: 'South Mountain Community College',
        TohonoOOdhamCommunityCollege: "Tohono O'Odham Community College",
        YavapaiCollege: 'Yavapai College',
        ArkansasNortheasternCollege: 'Arkansas Northeastern College',
        ArkansasStateUniversityBeebe: 'Arkansas State University-Beebe',
        ArkansasStateUniversityMountainHome: 'Arkansas State University-Mountain Home',
        ArkansasStateUniversityNewport: 'Arkansas State University-Newport',
        BlackRiverTechnicalCollege: 'Black River Technical College',
        CollegeoftheOuachitas: 'College of the Ouachitas',
        CossatotCommunityCollegeoftheUniversityofArkansas: 'Cossatot Community College of the University of Arkansas',
        EastArkansasCommunityCollege: 'East Arkansas Community College',
        MidSouthCommunityCollege: 'Mid-South Community College',
        NationalParkCommunityCollege: 'National Park Community College',
        NorthArkansasCollege: 'North Arkansas College',
        NorthWestArkansasCommunityCollege: 'NorthWest Arkansas Community College',
        OzarkaCollege: 'Ozarka College',
        PhillipsCommunityCollegeoftheUniversityofArkansas: 'Phillips Community College of the University of Arkansas',
        PulaskiTechnicalCollege: 'Pulaski Technical College',
        RichMountainCommunityCollege: 'Rich Mountain Community College',
        SouthArkansasCommunityCollege: 'South Arkansas Community College',
        SoutheastArkansasCollege: 'Southeast Arkansas College',
        SouthernArkansasUniversityTech: 'Southern Arkansas University Tech',
        UniversityofArkansasCommunityCollegeBatesville: 'University of Arkansas Community College-Batesville',
        UniversityofArkansasCommunityCollegeHope: 'University of Arkansas Community College-Hope',
        UniversityofArkansasCommunityCollegeMorrilton: 'University of Arkansas Community College-Morrilton',
        AllanHancockCollege: 'Allan Hancock College',
        AmericanRiverCollege: 'American River College',
        AntelopeValleyCollege: 'Antelope Valley College',
        BakersfieldCollege: 'Bakersfield College',
        BarstowCommunityCollege: 'Barstow Community College',
        BerkeleyCityCollege: 'Berkeley City College',
        ButteCollege: 'Butte College',
        CabrilloCollege: 'Cabrillo College',
        CanadaCollege: 'Canada College',
        CarringtonCollegeCaliforniaSacramento: 'Carrington College California-Sacramento',
        CerritosCollege: 'Cerritos College',
        CerroCosoCommunityCollege: 'Cerro Coso Community College',
        ChabotCollege: 'Chabot College',
        ChaffeyCollege: 'Chaffey College',
        CitrusCollege: 'Citrus College',
        CityCollegeofSanFrancisco: 'City College of San Francisco',
        CoastlineCommunityCollege: 'Coastline Community College',
        CollegeofAlameda: 'College of Alameda',
        CollegeofMarin: 'College of Marin',
        CollegeofSanMateo: 'College of San Mateo',
        CollegeoftheCanyons: 'College of the Canyons',
        CollegeoftheDesert: 'College of the Desert',
        CollegeoftheRedwoods: 'College of the Redwoods',
        CollegeoftheSequoias: 'College of the Sequoias',
        CollegeoftheSiskiyous: 'College of the Siskiyous',
        ColumbiaCollegeMO: 'Columbia College (MO)',
        ColumbiaCollegeCA: 'Columbia College (CA)',
        ContraCostaCollege: 'Contra Costa College',
        CopperMountainCommunityCollege: 'Copper Mountain Community College',
        CosumnesRiverCollege: 'Cosumnes River College',
        CraftonHillsCollege: 'Crafton Hills College',
        CuestaCollege: 'Cuesta College',
        CuyamacaCollege: 'Cuyamaca College',
        CypressCollege: 'Cypress College',
        DeAnzaCollege: 'De Anza College',
        DiabloValleyCollege: 'Diablo Valley College',
        EastLosAngelesCollege: 'East Los Angeles College',
        ElCaminoCollegeComptonCenter: 'El Camino College-Compton Center',
        ElCaminoCommunityCollegeDistrict: 'El Camino Community College District',
        EvergreenValleyCollege: 'Evergreen Valley College',
        FeatherRiverCommunityCollegeDistrict: 'Feather River Community College District',
        FolsomLakeCollege: 'Folsom Lake College',
        FoothillCollege: 'Foothill College',
        FresnoCityCollege: 'Fresno City College',
        FullertonCollege: 'Fullerton College',
        GavilanCollege: 'Gavilan College',
        GoldenWestCollege: 'Golden West College',
        GrossmontCollege: 'Grossmont College',
        HartnellCollege: 'Hartnell College',
        ImperialValleyCollege: 'Imperial Valley College',
        LakeTahoeCommunityCollege: 'Lake Tahoe Community College',
        LaneyCollege: 'Laney College',
        LasPositasCollege: 'Las Positas College',
        LassenCommunityCollege: 'Lassen Community College',
        LongBeachCityCollege: 'Long Beach City College',
        LosAngelesCityCollege: 'Los Angeles City College',
        LosAngelesCountyCollegeofNursingandAlliedHealth: 'Los Angeles County College of Nursing and Allied Health',
        LosAngelesHarborCollege: 'Los Angeles Harbor College',
        LosAngelesMissionCollege: 'Los Angeles Mission College',
        LosAngelesPierceCollege: 'Los Angeles Pierce College',
        LosAngelesSouthwestCollege: 'Los Angeles Southwest College',
        LosAngelesTradeTechnicalCollege: 'Los Angeles Trade Technical College',
        LosAngelesValleyCollege: 'Los Angeles Valley College',
        LosMedanosCollege: 'Los Medanos College',
        MendocinoCollege: 'Mendocino College',
        MercedCollege: 'Merced College',
        MerrittCollege: 'Merritt College',
        MiraCostaCollege: 'MiraCosta College',
        MissionCollege: 'Mission College',
        ModestoJuniorCollege: 'Modesto Junior College',
        MontereyPeninsulaCollege: 'Monterey Peninsula College',
        MoorparkCollege: 'Moorpark College',
        MorenoValleyCollege: 'Moreno Valley College',
        MTICollege: 'MTI College',
        MtSanAntonioCollege: 'Mt. San Antonio College',
        MtSanJacintoCommunityCollegeDistrict: 'Mt. San Jacinto Community College District',
        NapaValleyCollege: 'Napa Valley College',
        OhloneCollege: 'Ohlone College',
        OrangeCoastCollege: 'Orange Coast College',
        OxnardCollege: 'Oxnard College',
        PalomarCollege: 'Palomar College',
        PaloVerdeCollege: 'Palo Verde College',
        PasadenaCityCollege: 'Pasadena City College',
        PortervilleCollege: 'Porterville College',
        ReedleyCollege: 'Reedley College',
        RioHondoCollege: 'Rio Hondo College',
        RiversideCityCollege: 'Riverside City College',
        SacramentoCityCollege: 'Sacramento City College',
        SaddlebackCollege: 'Saddleback College',
        SanBernardinoValleyCollege: 'San Bernardino Valley College',
        SanDiegoCityCollege: 'San Diego City College',
        SanDiegoMesaCollege: 'San Diego Mesa College',
        SanDiegoMiramarCollege: 'San Diego Miramar College',
        SanJoaquinDeltaCollege: 'San Joaquin Delta College',
        SanJoaquinValleyCollegeVisalia: 'San Joaquin Valley College-Visalia',
        SanJoseCityCollege: 'San Jose City College',
        SantaAnaCollege: 'Santa Ana College',
        SantaBarbaraCityCollege: 'Santa Barbara City College',
        SantaMonicaCollege: 'Santa Monica College',
        SantaRosaJuniorCollege: 'Santa Rosa Junior College',
        SantiagoCanyonCollege: 'Santiago Canyon College',
        ShastaCollege: 'Shasta College',
        SierraCollege: 'Sierra College',
        SkylineCollege: 'Skyline College',
        SolanoCommunityCollege: 'Solano Community College',
        SouthwesternCollegeCA: 'Southwestern College (CA)',
        TaftCollege: 'Taft College',
        VenturaCollege: 'Ventura College',
        VictorValleyCollege: 'Victor Valley College',
        WestHillsCollegeCoalinga: 'West Hills College-Coalinga',
        WestLosAngelesCollege: 'West Los Angeles College',
        WestValleyCollege: 'West Valley College',
        WoodlandCommunityCollege: 'Woodland Community College',
        YubaCollege: 'Yuba College',
        AimsCommunityCollege: 'Aims Community College',
        ArapahoeCommunityCollege: 'Arapahoe Community College',
        ColoradoNorthwesternCommunityCollege: 'Colorado Northwestern Community College',
        CommunityCollegeofAurora: 'Community College of Aurora',
        CommunityCollegeofDenver: 'Community College of Denver',
        FrontRangeCommunityCollege: 'Front Range Community College',
        LamarCommunityCollege: 'Lamar Community College',
        MorganCommunityCollege: 'Morgan Community College',
        NortheasternJuniorCollege: 'Northeastern Junior College',
        OteroJuniorCollege: 'Otero Junior College',
        PikesPeakCommunityCollege: 'Pikes Peak Community College',
        PuebloCommunityCollege: 'Pueblo Community College',
        RedRocksCommunityCollege: 'Red Rocks Community College',
        TrinidadStateJuniorCollege: 'Trinidad State Junior College',
        AsnuntuckCommunityCollege: 'Asnuntuck Community College',
        CapitalCommunityCollege: 'Capital Community College',
        GatewayCommunityCollege: 'Gateway Community College',
        HousatonicCommunityCollege: 'Housatonic Community College',
        ManchesterCommunityCollege: 'Manchester Community College',
        MiddlesexCommunityCollege: 'Middlesex Community College',
        NaugatuckValleyCommunityCollege: 'Naugatuck Valley Community College',
        NorthwesternConnecticutCommunityCollege: 'Northwestern Connecticut Community College',
        NorwalkCommunityCollege: 'Norwalk Community College',
        QuinebaugValleyCommunityCollege: 'Quinebaug Valley Community College',
        ThreeRiversCommunityCollege: 'Three Rivers Community College',
        TunxisCommunityCollege: 'Tunxis Community College',
        DelawareCollegeofArtandDesign: 'Delaware College of Art and Design',
        DelawareTechnicalCommunityCollege: 'Delaware Technical Community College',
        FloridaKeysCommunityCollege: 'Florida Keys Community College',
        HillsboroughCommunityCollege: 'Hillsborough Community College',
        NorthFloridaCommunityCollege: 'North Florida Community College',
        PascoHernandoCommunityCollege: 'Pasco-Hernando Community College',
        TallahasseeCommunityCollege: 'Tallahassee Community College',
        AlbanyTechnicalCollege: 'Albany Technical College',
        AndrewCollege: 'Andrew College',
        AthensTechnicalCollege: 'Athens Technical College',
        AtlantaTechnicalCollege: 'Atlanta Technical College',
        AugustaTechnicalCollege: 'Augusta Technical College',
        BainbridgeStateCollege: 'Bainbridge State College',
        CentralGeorgiaTechnicalCollege: 'Central Georgia Technical College',
        ChattahoocheeTechnicalCollege: 'Chattahoochee Technical College',
        ColumbusTechnicalCollege: 'Columbus Technical College',
        DartonStateCollege: 'Darton State College',
        GeorgiaHighlandsCollege: 'Georgia Highlands College',
        GeorgiaMilitaryCollege: 'Georgia Military College',
        GeorgiaNorthwesternTechnicalCollege: 'Georgia Northwestern Technical College',
        GeorgiaPerimeterCollege: 'Georgia Perimeter College',
        GeorgiaPiedmontTechnicalCollege: 'Georgia Piedmont Technical College',
        GwinnettTechnicalCollege: 'Gwinnett Technical College',
        LanierTechnicalCollege: 'Lanier Technical College',
        MoultrieTechnicalCollege: 'Moultrie Technical College',
        NorthGeorgiaTechnicalCollege: 'North Georgia Technical College',
        OgeecheeTechnicalCollege: 'Ogeechee Technical College',
        CoastalPinesTechnicalCollege: 'Coastal Pines Technical College',
        SavannahTechnicalCollege: 'Savannah Technical College',
        SoutheasternTechnicalCollege: 'Southeastern Technical College',
        SouthernCrescentTechnicalCollege: 'Southern Crescent Technical College',
        SouthGeorgiaTechnicalCollege: 'South Georgia Technical College',
        SouthwestGeorgiaTechnicalCollege: 'Southwest Georgia Technical College',
        WestGeorgiaTechnicalCollege: 'West Georgia Technical College',
        WiregrassGeorgiaTechnicalCollege: 'Wiregrass Georgia Technical College',
        HawaiiCommunityCollege: 'Hawaii Community College',
        HonoluluCommunityCollege: 'Honolulu Community College',
        KapiolaniCommunityCollege: 'Kapiolani Community College',
        KauaiCommunityCollege: 'Kauai Community College',
        LeewardCommunityCollege: 'Leeward Community College',
        WindwardCommunityCollege: 'Windward Community College',
        CollegeofSouthernIdaho: 'College of Southern Idaho',
        CollegeofWesternIdaho: 'College of Western Idaho',
        EasternIdahoTechnicalCollege: 'Eastern Idaho Technical College',
        NorthIdahoCollege: 'North Idaho College',
        BlackHawkCollege: 'Black Hawk College',
        CarlSandburgCollege: 'Carl Sandburg College',
        CityCollegesofChicago: 'City Colleges of Chicago',
        CollegeofDuPage: 'College of DuPage',
        CollegeofLakeCounty: 'College of Lake County',
        DanvilleAreaCommunityCollege: 'Danville Area Community College',
        ElginCommunityCollege: 'Elgin Community College',
        FoxCollege: 'Fox College',
        HarperCollege: 'Harper College',
        HeartlandCommunityCollege: 'Heartland Community College',
        HighlandCommunityCollege: 'Highland Community College',
        IllinoisCentralCollege: 'Illinois Central College',
        IllinoisValleyCommunityCollege: 'Illinois Valley Community College',
        JohnALoganCollege: 'John A Logan College',
        JohnWoodCommunityCollege: 'John Wood Community College',
        JolietJuniorCollege: 'Joliet Junior College',
        KankakeeCommunityCollege: 'Kankakee Community College',
        KaskaskiaCollege: 'Kaskaskia College',
        KishwaukeeCollege: 'Kishwaukee College',
        LakeLandCollege: 'Lake Land College',
        LeCordonBleuCollegeofCulinaryArtsChicago: 'Le Cordon Bleu College of Culinary Arts-Chicago',
        LewisandClarkCommunityCollege: 'Lewis and Clark Community College',
        LincolnLandCommunityCollege: 'Lincoln Land Community College',
        MacCormacCollege: 'MacCormac College',
        McHenryCountyCollege: 'McHenry County College',
        MoraineValleyCommunityCollege: 'Moraine Valley Community College',
        MortonCollege: 'Morton College',
        NorthwesternCollegeChicagoCampus: 'Northwestern College-Chicago Campus',
        OaktonCommunityCollege: 'Oakton Community College',
        ParklandCollege: 'Parkland College',
        PrairieStateCollege: 'Prairie State College',
        RendLakeCollege: 'Rend Lake College',
        RichlandCommunityCollege: 'Richland Community College',
        RockValleyCollege: 'Rock Valley College',
        SaukValleyCommunityCollege: 'Sauk Valley Community College',
        ShawneeCommunityCollege: 'Shawnee Community College',
        SoutheasternIllinoisCollege: 'Southeastern Illinois College',
        SouthSuburbanCollege: 'South Suburban College',
        SouthwesternIllinoisCollege: 'Southwestern Illinois College',
        SpoonRiverCollege: 'Spoon River College',
        TritonCollege: 'Triton College',
        AncillaCollege: 'Ancilla College',
        IvyTechCommunityCollege: 'Ivy Tech Community College',
        DesMoinesAreaCommunityCollege: 'Des Moines Area Community College',
        EasternIowaCommunityCollegeDistrict: 'Eastern Iowa Community College District',
        HawkeyeCommunityCollege: 'Hawkeye Community College',
        IndianHillsCommunityCollege: 'Indian Hills Community College',
        IowaCentralCommunityCollege: 'Iowa Central Community College',
        IowaLakesCommunityCollege: 'Iowa Lakes Community College',
        IowaWesternCommunityCollege: 'Iowa Western Community College',
        KirkwoodCommunityCollege: 'Kirkwood Community College',
        MarshalltownCommunityCollege: 'Marshalltown Community College',
        NortheastIowaCommunityCollege: 'Northeast Iowa Community College',
        NorthIowaAreaCommunityCollege: 'North Iowa Area Community College',
        NorthwestIowaCommunityCollege: 'Northwest Iowa Community College',
        SoutheasternCommunityCollege: 'Southeastern Community College',
        SouthwesternCommunityCollege: 'Southwestern Community College',
        WesternIowaTechCommunityCollege: 'Western Iowa Tech Community College',
        AllenCountyCommunityCollege: 'Allen County Community College',
        BartonCountyCommunityCollege: 'Barton County Community College',
        ButlerCommunityCollege: 'Butler Community College',
        CloudCountyCommunityCollege: 'Cloud County Community College',
        CoffeyvilleCommunityCollege: 'Coffeyville Community College',
        ColbyCommunityCollege: 'Colby Community College',
        CowleyCountyCommunityCollege: 'Cowley County Community College',
        DodgeCityCommunityCollege: 'Dodge City Community College',
        FlintHillsTechnicalCollege: 'Flint Hills Technical College',
        FortScottCommunityCollege: 'Fort Scott Community College',
        GardenCityCommunityCollege: 'Garden City Community College',
        HutchinsonCommunityCollege: 'Hutchinson Community College',
        IndependenceCommunityCollege: 'Independence Community College',
        JohnsonCountyCommunityCollege: 'Johnson County Community College',
        KansasCityKansasCommunityCollege: 'Kansas City Kansas Community College',
        LabetteCommunityCollege: 'Labette Community College',
        ManhattanAreaTechnicalCollege: 'Manhattan Area Technical College',
        NeoshoCountyCommunityCollege: 'Neosho County Community College',
        NorthCentralKansasTechnicalCollege: 'North Central Kansas Technical College',
        NorthwestKansasTechnicalCollege: 'Northwest Kansas Technical College',
        PrattCommunityCollege: 'Pratt Community College',
        SewardCountyCommunityCollegeandAreaTechnicalSchool: 'Seward County Community College and Area Technical School',
        WichitaAreaTechnicalCollege: 'Wichita Area Technical College',
        AshlandCommunityandTechnicalCollege: 'Ashland Community and Technical College',
        BigSandyCommunityandTechnicalCollege: 'Big Sandy Community and Technical College',
        BluegrassCommunityandTechnicalCollege: 'Bluegrass Community and Technical College',
        ElizabethtownCommunityandTechnicalCollege: 'Elizabethtown Community and Technical College',
        GalenCollegeofNursingLouisville: 'Galen College of Nursing-Louisville',
        GatewayCommunityandTechnicalCollege: 'Gateway Community and Technical College',
        HazardCommunityandTechnicalCollege: 'Hazard Community and Technical College',
        HendersonCommunityCollege: 'Henderson Community College',
        HopkinsvilleCommunityCollege: 'Hopkinsville Community College',
        JeffersonCommunityandTechnicalCollege: 'Jefferson Community and Technical College',
        MadisonvilleCommunityCollege: 'Madisonville Community College',
        MaysvilleCommunityandTechnicalCollege: 'Maysville Community and Technical College',
        OwensboroCommunityandTechnicalCollege: 'Owensboro Community and Technical College',
        SomersetCommunityCollege: 'Somerset Community College',
        SouthcentralKentuckyCommunityandTechnicalCollege: 'Southcentral Kentucky Community and Technical College',
        SoutheastKentuckyCommunityandTechnicalCollege: 'Southeast Kentucky Community and Technical College',
        WestKentuckyCommunityandTechnicalCollege: 'West Kentucky Community and Technical College',
        BatonRougeCommunityCollege: 'Baton Rouge Community College',
        BossierParishCommunityCollege: 'Bossier Parish Community College',
        DelgadoCommunityCollege: 'Delgado Community College',
        LEFletcherTechnicalCommunityCollege: 'L E Fletcher Technical Community College',
        LouisianaDeltaCommunityCollege: 'Louisiana Delta Community College',
        LouisianaStateUniversityEunice: 'Louisiana State University-Eunice',
        NunezCommunityCollege: 'Nunez Community College',
        RiverParishesCommunityCollege: 'River Parishes Community College',
        SouthernUniversityatShreveport: 'Southern University at Shreveport',
        SouthLouisianaCommunityCollege: 'South Louisiana Community College',
        SOWELATechnicalCommunityCollege: 'SOWELA Technical Community College',
        CentralMaineCommunityCollege: 'Central Maine Community College',
        CentralMaineMedicalCenterCollegeofNursingandHealthProfessions:
            'Central Maine Medical Center College of Nursing and Health Professions',
        EasternMaineCommunityCollege: 'Eastern Maine Community College',
        KennebecValleyCommunityCollege: 'Kennebec Valley Community College',
        NorthernMaineCommunityCollege: 'Northern Maine Community College',
        SouthernMaineCommunityCollege: 'Southern Maine Community College',
        WashingtonCountyCommunityCollege: 'Washington County Community College',
        YorkCountyCommunityCollege: 'York County Community College',
        AlleganyCollegeofMaryland: 'Allegany College of Maryland',
        BaltimoreCityCommunityCollege: 'Baltimore City Community College',
        CarrollCommunityCollege: 'Carroll Community College',
        CecilCollege: 'Cecil College',
        CollegeofSouthernMaryland: 'College of Southern Maryland',
        GarrettCollege: 'Garrett College',
        PrinceGeorgesCommunityCollege: "Prince George's Community College",
        WorWicCommunityCollege: 'Wor-Wic Community College',
        BerkshireCommunityCollege: 'Berkshire Community College',
        BristolCommunityCollege: 'Bristol Community College',
        BunkerHillCommunityCollege: 'Bunker Hill Community College',
        CapeCodCommunityCollege: 'Cape Cod Community College',
        GreenfieldCommunityCollege: 'Greenfield Community College',
        HolyokeCommunityCollege: 'Holyoke Community College',
        MassachusettsBayCommunityCollege: 'Massachusetts Bay Community College',
        MassasoitCommunityCollege: 'Massasoit Community College',
        MountWachusettCommunityCollege: 'Mount Wachusett Community College',
        NorthernEssexCommunityCollege: 'Northern Essex Community College',
        NorthShoreCommunityCollege: 'North Shore Community College',
        QuincyCollege: 'Quincy College',
        QuinsigamondCommunityCollege: 'Quinsigamond Community College',
        RoxburyCommunityCollege: 'Roxbury Community College',
        SpringfieldTechnicalCommunityCollege: 'Springfield Technical Community College',
        UrbanCollegeofBoston: 'Urban College of Boston',
        AlpenaCommunityCollege: 'Alpena Community College',
        BaydeNocCommunityCollege: 'Bay de Noc Community College',
        BayMillsCommunityCollege: 'Bay Mills Community College',
        DeltaCollege: 'Delta College',
        GlenOaksCommunityCollege: 'Glen Oaks Community College',
        GogebicCommunityCollege: 'Gogebic Community College',
        GrandRapidsCommunityCollege: 'Grand Rapids Community College',
        HenryFordCollege: 'Henry Ford College',
        JacksonCollege: 'Jackson College',
        KalamazooValleyCommunityCollege: 'Kalamazoo Valley Community College',
        KelloggCommunityCollege: 'Kellogg Community College',
        KeweenawBayOjibwaCommunityCollege: 'Keweenaw Bay Ojibwa Community College',
        KirtlandCommunityCollege: 'Kirtland Community College',
        LakeMichiganCollege: 'Lake Michigan College',
        LansingCommunityCollege: 'Lansing Community College',
        MacombCommunityCollege: 'Macomb Community College',
        MidMichiganCommunityCollege: 'Mid Michigan Community College',
        MonroeCountyCommunityCollege: 'Monroe County Community College',
        MontcalmCommunityCollege: 'Montcalm Community College',
        MuskegonCommunityCollege: 'Muskegon Community College',
        NorthCentralMichiganCollege: 'North Central Michigan College',
        OaklandCommunityCollege: 'Oakland Community College',
        SaginawChippewaTribalCollege: 'Saginaw Chippewa Tribal College',
        SchoolcraftCollege: 'Schoolcraft College',
        SouthwesternMichiganCollege: 'Southwestern Michigan College',
        StClairCountyCommunityCollege: 'St. Clair County Community College',
        WashtenawCommunityCollege: 'Washtenaw Community College',
        WayneCountyCommunityCollegeDistrict: 'Wayne County Community College District',
        WestShoreCommunityCollege: 'West Shore Community College',
        AlexandriaTechnicalCommunityCollege: 'Alexandria Technical & Community College',
        AnokaRamseyCommunityCollege: 'Anoka-Ramsey Community College',
        AnokaTechnicalCollege: 'Anoka Technical College',
        CentralLakesCollegeBrainerd: 'Central Lakes College-Brainerd',
        CenturyCollege: 'Century College',
        DakotaCountyTechnicalCollege: 'Dakota County Technical College',
        FondduLacTribalandCommunityCollege: 'Fond du Lac Tribal and Community College',
        HennepinTechnicalCollege: 'Hennepin Technical College',
        HibbingCommunityCollege: 'Hibbing Community College',
        InverHillsCommunityCollege: 'Inver Hills Community College',
        ItascaCommunityCollege: 'Itasca Community College',
        LeechLakeTribalCollege: 'Leech Lake Tribal College',
        MesabiRangeCommunityandTechnicalCollege: 'Mesabi Range Community and Technical College',
        MinneapolisCommunityandTechnicalCollege: 'Minneapolis Community and Technical College',
        MinnesotaStateCollegeSoutheastTechnical: 'Minnesota State College-Southeast Technical',
        MinnesotaStateCommunityandTechnicalCollege: 'Minnesota State Community and Technical College',
        MinnesotaWestCommunityandTechnicalCollege: 'Minnesota West Community and Technical College',
        NormandaleCommunityCollege: 'Normandale Community College',
        NorthHennepinCommunityCollege: 'North Hennepin Community College',
        NorthlandCommunityandTechnicalCollege: 'Northland Community and Technical College',
        NorthwestTechnicalCollege: 'Northwest Technical College',
        PineTechnicalCollege: 'Pine Technical College',
        RainyRiverCommunityCollege: 'Rainy River Community College',
        RidgewaterCollege: 'Ridgewater College',
        RiverlandCommunityCollege: 'Riverland Community College',
        RochesterCommunityandTechnicalCollege: 'Rochester Community and Technical College',
        SaintPaulCollege: 'Saint Paul College',
        SouthCentralCollege: 'South Central College',
        StCloudTechnicalandCommunityCollege: 'St Cloud Technical and Community College',
        VermilionCommunityCollege: 'Vermilion Community College',
        WhiteEarthTribalandCommunityCollege: 'White Earth Tribal and Community College',
        CoahomaCommunityCollege: 'Coahoma Community College',
        CopiahLincolnCommunityCollege: 'Copiah-Lincoln Community College',
        OlinCollegeofEngineering: 'Olin College of Engineering',
        EastCentralCommunityCollege: 'East Central Community College',
        EastMississippiCommunityCollege: 'East Mississippi Community College',
        HindsCommunityCollege: 'Hinds Community College',
        HolmesCommunityCollege: 'Holmes Community College',
        ItawambaCommunityCollege: 'Itawamba Community College',
        JonesCountyJuniorCollege: 'Jones County Junior College',
        MeridianCommunityCollege: 'Meridian Community College',
        MississippiDeltaCommunityCollege: 'Mississippi Delta Community College',
        MississippiGulfCoastCommunityCollege: 'Mississippi Gulf Coast Community College',
        NortheastMississippiCommunityCollege: 'Northeast Mississippi Community College',
        NorthwestMississippiCommunityCollege: 'Northwest Mississippi Community College',
        PearlRiverCommunityCollege: 'Pearl River Community College',
        SouthwestMississippiCommunityCollege: 'Southwest Mississippi Community College',
        CrowderCollege: 'Crowder College',
        EastCentralCollege: 'East Central College',
        JeffersonCollege: 'Jefferson College',
        LinnStateTechnicalCollege: 'Linn State Technical College',
        MineralAreaCollege: 'Mineral Area College',
        MissouriStateUniversity: 'Missouri State University',
        MissouriStateUniversityWestPlains: 'Missouri State University-West Plains',
        MoberlyAreaCommunityCollege: 'Moberly Area Community College',
        NorthCentralMissouriCollege: 'North Central Missouri College',
        OzarksTechnicalCommunityCollege: 'Ozarks Technical Community College',
        SoutheastMissouriHospitalCollegeofNursingandHealthSciences:
            'Southeast Missouri Hospital College of Nursing and Health Sciences',
        StateFairCommunityCollege: 'State Fair Community College',
        StCharlesCommunityCollege: 'St Charles Community College',
        WentworthMilitaryAcademyJuniorCollege: 'Wentworth Military Academy & Junior College',
        AaniiihNakodaCollege: 'Aaniiih Nakoda College',
        BlackfeetCommunityCollege: 'Blackfeet Community College',
        ChiefDullKnifeCollege: 'Chief Dull Knife College',
        DawsonCommunityCollege: 'Dawson Community College',
        FlatheadValleyCommunityCollege: 'Flathead Valley Community College',
        FortPeckCommunityCollege: 'Fort Peck Community College',
        GreatFallsCollegeMontanaStateUniversity: 'Great Falls College Montana State University',
        HelenaCollegeUniversityofMontana: 'Helena College University of Montana',
        LittleBigHornCollege: 'Little Big Horn College',
        MilesCommunityCollege: 'Miles Community College',
        StoneChildCollege: 'Stone Child College',
        CentralCommunityCollege: 'Central Community College',
        LittlePriestTribalCollege: 'Little Priest Tribal College',
        MidPlainsCommunityCollege: 'Mid-Plains Community College',
        NebraskaCollegeofTechnicalAgriculture: 'Nebraska College of Technical Agriculture',
        NebraskaIndianCommunityCollege: 'Nebraska Indian Community College',
        NortheastCommunityCollege: 'Northeast Community College',
        SoutheastCommunityCollegeArea: 'Southeast Community College Area',
        WesternNebraskaCommunityCollege: 'Western Nebraska Community College',
        TruckeeMeadowsCommunityCollege: 'Truckee Meadows Community College',
        GreatBayCommunityCollege: 'Great Bay Community College',
        LakesRegionCommunityCollege: 'Lakes Region Community College',
        NashuaCommunityCollege: 'Nashua Community College',
        NHTIConcordsCommunityCollege: "NHTI-Concord's Community College",
        RiverValleyCommunityCollege: 'River Valley Community College',
        WhiteMountainsCommunityCollege: 'White Mountains Community College',
        AssumptionCollegeforSisters: 'Assumption College for Sisters',
        AtlanticCapeCommunityCollege: 'Atlantic Cape Community College',
        BergenCommunityCollege: 'Bergen Community College',
        BrookdaleCommunityCollege: 'Brookdale Community College',
        CamdenCountyCollege: 'Camden County College',
        CountyCollegeofMorris: 'County College of Morris',
        CumberlandCountyCollege: 'Cumberland County College',
        EssexCountyCollege: 'Essex County College',
        GloucesterCountyCollege: 'Gloucester County College',
        HudsonCountyCommunityCollege: 'Hudson County Community College',
        MiddlesexCountyCollege: 'Middlesex County College',
        OceanCountyCollege: 'Ocean County College',
        PassaicCountyCommunityCollege: 'Passaic County Community College',
        RaritanValleyCommunityCollege: 'Raritan Valley Community College',
        SalemCommunityCollege: 'Salem Community College',
        SussexCountyCommunityCollege: 'Sussex County Community College',
        UnionCountyCollege: 'Union County College',
        WarrenCountyCommunityCollege: 'Warren County Community College',
        CentralNewMexicoCommunityCollege: 'Central New Mexico Community College',
        ClovisCommunityCollege: 'Clovis Community College',
        EasternNewMexicoUniversityRoswellCampus: 'Eastern New Mexico University-Roswell Campus',
        LunaCommunityCollege: 'Luna Community College',
        MesalandsCommunityCollege: 'Mesalands Community College',
        NewMexicoJuniorCollege: 'New Mexico Junior College',
        NewMexicoMilitaryInstitute: 'New Mexico Military Institute',
        NewMexicoStateUniversityAlamogordo: 'New Mexico State University-Alamogordo',
        NewMexicoStateUniversityCarlsbad: 'New Mexico State University-Carlsbad',
        NewMexicoStateUniversityDonaAna: 'New Mexico State University-Dona Ana',
        SanJuanCollege: 'San Juan College',
        SantaFeCommunityCollege: 'Santa Fe Community College',
        SouthwesternIndianPolytechnicInstitute: 'Southwestern Indian Polytechnic Institute',
        AdirondackCommunityCollege: 'Adirondack Community College',
        AmericanAcademyofDramaticArtsNewYork: 'American Academy of Dramatic Arts-New York',
        ASACollege: 'ASA College',
        CayugaCountyCommunityCollege: 'Cayuga County Community College',
        ClintonCommunityCollege: 'Clinton Community College',
        ColumbiaGreeneCommunityCollege: 'Columbia-Greene Community College',
        CUNYBoroughofManhattanCommunityCollege: 'CUNY Borough of Manhattan Community College',
        CUNYBronxCommunityCollege: 'CUNY Bronx Community College',
        CUNYHostosCommunityCollege: 'CUNY Hostos Community College',
        CUNYKingsboroughCommunityCollege: 'CUNY Kingsborough Community College',
        CUNYLaGuardiaCommunityCollege: 'CUNY LaGuardia Community College',
        CUNYQueensboroughCommunityCollege: 'CUNY Queensborough Community College',
        CUNYBaruchCollege: 'CUNY Baruch College',
        CUNYQueensCollege: 'CUNY Queens College',
        CUNYCityTech: 'CUNY City Tech',
        CUNYCityCollegeofNY: 'CUNY City College of NY',
        CUNYBrooklynCollege: 'CUNY Brooklyn College',
        CUNYMedgarEversCollege: 'CUNY Medgar Evers College',
        CUNYCollegeofStatenIsland: 'CUNY College of Staten Island',
        CUNYMacaulyHonorsCollege: 'CUNY Macauly Honors College',
        DutchessCommunityCollege: 'Dutchess Community College',
        FingerLakesCommunityCollege: 'Finger Lakes Community College',
        FultonMontgomeryCommunityCollege: 'Fulton-Montgomery Community College',
        GeneseeCommunityCollege: 'Genesee Community College',
        HerkimerCountyCommunityCollege: 'Herkimer County Community College',
        HudsonValleyCommunityCollege: 'Hudson Valley Community College',
        JamestownCommunityCollege: 'Jamestown Community College',
        JeffersonCommunityCollege: 'Jefferson Community College',
        MohawkValleyCommunityCollege: 'Mohawk Valley Community College',
        MonroeCommunityCollege: 'Monroe Community College',
        NassauCommunityCollege: 'Nassau Community College',
        NiagaraCountyCommunityCollege: 'Niagara County Community College',
        NorthCountryCommunityCollege: 'North Country Community College',
        OnondagaCommunityCollege: 'Onondaga Community College',
        OrangeCountyCommunityCollege: 'Orange County Community College',
        RocklandCommunityCollege: 'Rockland Community College',
        SaintElizabethCollegeofNursing: 'Saint Elizabeth College of Nursing',
        SchenectadyCountyCommunityCollege: 'Schenectady County Community College',
        SuffolkCountyCommunityCollege: 'Suffolk County Community College',
        SullivanCountyCommunityCollege: 'Sullivan County Community College',
        SUNYBroomeCommunityCollege: 'SUNY Broome Community College',
        SUNYWestchesterCommunityCollege: 'SUNY Westchester Community College',
        TechnicalCareerInstitutes: 'Technical Career Institutes',
        TompkinsCortlandCommunityCollege: 'Tompkins Cortland Community College',
        UlsterCountyCommunityCollege: 'Ulster County Community College',
        AlamanceCommunityCollege: 'Alamance Community College',
        AshevilleBuncombeTechnicalCommunityCollege: 'Asheville-Buncombe Technical Community College',
        BeaufortCountyCommunityCollege: 'Beaufort County Community College',
        BladenCommunityCollege: 'Bladen Community College',
        BlueRidgeCommunityCollege: 'Blue Ridge Community College',
        BrunswickCommunityCollege: 'Brunswick Community College',
        CaldwellCommunityCollegeandTechnicalInstitute: 'Caldwell Community College and Technical Institute',
        CapeFearCommunityCollege: 'Cape Fear Community College',
        CarolinasCollegeofHealthSciences: 'Carolinas College of Health Sciences',
        CarteretCommunityCollege: 'Carteret Community College',
        CatawbaValleyCommunityCollege: 'Catawba Valley Community College',
        CentralCarolinaCommunityCollege: 'Central Carolina Community College',
        CentralPiedmontCommunityCollege: 'Central Piedmont Community College',
        ClevelandCommunityCollege: 'Cleveland Community College',
        CoastalCarolinaCommunityCollege: 'Coastal Carolina Community College',
        CollegeoftheAlbemarle: 'College of the Albemarle',
        CravenCommunityCollege: 'Craven Community College',
        DavidsonCountyCommunityCollege: 'Davidson County Community College',
        DurhamTechnicalCommunityCollege: 'Durham Technical Community College',
        EdgecombeCommunityCollege: 'Edgecombe Community College',
        FayettevilleTechnicalCommunityCollege: 'Fayetteville Technical Community College',
        ForsythTechnicalCommunityCollege: 'Forsyth Technical Community College',
        GastonCollege: 'Gaston College',
        GuilfordTechnicalCommunityCollege: 'Guilford Technical Community College',
        HalifaxCommunityCollege: 'Halifax Community College',
        HaywoodCommunityCollege: 'Haywood Community College',
        IsothermalCommunityCollege: 'Isothermal Community College',
        JamesSpruntCommunityCollege: 'James Sprunt Community College',
        JohnstonCommunityCollege: 'Johnston Community College',
        LouisburgCollege: 'Louisburg College',
        MartinCommunityCollege: 'Martin Community College',
        MaylandCommunityCollege: 'Mayland Community College',
        McDowellTechnicalCommunityCollege: 'McDowell Technical Community College',
        MitchellCommunityCollege: 'Mitchell Community College',
        MontgomeryCommunityCollege: 'Montgomery Community College',
        NashCommunityCollege: 'Nash Community College',
        PamlicoCommunityCollege: 'Pamlico Community College',
        PiedmontCommunityCollege: 'Piedmont Community College',
        PittCommunityCollege: 'Pitt Community College',
        RandolphCommunityCollege: 'Randolph Community College',
        RichmondCommunityCollege: 'Richmond Community College',
        RoanokeChowanCommunityCollege: 'Roanoke-Chowan Community College',
        RobesonCommunityCollege: 'Robeson Community College',
        RockinghamCommunityCollege: 'Rockingham Community College',
        RowanCabarrusCommunityCollege: 'Rowan-Cabarrus Community College',
        SampsonCommunityCollege: 'Sampson Community College',
        SandhillsCommunityCollege: 'Sandhills Community College',
        SouthPiedmontCommunityCollege: 'South Piedmont Community College',
        StanlyCommunityCollege: 'Stanly Community College',
        SurryCommunityCollege: 'Surry Community College',
        TriCountyCommunityCollege: 'Tri-County Community College',
        WakeTechnicalCommunityCollege: 'Wake Technical Community College',
        WayneCommunityCollege: 'Wayne Community College',
        WesternPiedmontCommunityCollege: 'Western Piedmont Community College',
        WilkesCommunityCollege: 'Wilkes Community College',
        WilsonCommunityCollege: 'Wilson Community College',
        CankdeskaCikanaCommunityCollege: 'Cankdeska Cikana Community College',
        DakotaCollegeatBottineau: 'Dakota College at Bottineau',
        LakeRegionStateCollege: 'Lake Region State College',
        NorthDakotaStateCollegeofScience: 'North Dakota State College of Science',
        WillistonStateCollege: 'Williston State College',
        BelmontCollege: 'Belmont College',
        CentralOhioTechnicalCollege: 'Central Ohio Technical College',
        ChatfieldCollege: 'Chatfield College',
        CincinnatiStateTechnicalandCommunityCollege: 'Cincinnati State Technical and Community College',
        ClarkStateCommunityCollege: 'Clark State Community College',
        ColumbusStateCommunityCollege: 'Columbus State Community College',
        CuyahogaCommunityCollegeDistrict: 'Cuyahoga Community College District',
        DavisCollege: 'Davis College',
        EasternGatewayCommunityCollege: 'Eastern Gateway Community College',
        EdisonStateCommunityCollege: 'Edison State Community College',
        HockingCollege: 'Hocking College',
        JamesARhodesStateCollege: 'James A Rhodes State College',
        LakelandCommunityCollege: 'Lakeland Community College',
        LorainCountyCommunityCollege: 'Lorain County Community College',
        MarionTechnicalCollege: 'Marion Technical College',
        NorthCentralStateCollege: 'North Central State College',
        NorthwestStateCommunityCollege: 'Northwest State Community College',
        OhioStateUniversityAgriculturalTechnicalInstitute: 'Ohio State University Agricultural Technical Institute',
        OwensCommunityCollege: 'Owens Community College',
        SouthernStateCommunityCollege: 'Southern State Community College',
        StarkStateCollege: 'Stark State College',
        TerraStateCommunityCollege: 'Terra State Community College',
        UniversityofAkronWayneCollege: 'University of Akron Wayne College',
        WashingtonStateCommunityCollege: 'Washington State Community College',
        ZaneStateCollege: 'Zane State College',
        CarlAlbertStateCollege: 'Carl Albert State College',
        ConnorsStateCollege: 'Connors State College',
        EasternOklahomaStateCollege: 'Eastern Oklahoma State College',
        MurrayStateCollege: 'Murray State College',
        NortheasternOklahomaAMCollege: 'Northeastern Oklahoma A&M College',
        NorthernOklahomaCollege: 'Northern Oklahoma College',
        OklahomaCityCommunityCollege: 'Oklahoma City Community College',
        RedlandsCommunityCollege: 'Redlands Community College',
        RoseStateCollege: 'Rose State College',
        SeminoleStateCollege: 'Seminole State College',
        TulsaCommunityCollege: 'Tulsa Community College',
        WesternOklahomaStateCollege: 'Western Oklahoma State College',
        BlueMountainCommunityCollege: 'Blue Mountain Community College',
        CentralOregonCommunityCollege: 'Central Oregon Community College',
        ChemeketaCommunityCollege: 'Chemeketa Community College',
        ClackamasCommunityCollege: 'Clackamas Community College',
        ClatsopCommunityCollege: 'Clatsop Community College',
        ColumbiaGorgeCommunityCollege: 'Columbia Gorge Community College',
        KlamathCommunityCollege: 'Klamath Community College',
        LaneCommunityCollege: 'Lane Community College',
        LinnBentonCommunityCollege: 'Linn-Benton Community College',
        MtHoodCommunityCollege: 'Mt. Hood Community College',
        RogueCommunityCollege: 'Rogue Community College',
        SouthwesternOregonCommunityCollege: 'Southwestern Oregon Community College',
        TillamookBayCommunityCollege: 'Tillamook Bay Community College',
        TreasureValleyCommunityCollege: 'Treasure Valley Community College',
        UmpquaCommunityCollege: 'Umpqua Community College',
        BucksCountyCommunityCollege: 'Bucks County Community College',
        ButlerCountyCommunityCollege: 'Butler County Community College',
        CommunityCollegeofAlleghenyCounty: 'Community College of Allegheny County',
        CommunityCollegeofBeaverCounty: 'Community College of Beaver County',
        CommunityCollegeofPhiladelphia: 'Community College of Philadelphia',
        DelawareCountyCommunityCollege: 'Delaware County Community College',
        HarcumCollege: 'Harcum College',
        HarrisburgAreaCommunityCollegeHarrisburg: 'Harrisburg Area Community College-Harrisburg',
        LackawannaCollege: 'Lackawanna College',
        LehighCarbonCommunityCollege: 'Lehigh Carbon Community College',
        LuzerneCountyCommunityCollege: 'Luzerne County Community College',
        ManorCollege: 'Manor College',
        MontgomeryCountyCommunityCollege: 'Montgomery County Community College',
        PennsylvaniaHighlandsCommunityCollege: 'Pennsylvania Highlands Community College',
        PennsylvaniaInstituteofTechnology: 'Pennsylvania Institute of Technology',
        PittsburghTechnicalInstitute: 'Pittsburgh Technical Institute',
        ReadingAreaCommunityCollege: 'Reading Area Community College',
        ThaddeusStevensCollegeofTechnology: 'Thaddeus Stevens College of Technology',
        UniversityofPittsburghTitusville: 'University of Pittsburgh-Titusville',
        ValleyForgeMilitaryAcademyandCollege: 'Valley Forge Military Academy and College',
        WestmorelandCountyCommunityCollege: 'Westmoreland County Community College',
        CommunityCollegeofRhodeIsland: 'Community College of Rhode Island',
        CentralCarolinaTechnicalCollege: 'Central Carolina Technical College',
        DenmarkTechnicalCollege: 'Denmark Technical College',
        FlorenceDarlingtonTechnicalCollege: 'Florence-Darlington Technical College',
        GreenvilleTechnicalCollege: 'Greenville Technical College',
        HorryGeorgetownTechnicalCollege: 'Horry-Georgetown Technical College',
        MidlandsTechnicalCollege: 'Midlands Technical College',
        NortheasternTechnicalCollege: 'Northeastern Technical College',
        OrangeburgCalhounTechnicalCollege: 'Orangeburg Calhoun Technical College',
        PiedmontTechnicalCollege: 'Piedmont Technical College',
        SpartanburgCommunityCollege: 'Spartanburg Community College',
        SpartanburgMethodistCollege: 'Spartanburg Methodist College',
        TechnicalCollegeoftheLowcountry: 'Technical College of the Lowcountry',
        TriCountyTechnicalCollege: 'Tri-County Technical College',
        TridentTechnicalCollege: 'Trident Technical College',
        WilliamsburgTechnicalCollege: 'Williamsburg Technical College',
        YorkTechnicalCollege: 'York Technical College',
        KilianCommunityCollege: 'Kilian Community College',
        LakeAreaTechnicalInstitute: 'Lake Area Technical Institute',
        MitchellTechnicalInstitute: 'Mitchell Technical Institute',
        SissetonWahpetonCollege: 'Sisseton Wahpeton College',
        SoutheastTechnicalInstitute: 'Southeast Technical Institute',
        WesternDakotaTechnicalInstitute: 'Western Dakota Technical Institute',
        ChattanoogaStateCommunityCollege: 'Chattanooga State Community College',
        ClevelandStateCommunityCollege: 'Cleveland State Community College',
        ColumbiaStateCommunityCollege: 'Columbia State Community College',
        DyersburgStateCommunityCollege: 'Dyersburg State Community College',
        JacksonStateCommunityCollege: 'Jackson State Community College',
        JohnAGuptonCollege: 'John A Gupton College',
        MotlowStateCommunityCollege: 'Motlow State Community College',
        NortheastStateCommunityCollege: 'Northeast State Community College',
        PellissippiStateCommunityCollege: 'Pellissippi State Community College',
        RoaneStateCommunityCollege: 'Roane State Community College',
        SouthwestTennesseeCommunityCollege: 'Southwest Tennessee Community College',
        VolunteerStateCommunityCollege: 'Volunteer State Community College',
        WaltersStateCommunityCollege: 'Walters State Community College',
        AlvinCommunityCollege: 'Alvin Community College',
        AmarilloCollege: 'Amarillo College',
        AngelinaCollege: 'Angelina College',
        BlinnCollege: 'Blinn College',
        BrookhavenCollege: 'Brookhaven College',
        CedarValleyCollege: 'Cedar Valley College',
        CentralTexasCollege: 'Central Texas College',
        SchreinerUniversity: 'Schreiner University',
        CiscoCollege: 'Cisco College',
        ClarendonCollege: 'Clarendon College',
        CoastalBendCollege: 'Coastal Bend College',
        CollegeoftheMainland: 'College of the Mainland',
        CollinCountyCommunityCollegeDistrict: 'Collin County Community College District',
        DelMarCollege: 'Del Mar College',
        EastfieldCollege: 'Eastfield College',
        ElCentroCollege: 'El Centro College',
        ElPasoCommunityCollege: 'El Paso Community College',
        FrankPhillipsCollege: 'Frank Phillips College',
        GalvestonCollege: 'Galveston College',
        GraysonCollege: 'Grayson College',
        HillCollege: 'Hill College',
        HoustonCommunityCollege: 'Houston Community College',
        HowardCollege: 'Howard College',
        JacksonvilleCollegeMainCampus: 'Jacksonville College-Main Campus',
        KilgoreCollege: 'Kilgore College',
        LamarInstituteofTechnology: 'Lamar Institute of Technology',
        LamarStateCollegeOrange: 'Lamar State College-Orange',
        LamarStateCollegePortArthur: 'Lamar State College-Port Arthur',
        LaredoCommunityCollege: 'Laredo Community College',
        LeeCollege: 'Lee College',
        LoneStarCollegeSystem: 'Lone Star College System',
        McLennanCommunityCollege: 'McLennan Community College',
        NavarroCollege: 'Navarro College',
        NorthCentralTexasCollege: 'North Central Texas College',
        NortheastTexasCommunityCollege: 'Northeast Texas Community College',
        NorthLakeCollege: 'North Lake College',
        OdessaCollege: 'Odessa College',
        AlamoCollegesDistrict: 'Alamo Colleges District',
        PanolaCollege: 'Panola College',
        ParisJuniorCollege: 'Paris Junior College',
        RangerCollege: 'Ranger College',
        RichlandCollege: 'Richland College',
        SouthPlainsCollege: 'South Plains College',
        SouthwestTexasJuniorCollege: 'Southwest Texas Junior College',
        TempleCollege: 'Temple College',
        TexarkanaCollege: 'Texarkana College',
        TexasStateTechnicalCollegeHarlingen: 'Texas State Technical College-Harlingen',
        TexasStateTechnicalCollegeMarshall: 'Texas State Technical College-Marshall',
        TexasStateTechnicalCollegeWaco: 'Texas State Technical College-Waco',
        TexasStateTechnicalCollegeWestTexas: 'Texas State Technical College-West Texas',
        TrinityValleyCommunityCollege: 'Trinity Valley Community College',
        TylerJuniorCollege: 'Tyler Junior College',
        VernonCollege: 'Vernon College',
        VictoriaCollege: 'Victoria College',
        WeatherfordCollege: 'Weatherford College',
        WesternTexasCollege: 'Western Texas College',
        WhartonCountyJuniorCollege: 'Wharton County Junior College',
        LatterdaySaintsBusinessCollege: 'Latter-day Saints Business College',
        SaltLakeCommunityCollege: 'Salt Lake Community College',
        CommunityCollegeofVermont: 'Community College of Vermont',
        CentralVirginiaCommunityCollege: 'Central Virginia Community College',
        DabneySLancasterCommunityCollege: 'Dabney S Lancaster Community College',
        DanvilleCommunityCollege: 'Danville Community College',
        EasternShoreCommunityCollege: 'Eastern Shore Community College',
        GermannaCommunityCollege: 'Germanna Community College',
        JohnTylerCommunityCollege: 'John Tyler Community College',
        JSargeantReynoldsCommunityCollege: 'J Sargeant Reynolds Community College',
        LordFairfaxCommunityCollege: 'Lord Fairfax Community College',
        MountainEmpireCommunityCollege: 'Mountain Empire Community College',
        NewRiverCommunityCollege: 'New River Community College',
        NorthernVirginiaCommunityCollege: 'Northern Virginia Community College',
        PatrickHenryCommunityCollege: 'Patrick Henry Community College',
        PaulDCampCommunityCollege: 'Paul D Camp Community College',
        PiedmontVirginiaCommunityCollege: 'Piedmont Virginia Community College',
        RappahannockCommunityCollege: 'Rappahannock Community College',
        RichardBlandCollegeoftheCollegeofWilliamandMary: 'Richard Bland College of the College of William and Mary',
        SouthsideVirginiaCommunityCollege: 'Southside Virginia Community College',
        SouthwestVirginiaCommunityCollege: 'Southwest Virginia Community College',
        ThomasNelsonCommunityCollege: 'Thomas Nelson Community College',
        TidewaterCommunityCollege: 'Tidewater Community College',
        VirginiaHighlandsCommunityCollege: 'Virginia Highlands Community College',
        VirginiaWesternCommunityCollege: 'Virginia Western Community College',
        WythevilleCommunityCollege: 'Wytheville Community College',
        BatesTechnicalCollege: 'Bates Technical College',
        BellinghamTechnicalCollege: 'Bellingham Technical College',
        BigBendCommunityCollege: 'Big Bend Community College',
        CascadiaCommunityCollege: 'Cascadia Community College',
        ClarkCollege: 'Clark College',
        CloverParkTechnicalCollege: 'Clover Park Technical College',
        EdmondsCommunityCollege: 'Edmonds Community College',
        EverettCommunityCollege: 'Everett Community College',
        GraysHarborCollege: 'Grays Harbor College',
        GreenRiverCommunityCollege: 'Green River Community College',
        HighlineCommunityCollege: 'Highline Community College',
        LowerColumbiaCollege: 'Lower Columbia College',
        PierceCollegeatPuyallup: 'Pierce College at Puyallup',
        RentonTechnicalCollege: 'Renton Technical College',
        ShorelineCommunityCollege: 'Shoreline Community College',
        SkagitValleyCollege: 'Skagit Valley College',
        SouthPugetSoundCommunityCollege: 'South Puget Sound Community College',
        SpokaneCommunityCollege: 'Spokane Community College',
        SpokaneFallsCommunityCollege: 'Spokane Falls Community College',
        TacomaCommunityCollege: 'Tacoma Community College',
        WallaWallaCommunityCollege: 'Walla Walla Community College',
        WenatcheeValleyCollege: 'Wenatchee Valley College',
        WhatcomCommunityCollege: 'Whatcom Community College',
        YakimaValleyCommunityCollege: 'Yakima Valley Community College',
        BlueRidgeCommunityandTechnicalCollege: 'Blue Ridge Community and Technical College',
        BridgemontCommunityandTechnicalCollege: 'Bridgemont Community and Technical College',
        EasternWestVirginiaCommunityandTechnicalCollege: 'Eastern West Virginia Community and Technical College',
        HuntingtonJuniorCollege: 'Huntington Junior College',
        KanawhaValleyCommunityandTechnicalCollege: 'Kanawha Valley Community and Technical College',
        MountwestCommunityandTechnicalCollege: 'Mountwest Community and Technical College',
        NewRiverCommunityandTechnicalCollege: 'New River Community and Technical College',
        PierpontCommunityandTechnicalCollege: 'Pierpont Community and Technical College',
        SouthernWestVirginiaCommunityandTechnicalCollege: 'Southern West Virginia Community and Technical College',
        WestVirginiaNorthernCommunityCollege: 'West Virginia Northern Community College',
        BlackhawkTechnicalCollege: 'Blackhawk Technical College',
        ChippewaValleyTechnicalCollege: 'Chippewa Valley Technical College',
        FoxValleyTechnicalCollege: 'Fox Valley Technical College',
        GatewayTechnicalCollege: 'Gateway Technical College',
        LacCourteOreillesOjibwaCommunityCollege: 'Lac Courte Oreilles Ojibwa Community College',
        LakeshoreTechnicalCollege: 'Lakeshore Technical College',
        MadisonAreaTechnicalCollege: 'Madison Area Technical College',
        MidStateTechnicalCollege: 'Mid-State Technical College',
        MilwaukeeAreaTechnicalCollege: 'Milwaukee Area Technical College',
        MoraineParkTechnicalCollege: 'Moraine Park Technical College',
        NicoletAreaTechnicalCollege: 'Nicolet Area Technical College',
        NorthcentralTechnicalCollege: 'Northcentral Technical College',
        NortheastWisconsinTechnicalCollege: 'Northeast Wisconsin Technical College',
        SouthwestWisconsinTechnicalCollege: 'Southwest Wisconsin Technical College',
        UniversityofWisconsinColleges: 'University of Wisconsin Colleges',
        WaukeshaCountyTechnicalCollege: 'Waukesha County Technical College',
        WesternTechnicalCollege: 'Western Technical College',
        WisconsinIndianheadTechnicalCollege: 'Wisconsin Indianhead Technical College',
        CasperCollege: 'Casper College',
        CentralWyomingCollege: 'Central Wyoming College',
        EasternWyomingCollege: 'Eastern Wyoming College',
        LaramieCountyCommunityCollege: 'Laramie County Community College',
        NorthwestCollege: 'Northwest College',
        SheridanCollege: 'Sheridan College',
        WesternWyomingCommunityCollege: 'Western Wyoming Community College',
        TrinityBibleCollegeGraduateSchool: 'Trinity Bible College & Graduate School',
        UniversityofMichiganFlint: 'University of Michigan-Flint',
        KenaiPeninsulaCollege: 'Kenai Peninsula College',
        MatSuCollege: 'Mat-Su College',
        KodiakCollege: 'Kodiak College',
        AlaskaPacificUniversity: 'Alaska Pacific University',
        WaylandBaptistUniversity: 'Wayland Baptist University',
        PolkStateCollege: 'Polk State College',
        BresciaUniversity: 'Brescia University',
        RogersStateUniversity: 'Rogers State University',
        NeumannUniversity: 'Neumann University',
        SavannahCollegeofArtandDesign: 'Savannah College of Art and Design',
        AshfordUniversity: 'Ashford University',
        McPhersonCollege: 'McPherson College',
        CommunityCollegeSystemofNewHampshire: 'Community College System of New Hampshire',
        DefianceCollege: 'Defiance College',
        MedicalUniversityofSouthCarolina: 'Medical University of South Carolina',
        GulfCoastStateCollege: 'Gulf Coast State College',
        AcademyofArtUniversity: 'Academy of Art University',
        LenoirRhyneUniversity: 'Lenoir-Rhyne University',
        PointParkUniversity: 'Point Park University',
        NorthwoodUniversity: 'Northwood University',
        ClarkAtlantaUniversity: 'Clark Atlanta University',
        Morehouse: 'Morehouse',
        UniversityofStAugustineforHealthSciences: 'University of St. Augustine for Health Sciences',
        GwyneddMercyUniversity: 'Gwynedd Mercy University',
        CaliforniaInstituteoftheArts: 'California Institute of the Arts',
        DesMoinesUniversity: 'Des Moines University',
        UtahTechUniversity: 'Utah Tech University',
        MidwesternUniversity: 'Midwestern University',
        IllinoisWesleyanUniversity: 'Illinois Wesleyan University',
        MaryvilleCollege: 'Maryville College',
        EndicottCollege: 'Endicott College',
        TheUniversityofAlabama: 'The University of Alabama',
        GreatBasinCollege: 'Great Basin College',
        Einsteinmed: 'Einsteinmed',
        MiamiDadeCollege: 'Miami Dade College',
        FloridaSouthWesternStateCollege: 'Florida SouthWestern State College',
        BishopMcNamaraHighSchool: 'Bishop McNamara High School',
        ChaminadeUniversityofHonolulu: 'Chaminade University of Honolulu',
        DaltonStateCollege: 'Dalton State College',
        OjaiUnifiedSchoolDistrict: 'Ojai Unified School District',
        BismarkStateCollege: 'Bismark State College',
        QuadratAcademy: 'Quadrat Academy',
        UniversityofNorthAlabama: 'University of North Alabama',
        BellevueCollege: 'Bellevue College',
        CUNYLehmanCollege: 'CUNY Lehman College',
        CUNYJohnJayCollegeofCriminalJustice: 'CUNY John Jay College of Criminal Justice',
        HunterCollegeCUNY: 'Hunter College CUNY',
        CUNYYorkCollege: 'CUNY York College',
        PennsylvaniaStateUniversityHarrisburg: 'Pennsylvania State University - Harrisburg',
        AlcornStateUniversity: 'Alcorn State University',
        AllenUniversity: 'Allen University',
        AmericanBaptistCollege: 'American Baptist College',
        ArkansasatPineBluffUniversityof: 'Arkansas at Pine Bluff University of',
        ArkansasBaptistCollege: 'Arkansas Baptist College',
        BarberScotiaCollege: 'Barber-Scotia College',
        BenedictCollege: 'Benedict College',
        BennettCollege: 'Bennett College',
        BethuneCookmanUniversity: 'Bethune-Cookman University',
        BluefieldStateCollege: 'Bluefield State College',
        CarverCollege: 'Carver College',
        CentralStateUniversity: 'Central State University',
        ClaflinUniversity: 'Claflin University',
        ClintonCollege: 'Clinton College',
        EdwardWatersUniversity: 'Edward Waters University',
        FloridaMemorialUniversity: 'Florida Memorial University',
        FortValleyStateUniversity: 'Fort Valley State University',
        GasdenStateCommunityCollege: 'Gasden State Community College',
        GramblingStateUniversity: 'Grambling State University',
        HarrisStoweStateUniversity: 'Harris-Stowe State University',
        HoodTheological: 'Hood Theological',
        HustonTillotsonUniversity: 'Huston-Tillotson University',
        InterdenominationalTheologicalCenter: 'Interdenominational Theological Center',
        JarvisChristianCollege: 'Jarvis Christian College',
        JohnsonCSmithTheologicalSeminary: 'Johnson C Smith Theological Seminary *',
        KentuckyStateUniversity: 'Kentucky State University',
        KnoxvilleCollege: 'Knoxville College',
        LaneCollege: 'Lane College',
        LawsonStateCommunityCollege: 'Lawson State Community College',
        LeMoyneOwenCollege: 'LeMoyne-Owen College',
        LivingstoneCollege: 'Livingstone College',
        MeharryMedicalCollege: 'Meharry Medical College',
        MilesCollege: 'Miles College',
        MilesSchoolofLaw: 'Miles School of Law',
        MorehouseSchoolofMedicine: 'Morehouse School of Medicine',
        MorrisBrownCollege: 'Morris Brown College',
        MorrisCollege: 'Morris College',
        PaineCollege: 'Paine College',
        PaulQuinnCollege: 'Paul Quinn College',
        PhilanderSmithCollege: 'Philander Smith College',
        RustCollege: 'Rust College',
        SavannahStateUniversity: 'Savannah State University',
        SelmaUniversity: 'Selma University',
        ShawUniversity: 'Shaw University',
        SimmonsCollegeofKentucky: 'Simmons College of Kentucky',
        SouthCarolinaStateUniversity: 'South Carolina State University',
        SouthernUniversityatNewOrleans: 'Southern University at New Orleans',
        SouthwesternChristianCollege: 'Southwestern Christian College',
        StAugustinesUniversity: "St. Augustine's University",
        StillmanCollege: 'Stillman College',
        TalladegaCollege: 'Talladega College',
        TexasCollege: 'Texas College',
        TougalooCollege: 'Tougaloo College',
        VirginiaUnionUniversity: 'Virginia Union University',
        VirginiaUniversityofLynchburg: 'Virginia University of Lynchburg',
        VoorheesUniversity: 'Voorhees University',
        WestVirginiaStateUniversity: 'West Virginia State University',
        WileyCollege: 'Wiley College',
        WinstonSalemStateUniversity: 'Winston-Salem State University',
        UnityEnvironmentalCollege: 'Unity Environmental College',
        CatawbaCollege: 'Catawba College',
        TexasAMUniversityTexarkana: 'Texas A&M University-Texarkana',
        BelhavenUniversity: 'Belhaven University',
        BerryCollege: 'Berry College',
        BloomfieldCollege: 'Bloomfield College',
        BridgewaterStateUniversity: 'Bridgewater State University',
        CabriniUniversity: 'Cabrini University',
        CaldwellUniversity: 'Caldwell University',
        CovenantCollege: 'Covenant College',
        ChicagoStateUniversity: 'Chicago State University',
        CaliforniaStateUniversity: 'California State University',
        ColoradoStateUniversityPueblo: 'Colorado State University-Pueblo',
        BayAtlanticUniversity: 'Bay Atlantic University',
        SienaCollege: 'Siena College',
        TexasWesleyanUniversity: 'Texas Wesleyan University',
        IslandDraftingTechnicalInstitute: 'Island Drafting & Technical Institute',
        BayPathUniversity: 'Bay Path University',
        SnowCollege: 'Snow College',
        StateCenterCommunityCollegeDistrict: 'State Center Community College District',
        DavidsonDavieCommunityCollege: 'Davidson-Davie Community College',
        BerkeleyCollege: 'Berkeley College',
        SchillerInternationalUniversity: 'Schiller International University',
        GreensboroCollege: 'Greensboro College',
        VerdeValleySchool: 'Verde Valley School',
        OglethorpeUniversity: 'Oglethorpe University',
        DetroitSchoolofTechnology: 'Detroit School of Technology',
        NorthCentralCollege: 'North Central College',
        TheUniversityofMontanaWestern: 'The University of Montana Western',
        QueensUniversityofCharlotte: 'Queens University of Charlotte',
        LincolnMemorialUniversity: 'Lincoln Memorial University',
        GreenvilleUniversity: 'Greenville University',
        StateUniversityofNewYorkatOneonta: 'State University of New York at Oneonta',
        NorthwesternMichiganCollege: 'Northwestern Michigan College',
        AdiranCollge: 'Adiran Collge',
        ConcordiaUniversityWisconsin: 'Concordia University Wisconsin',
        DelawareValleyUniversity: 'Delaware Valley University',
        EckerdCollege: 'Eckerd College',
        UniversityofSouthCarolinaUpstate: 'University of South Carolina Upstate',
        FairfieldUniversity: 'Fairfield University',
        FoothillDeAnzaCommunityCollegeDistrict: 'Foothill-De Anza Community College District',
        FrancisMarionUniversity: 'Francis Marion University',
        GeorgeFoxUniversity: 'George Fox University',
        GrandViewUniversity: 'Grand View University',
        AbrahamBaldwinAgriculturalCollege: 'Abraham Baldwin Agricultural College',
        CapitalUniversity: 'Capital University',
        StateUniversityofNewYorkCollegeatOneonta: 'State University of New York College at Oneonta',
        UniversityofMountOlive: 'University of Mount Olive',
        TheUniversityofOlivet: 'The University of Olivet',
        WestminsterUniversity: 'Westminster University',
        DominicanUniversity: 'Dominican University',
        ClaremontColleges: 'Claremont Colleges',
        RowanCollegeatBurlingtonCounty: 'Rowan College at Burlington County',
        AspenUniversity: 'Aspen University',
        CaliforniaUniversityofScienceandMedicine: 'California University of Science and Medicine',
        CUNYSchoolofLaw: 'CUNY School of Law',
        DoaneUniversity: 'Doane University',
        EasternVirginiaMedicalSchool: 'Eastern Virginia Medical School',
        ElmsCollege: 'Elms College',
        EstrellaMountainCommunityCollege: 'Estrella Mountain Community College',
        FloridaSouthernCollege: 'Florida Southern College',
        FranklinPierceUniversity: 'Franklin Pierce University',
        KansasCityUniversity: 'Kansas City University',
        LoyolaUniversityNewOrleans: 'Loyola University New Orleans',
        MarylandInstituteCollegeofArts: 'Maryland Institute College of Arts',
        MerrimackCollege: 'Merrimack College',
        NebraskaWesleyanUniversity: 'Nebraska Wesleyan University',
        NewYorkMedicalCollege: 'New York Medical College',
        PacificNorthwestUniversityofHealthSciences: 'Pacific Northwest University of Health Sciences',
        PurdueGlobal: 'Purdue Global',
        RowanCollegeofSouthJersey: 'Rowan College of South Jersey',
        SofiaUniversity: 'Sofia University',
        SUNYDownstateHealthSciencesUniversity: 'SUNY Downstate Health Sciences University',
        UniversityofSouthCarolinaBeaufort: 'University of South Carolina Beaufort',
        VermontLawSchool: 'Vermont Law School',
        WesternNevadaCollege: 'Western Nevada College',
        WesternUniversityofHealthSciences: 'Western University of Health Sciences',
        StMarysUniversity: "St. Mary's University",
        SouthFloridaStateCollege: 'South Florida State College',
    },
    CONSTANTS: {
        TIME_OF_DAY: {
            BEFORE_NOON: '07:00 - 12:00',
            NOON_TO_FIVE: '12:00 - 17:00',
            AFTER_FIVE: '17:00 - 22:00',
        },
        MONTHS_SHORT: {
            JAN: 'Jan',
            FEB: 'Feb',
            MAR: 'Mar',
            APR: 'Apr',
            MAY: 'May',
            JUN: 'Jun',
            JUL: 'Jul',
            AUG: 'Aug',
            SEP: 'Sep',
            OCT: 'Oct',
            NOV: 'Nov',
            DEC: 'Dec',
        },
        MONTHS_LONG: {
            JAN: 'January',
            FEB: 'February',
            MAR: 'March',
            APR: 'April',
            MAY: 'May',
            JUN: 'June',
            JUL: 'July',
            AUG: 'August',
            SEP: 'September',
            OCT: 'October',
            NOV: 'November',
            DEC: 'December',
        },
        DAYS_SHORT: {
            MON: 'Mon',
            TUE: 'Tue',
            WED: 'Wed',
            THU: 'Thu',
            FRI: 'Fri',
            SAT: 'Sat',
            SUN: 'Sun',
        },
        DAYS_LONG: {
            MON: 'Monday',
            TUE: 'Tuesday',
            WED: 'Wednesday',
            THU: 'Thursday',
            FRI: 'Friday',
            SAT: 'Saturday',
            SUN: 'Sunday',
            '1': 'Monday',
            '2': 'Tuesday',
            '3': 'Wednesday',
            '4': 'Thursday',
            '5': 'Friday',
            '6': 'Saturday',
            '7': 'Sunday',
        },
    },
    PATHS: {
        ADD_CHILD: '/en/add-child',
        FORGOT_PASSWORD: '/en/forgot-password',
        RESET_PASSWORD: '/en/reset-password',
        MY_BOOKINGS: '/en/my-bookings',
        SEARCH_TUTORS: '/en/search-tutors',
        SEARCH_TUTORS_TUTOR_PROFILE: '/en/profile/:tutorSlug',
        STUDENT_PROFILE: '/en/student-profile/:userId',
        PROFILE: '/en/search-tutors/profile/:tutorSlug',
        SEARCH_TUTORS_TUTOR_BOOKINGS: '/en/search-tutors/bookings/:tutorSlug',
        MY_REVIEWS: '/en/my-reviews',
        COMPLETED_LESSONS: '/en/completed-lessons',
        CHAT: '/en/chat',
        DASHBOARD: '/en/dashboard',
        NOTIFICATIONS: '/en/dashboard/notifications',
        EARNINGS: '/en/earnings',
        TERMS: '/en/terms',
        PRIVACY: '/en/privacy-policy',
        TUTOR_MANAGMENT: '/en/tutor-managment',
        STUDENT_MANAGEMENT: '/en/student-management',
        BOOKING_MANAGEMENT: '/en/all-bookings',
        TUTOR_MANAGMENT_TUTOR_PROFILE: '/en/tutor-managment/profile/:tutorSlug',
        ONBOARDING_PATHS: {
            ONBOARDING: '/en/onboarding',
            TUTOR_ONBOARDING: '/en/onboarding/tutor',
            STUDENT_ONBOARDING: '/en/onboarding/student',
            PARENT_ONBOARDING: '/en/onboarding/parent',
        },
        LANDING_PATHS: {
            HOW_IT_WORKS: '/en/online-tutoring',
            BECOME_TUTOR: '/en/become-tutor',
            PRICING: '/en/pricing',
            TERMS: '/en/terms',
            PRIVACY: '/en/privacy-policy',
        },
        PROFILE_PATHS: {
            MY_PROFILE: '/en/my-profile',
            MY_PROFILE_INFO: '/en/my-profile/info',
            MY_PROFILE_INFO_PERSONAL: '/en/my-profile/info/personal',
            MY_PROFILE_INFO_AVAILABILITY: '/en/my-profile/info/availability',
            MY_PROFILE_INFO_TEACHINGS: '/en/my-profile/info/teachings',
            MY_PROFILE_INFO_ADDITIONAL: '/en/my-profile/info/additional',
            MY_PROFILE_ACCOUNT: '/en/my-profile/account',
            MY_PROFILE_CHILD_INFO: '/en/my-profile/children',
        },
        TOKEN_NOT_VALID: '/en/invalid-token',
        STRIPE_CONNECTED: '/en/stripe-connected',
        STRIPE_FAIL: '/en/stripe-failed',
        RESEND_ACTIVATION_TOKEN: '/en/reset-token',
        TUTOR_VIDEOS: '/en/tutor-previews',
    },
    SEO: {
        FALLBACK: {
            TITLE: 'Teorem',
            META: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
        },
        HOW_IT_WORKS: {
            TITLE: 'Tailored 1-to-1 online tutoring & homework help - Teorem',
            META: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
        },
        PRICING: {
            TITLE: 'High quality online tutoring prices | Teorem',
            META: 'Prices for 1-to-1 50-minute lessons start at $12.50, with the average price of $25.40, depending on the tutor and learning plan. Get more pricing info by contacting a tutor.',
        },
        BECOME_TUTOR: {
            TITLE: 'Become an online tutor | Teorem',
            META: 'How to become an online tutor with Teorem - 1. Register to become a tutor - 2. Complete your subject quiz - 3. Prove your identity - 4. Start tutoring',
        },
        TERMS: {
            TITLE: 'Terms & Conditions | Teorem',
            META: "Welcome to Teorem! These terms and conditions outline the rules and regulations for the use of Teorem's website.",
        },
        PRIVACY: {
            TITLE: 'Privacy Policy | Teorem',
            META: 'One of our main priorities at Teorem is the privacy of our visitors. This Privacy Policy  contains types of information that is collected and recorded by Teorem and how we use it.',
        },
    },
    LANGUAGE_MODAL: {
        WELCOME: 'Welcome to Teorem’s online tutoring platform!',
        ABOUT: 'Please select your country.',
    },
    TOKEN_EXPIRED: {
        RESEND_ACTIVATION_MESSAGE: 'RESEND_ACTIVATION_MESSAGE',
        WELCOME: 'WELCOME',
        DESCRIPTION: 'DESCRIPTION',
    },
    BOOKING: {
        SUCCESS: 'Booking successful!',
        FAILURE: 'Booking was not successful!',
        TOAST_CANT_BOOK: "You can't book a lesson at selected time!",
        CANT_BOOK_MESSAGE: 'Please book at least 3 hours in advance',
    },
    TUTOR_INTRO: {
        MODAL: {
            TITLE: 'Do you want to go through the tutorial?',
            BODY: 'The tutorial will briefly introduce you to the basic functionalities of Teorem',
            BUTTON_SKIP: 'Skip',
            BUTTON_START: 'Start',
        },
        DASHBOARD: {
            STEP1: {
                TITLE: 'Booking requests',
                BODY: 'Here you can see and manage your booking requests.',
            },
            STEP2: {
                TITLE: 'Joining classes',
                BODY: "You'll be able to start your lessons directly from your \"Today's Schedule\" (you can also join through your calendar). After clicking 'Finish' test meeting will automatically start!",
            },
        },
        BUTTON_NEXT: 'Next',
        BUTTON_PREVIOUS: 'Previous',
        BUTTON_FINISH: 'Finish',
        BUTTON_RESTART: 'Restart tutorial',
    },
    STUDENT_INTRO: {
        DASHBOARD: {
            STEP1: {
                TITLE: "Today's Schedule",
                BODY: 'Here you will see the lectures you have today and you will be able to connect to the lectures. (You can also see the lectures on the calendar)',
            },
            STEP2: {
                TITLE: 'Unread Messages',
                BODY: "Here you will see all the messages that your instructor sends you. (You can also see this on the 'Chat' tab)",
            },
            STEP3: {
                TITLE: 'Instructor Recommendations',
                BODY: 'Here are three instructors who most closely match your needs (depending on what you selected at registration). Using filters you can choose the subject, level, or time when the instructor is available,',
            },
        },
        BUTTON_NEXT: 'Next',
        BUTTON_PREVIOUS: 'Previous',
        BUTTON_FINISH: 'Finish',
        BUTTON_RESTART: 'Restart tutorial',
    },
    BOOKING_POPUP: {
        TITLE: 'Payment successful',
        DESCRIPTION:
            "Your tutor now has 24 hours to accept your booking. For the best learning experience, please share insights into the curriculum you'd like to focus on.",
        CURRICULUM_PLACEHOLDER: 'Which school curriculum do you need help with?',
        TEXTBOOK_PLACEHOLDER: 'Which textbook is used?',
        GRADE_PLACEHOLDER: 'Current grade',
        NOTES_PLACEHOLDER: 'Notes or special requirements',
        BUTTON: {
            COMPLETE: 'Complete',
            SKIP: 'Skip for now',
        },
    },
    CREDITS: {
        TITLE: 'Credits',
    },
    RESCHEDULE: {
        ACCEPT: 'Modification accepted',
        DENIED: 'Modification denied',
    },
    CHECKOUT: {
        PRICE: 'Price:',
        DISCOUNT: 'Discount:',
        TOTAL: 'Total:',
        AVAILABLE_CREDITS: 'Available credits:',
        NEW_CREDITS_BALANCE: 'New credits balance:',
    },
    ID_VERIFICATION: {
        ID_SECTION: 'Proof of identity',
        ID_SECTION_DESCRIPTION:
            'For the purposes of preventing money laundering and financing of terrorism, please confirm your identity.',
        BANNER: 'NOTICE: For the purposes of preventing money laundering and financing of terrorism, please confirm your identity to continue receiving payouts.',
        ACCOUNT_VERIFIED: 'Thank you! Your account is verified.',
        DOCUMENTS_PROVIDED: 'Thank you! Documentation submitted, waiting for verification.',
        DISABLED_PAYOUTS: {
            PART_1: 'Your payouts have been suspended. Please confirm your identity to continue receiving payouts!',
            SOON: 'Deadline: soon',
        },
        DAYS_REMAINING: {
            P_1: 'You have ',
            P_2: ' day(s) before your payouts are suspended. Please confirm your identity to avoid suspension of payouts.!',
        },
        FORM: {
            FRONT: 'Front side',
            BACK: 'Back side',
            TIP_1: 'Picture must be in color',
            TIP_2: 'Document must contain name used during registration',
            SUBMIT: 'Submit',
        },
    },

    VIDEO_PREVIEW: {
        TITLE: 'Video preview',
        NOT_APPROVED: 'The administrator has not yet approved your video (it will not be displayed on your profile)',
        TRANSCODING: 'Your video is transcoding. It usually takes several minutes...',
        RECORD_VIDEO: 'Record video',
        ADD_VIDEO: 'Add your video',
        OR: 'or',
        UPLOAD_VIDEO: 'Upload video',
        START: 'Start',
        BROWSE: 'Browse',
        RECORD: 'Record',
        DELETE: 'Delete',
        SUCCESS_TITLE: 'Success! Your video has been uploaded!',
        SUCCESS_DESCRIPTION:
            "We're adding some finishing touches. You can finish onboarding while we take care of the rest!",
        RECORD_MODAL: {
            TITLE: 'Record video',
            SETTINGS: 'Audio and Video Settings',
            CAMERA: 'Camera',
            MICROPHONE: 'Microphone',
            START: 'Start recording',
            STOP: 'Stop recording',
            RESTART: 'Re-record',
            UPLOAD: 'Upload',
            RECORDING_TIME: 'Recording time:',
            REMAINING_TIME: {
                PART_1: 'Recording will stop automatically in ',
                PART_2: ' s',
            },
            PERMISSIONS_ERROR_MESSAGE:
                'You have not allowed the use of the camera and microphone. You will have to allow them to be able to record the video and teach',
        },
        LOADING: {
            PREPARING: 'Preparing the upload...',
            UPLOADING: 'Uploading',
            SUCCESS: 'You have successfully uploaded the video',
        },
        CONFIRMATION: {
            TITLE: 'Are you sure you want to delete the video?',
            DESCRIPTION: 'This will delete your video, and you will have to record a new one!',
            CONFIRM: 'Delete',
            CANCEL: 'Cancel',
        },
        FILE_UPLOAD: {
            FORMAT: 'MP4 format',
            SIZE_MESSAGE: 'Maximum file size is 10MB!',
            DURATION_MESSAGE: 'Maximum length of video is 2 minutes!',
        },
        TIPS: {
            DO: {
                TITLE: 'Do:',
                LIST: {
                    TIP_1: 'Between 30 seconds and 2 minutes long',
                    TIP_2: 'Record in horizontal mode and at eye level',
                    TIP_3: 'Use good lighting and a neutral background',
                    TIP_4: 'Use a stable surface so that your video is not shaky',
                    TIP_5: 'Make sure your face and eyes are fully visible',
                    TIP_6: 'Highlight your teaching experience',
                    TIP_7: 'Greet your students warmly and invite them to book a lesson',
                },
            },
            DONT: {
                TITLE: "Don't:",
                LIST: {
                    TIP_1: 'Include your surname or any contact details',
                    TIP_2: 'Use logos or links',
                    TIP_3: 'Use slideshows or presentations',
                    TIP_4: 'Have any other people visible in your video',
                },
            },
        },
    },
    TUTOR_CARD: {
        LESSON: 'lesson',
    },
    COUNTRY: {
        PLACEHOLDER: 'Country',
        HR: 'Croatia',
        US: 'USA',
    },
    CURRENCY: {
        EUR: '€',
        USD: '$',
    },
    BANNER: {
        REVIEW: {
            PART_1: 'You recently had a lesson in ',
            PART_2: 'with tutor ',
            PART_3: '. Take a moment to share your experience by leaving a review.',
            TOOLTIP: 'Leaving a review helps others. It creates a more efficient, transparent marketplace.',
        },
        ADD_CHILD: {
            BUTTON: 'Add child',
        },
        VIDEO_PREVIEW: {
            TEXT: 'Add a video preview to your profile to boost your ranking and visibility to parents. Video previews will become mandatory to keep your tutor profile from June 30th 2024.',
            BUTTON: 'Add video',
        },
    },
};
